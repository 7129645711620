import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import './SupplierNormalization.scss';
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import {Chip, Grid, Typography} from "@mui/material";
import {useRouteMatch} from "react-router-dom";
import {useStores} from "../../stores";
import {Bag} from "../../services/classes/Bag";
import {KoiFacade} from "../../components/koi-facade/KoiFacade";
import {SupplierNormalizationWelcomeStep} from "./pages/SupplierNormalizationWelcomeStep";
import {environment} from "../../env";
import {SupplierNormalizationApplyStep} from "./pages/SupplierNormalizationApplyStep";
import {SupplierNormalizationModelRunningStep} from "./pages/SupplierNormalizationModelRunningStep";
import {SupplierNormalizationApplyingResultsStep} from "./pages/SupplierNormalizationApplyingResultsStep";
import {ParentSuppliersStep} from "./pages/parent-suppliers/ParentSuppliersStep";
import {generateHomePath} from "../../routing/routing.utils";
import {JobRouteMatch} from '../../routing/routes';
import {useHistory} from "react-router";

export const SupplierNormalizationPage: React.FC = observer(() => {
    const {p, rootAppStore, bagStore, supplierNormalizationStore} = useStores();
    const bagId = bagStore.bagId;
    const bag = bagStore.bag as Bag;
    const history = useHistory();
    const routeMatch = useRouteMatch<JobRouteMatch>();


    useEffect(() => {
        // Check route
        if (supplierNormalizationStore.isMaster) {
            if (!p.p.hardcodeMasterBagId) throw new Error('Hardcode master bag id is not set (ERROR FROM PAGE!!)');
            supplierNormalizationStore.init(p.p.hardcodeMasterBagId, bag.taxonomy_size)
        } else {
            supplierNormalizationStore.init(bagId, bag.taxonomy_size)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const activeState = supplierNormalizationStore.state;
    useEffect(() => {
        if (activeState === 'running') {
            setTimeout(() => {
                supplierNormalizationStore.goToState('clean_parents');
            }, environment.aiResultFakeTimeout);
        }
        if (activeState === 'applying') {
            setTimeout(() => {
                supplierNormalizationStore.goToState('done');
            }, environment.aiResultFakeTimeout);
        }
    }, [activeState, supplierNormalizationStore]);

    const activeStep = supplierNormalizationStore.stepNumber;

    let goBack = () => history.push(generateHomePath(routeMatch, {id: String(bagId)}, rootAppStore.app));
    let backLabel = 'Home';
    if (activeState === 'start' || activeState === 'running') {
        // Go Home
    } else if (activeStep === 1) {
        goBack = () => supplierNormalizationStore.goToState('start');
        backLabel = 'Back';
    } else if (activeState === 'apply') {
        goBack = () => supplierNormalizationStore.goToState('clean_parents');
        backLabel = 'Back';
    }

    return <KoiFacade
        title={(!p.p.masterCompanyName && !p.p.compareCompanyName) ? 'Supplier Normalization'
            : (supplierNormalizationStore.isMaster
                ? <Typography variant="h2" component="h1">
                    Supplier Normalization for {p.p.masterCompanyName + ' '}
                    {p.p.showResultPills && <Chip label="SAP results" size="medium"/>}
                </Typography>
                : <Typography variant="h2" component="h1">
                    Supplier Normalization for {p.p.compareCompanyName + ' '}
                    {p.p.showResultPills && <Chip color="primary" label="Mithra results" size="medium"/>}
                </Typography>)}
        intro={<></>}
        onBack={goBack}
        backLabel={backLabel}
        className="sup-norm-page">

        <Grid container justifyContent="space-evenly" className="supplier-normalization-stepper">

            <Grid item xs={8}>
                <Stepper orientation={"horizontal"} activeStep={activeStep}>
                    <Step onClick={() => environment.isTest && supplierNormalizationStore.goToState('start')}>
                        <StepLabel>
                            {
                                activeState === 'running' ? 'Running ...' :
                                    p.p.skipAiSupplierNormalizationModel ? 'Start model' : 'Start model'
                            }
                        </StepLabel>
                    </Step>
                    <Step onClick={() => environment.isTest && supplierNormalizationStore.goToState('clean_parents')}>
                        <StepLabel>
                            Determine the parent suppliers
                        </StepLabel>
                    </Step>
                    <Step onClick={() => environment.isTest && supplierNormalizationStore.goToState('apply')}>
                        <StepLabel>
                            {
                                activeState === 'applying' ? 'Applying ...'
                                    : p.p.skipAiSupplierNormalizationModel ? 'Results' : 'Apply results'
                            }
                        </StepLabel>
                    </Step>
                </Stepper>
            </Grid>
        </Grid>

        {activeState === 'start' && <SupplierNormalizationWelcomeStep/>}
        {activeState === 'running' && <SupplierNormalizationModelRunningStep/>}
        {activeStep === 1 && <ParentSuppliersStep/>}
        {activeState === 'apply' && <SupplierNormalizationApplyStep/>}
        {(activeState === 'applying' || activeState === 'done') && <SupplierNormalizationApplyingResultsStep/>}

    </KoiFacade>
});
