import {
    MatPartReviewRow,
    MatPartReviewRowWithContext,
    MatReviewLevelStatisticsTreeSerializer
} from "./MaterializedClasses";
import {Categories} from "./AiClasses";
import {
    fromFieldsCategories,
    fromFieldsCategoriesOrUncat,
    fromSimpleFieldsCategoriesOrUncat,
    pickDefinedOrUncat
} from "../ApiHelpers";


export type ApprovalCategorization = 'input'
    | 'ai'
    | 'review|ai'
    | 'none'

export function getContextReviewCategory(part: MatPartReviewRowWithContext, taxonomySize: number): Categories | false {
    return fromSimpleFieldsCategoriesOrUncat(part.context, taxonomySize);
}

export function getActiveReviewCategory(part: MatPartReviewRow, taxonomySize): Categories {
    const prevCats = fromFieldsCategories(part, 'p_prev_cat', taxonomySize)
    const algCats = fromFieldsCategories(part, 'p_suggested_cat', taxonomySize)
    const reviewedCats = fromFieldsCategories(part, 'p_review_cat', taxonomySize)
    return pickDefinedOrUncat([reviewedCats, algCats, prevCats], taxonomySize)
}

export function getSpecificCategorization(part: MatPartReviewRow, taxonomySize: number, spec: ApprovalCategorization): Categories | false {
    switch (spec) {
        case 'input':
            return fromFieldsCategoriesOrUncat(part, 'p_prev_cat', taxonomySize)
        case 'ai':
            return fromFieldsCategories(part, 'p_suggested_cat', taxonomySize);
        case 'review|ai':
            return pickDefinedOrUncat([
                fromFieldsCategories(part, 'p_review_cat', taxonomySize),
                fromFieldsCategories(part, 'p_suggested_cat', taxonomySize),
            ], taxonomySize)
        case 'none':
            return false
    }
}

export function selectMatReviewLevelStatistics(tree: MatReviewLevelStatisticsTreeSerializer, categorySelection: string[]): MatReviewLevelStatisticsTreeSerializer[] {
    const result = [tree]
    if (!categorySelection) return result;
    for (const category of categorySelection) {
        let parent = result.at(-1) as MatReviewLevelStatisticsTreeSerializer;
        let node = parent.children.find(n => n.label === category)
        if (!node) {
            throw new Error(`Cannot find category ${categorySelection} in tree`)
        }
        result.push(node);
    }
    return result;
}
