import React, {ChangeEvent} from 'react';
import {useStores} from "../../stores";
import Button from '@mui/material/Button';
import {generatePath, useHistory} from "react-router";
import {routes} from "../../routing/routes";
import {CircularProgress} from "@mui/material";
import {C} from "../../configurations/active-configuration";
import {UploadFile} from "@mui/icons-material";


export const FileUploadSingle: React.FC = () => {
    const {dataIngestionStore} = useStores();
    const history = useHistory();

    const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            dataIngestionStore.setDatasetUploadLoading(true);

            /*
            * 1) Upload file
            * 2) Start AI
            * 3) (done by wrapper) df getter
            * 3) (done by wrapper) get mappings
            * 3) (done by wrapper) allowed op
            * 3) (done by wrapper) data ing
            * */

            const file = e.target.files[0];

            if(dataIngestionStore.datasetName == null || dataIngestionStore.datasetName.trim() === '') {
                dataIngestionStore.setDatasetName(file.name);
            }

            // Fake it for now
            if(C.package === 'sales_demo') {
                setTimeout(() => {
                    dataIngestionStore.setDatasetUploadLoading(false);
                    dataIngestionStore.setDataFileId(-1)
                    dataIngestionStore.setErrorMsg('')
                    // dataIngestionStore.navigateToPage('data_mapping')
                }, 2000);
                return;
            }

            try {
                // First upload the file
                let dataFileId: number;
                try {
                    dataFileId = await dataIngestionStore.uploadDatafile(file);
                    dataIngestionStore.setDataFileId(dataFileId);
                    dataIngestionStore.setErrorMsg('');
                } catch (error) {
                    // TODO: verify how to catch the error message is if the user uploads a file > 32 MB
                    dataIngestionStore.setErrorMsg("Could not upload file");
                    e.target.files = null;
                    return;
                }

                // Then start the AI mapping
                await dataIngestionStore.startAiMapping(dataFileId);

                // If successful redirect the user
                history.push(generatePath(routes.data_upload_mapping, {dataFileId: String(dataFileId)}));

            } catch (error) {
                e.target.files = null;
            } finally {
                dataIngestionStore.setDatasetUploadLoading(false);
            }
        }
    };

    return <Button
        disabled={dataIngestionStore.datasetUploadIsLoading}
        startIcon={<UploadFile/>}
        // onClick={onSave}
        endIcon={dataIngestionStore.datasetUploadIsLoading && <CircularProgress size={20}/> || undefined}
        // endIcon={<CircularProgress size={20}/> || undefined}
        variant="outlined"
        component="label"
        className="upload-file-button"
    >
        Upload
        <input
            hidden
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            type="file"
            onChange={handleFileChange}
        />
    </Button>
}
