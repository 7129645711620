import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../stores";
import {RouteWrapperProps} from "./RouteWrapperProps";
import {MultiDatabagWrapped} from "./base/MultiDatabagWrapped";

/**
 * Hardcoded wrapper for the merge1 dashboard
 */
export const Merge1EnvWrapper: React.FC<RouteWrapperProps> = observer(({children, state}) => {
    const {
        rootAppStore,
        bagLoadingStore,
        cmpStore,
        taxonomyManagerStore,
        p
    } = useStores();
    // const location = useLocation<MithraHistoryState>();
    // const match = useRouteMatch<JobRouteMatch>();
    // const history = useHistory();

    // TODO: See if this can be optimized, probably by the usage of react Context
    // Beware: This can cause the page to be rendered with the previous state, we have to be resilient against this
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => rootAppStore.setApp(state), [])

    useEffect(() => {
        if (!p.p.hardcodedBagId) throw new Error('No hardcodedBagId provided');
        if (!p.p.hardcodeMasterBagId) throw new Error('No hardcoded hardcodeMasterBagId provided');
        if (!p.p.hardcodedCombinedBagId) throw new Error('No hardcoded hardcodedCombinedBagId provided');
        if (!p.p.hardcodedCategorizationBagId) throw new Error('No hardcoded hardcodedCategorizationBagId provided');
        if (!p.p.masterTaxonomyId) throw new Error('No hardcoded masterTaxonomyId provided');
        bagLoadingStore.loadBagWithoutLoadingStores(p.p.hardcodedBagId).then(bag => {
            console.log(`Wrapper: Bag ${p.p.hardcodedBagId} loaded`);
            cmpStore.setCompareBag(bag);
        });

        // categorizationReviewStore.reInitializeWithTaxonomy(p.p.hardcodedCategorizationBagId, p.p.masterTaxonomyId, 3)

        cmpStore.initBags(p.p.hardcodeMasterBagId)

        taxonomyManagerStore.setDesiredTaxonomyId(p.p.masterTaxonomyId);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // console.debug('Re-rendering MultiEnvWrapper', state.dashEnv)
    return <MultiDatabagWrapped state={state}>{children}</MultiDatabagWrapped>;
});
