import {observer} from "mobx-react-lite";
import {Autocomplete, Button, Checkbox, Grid, TextField, Typography} from "@mui/material";
import {CheckBox, CheckBoxOutlineBlank, CloseOutlined} from "@mui/icons-material";
import {CurrencyComponent} from "../../components/currency-component/CurrencyComponent";
import {BagAdvancedInsightsManager} from "../../stores/managers/BagAdvancedInsightsManager";
import {countriesToContinents, Country, countryToContinent} from "../../components/visualization/bubble-map/countries";
import {SearchSupplierAutocomplete} from "./SearchSupplierAutocomplete";
import React from "react";
import {useStores} from "../../stores";
import {SearchParentSupplierAutocomplete} from "./SearchParentSupplierAutocomplete";
import {JobRouteMatch, routes} from "../../routing/routes";
import {useRouteMatch} from "react-router-dom";
import {ShadcnDateRangePicker} from "../../shadcn/components/ui/ShadcnDateRangePicker";
import moment from "moment";
import {C} from "../../configurations/active-configuration";

export const AdvancedDashboardHeaderFilters = observer(() => {
    const {p, bagStore} = useStores();
    const routeMatch = useRouteMatch<JobRouteMatch>();
    const isMergeXMergePage = routeMatch.path === routes.merge_x_merge_advanced_dashboard;
    const hideParent = Boolean(p.p.hideParentSupplierOpportunityDashboard) || isMergeXMergePage;
    return <Grid container className="filter-container header-filter-container">
        <Grid item>
            <Typography variant="h6" align="left" style={{marginBottom: '1em', color: '#193150'}}
                        className="font-weight-bold key-value">
                Filter by date
            </Typography>
            <ShadcnDateRangePicker
                onUpdate={(values) => {
                    const startDate = moment(values.range.from).format('YYYY-MM-DD'); // Convert from Date to formatted string
                    const endDate = moment(values.range.to).format('YYYY-MM-DD'); // Convert to Date to formatted string, if available
                    console.log('startDate', startDate, values.range.from)
                    console.log('endDate', endDate, values.range.to)
                    bagStore.advanced.dateFilterChanged(startDate, endDate); // Call your dateFilterChanged function with the formatted dates
                }}
                dateFrom={bagStore.advanced.filters && bagStore.advanced.filters.date_range && bagStore.advanced.filters.date_range[0]}
                dateTo={bagStore.advanced.filters && bagStore.advanced.filters.date_range && bagStore.advanced.filters.date_range[1]}
                align="start"
            />
        </Grid>

        <Grid item>
            <Typography variant="h6" align="left" style={{marginBottom: '1em', color: '#193150'}}
                        className="font-weight-bold key-value">
                Filter by country
            </Typography>

            <Autocomplete
                className="adv-dashboard-country-filter"
                multiple
                limitTags={1}
                value={bagStore.advanced.filters.country || []}
                options={bagStore.advanced.geoDataCountryStringArr}
                disableCloseOnSelect
                getOptionLabel={(option) => option ? option : '-'}
                renderOption={(props, option, {selected}) =>
                    <li {...props}>
                        <Checkbox
                            icon={<CheckBoxOutlineBlank fontSize="small"/>}
                            checkedIcon={<CheckBox fontSize="small"/>}
                            style={{marginRight: 8}}
                            checked={selected}
                        />

                        <Grid container>
                            <Grid item xs={2}>
                                <img
                                    loading="lazy"
                                    width="20"
                                    srcSet={`https://flagcdn.com/w40/${option.toLowerCase()}.png 2x`}
                                    src={`https://flagcdn.com/w20/${option.toLowerCase()}.png`}
                                    alt=""
                                />
                            </Grid>
                            <Grid item xs={5} style={{display: 'flex', alignItems: 'center'}}>
                                {option ? option : '-'}
                            </Grid>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={4} style={{display: 'flex', alignItems: 'center'}}>
                                <CurrencyComponent
                                    v={BagAdvancedInsightsManager.getCountrySpend(bagStore.advanced.geoData, option)}
                                    nDigits={3}/>
                            </Grid>
                        </Grid>
                    </li>
                }
                onChange={(_, value) => {
                    bagStore.advanced.countryFilterChangedNew(value);
                }}
                renderInput={(params) => (
                    <TextField {...params} label="Countries"/>
                )}
            />
        </Grid>

        <Grid item>
            <Typography variant="h6" align="left" style={{marginBottom: '1em', color: '#193150'}}
                        className="font-weight-bold key-value">
                Filter by continent
            </Typography>

            <Autocomplete
                className="adv-dashboard-filter-continent"
                multiple
                limitTags={1}
                value={countriesToContinents(bagStore.advanced.filters.country as Country[] || [])}
                options={bagStore.advanced.continentFilterData}
                disableCloseOnSelect
                getOptionLabel={(option) => option ? option : '-'}
                renderOption={(props, option) => {
                    const s = bagStore.advanced.filters.country && bagStore.advanced.filters.country.some((selectedCountry) => {
                        const continent = countryToContinent(selectedCountry as Country);
                        return continent === option;
                    });
                    return (
                        <li {...props}>
                            <Checkbox
                                icon={<CheckBoxOutlineBlank fontSize="small"/>}
                                checkedIcon={<CheckBox fontSize="small"/>}
                                style={{marginRight: 8}}
                                checked={s}
                            />
                            <Grid container>
                                <Grid item xs={6}>
                                    {option ? option : '-'}
                                </Grid>
                                <Grid item xs={6}>
                                    {/*TODO: No calculation in render thread*/}
                                    <CurrencyComponent
                                        v={BagAdvancedInsightsManager.calculateContinentSpend(bagStore.advanced.geoData, option)}
                                        nDigits={3}/>
                                </Grid>
                            </Grid>
                        </li>
                    );
                }}
                onChange={(_, i) => {
                    const newCountries = i.flatMap((continent) => {
                        return bagStore.advanced.geoData.filter((country) => {
                            return countryToContinent(country.country) === continent;
                        }).map((country) => country.country);
                    });
                    bagStore.advanced.countryFilterChanged(newCountries);
                }}
                renderInput={(params) => (
                    <TextField {...params} label="Continents"/>
                )}
            />


        </Grid>

        {!C.profile.disableAdvDashboardSearchBySupplier && <Grid item>
            <Typography variant="h6" align="left" style={{marginBottom: '1em', color: '#193150'}}
                        className="font-weight-bold key-value">
                Filter by supplier
            </Typography>
            <SearchSupplierAutocomplete wrapperClassName="supplier-search-autocomplete-wrapper" limitTags={1}/>
        </Grid>}
        {!hideParent &&
            <Grid item>
                <Typography variant="h6" align="left" style={{marginBottom: '1em', color: '#193150'}}
                            className="font-weight-bold key-value">
                    Filter by parent supplier
                </Typography>
                <SearchParentSupplierAutocomplete wrapperClassName="parent-supplier-search-autocomplete-wrapper" limitTags={1}/>
            </Grid>
        }
        <Grid item>
            <div style={{marginBottom: '4em'}}></div>
            <Button
                variant="outlined"
                style={{
                    width: '3.5em',
                    height: '3.5em',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
                onClick={() => {
                    bagStore.advanced.reset()
                }}>
                <CloseOutlined/>
            </Button>
        </Grid>
    </Grid>
})
