import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {useHistory, useLocation} from "react-router";
import {JobRouteMatch, MithraHistoryState} from "../../routing/routes";
import {useRouteMatch} from "react-router-dom";
import {useStores} from "../../stores";
import {RouteWrapperProps} from "./RouteWrapperProps";
import {MultiDatabagWrapped} from "./base/MultiDatabagWrapped";

export const MultiEnvWrapper: React.FC<RouteWrapperProps> = observer(({children, state}) => {
    const {rootAppStore, bagStore, bagLoadingStore, cmpStore, taxonomySuggestorStore} = useStores();
    const location = useLocation<MithraHistoryState>();
    const match = useRouteMatch<JobRouteMatch>();
    const history = useHistory();

    // TODO: See if this can be optimized, probably by the usage of react Context
    // Beware: This can cause the page to be rendered with the previous state, we have to be resilient against this
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => rootAppStore.setApp(state), [])

    useEffect(() => {
        const estBagId = bagLoadingStore.initBag(location, history, match)
        if (!estBagId) {
            console.warn('Could not initialize master bag', estBagId);
            return;
        }
        const masterBagId = cmpStore.determineMasterBag({id: estBagId});
        if (masterBagId) {
            cmpStore.initBags(masterBagId)
        } else {
            cmpStore.setMissingMasterBag()
        }

        taxonomySuggestorStore.getTaxonomySuggestion(-1)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const bag = bagStore.bag;
    useEffect(() => {
        cmpStore.setCompareBag(bag);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bag?.id])

    // if(!rootAppStore.routeApp) return <></>;

    // console.debug('Re-rendering MultiEnvWrapper', state.dashEnv)
    return <MultiDatabagWrapped state={state}>{children}</MultiDatabagWrapped>;
});
