import './CategorizationStatisticsDashboard.scss'
import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../../stores";
import Typography from "@mui/material/Typography";
import {Grid} from "@mui/material";
import {DistributionComponent} from "../../../../components/categorization-review/DistributionComponent";
import {TotalReviewedAiResult} from "../../../../components/categorization-review/TotalReviewedAiResult";
import {CoveredByAi} from "../../../../components/categorization-review/CoveredByAi";
import {CategorizedTransactions} from "../../../../components/categorization-review/CategorizedTransactions";
import {CategoriesCoveredByAi} from "../../../../components/categorization-review/CategoriesCoveredByAi";
import {toCurrency} from "../../../../components/currency-component/CurrencyClasses";
import {useHistory} from "react-router";
import {generateHomePath} from "../../../../routing/routing.utils";
import {useRouteMatch} from "react-router-dom";
import {JobRouteMatch} from "../../../../routing/routes";
import {HelpComponent} from "../../../../components/help-component/HelpComponent";

export const CategorizationStatisticsDashboard: React.FC = observer(() => {
    const {p, rootAppStore, categorizationReviewStore} = useStores();
    const statisticsDashboardController = categorizationReviewStore.statisticsDashboardController;

    const bagId = categorizationReviewStore.bagId;
    const routeMatch = useRouteMatch<JobRouteMatch>();
    const history = useHistory();
    const back = generateHomePath(routeMatch, {id: String(bagId)}, rootAppStore.app)

    useEffect(() => {
        statisticsDashboardController.getCategorizationVersionedStatistics(categorizationReviewStore.bagId)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <div className="categorization-statistics-dashboard" style={{cursor: 'default'}}>
        <Grid container justifyContent="space-around">

            {/*1st half of the page*/}
            <Grid container justifyContent="center"
                  item xs={6}>
                <Grid container direction='column'
                      item xs={12} style={{marginBottom: '3em', marginLeft: '5em'}}>
                    <Typography className="font-weight-bold key-value">Average Ai score</Typography>
                    <br/>
                    <Grid item>
                        <Typography variant="h4"
                                    className="key-values">
                            {statisticsDashboardController.average_confidence_score !== undefined
                                ? (statisticsDashboardController.average_confidence_score * 100).toFixed(2)
                                : 0
                            } %
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container
                      item xs={12}
                      style={{marginLeft: '5em'}}>

                    <Grid item xs={12}>
                        <Typography className="font-weight-bold key-value">Total of review Ai results
                            (transactions)</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TotalReviewedAiResult
                            data={statisticsDashboardController.getTotalReviewedAiResultBarDataPoint()}/>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container justifyContent="center" direction='column' align-items='strech'
                  item xs={6} style={{height: '100%', marginBottom: '4em'}}>
                <Grid item>
                    <Typography className="font-weight-bold key-value">
                        Distribution of Ai results
                    </Typography>
                </Grid>
                <Grid item>
                    <DistributionComponent
                        data={statisticsDashboardController.getDistributionBarDataPoint()}
                        handleBarClick={(d) => {
                            categorizationReviewStore.reviewPageController.handleDistributionClick(d)
                            categorizationReviewStore.reviewPageController.setSingleMode(true);
                            history.goBack()
                        }}
                    />
                </Grid>
            </Grid>

            {/*2nd half of the page*/}
            <Grid container direction='row' justifyContent="space-evenly">
                <Grid container direction='column'
                      item xs={4} style={{marginLeft: '5em'}}>
                    <Grid item style={{marginBottom: '1em'}}>
                        <Typography className="font-weight-bold key-value text-center">
                            Spend covered by Ai
                        </Typography>
                    </Grid>
                    <Grid item>
                        <CoveredByAi
                            data={statisticsDashboardController.getSpendCoveredByAiBarDataPoint()}
                            itemsCoveredByAi={toCurrency(p.currencySymbol, (statisticsDashboardController.covered_by_ai_spend || 0))}
                            percentageCoveredByAi={statisticsDashboardController.getCoveredByAiSpendPercentage()}
                        />
                    </Grid>
                </Grid>


                <Grid container direction='column'
                      item xs={4} style={{marginLeft: '3em'}}>
                    <Grid item style={{marginBottom: '1em'}}>
                        <Typography className="font-weight-bold key-value text-center">
                            Suppliers covered by Ai
                            <HelpComponent helpContent={
                                <Typography component="p">
                                    Since a supplier might have some transactions reviewed and some unreviewed,
                                    sum of these might be higher than covered suppliers.
                                </Typography>
                            } />
                        </Typography>
                    </Grid>
                    <Grid item>
                        <CoveredByAi
                            data={statisticsDashboardController.getSuppliersCoveredByAiBarDataPoint()}
                            itemsCoveredByAi={String(statisticsDashboardController.covered_suppliers ?? 0)}
                            percentageCoveredByAi={statisticsDashboardController.getCoveredByAiSuppliersPercentage()}
                        />
                    </Grid>
                </Grid>
            </Grid>


            <Grid container style={{marginLeft: '5em'}}
                  item>
                <Grid container direction='column' style={{marginBottom: '4em'}}
                      item>
                    <Grid item style={{marginBottom: '1em'}}>
                        <Typography className="font-weight-bold key-value">
                            Categorized transactions
                        </Typography>
                    </Grid>
                    <CategorizedTransactions
                        categorized={statisticsDashboardController.getCategorizationCategorized()}
                        recategorized={statisticsDashboardController.getCategorizationRecategorized()}
                        uncategorized={statisticsDashboardController.getCategorizationUncategorized()}
                    />
                </Grid>

                <Grid container
                      item>
                    <Grid
                        item xs={10} style={{marginBottom: '2em'}}>
                        <Typography className="font-weight-bold key-value">
                            Categories covered by Ai
                        </Typography>
                    </Grid>
                    <Grid item xs={10} style={{marginBottom: '4em'}}>
                        <CategoriesCoveredByAi
                            covered={statisticsDashboardController.getCategoriesCoveredByAiBarDataPoint()}
                            uncovered={statisticsDashboardController.getCategoriesUncoveredByAiBarDataPoint()}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </div>
});
