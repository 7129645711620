import MithraMaterializedApi from "../../services/MithraMaterializedApi";
import {BagStore} from "../BagStore";
import ProfileStore from "../ProfileStore";
import {makeAutoObservable, reaction, runInAction} from "mobx";
import {
    AdvancedFilter,
    AdvancedFilterData,
    AdvancedGeoData,
    AdvancedOpportunityData,
    AdvancedSpendData,
    AdvancedSpendKPI,
    AdvancedSpendPerGroup,
    AdvancedSupplierBreakdownData,
    AdvancedTreeData,
    CategoryFilter,
    DonutChartSupplierSpendData,
    ParentSupplierSearchResponse,
    SupplierSearchResponse
} from "../../services/ApiTypes";
import {Country, countryToContinent} from "../../components/visualization/bubble-map/countries";
import {HashedLoadingDataWrapper, LoadingDataWrapper} from "../../utils/LoadingDataWrapper";
import {AxoisRequestManager} from "./RequestManager";
import {from, Subject, switchMap, tap} from "rxjs";
import moment from "moment";

// const parseTime = d3.timeParse("%Y-%m-%d");

declare type TreeDataFilter = Omit<AdvancedFilter, 'category'>

export type Option = {
    value: string
    label: string
    subLabel: string
}

export class BagAdvancedInsightsManager {
    public databagId?: number;
    public geoData: AdvancedGeoData[] = [];
    public geoDataCountryStringArr: string[] = [];

    public initialFilter: AdvancedFilter = {};
    public filters: AdvancedFilter = {}
    public opportunity_ordering: string;
    public sup_breakdown_ordering: string;

    public treeDataRequest = new AxoisRequestManager<[number, TreeDataFilter], AdvancedTreeData[]>(([databag_id, filters]) => from(this.api.getAdvancedSpendTree(databag_id, filters)))

    /**
     * Shallow observable array of AdvancedSpendPerGroup
     */
    public readonly spendPerSupplierData = new HashedLoadingDataWrapper<AdvancedSpendPerGroup>();
    /**
     * TODO: Convert to derived cached property
     */
    public spendPerSupplierDataStringArr: string[] = [];

    public spendPerActiveL1Data = new HashedLoadingDataWrapper<AdvancedSpendPerGroup>();
    public spendPerActiveL1DataStringArr: string[] = [];

    public spendPerActiveL2Data: AdvancedSpendPerGroup[] = [];
    public spendPerActiveL2DataStringArr: string[] = [];
    public spendPerActiveL3Data: AdvancedSpendPerGroup[] = [];
    public spendPerActiveL3DataStringArr: string[] = [];
    public spendPerActiveL4Data: AdvancedSpendPerGroup[] = [];
    public spendPerActiveL4DataStringArr: string[] = [];
    public spendDonutData: DonutChartSupplierSpendData[] = [];
    public spendDonutDataLoading: boolean = false;
    public spendConcentrationData = new HashedLoadingDataWrapper<AdvancedSpendData>();
    public opportunityData = new LoadingDataWrapper<AdvancedOpportunityData>();
    public supplierBreakdownData = new LoadingDataWrapper<AdvancedSupplierBreakdownData>();
    public kpi?: AdvancedSpendKPI;

    public searchSupplierResult: Option[] | undefined = undefined;
    public searchSupplierSearchTerm: string = '';
    /**
     * This is needed to track options that are not in the search at the moment
     */
    public searchSupplierCachedOptions: Option[] = [];
    public readonly searchSupplierSubject: Subject<string> = new Subject();
    public readonly searchSupplierPipe = this.searchSupplierSubject.pipe(
        switchMap((searchTerm) => from(this.searchSupplier(searchTerm))),
        tap((results) => {
            this.setSearchSupplierSearchResults(results)
        }),
    )

    public searchParentSupplierResult: Option[] | undefined = undefined;
    public searchParentSupplierSearchTerm: string = '';
    /**
     * This is needed to track options that are not in the search at the moment
     */
    public searchParentSupplierCachedOptions: Option[] = [];
    public readonly searchParentSupplierSubject: Subject<string> = new Subject();
    public readonly searchParentSupplierPipe = this.searchParentSupplierSubject.pipe(
        switchMap((searchTerm) => from(this.searchParentSupplier(searchTerm))),
        tap((results) => {
            this.setSearchParentSupplierSearchResults(results)
        }),
    )


    // noinspection JSUnusedLocalSymbols
    constructor(private api: MithraMaterializedApi, private bagStore: BagStore, private profileStore: ProfileStore) {
        makeAutoObservable(this);
        // Fix last year case if filters
        if(profileStore.p.advDashboardInitialDateFilter === 'last_year') {
            const start = new Date(new Date().getFullYear() - 1, 0, 1);
            const end = new Date(new Date().getFullYear(), 0, 0);
            this.initialFilter.date_range = [moment(start).format('YYYY-MM-DD'), moment(end).format('YYYY-MM-DD')];
        }
        // Set values that does not need reaction
        this.opportunity_ordering = '-spend';
        this.sup_breakdown_ordering = '';
        // Register reactions
        reaction(() => this.databagId, (databag) => {
            if (!databag) return;
            // getGeoData
            this.api.getAdvancedSpendGeo(databag, this.geoFilter)
                .then(resp => runInAction(() => {
                    // Convert all the country codes to strings for countryString
                    this.geoDataCountryStringArr = resp.data.map(x => x.country);
                    this.geoData = resp.data
                }));
            // getTreeData
            this.treeDataRequest.request([databag, this.treeDataFilters]);
        });
        // Since this.filters is an object, we need to observe it's properties separetly or use deepObserve
        // Note to modify properties if observable here if they changed on type
        reaction(() => [this.filters.s_ids, this.filters.sp_ids, this.filters.country,this.filters.date_range,
                    this.filters.category, this.databagId], (changed_values) => {
            const filters = this.filters;
            const databag = this.databagId;
            if (!databag) return;
            // getSpendPerSupplierData
            this.spendPerSupplierData.setLoading(true);
            this.api.getAdvancedSpendTimePerSupplier(databag, filters)
                .then(resp => {
                    const spendPerSupplierData = resp.data.map(x => {
                        return {
                            s_id: x.s_id,
                            spend: x.spend,
                            date: new Date(x.date),
                            group: x.group,
                        } as AdvancedSpendPerGroup
                    });
                    this.spendPerSupplierData.updateData(spendPerSupplierData);

                    runInAction(() => {
                        this.spendPerSupplierDataStringArr = resp.data.map((x, index) => x.s_id ? x.s_id : ("Unknown supplier " + index));
                    });
                })
            // getSpendPerActiveL1Data
            this.spendPerActiveL1Data.setLoading(true);
            this.api.getAdvancedSpendTimePerCategory(databag, filters, "active_l1")
                .then(resp => {
                    const elements = resp.data.map(x => {
                        return {
                            s_id: x.s_id,
                            spend: x.spend,
                            // date: parseTime(x.date as string),
                            date: new Date(x.date),
                            group: x.group,
                        } as AdvancedSpendPerGroup
                    });
                    this.spendPerActiveL1Data.updateData(elements);
                    runInAction(() => {
                        // this.spendPerActiveL1DataStringArr = resp.data.map((x, index) => x.group ? x.group : ("Unknown group " + index));
                        this.spendPerActiveL1DataStringArr = Array.from(new Set(
                            resp.data.map((x) => x.group ? x.group : '-')
                        ));
                    });
                });
            // getSpendPerActiveL2Data
            this.api.getAdvancedSpendTimePerCategory(databag, filters, "active_l2")
                .then(resp => runInAction(() => {
                    this.spendPerActiveL2DataStringArr = Array.from(new Set(
                        resp.data.map((x) => x.group ? x.group : '-')
                    ));
                    this.spendPerActiveL2Data = resp.data.map(x => {
                        return {
                            s_id: x.s_id,
                            spend: x.spend,
                            // date: parseTime(x.date as string),
                            date: new Date(x.date),
                            group: x.group,
                        } as AdvancedSpendPerGroup
                    });

                }));
            // getSpendPerActiveL3Data
            this.api.getAdvancedSpendTimePerCategory(databag, filters, "active_l3")
                .then(resp => runInAction(() => {
                    this.spendPerActiveL3DataStringArr = Array.from(new Set(
                        resp.data.map((x) => x.group ? x.group : '-')
                    ));
                    this.spendPerActiveL3Data = resp.data.map(x => {
                        return {
                            s_id: x.s_id,
                            spend: x.spend,
                            // date: parseTime(x.date as string),
                            date: new Date(x.date),
                            group: x.group,
                        } as AdvancedSpendPerGroup
                    });

                }));
            // getSpendPerActiveL4Data
            this.api.getAdvancedSpendTimePerCategory(databag, filters, "active_l4")
                .then(resp => runInAction(() => {
                    this.spendPerActiveL4DataStringArr = Array.from(new Set(
                        resp.data.map((x) => x.group ? x.group : '-')
                    ));
                    this.spendPerActiveL4Data = resp.data.map(x => {
                        return {
                            s_id: x.s_id,
                            spend: x.spend,
                            // date: parseTime(x.date as string),
                            date: new Date(x.date),
                            group: x.group,
                        } as AdvancedSpendPerGroup
                    });
                }));
            // getDonutData
            this.spendDonutDataLoading = true;
            this.api.getAdvancedDonutData(databag, filters)
                .then(resp => runInAction(() => {
                    this.spendDonutData = resp.data
                }))
                .finally(() => runInAction(() => {
                    this.spendDonutDataLoading = false
                }));
            // getSpendConcentrationData
            this.api.getAdvancedSpendConcentrationData(databag, filters)
                .then(resp => this.spendConcentrationData.updateData(resp.data));
            // getSpendKPI
            this.api.getAdvancedSpendKPI(databag, filters)
                .then(resp => runInAction(() => {
                    this.kpi = resp.data[0]
                }));
            // getOpportunityData
            this.opportunityData.setLoading(true);
            this.api.getAdvancedOpportunityData(databag, filters, this.opportunity_ordering)
                .then(resp => this.opportunityData.updateData(resp.data))
                .finally(() => this.opportunityData.setLoading(false));
            // getSupplierBreakdownData
            this.supplierBreakdownData.setLoading(true);
            this.api.getAdvancedSupplierBreakdownData(databag, filters, this.sup_breakdown_ordering)
                .then(data => this.supplierBreakdownData.updateData(data))
                .finally(() => this.supplierBreakdownData.setLoading(false));
        });
        reaction(() => this.opportunity_ordering, (opportunity_ordering) => {
            if (!this.databagId) return;
            // getOpportunityData
            this.opportunityData.setLoading(true)
            this.api.getAdvancedOpportunityData(this.databagId, this.filters, opportunity_ordering)
                .then(resp => this.opportunityData.updateData(resp.data))
                .finally(() => this.opportunityData.setLoading(false));
        });
        reaction(() => this.sup_breakdown_ordering, (sup_breakdown_ordering) => {
            if (!this.databagId) return;
            // getSupplierBreakdownData
            this.supplierBreakdownData.setLoading(true)
            this.api.getAdvancedSupplierBreakdownData(this.databagId, this.filters, sup_breakdown_ordering)
                .then(data => this.supplierBreakdownData.updateData(data))
                .finally(() => this.supplierBreakdownData.setLoading(false));
        });
        // Set filters to trigger reactions for the first time
        this.resetFilters();
    }

    get isLoading() {
        return this.treeDataRequest.busy;
    }

    get treeData(): AdvancedTreeData {
        let result = this.treeDataRequest.result;
        if (!result) {
            return {value: 0, label: '', children: []};
        }
        return {
            value: result.reduce((sum, element) => sum + element.value, 0),
            label: 'All',
            children: result,
        }
    }

    get continentFilterData(): string[] {
        const continentData: string[] = this.geoDataCountryStringArr.map((country: string) => {
            return countryToContinent(country as Country);
        });

        return Array.from(new Set(continentData));
    }

    get groupedSpendPerSupplierFilterDataOriginal(): AdvancedFilterData[] {
        return BagAdvancedInsightsManager.calcGroupedSpendFilterDataOriginal(this.spendPerSupplierData.elements);
    }

    get Supplier(): AdvancedFilterData[] {
        return BagAdvancedInsightsManager.calcGroupedSpendFilterDataOriginal(this.spendPerSupplierData.elements);
    }

    get groupedSpendPerSupplierFilterData(): string[] {
        return BagAdvancedInsightsManager.calcGroupedSpendFilterData(this.spendPerSupplierData.elements);
    }

    get groupedSpendPerActiveL1FilterDataOriginal(): AdvancedFilterData[] {
        // activeL1Arr
        return BagAdvancedInsightsManager.calcGroupedSpendFilterDataOriginal(this.spendPerActiveL1Data.elements);
    }

    get groupedSpendPerActiveL2FilterDataOriginal(): AdvancedFilterData[] {
        // activeL2Arr
        return BagAdvancedInsightsManager.calcGroupedSpendFilterDataOriginal(this.spendPerActiveL2Data);
    }

    get groupedSpendPerActiveL3FilterDataOriginal(): AdvancedFilterData[] {
        // activeL3Arr
        return BagAdvancedInsightsManager.calcGroupedSpendFilterDataOriginal(this.spendPerActiveL3Data);
    }

    get groupedSpendPerActiveL4FilterDataOriginal(): AdvancedFilterData[] {
        // activeL4Arr
        return BagAdvancedInsightsManager.calcGroupedSpendFilterDataOriginal(this.spendPerActiveL4Data);
    }

    get onlyCountryFiltered() {
        return this.filters.country !== undefined
                && this.filters.s_ids === undefined
                && this.filters.sp_ids === undefined
                && this.filters.category === undefined
                && this.filters.date_range === undefined
    }

    get geoFilter(): AdvancedFilter {
        // When only the countries are filtered, do not hide the other countries
        return this.onlyCountryFiltered ? {} : this.filters;
    }

    get treeDataFilters(): TreeDataFilter {
        // When only the countries are filtered, do not hide the other countries
        return {
            country: this.filters.country,
            s_ids: this.filters.s_ids,
            sp_ids: this.filters.sp_ids,
            date_range: this.filters.date_range,
        };
    }

    setDatabagId(databagId: number | undefined) {
        console.log('BagAdvancedInsightsManager.setDatabagId', databagId, this.databagId);
        if (databagId !== this.databagId) {
            this.filters = {...this.initialFilter};
        }
        this.databagId = databagId;
    }

    setSearchSupplierSearchTerm(searchTerm: string) {
        this.searchSupplierSearchTerm = searchTerm;
        this.searchSupplierSubject.next(searchTerm);
    }

    setSearchParentSupplierSearchTerm(searchTerm: string) {
        this.searchParentSupplierSearchTerm = searchTerm;
        this.searchParentSupplierSubject.next(searchTerm);
    }

    setSearchSupplierSearchResults(response: SupplierSearchResponse) {
        this.searchSupplierResult = response.results.map((supplier) => ({
            value: supplier.s_id,
            label: supplier.s_name,
            subLabel: supplier.s_id,
        }))
    }

    setSearchParentSupplierSearchResults(response: ParentSupplierSearchResponse) {
        this.searchParentSupplierResult = response.results.map((parentSupplier) => ({
            value: parentSupplier.sp_id,
            label: parentSupplier.sp_name,
            subLabel: parentSupplier.sp_id,
        }))
    }

    setSearchSupplierCachedOptions(cachedOptions: Option[]) {
        this.searchSupplierCachedOptions = cachedOptions;
    }

    setSearchParentSupplierCachedOptions(cachedOptions: Option[]) {
        this.searchParentSupplierCachedOptions = cachedOptions;
    }

    setOrderingWithPrefix(ordering: string, prefix: string) {
        if (prefix === 'opportunity_')
            this.opportunity_ordering = ordering;
        else if (prefix === 'sup_breakdown_')
            this.sup_breakdown_ordering = ordering;
        return;
    }

    getOrderingWithPrefix(prefix: string) {
        if (prefix === 'opportunity_')
            return this.opportunity_ordering;
        else if (prefix === 'sup_breakdown_')
            return this.sup_breakdown_ordering;
        return '';
    }

    getActiveL1Spend(lookup: string): number {
        return BagAdvancedInsightsManager.calcActiveSpend(this.groupedSpendPerActiveL1FilterDataOriginal, lookup);
    }

    getActiveL2Spend(lookup: string): number {
        return BagAdvancedInsightsManager.calcActiveSpend(this.groupedSpendPerActiveL2FilterDataOriginal, lookup);
    }

    getActiveL3Spend(lookup: string): number {
        return BagAdvancedInsightsManager.calcActiveSpend(this.groupedSpendPerActiveL3FilterDataOriginal, lookup);
    }

    getActiveL4Spend(lookup: string): number {
        return BagAdvancedInsightsManager.calcActiveSpend(this.groupedSpendPerActiveL4FilterDataOriginal, lookup);
    }

    resetFilters() {
        this.filters = {...this.initialFilter};
    }

    reset() {
        this.setSearchSupplierSearchTerm('')
        this.setSearchParentSupplierSearchTerm('')
        this.resetFilters()
    }

    dateFilterChanged = (startDate: string | undefined, endDate: string | undefined) => {
        this.filters.date_range = startDate && endDate ? [startDate, endDate] : undefined;
    }

    categoryFilterChanged = (categories: CategoryFilter | null) => {
        this.filters.category = categories && categories.length > 0 ? categories : undefined;
    }

    countryFilterChangedNew = (selectedCountries: string[]) => {
        this.filters.country = Array.from(new Set(selectedCountries));
    }

    countryFilterChanged = (country?: string[]) => {
        this.filters.country = country && country.length > 0 ? country : undefined;
    }

    supplierFilterChanged = (s_ids?: string[]) => {
        this.filters.s_ids = s_ids && s_ids.length > 0 ? s_ids : undefined;
    }

    parentSupplierFilterChanged = (sp_ids?: string[]) => {
        this.filters.sp_ids = sp_ids && sp_ids.length > 0 ? sp_ids : undefined;
    }

    breakdownFilterChanged = (row?: AdvancedSupplierBreakdownData) => {
        this.filters.s_ids = row?.s_id ? [row.s_id] : undefined;
        this.filters.sp_ids = row?.sp_id ? [row.sp_id] : undefined;
        this.filters.country = row?.s_country_code ? [row.s_country_code] : undefined;
        this.filters.category = (row?.active_l1 || row?.active_l2 || row?.active_l3 || row?.active_l4)
                ? [row.active_l1, row.active_l2, row.active_l3, row.active_l4]
                : undefined;
    }

    async searchSupplier(supplier: string): Promise<SupplierSearchResponse> {
        if (!this.databagId) throw new Error('DatabagId is not set');
        const response = await this.api.searchSupplier(this.databagId, supplier);
        return response.data;
    }

    async searchParentSupplier(parentSupplier: string): Promise<ParentSupplierSearchResponse> {
        if (!this.databagId) throw new Error('DatabagId is not set');
        const response = await this.api.searchParentSupplier(this.databagId, parentSupplier);
        return response.data;
    }

    private static calcGroupedSpendFilterDataOriginal(data: AdvancedSpendPerGroup[]): AdvancedFilterData[] {
        const resultMap = new Map<string, AdvancedFilterData>();

        data.forEach((current: AdvancedSpendPerGroup) => {
            if (current.group === '') {
                return;
            }
            const existing = resultMap.get(current.group);
            if (existing) {
                existing.spend += current.spend;
            } else {
                resultMap.set(current.group, {id: current.s_id, name: current.group, spend: current.spend});
            }
        });

        return Array.from(resultMap.values());
    }

    private static calcGroupedSpendFilterData(data: AdvancedSpendPerGroup[]): string[] {
        const resultMap = new Map<string, AdvancedFilterData>();

        data.forEach((current: AdvancedSpendPerGroup) => {
            if (current.group === '') {
                return;
            }
            const existing = resultMap.get(current.group);
            if (existing) {
                existing.spend += current.spend;
            } else {
                resultMap.set(current.group, {id: current.s_id, name: current.group, spend: current.spend});
            }
        });

        const supplierIds: (string | undefined)[] = Array.from(resultMap.values()).map(item => item.id ? item.id.toString() : undefined);
        return supplierIds.filter(id => id !== undefined) as string[];
    }

    public static calcActiveSpend(data: AdvancedFilterData[], lookup: string): number {
        const obj = data.find((item) => item.name === lookup);
        return obj?.spend || 0;
    }

    public static getCountrySpend(geoData: AdvancedGeoData[], countryId: string): number {
        const countryArr = geoData.map(x => ({name: x.country, spend: x.spend}))
        // eslint-disable-next-line eqeqeq
        const country = countryArr.find((country) => country.name == countryId);
        return country?.spend || 0;
    }

    public static calculateContinentSpend(geoData: AdvancedGeoData[], continent: string): number {
        const continentData: AdvancedGeoData[] = geoData.filter(
            (country) => countryToContinent(country.country) === continent
        );

        return continentData.reduce((total, country) => {
            return total + BagAdvancedInsightsManager.getCountrySpend(geoData, country.country);
        }, 0);
    };
}
