import './SingleDropdownMenu.scss'
import React, {useEffect} from "react";
import {Badge, Chip, Divider, Grow, IconButton, ListItem, Menu, MenuItem, Typography} from "@mui/material";
import {AccountCircleOutlined} from "@mui/icons-material";
import {useHistory} from "react-router";
import {CmpDataBagRouteMatch, routes, TaxonomyRouteMatch} from "../../routing/routes";
import {useStores} from "../../stores";
import {observer} from "mobx-react-lite";
import {useRouteMatch} from "react-router-dom";
import {getAppEnvironment} from "../../routing/routing.utils";
import {useAuth0} from '@auth0/auth0-react';
import {environment} from "../../env";
import {ApprovalStatusEnum} from "../../services/classes/AiClasses";

const menuId = 'single-dropdown-menu';

export const SingleDropdownMenu = observer(() => {
    const {authStore, approvalStore} = useStores();
    const {logout} = useAuth0();
    const history = useHistory();
    const m = useRouteMatch<CmpDataBagRouteMatch | TaxonomyRouteMatch>();

    const [menuElement, setMenuElement] = React.useState<null | HTMLElement>(null);
    const isMenuOpen = Boolean(menuElement);

    useEffect(() => {
        if (approvalStore.statusCount.result === undefined)
            approvalStore.statusCount.request();
    }, [approvalStore.statusCount]);

    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setMenuElement(event.currentTarget);
    };

    const handleMenuClose = () => {
        setMenuElement(null);
    };

    const handleLogout = () => {
        authStore.auth0Logout(logout);
        history.push(routes.login);
    };

    const renderMenu = (
            <Menu
                    anchorEl={menuElement}
                    anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                    id={menuId}
                    keepMounted
                    transformOrigin={{vertical: 'top', horizontal: 'right'}}
                    open={isMenuOpen}
                    onClose={handleMenuClose}
                    TransitionComponent={Grow}
            >
                <ListItem title={(authStore.userId ? `[${authStore.userId}] ` : '') + authStore.email}>
                    {authStore.displayUser}
                </ListItem>

                <MenuItem disabled>
                    <Typography variant="inherit">
                        Account type: {authStore.customerRole}{authStore.isMithraStaff ? ' (Staff)' : ''}
                    </Typography>
                </MenuItem>

                {authStore.isMithraStaff &&
                        <>
                            <MenuItem disabled>
                                <Typography variant="inherit">
                                    User ID: {authStore.userId}{authStore.isMithraStaff ? ' (Staff)' : ''}
                                </Typography>
                            </MenuItem>
                            <MenuItem disabled>
                                <Typography variant="inherit">Environment: {'' + getAppEnvironment(m.path)}</Typography>
                            </MenuItem>

                            <Divider/>
                        </>
                }

                {(authStore.isCustomerSuper || !environment.production) &&
                        <MenuItem onClick={() => {
                            handleMenuClose()
                            history.push(routes.approval);
                        }}>
                            Awaiting approvals
                            <span className="flex-grow-1"/>
                            {(approvalStore.getCountForStatus(ApprovalStatusEnum.PENDING) ?? 0) > 0 &&
                                    <Chip size="small" label={approvalStore.getCountForStatus(ApprovalStatusEnum.PENDING)}
                                          className="color-error"/>
                            }
                        </MenuItem>
                }
                <Divider/>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
    );

    return <>
        <IconButton
                edge="end"
                size="medium"
                aria-label="account"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit">
            {
                (authStore.isCustomerSuper || !environment.production) &&
                (approvalStore.getCountForStatus(ApprovalStatusEnum.PENDING) ?? 0) > 0
                        ? <Badge
                                badgeContent={approvalStore.getCountForStatus(ApprovalStatusEnum.PENDING)}
                                color="error">
                            <AccountCircleOutlined/>
                        </Badge>
                        : <AccountCircleOutlined/>
            }
        </IconButton>
        {renderMenu}
    </>
})
