import {makeAutoObservable} from "mobx";
import {CommonSupplierQueryFilter, CommonSuppliersReviewDelegate} from "./CommonSuppliersReviewDelegate";
import {SynergyStore} from "../SynergyStore";
import {SearchManager} from "../managers/SearchManager";

const SHOW_STANDALONE_SUPPLIERS_DEFAULT = false;

export class CommonSupplierFilterDelegate {
    readonly parentSupplierSearch = new SearchManager('parent-supplier', () => this.startRowsRequest());

    filter: Pick<CommonSupplierQueryFilter, 'showStandaloneSuppliers'> = {
        showStandaloneSuppliers: SHOW_STANDALONE_SUPPLIERS_DEFAULT
    }

    constructor(
        private readonly commonSupplierReviewDelegate: CommonSuppliersReviewDelegate,
        private readonly synergyStore: SynergyStore,
    ) {
        makeAutoObservable(this)
    }

    startRowsRequest() {
        // if (!._parentSupplierManager.result) {
        //     console.warn('CommonSupplierReviewDelegate startRowsRequest() should not start the request, ' +
        //         'as there are not parent suppliers to match the rows against...')
        //     return;
        // }
        const synergy = this.synergyStore.requestedSynergyId;
        if (!synergy) {
            console.error('CommonSupplierFilterDelegate startRowsRequest() no synergy')
            this.commonSupplierReviewDelegate._commonSupplierGroupRequest.reset();
            this.commonSupplierReviewDelegate.isLoadingSubRows = false;
            return;
        }
        this.commonSupplierReviewDelegate.isLoadingSubRows = true;
        const filter: CommonSupplierQueryFilter = {
            group_search: this.parentSupplierSearch.activeSearchString,
            showStandaloneSuppliers: this.filter.showStandaloneSuppliers,
        };
        this.commonSupplierReviewDelegate._commonSupplierGroupRequest._request({synergy, filter})
    }

    get hits() {
        return this.commonSupplierReviewDelegate._commonSupplierGroupRequest.count;
    }

    setShowStandAloneSuppliers(showStandAloneSuppliers: boolean) {
        this.filter.showStandaloneSuppliers = showStandAloneSuppliers;
        this.startRowsRequest();
    }

    toggleShowStandAloneSuppliers() {
        this.setShowStandAloneSuppliers(!this.filter.showStandaloneSuppliers);
    }

    reset() {
        this.parentSupplierSearch.resetSearch();
        this.filter.showStandaloneSuppliers = SHOW_STANDALONE_SUPPLIERS_DEFAULT;
    }
}
