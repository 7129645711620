import MithraMaterializedApi from "../../../services/MithraMaterializedApi";
import {makeAutoObservable} from "mobx";
import {MatReviewLevelStatisticsTreeSerializer, ReviewChoice} from "../../../services/classes/MaterializedClasses";
import {selectMatReviewLevelStatistics} from "../../../services/classes/MatReviewHelpers";
import {single_bar_chart} from "../../../components/visualization/single-barchart/SingleBarChartBuilder";
import {
    AiChangeChart,
    convertSingleToChart,
    convertToChart,
    convertToChartOnlyNew
} from "../../../services/classes/CategorizationHelpers";
import {CurrencyAbbreviation} from "../../../components/currency-component/CurrencyClasses";
import AuthStore from "../../../stores/AuthStore";
import ProfileStore from "../../../stores/ProfileStore";
import {CategorizationReviewPageFilterController} from "./CategorizationReviewPageFilterController";
import {UNCATEGORIZED_VALUE} from "../../../constants";
import {CategorizationReviewPageDataController} from "./CategorizationReviewPageDataController";
import {CategorizationReviewPageStatisticsController} from "./CategorizationReviewPageStatisticsController";
import {CategorizationReviewPageApprovalController} from "./CategorizationReviewPageApprovalController";
import {CategorizationReviewPageStateController, Page} from "./CategorizationReviewPageStateController";
import {ApprovalStore} from "../../../stores/ApprovalStore";
import {BarDataPoint} from "../../../components/visualization/BarChart";
import {CategorizationReviewPageSelectionController} from "./CategorizationReviewPageSelectionController";
import {CategorizationReviewStore} from "../../../stores/categorization-review/CategorizationReviewStore";
import {PartRowState} from "../classes/PartRowState";
import {Categories} from "../../../services/classes/AiClasses";
import {GroupedRowState} from "../classes/GroupedRowState";

export type SummaryKeyValues = {
    abbreviation: CurrencyAbbreviation,
    after: {
        classified_spend: number, // total - unclassified
        reclassified_spend: number,
        unclassified_spend: number,
    }
}

/**
 * Class to manage the data and interactions of the categorization review
 *
 * This store holds all controllers needed for the review page to function.
 * There are many dynamic elements, so the controllers are split into different files.
 *
 * Development:
 * - Interactions that require coordination between controllers should be defined in this class
 * - Information that is required outside the ReviewPage should be exposed in this class
 * - Each controller should be accessed through this class only, and not passed directly
 */
export class CategorizationReviewPageController {
    readonly reviewPageStateController = new CategorizationReviewPageStateController(this, this.profile, this.auth);
    readonly reviewPageDataController = new CategorizationReviewPageDataController(this, this.api, this.auth, this.profile);
    readonly reviewPageFilterController = new CategorizationReviewPageFilterController(this, this.profile)
    readonly reviewPageSelectionController = new CategorizationReviewPageSelectionController(this, this.api);
    readonly reviewPageStatisticsController = new CategorizationReviewPageStatisticsController(this.profile, this.api);
    readonly reviewPageApprovalController = new CategorizationReviewPageApprovalController(this, this.approvalStore, this.api)

    /**
     * Shows whether we are looking at a grouped or single table for all the data
     * This affects all controllers below this page
     */
    singleMode: boolean =
        // environment.isTestReviewPage ? true :
        Boolean(this.profile.p.initialGroupBySupplierInactive);

    constructor(
        private categorizationStore: CategorizationReviewStore,
        private approvalStore: ApprovalStore,
        private api: MithraMaterializedApi,
        private auth: AuthStore,
        private profile: ProfileStore,
    ) {
        makeAutoObservable(this)
    }

    get bagId(): number {
        return this.categorizationStore.bagId;
    }

    get taxonomySize(): number {
        return this.categorizationStore.taxonomySize;
    }

    /**
     * TODO: This needs to be deprecated as for SHV
     */
    resetAndRequestSupplierPage() {
        this.reviewPageDataController.supplierPages.page = 1;
        this.reviewPageDataController.supplierPages.init(this.reviewPageFilterController.selectedFilter);
    }

    resetPage() {
        this.reviewPageDataController.supplierPages.page = 1;
        this.reviewPageDataController.partPages.page = 1;
    }

    get _selectedStats() {
        const s = this.reviewPageStatisticsController.reviewLevelStatistics;
        if (!s) {
            return undefined
        }
        return selectMatReviewLevelStatistics(s, this.reviewPageFilterController.selectedCategory)
    }

    get parentCharts(): {
        charts: {
            category: string,
            data: single_bar_chart.Data
        }[],
        max: number,
    } | undefined {
        if (!this._selectedStats) return undefined;
        const parentStats = this._selectedStats.slice(1);
        if (parentStats.length === 0) return undefined;
        const charts = parentStats.map((stat, i) => {
            const aiChangeChart = convertSingleToChart(stat, this.profile.currencySymbol, true);
            const data: single_bar_chart.Data = {
                mainLabel: `L${i + 1}: ${aiChangeChart.label}`,
                values: aiChangeChart.values,
            }
            return {category: aiChangeChart.category, data};
        })
        const COL_WIDTH = 6 / 9 // Show as width col=6, while the whole viz goes to col=9
        const max = Math.max(...charts.map(c => Math.max(...c.data.values.map(v => v.value)))) / COL_WIDTH;
        return {charts, max}
    }

    get currentSelectionStats(): undefined | MatReviewLevelStatisticsTreeSerializer[] {
        if (!this.reviewPageFilterController.hasRemainingSelectionLevels) return undefined;
        if (!this._selectedStats) return undefined;
        return this._selectedStats[this._selectedStats.length - 1].children;
    }

    get selectionCharts(): {
        data: AiChangeChart[],
        max: number,
    } | undefined {
        if (!this.reviewPageFilterController.canSelectLevelDeeper) return undefined;
        let d = this.currentSelectionStats;
        if (!d) return undefined;

        const data = convertToChart(d, this.profile.currencySymbol)

        if (this.profile.p.hackHideUncategorizedInReview) {
            const uncategorizedIndex = data.findIndex(d => d.category === UNCATEGORIZED_VALUE)
            if (uncategorizedIndex !== -1) {
                console.warn('Hack: Hiding uncategorized in review')
                data.splice(uncategorizedIndex, 1)
            }
        }

        const COL_WIDTH = 6 / 9 // Show as width col=6, while the whole viz goes to col=9
        const max = Math.max(...data.map(D => Math.max(...D.values.map(v => v.value)))) / COL_WIDTH;
        return {data, max}
    }

    get reviewPageIsLoading() {
        return this.reviewPageStatisticsController._reviewStatistics.busy
            || this.reviewPageStatisticsController._reviewLevelStatistics.busy
    }

    get onlyNewSelectionCharts(): {
        data: AiChangeChart[],
        max: number,
    } | undefined {
        if (!this.reviewPageFilterController.hasRemainingSelectionLevels) return undefined;
        if (!this._selectedStats) return undefined;
        let d: MatReviewLevelStatisticsTreeSerializer[] = this._selectedStats[this._selectedStats.length - 1].children;

        const data = convertToChartOnlyNew(d, this.profile.currencySymbol)
        const COL_WIDTH = 6 / 9 // Show as width col=6, while the whole viz goes to col=9
        const max = Math.max(...data.map(D => Math.max(...D.values.map(v => v.value)))) / COL_WIDTH;
        return {data, max}
    }


    navigateToPage(page: Page) {
        if (page === 'ai-run') {
            this.reviewPageFilterController.selectedCategory = [];
        }
        if (page === 'review-dashboard') {
            this.reviewPageFilterController.clearAdvancedFilter()
        }
        this.reviewPageStateController.setPage(page);
    }

    handleDistributionClick(d: BarDataPoint) {
        if (d.category === "0_20") {
            this.reviewPageFilterController.clickAdvancedFilter(0, 20)
        }
        if (d.category === "20_40") {
            this.reviewPageFilterController.clickAdvancedFilter(21, 40)
        }
        if (d.category === "40_60") {
            this.reviewPageFilterController.clickAdvancedFilter(41, 60)
        }
        if (d.category === "60_80") {
            this.reviewPageFilterController.clickAdvancedFilter(61, 80)
        }
        if (d.category === "80_100") {
            this.reviewPageFilterController.clickAdvancedFilter(81, 100)
        }
    }

    requestPartPages() {
        const bagId = this.bagId;
        this.reviewPageSelectionController.lastSelected = undefined;
        if (!bagId) {
            console.error('No bag id set for part pages');
            this.reviewPageDataController.partPages.reset()
        } else {
            const urlParams = this.reviewPageFilterController.getPartUrlSearchParams();

            this.reviewPageDataController.partPages._request({
                databag: bagId,
                urlParams,
            });
        }
    }

    loadData(initialLoad = false) {
        const bagId = this.bagId;
        const taxonomySize = this.categorizationStore.taxonomySize;
        const businessUnitId = this.reviewPageFilterController.filteredBusinessUnitId;
        const singleMode = this.singleMode;

        console.log('CategorizationReviewPageController.loadData: ', {bagId, businessUnitId, singleMode, taxonomySize});
        if (bagId === -1) {
            // Reset
            this.reviewPageSelectionController.reset()
            this.reviewPageDataController.partPages.reset()
            this.reviewPageDataController.supplierPages.reset()
            this.reviewPageStatisticsController.reset();
        } else if (bagId) {
            this.reviewPageSelectionController.reset();
            if (singleMode) {
                this.requestPartPages()
            } else {
                this.resetAndRequestSupplierPage();
            }
            if (initialLoad) {
                this.reviewPageStatisticsController.request(bagId, businessUnitId, taxonomySize)
            }
        }
    }

    loadSubTable() {
        const bagId = this.bagId;
        const taxonomySize = this.categorizationStore.taxonomySize;
        const businessUnitId = this.reviewPageFilterController.filteredBusinessUnitId;
        const singleMode = this.singleMode;

        if (bagId) {
            this.resetAndRequestSupplierPage();
        }
    }

    setSingleMode(singleMode: boolean) {
        this.singleMode = singleMode;
    }

    /**
     * Clicked on the accept/reject button of a part
     * @param part
     * @param review_choice
     */
    onClickAcceptRejectPart(part: PartRowState, review_choice: ReviewChoice) {
        if (!part.isOpenToOnlyAccept()) {
            console.warn(`onClickAcceptRejectPart(${part.id}): The user clicked a part that should not be clickable`)
            return;
        }

        // De-select everything, as the user is interacting with a single part to avoid confusion
        this.reviewPageSelectionController.onDeselectAll();

        this.reviewPageDataController.sendPartReviewChoice(part, review_choice);
    }

    /**
     * Clicked on the change category button of a part
     * @param part
     * @param category
     */
    onClickReCategorizePart(part: PartRowState, category: Categories) {
        if (!part.isOpenToRecat()) {
            console.warn(`onClickReCategorizePart(${part.id}): The user clicked a part that should not be clickable`)
            return;
        }

        // De-select everything, as the user is interacting with a single part to avoid confusion
        this.reviewPageSelectionController.onDeselectAll();

        this.reviewPageDataController.sendPartReviewCategory(part, category);
    }

    /**
     * Clicked on the accept/reject button of a supplier
     * @param row
     * @param review_choice
     */
    onClickAcceptRejectSupplierParts(row: GroupedRowState, review_choice: ReviewChoice): void {
        // De-select everything, as the user is interacting with a single part to avoid confusion
        this.reviewPageSelectionController.onDeselectAll();

        this.reviewPageDataController.sendSupplierReviewChoice(row, review_choice);
    }


    /**
     * Clicked on the change category button of a supplier
     * @param row
     * @param category
     */
    onClickReCategorizeSupplierParts(row: GroupedRowState, category: Categories): void {
        // De-select everything, as the user is interacting with a single part to avoid confusion
        this.reviewPageSelectionController.onDeselectAll();

        this.reviewPageDataController.sendSupplierReviewCategory(row, category);
    }

    /**
     * When the user clicks the accept/reject button for the entire selection
     */
    onClickAcceptRejectSelection(reviewChoice: ReviewChoice): void {
        const singleMode = this.singleMode;
        if (singleMode) {
            // Check if ALL parts are selected
            if (this.reviewPageSelectionController.isAllSelected) {
                this.reviewPageDataController.sendAllPartsReviewChoice(reviewChoice);
            } else {
                // Change the selected parts
                this.reviewPageDataController.sendPartsReviewChoice(this.reviewPageSelectionController.selectedPartsInView, reviewChoice)
            }
        } else {
            if (this.reviewPageSelectionController.isAllSelected) {
                this.reviewPageDataController.sendAllSuppliersReviewChoice(reviewChoice);
            } else {
                // Change the selected suppliers
                const ss = this.reviewPageSelectionController.selectedSuppliersInView;
                const fullSelected = ss.filter(s => s.isAllSelected);
                const partialSelected = ss.filter(s => !s.isAllSelected);

                this.reviewPageDataController.sendSuppliersReviewChoice(fullSelected, reviewChoice);
                partialSelected.forEach(s => {
                    const selectedParts = s.partStates?.filter(p => p.selected);
                    if (selectedParts) {
                        this.reviewPageDataController.sendPartsReviewChoice(selectedParts, reviewChoice)
                    }
                })
                // TODO: Do we have to update the view here as well?
            }
        }

        // Then de-select everything
        this.reviewPageSelectionController.onDeselectAll()
    }


    /**
     * used for bulk edit
     * Clicked the new category for all selected rows; parts, suppliers or supplier->parts
     * @param categories
     */
    onClickReCategorizeSelection(categories: Categories) {
        const singleMode = this.singleMode;
        if (singleMode) {
            // Check if simply ALL parts are selected
            if (this.reviewPageSelectionController.isAllSelected) {
                this.reviewPageDataController.sendAllPartsReviewCategory(categories);
            } else {
                // Change the selected parts
                this.reviewPageDataController.sendPartsReviewCategory(this.reviewPageSelectionController.selectedPartsInView, categories)
            }
        } else {
            if (this.reviewPageSelectionController.isAllSelected) {
                this.reviewPageDataController.sendAllSuppliersReviewCategory(categories);
            } else {
                // Change the selected suppliers
                const ss = this.reviewPageSelectionController.selectedSuppliersInView;
                const fullSelected = ss.filter(s => s.isAllSelected);
                const partialSelected = ss.filter(s => !s.isAllSelected);

                this.reviewPageDataController.sendSuppliersReviewCategory(fullSelected, categories);
                partialSelected.forEach(s => {
                    const selectedParts = s.partStates?.filter(p => p.selected);
                    if (selectedParts) {
                        this.reviewPageDataController.sendPartsReviewCategory(selectedParts, categories)
                    }
                })
                // TODO: Do we have to update the view here as well?
            }
        }

        // Then de-select everything
        this.reviewPageSelectionController.onDeselectAll()
    }
}
