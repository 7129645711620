import MithraMaterializedApi from "../../services/MithraMaterializedApi";
import {ApprovalStore} from "../ApprovalStore";
import {forkJoin, from, mergeMap, tap} from "rxjs";
import {ReviewChoice} from "../../services/classes/MaterializedClasses";
import {makeAutoObservable, runInAction} from "mobx";
import {ApprovalStatusEnum} from "../../services/classes/AiClasses";
import {BagStore} from "../BagStore";
import {TaxonomyCategoryProvider} from "../TaxonomyCategoryProvider";
import {History} from "history";
import {MithraHistoryState, routes} from "../../routing/routes";
import AuthStore from "../AuthStore";
import ProfileStore from "../ProfileStore";
import {CategorizationReviewFeedbackDelegate} from "./CategorizationReviewFeedbackDelegate";
import {CategorizationReviewStore} from "../categorization-review/CategorizationReviewStore";

/**
 * The Approval Store delegate for Categorization
 */
export class CategorizationApprovalDelegate {
    readonly reviewFeedback = new CategorizationReviewFeedbackDelegate(this.api);

    /**
     * Used for reloading data in the app after approval
     *
     * This link is during the instantiation of the categorizationStore, as both depend on each-other.
     * We can consider moving this link to a separate flow store to circumvent this problem.
     */
    categorizationStore: CategorizationReviewStore = undefined as any;

    // noinspection JSUnusedLocalSymbols
    constructor(
        private approvalStore: ApprovalStore,
        private auth: AuthStore,
        private bagStore: BagStore,
        private profileStore: ProfileStore,
        private api: MithraMaterializedApi,
    ) {
        makeAutoObservable(this)
    }

    /**
     * @deprecated Feedback is not allowed to change the categories anymore so this should not be needed
     */
    readonly categories = new TaxonomyCategoryProvider(this.profileStore, this.api);

    makeRetrieveApprovalRequest(approvalId: number) {
        return from(this.api.getCategorizationApprovalRequest(approvalId)).pipe(
            tap(r => {
                console.log('makeRetrieveApprovalRequest', r.data.current_status.status)
                this.approvalStore.setApproval(r.data);
            }),
            tap(r => {
                // Update the categories of the categorization feedback screen
                // Note: There is some unwanted subscription management here

                const taxonomyId = r.data.baseline_taxonomy;
                if (taxonomyId === null) {
                    console.warn('Categorization approval cannot be reviewed as taxonomy is missing')
                    this.categories.arrest('Approval has no associated taxonomy');
                } else {
                    this.categories.fillCategoriesFromTaxonomy(taxonomyId);
                }
            })
        );
    }

    applyApproval(history: History<MithraHistoryState>) {
        const approvalId = this.approvalStore.approval?.id;
        if (!approvalId) return

        const update = from(this.api.applyCategorizationApproval(approvalId, ApprovalStatusEnum.APPROVED));

        const request = update.pipe(
            mergeMap(() => forkJoin([
                this.approvalStore.fetchAll(),
                this.makeRetrieveApprovalRequest(approvalId),
            ])),
            tap(([_, r]) => runInAction(() => {
                this.approvalStore.isAcceptApprovalDialogOpen = false;

                if (!ApprovalStatusEnum.userCanUpdate(r.data.current_status.status)) {
                    history.push(routes.approval);
                }
            })),
        )

        this.approvalStore.approvalLoader.request(request);
    }

    loadStats() {
        const id = this.approvalStore.approval?.id;
        if (!id) {
            this.reviewFeedback.stats.cleanup();
        } else {
            this.reviewFeedback.stats.request(id)
        }
    }

    clickSetAll(feedback_choice: ReviewChoice) {
        const id = this.approvalStore.approval?.id;
        this.reviewFeedback.clickSetAll(id, feedback_choice);
    }
}
