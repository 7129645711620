import React from "react";
import {generatePath} from "react-router";
import {useRouteMatch} from "react-router-dom";
import {CmpDataBagRouteMatch, routes, TaxonomyRouteMatch} from "../../../../routing/routes";
import {lookupHomePath} from "../../../../routing/routing.utils";
import {MenuItem, MenuItems} from "../../../../components/main/MenuItems";

export const SalesDemoCleanupMainMenu: React.FC = () => {
    const m = useRouteMatch<CmpDataBagRouteMatch | TaxonomyRouteMatch>();
    const hasId1 = m.params.id !== undefined;
    const hasId2 = m.params['id2'] !== undefined;
    const homeLink = lookupHomePath(m.path);

    // const existsHome = homeLink !== null;
    const isHome = m.path === homeLink;

    const menu: MenuItem[] = [
        {
            label: 'Data',
            link: hasId1 ? generatePath(routes.job_manage, m.params) : routes.home,
            active: m.path === routes.home || m.path === routes.job_manage,
            className: '',
        },
        {
            label: 'Cleanup',
            link: hasId1 ? generatePath(routes.demo_cleanup_dashboard, m.params) : routes.home,
            active: (
                m.path === routes.home || m.path === routes.demo_cleanup_dashboard
                || m.path === routes.demo_cleanup_supplier_normalization
                || m.path === routes.demo_cleanup_categorization
                || m.path === routes.demo_cleanup_taxonomy_builder
                || m.path === routes.demo_cleanup_taxonomy_builder_id
            ),
            className: '',
        },
        {
            label: 'Analytics',
            link: hasId1 ? generatePath(routes.demo_cleanup_analytics_dashboard, m.params) : routes.home,
            active: (
                m.path === routes.demo_cleanup_analytics_dashboard
                || m.path === routes.demo_cleanup_analytics_koi_ss
                || m.path === routes.demo_cleanup_analytics_koi_kdf
                || m.path === routes.demo_cleanup_analytics_koi_ppv
                || m.path === routes.demo_cleanup_analytics_koi_sc
                || m.path === routes.demo_cleanup_analytics_adv_dashboard
            ),
            // className: '',
        },
        {
            label: 'Opportunities',
            link: hasId1 ? generatePath(routes.demo_cleanup_opportunities, m.params) : routes.home,
            active: !isHome && m.path === routes.demo_cleanup_opportunities,
            isTrial: false,
            className: 'menu-item-2nd-last',
        },
        // {
        //     label: 'Report',
        //     link: hasId1 ? generatePath(routes.job_report, m.params) : routes.home,
        //     active: !isHome && m.path === routes.job_report,
        //     isTrial: true,
        //     className: 'menu-item-last',
        // },
    ]
    return <MenuItems className="full-main-menu" items={menu}/>
}
