export const continents = ['EU', 'APAC', 'EMEA', 'NORTH_US', 'LATIN', 'Antarctica'];

const countries = {
    "AF": {
        coordinates: [65, 33],
        continent: "APAC",
    },
    "AL": {
        coordinates: [20, 41],
        continent: "EU",
    },
    "DZ": {
        coordinates: [3, 28],
        continent: "EMEA",
    },
    "AS": {
        coordinates: [-170, -14.3333],
        continent: "APAC",
    },
    "AD": {
        coordinates: [1.6, 42.5],
        continent: "EU",
    },
    "AO": {
        coordinates: [18.5, -12.5],
        continent: "EMEA",
    },
    "AI": {
        coordinates: [-63.1667, 18.25],
        continent: "NORTH_US",
    },
    "AQ": {
        coordinates: [0, -90],
        continent: "Antarctica",
    },
    "AG": {
        coordinates: [-61.8, 17.05],
        continent: "NORTH_US",
    },
    "AR": {
        coordinates: [-64, -34],
        continent: "LATIN",
    },
    "AM": {
        coordinates: [45, 40],
        continent: "APAC",
    },
    "AW": {
        coordinates: [-69.9667, 12.5],
        continent: "NORTH_US",
    },
    "AU": {
        coordinates: [133, -27],
        continent: "APAC",
    },
    "AT": {
        coordinates: [13.3333, 47.3333],
        continent: "EU",
    },
    "AZ": {
        coordinates: [47.5, 40.5],
        continent: "APAC",
    },
    "BS": {
        coordinates: [-76, 24.25],
        continent: "NORTH_US",
    },
    "BH": {
        coordinates: [50.55, 26],
        continent: "APAC",
    },
    "BD": {
        coordinates: [90, 24],
        continent: "APAC",
    },
    "BB": {
        coordinates: [-59.5333, 13.1667],
        continent: "NORTH_US",
    },
    "BY": {
        coordinates: [28, 53],
        continent: "EU",
    },
    "BE": {
        coordinates: [4, 50.8333],
        continent: "EU",
    },
    "BZ": {
        coordinates: [-88.75, 17.25],
        continent: "NORTH_US",
    },
    "BJ": {
        coordinates: [2.25, 9.5],
        continent: "EMEA",
    },
    "BM": {
        coordinates: [-64.75, 32.3333],
        continent: "NORTH_US",
    },
    "BT": {
        coordinates: [90.5, 27.5],
        continent: "APAC",
    },
    "BO": {
        coordinates: [-65, -17],
        continent: "LATIN",
    },
    "BA": {
        coordinates: [18, 44],
        continent: "EU",
    },
    "BW": {
        coordinates: [24, -22],
        continent: "EMEA",
    },
    "BV": {
        coordinates: [3.4, -54.4333],
        continent: "Antarctica",
    },
    "BR": {
        coordinates: [-55, -10],
        continent: "LATIN",
    },
    "IO": {
        coordinates: [71.5, -6],
        continent: "APAC",
    },
    "BN": {
        coordinates: [114.6667, 4.5],
        continent: "APAC",
    },
    "BG": {
        coordinates: [25, 43],
        continent: "EU",
    },
    "BF": {
        coordinates: [-2, 13],
        continent: "EMEA",
    },
    "BI": {
        coordinates: [30, -3.5],
        continent: "EMEA",
    },
    "KH": {
        coordinates: [105, 13],
        continent: "APAC",
    },
    "CM": {
        coordinates: [12, 6],
        continent: "EMEA",
    },
    "CA": {
        coordinates: [-95, 60],
        continent: "NORTH_US",
    },
    "CV": {
        coordinates: [-24, 16],
        continent: "EMEA",
    },
    "KY": {
        coordinates: [-80.5, 19.5],
        continent: "NORTH_US",
    },
    "CF": {
        coordinates: [21, 7],
        continent: "EMEA",
    },
    "TD": {
        coordinates: [19, 15],
        continent: "EMEA",
    },
    "CL": {
        coordinates: [-71, -30],
        continent: "LATIN",
    },
    "CN": {
        coordinates: [105, 35],
        continent: "APAC",
    },
    "CX": {
        coordinates: [105.6667, -10.5],
        continent: "APAC",
    },
    "CC": {
        coordinates: [96.8333, -12.5],
        continent: "APAC",
    },
    "CO": {
        coordinates: [-72, 4],
        continent: "LATIN",
    },
    "KM": {
        coordinates: [44.25, -12.1667],
        continent: "EMEA",
    },
    "CG": {
        coordinates: [15, -1],
        continent: "EMEA",
    },
    "CD": {
        coordinates: [25, 0],
        continent: "EMEA",
    },
    "CK": {
        coordinates: [-159.7667, -21.2333],
        continent: "APAC",
    },
    "CR": {
        coordinates: [-84, 10],
        continent: "NORTH_US",
    },
    "CI": {
        coordinates: [-5, 8],
        continent: "EMEA",
    },
    "HR": {
        coordinates: [15.5, 45.1667],
        continent: "EU",
    },
    "CU": {
        coordinates: [-80, 21.5],
        continent: "NORTH_US",
    },
    "CY": {
        coordinates: [33, 35],
        continent: "APAC",
    },
    "CZ": {
        coordinates: [15.5, 49.75],
        continent: "EU",
    },
    "DK": {
        coordinates: [10, 56],
        continent: "EU",
    },
    "DJ": {
        coordinates: [43, 11.5],
        continent: "EMEA",
    },
    "DM": {
        coordinates: [-61.3333, 15.4167],
        continent: "NORTH_US",
    },
    "DO": {
        coordinates: [-70.6667, 19],
        continent: "NORTH_US",
    },
    "EC": {
        coordinates: [-77.5, -2],
        continent: "LATIN",
    },
    "EG": {
        coordinates: [30, 27],
        continent: "EMEA",
    },
    "SV": {
        coordinates: [-88.9167, 13.8333],
        continent: "NORTH_US",
    },
    "GQ": {
        coordinates: [10, 2],
        continent: "EMEA",
    },
    "ER": {
        coordinates: [39, 15],
        continent: "EMEA",
    },
    "EE": {
        coordinates: [26, 59],
        continent: "EU",
    },
    "ET": {
        coordinates: [38, 8],
        continent: "EMEA",
    },
    "FK": {
        coordinates: [-59, -51.75],
        continent: "LATIN",
    },
    "FO": {
        coordinates: [-7, 62],
        continent: "EU",
    },
    "FJ": {
        coordinates: [175, -18],
        continent: "APAC",
    },
    "FI": {
        coordinates: [26, 64],
        continent: "EU",
    },
    "FR": {
        coordinates: [2, 46],
        continent: "EU",
    },
    "GF": {
        coordinates: [-53, 4],
        continent: "LATIN",
    },
    "PF": {
        coordinates: [-140, -15],
        continent: "APAC",
    },
    "TF": {
        coordinates: [67, -43],
        continent: "Antarctica",
    },
    "GA": {
        coordinates: [11.75, -1],
        continent: "EMEA",
    },
    "GM": {
        coordinates: [-16.5667, 13.4667],
        continent: "EMEA",
    },
    "GE": {
        coordinates: [43.5, 42],
        continent: "EU",
    },
    "DE": {
        coordinates: [9, 51],
        continent: "EU",
    },
    "GH": {
        coordinates: [-2, 8],
        continent: "EMEA",
    },
    "GI": {
        coordinates: [-5.3667, 36.1833],
        continent: "EU",
    },
    "GR": {
        coordinates: [22, 39],
        continent: "EU",
    },
    "GL": {
        coordinates: [-40, 72],
        continent: "NORTH_US",
    },
    "GD": {
        coordinates: [-61.6667, 12.1167],
        continent: "NORTH_US",
    },
    "GP": {
        coordinates: [-61.5833, 16.25],
        continent: "NORTH_US",
    },
    "GU": {
        coordinates: [144.7833, 13.4667],
        continent: "APAC",
    },
    "GT": {
        coordinates: [-90.25, 15.5],
        continent: "NORTH_US",
    },
    "GG": {
        coordinates: [-2.56, 49.5],
        continent: "EU",
    },
    "GN": {
        coordinates: [-10, 11],
        continent: "EMEA",
    },
    "GW": {
        coordinates: [-15, 12],
        continent: "EMEA",
    },
    "GY": {
        coordinates: [-59, 5],
        continent: "LATIN",
    },
    "HT": {
        coordinates: [-72.4167, 19],
        continent: "NORTH_US",
    },
    "HM": {
        coordinates: [72.5167, -53.1],
        continent: "Antarctica",
    },
    "VA": {
        coordinates: [12.45, 41.9],
        continent: "EU",
    },
    "HN": {
        coordinates: [-86.5, 15],
        continent: "NORTH_US",
    },
    "HK": {
        coordinates: [114.1667, 22.25],
        continent: "APAC",
    },
    "HU": {
        coordinates: [20, 47],
        continent: "EU",
    },
    "IS": {
        coordinates: [-18, 65],
        continent: "EU",
    },
    "IN": {
        coordinates: [77, 20],
        continent: "APAC",
    },
    "ID": {
        coordinates: [120, -5],
        continent: "APAC",
    },
    "IR": {
        coordinates: [53, 32],
        continent: "APAC",
    },
    "IQ": {
        coordinates: [44, 33],
        continent: "APAC",
    },
    "IE": {
        coordinates: [-8, 53],
        continent: "EU",
    },
    "IM": {
        coordinates: [-4.55, 54.23],
        continent: "EU",
    },
    "IL": {
        coordinates: [34.75, 31.5],
        continent: "APAC",
    },
    "IT": {
        coordinates: [12.8333, 42.8333],
        continent: "EU",
    },
    "JM": {
        coordinates: [-77.5, 18.25],
        continent: "NORTH_US",
    },
    "JP": {
        coordinates: [138, 36],
        continent: "APAC",
    },
    "JE": {
        coordinates: [-2.13, 49.21],
        continent: "EU",
    },
    "JO": {
        coordinates: [36, 31],
        continent: "APAC",
    },
    "KZ": {
        coordinates: [68, 48],
        continent: "APAC",
    },
    "KE": {
        coordinates: [38, 1],
        continent: "EMEA",
    },
    "KI": {
        coordinates: [173, 1.4167],
        continent: "APAC",
    },
    "KP": {
        coordinates: [127, 40],
        continent: "APAC",
    },
    "KR": {
        coordinates: [127.5, 37],
        continent: "APAC",
    },
    "KW": {
        coordinates: [47.6581, 29.3375],
        continent: "APAC",
    },
    "KG": {
        coordinates: [75, 41],
        continent: "APAC",
    },
    "LA": {
        coordinates: [105, 18],
        continent: "APAC",
    },
    "LV": {
        coordinates: [25, 57],
        continent: "EU",
    },
    "LB": {
        coordinates: [35.8333, 33.8333],
        continent: "APAC",
    },
    "LS": {
        coordinates: [28.5, -29.5],
        continent: "EMEA",
    },
    "LR": {
        coordinates: [-9.5, 6.5],
        continent: "EMEA",
    },
    "LY": {
        coordinates: [17, 25],
        continent: "EMEA",
    },
    "LI": {
        coordinates: [9.5333, 47.1667],
        continent: "EU",
    },
    "LT": {
        coordinates: [24, 56],
        continent: "EU",
    },
    "LU": {
        coordinates: [6.1667, 49.75],
        continent: "EU",
    },
    "MO": {
        coordinates: [113.55, 22.1667],
        continent: "APAC",
    },
    "MK": {
        coordinates: [22, 41.8333],
        continent: "EU",
    },
    "MG": {
        coordinates: [47, -20],
        continent: "EMEA",
    },
    "MW": {
        coordinates: [34, -13.5],
        continent: "EMEA",
    },
    "MY": {
        coordinates: [112.5, 2.5],
        continent: "APAC",
    },
    "MV": {
        coordinates: [73, 3.25],
        continent: "APAC",
    },
    "ML": {
        coordinates: [-4, 17],
        continent: "EMEA",
    },
    "MT": {
        coordinates: [14.5833, 35.8333],
        continent: "EU",
    },
    "MH": {
        coordinates: [168, 9],
        continent: "APAC",
    },
    "MQ": {
        coordinates: [-61, 14.6667],
        continent: "NORTH_US",
    },
    "MR": {
        coordinates: [-12, 20],
        continent: "EMEA",
    },
    "MU": {
        coordinates: [57.55, -20.2833],
        continent: "EMEA",
    },
    "YT": {
        coordinates: [45.1667, -12.8333],
        continent: "EMEA",
    },
    "MX": {
        coordinates: [-102, 23],
        continent: "NORTH_US",
    },
    "FM": {
        coordinates: [158.25, 6.9167],
        continent: "APAC",
    },
    "MD": {
        coordinates: [29, 47],
        continent: "EU",
    },
    "MC": {
        coordinates: [7.4, 43.7333],
        continent: "EU",
    },
    "MN": {
        coordinates: [105, 46],
        continent: "APAC",
    },
    "ME": {
        coordinates: [19, 42],
        continent: "EU",
    },
    "MS": {
        coordinates: [-62.2, 16.75],
        continent: "NORTH_US",
    },
    "MA": {
        coordinates: [-5, 32],
        continent: "EMEA",
    },
    "MZ": {
        coordinates: [35, -18.25],
        continent: "EMEA",
    },
    "MM": {
        coordinates: [98, 22],
        continent: "APAC",
    },
    "NA": {
        coordinates: [17, -22],
        continent: "EMEA",
    },
    "NR": {
        coordinates: [166.9167, -0.5333],
        continent: "APAC",
    },
    "NP": {
        coordinates: [84, 28],
        continent: "APAC",
    },
    "NL": {
        coordinates: [5.75, 52.5],
        continent: "EU",
    },
    "AN": {
        coordinates: [-68.75, 12.25],
        continent: "NORTH_US",
    },
    "NC": {
        coordinates: [165.5, -21.5],
        continent: "APAC",
    },
    "NZ": {
        coordinates: [174, -41],
        continent: "APAC",
    },
    "NI": {
        coordinates: [-85, 13],
        continent: "NORTH_US",
    },
    "NE": {
        coordinates: [8, 16],
        continent: "EMEA",
    },
    "NG": {
        coordinates: [8, 10],
        continent: "EMEA",
    },
    "NU": {
        coordinates: [-169.8667, -19.0333],
        continent: "APAC",
    },
    "NF": {
        coordinates: [167.95, -29.0333],
        continent: "APAC",
    },
    "MP": {
        coordinates: [145.75, 15.2],
        continent: "APAC",
    },
    "NO": {
        coordinates: [10, 62],
        continent: "EU",
    },
    "OM": {
        coordinates: [57, 21],
        continent: "APAC",
    },
    "PK": {
        coordinates: [70, 30],
        continent: "APAC",
    },
    "PW": {
        coordinates: [134.5, 7.5],
        continent: "APAC",
    },
    "PS": {
        coordinates: [35.25, 32],
        continent: "APAC",
    },
    "PA": {
        coordinates: [-80, 9],
        continent: "NORTH_US",
    },
    "PG": {
        coordinates: [147, -6],
        continent: "APAC",
    },
    "PY": {
        coordinates: [-58, -23],
        continent: "LATIN",
    },
    "PE": {
        coordinates: [-76, -10],
        continent: "LATIN",
    },
    "PH": {
        coordinates: [122, 13],
        continent: "APAC",
    },
    "PN": {
        coordinates: [-127.4, -24.7],
        continent: "APAC",
    },
    "PL": {
        coordinates: [20, 52],
        continent: "EU",
    },
    "PT": {
        coordinates: [-8, 39.5],
        continent: "EU",
    },
    "PR": {
        coordinates: [-66.5, 18.25],
        continent: "NORTH_US",
    },
    "QA": {
        coordinates: [51.25, 25.5],
        continent: "APAC",
    },
    "RE": {
        coordinates: [55.6, -21.1],
        continent: "EMEA",
    },
    "RO": {
        coordinates: [25, 46],
        continent: "EU",
    },
    "RU": {
        coordinates: [100, 60],
        continent: "EU",
    },
    "RW": {
        coordinates: [30, -2],
        continent: "EMEA",
    },
    "SH": {
        coordinates: [-5.7, -15.9333],
        continent: "EMEA",
    },
    "KN": {
        coordinates: [-62.75, 17.3333],
        continent: "NORTH_US",
    },
    "LC": {
        coordinates: [-61.1333, 13.8833],
        continent: "NORTH_US",
    },
    "PM": {
        coordinates: [-56.3333, 46.8333],
        continent: "NORTH_US",
    },
    "VC": {
        coordinates: [-61.2, 13.25],
        continent: "NORTH_US",
    },
    "WS": {
        coordinates: [-172.3333, -13.5833],
        continent: "APAC",
    },
    "SM": {
        coordinates: [12.4167, 43.7667],
        continent: "EU",
    },
    "ST": {
        coordinates: [7, 1],
        continent: "EMEA",
    },
    "SA": {
        coordinates: [45, 25],
        continent: "APAC",
    },
    "SN": {
        coordinates: [-14, 14],
        continent: "EMEA",
    },
    "RS": {
        coordinates: [21, 44],
        continent: "EU",
    },
    "SC": {
        coordinates: [55.6667, -4.5833],
        continent: "EMEA",
    },
    "SL": {
        coordinates: [-11.5, 8.5],
        continent: "EMEA",
    },
    "SG": {
        coordinates: [103.8, 1.3667],
        continent: "APAC",
    },
    "SK": {
        coordinates: [19.5, 48.6667],
        continent: "EU",
    },
    "SI": {
        coordinates: [15, 46],
        continent: "EU",
    },
    "SB": {
        coordinates: [159, -8],
        continent: "APAC",
    },
    "SO": {
        coordinates: [49, 10],
        continent: "EMEA",
    },
    "ZA": {
        coordinates: [24, -29],
        continent: "EMEA",
    },
    "GS": {
        coordinates: [-37, -54.5],
        continent: "Antarctica",
    },
    "SS": {
        coordinates: [30, 8],
        continent: "EMEA",
    },
    "ES": {
        coordinates: [-4, 40],
        continent: "EU",
    },
    "LK": {
        coordinates: [81, 7],
        continent: "APAC",
    },
    "SD": {
        coordinates: [30, 15],
        continent: "EMEA",
    },
    "SR": {
        coordinates: [-56, 4],
        continent: "LATIN",
    },
    "SJ": {
        coordinates: [20, 78],
        continent: "EU",
    },
    "SZ": {
        coordinates: [31.5, -26.5],
        continent: "EMEA",
    },
    "SE": {
        coordinates: [15, 62],
        continent: "EU",
    },
    "CH": {
        coordinates: [8, 47],
        continent: "EU",
    },
    "SY": {
        coordinates: [38, 35],
        continent: "APAC",
    },
    "TW": {
        coordinates: [121, 23.5],
        continent: "APAC",
    },
    "TJ": {
        coordinates: [71, 39],
        continent: "APAC",
    },
    "TZ": {
        coordinates: [35, -6],
        continent: "EMEA",
    },
    "TH": {
        coordinates: [100, 15],
        continent: "APAC",
    },
    "TL": {
        coordinates: [125.5167, -8.55],
        continent: "APAC",
    },
    "TG": {
        coordinates: [1.1667, 8],
        continent: "EMEA",
    },
    "TK": {
        coordinates: [-172, -9],
        continent: "APAC",
    },
    "TO": {
        coordinates: [-175, -20],
        continent: "APAC",
    },
    "TT": {
        coordinates: [-61, 11],
        continent: "NORTH_US",
    },
    "TN": {
        coordinates: [9, 34],
        continent: "EMEA",
    },
    "TR": {
        coordinates: [35, 39],
        continent: "APAC",
    },
    "TM": {
        coordinates: [60, 40],
        continent: "APAC",
    },
    "TC": {
        coordinates: [-71.5833, 21.75],
        continent: "NORTH_US",
    },
    "TV": {
        coordinates: [178, -8],
        continent: "APAC",
    },
    "UG": {
        coordinates: [32, 1],
        continent: "EMEA",
    },
    "UA": {
        coordinates: [32, 49],
        continent: "EU",
    },
    "AE": {
        coordinates: [54, 24],
        continent: "APAC",
    },
    "GB": {
        coordinates: [-2, 54],
        continent: "EU",
    },
    "US": {
        coordinates: [-97, 38],
        continent: "NORTH_US",
    },
    "UM": {
        coordinates: [166.6, 19.2833],
        continent: "APAC",
    },
    "UY": {
        coordinates: [-56, -33],
        continent: "LATIN",
    },
    "UZ": {
        coordinates: [64, 41],
        continent: "APAC",
    },
    "VU": {
        coordinates: [167, -16],
        continent: "APAC",
    },
    "VE": {
        coordinates: [-66, 8],
        continent: "LATIN",
    },
    "VN": {
        coordinates: [106, 16],
        continent: "APAC",
    },
    "VG": {
        coordinates: [-64.5, 18.5],
        continent: "NORTH_US",
    },
    "VI": {
        coordinates: [-64.8333, 18.3333],
        continent: "NORTH_US",
    },
    "WF": {
        coordinates: [-176.2, -13.3],
        continent: "APAC",
    },
    "EH": {
        coordinates: [-13, 24.5],
        continent: "EMEA",
    },
    "YE": {
        coordinates: [48, 15],
        continent: "APAC",
    },
    "ZM": {
        coordinates: [30, -15],
        continent: "EMEA",
    },
    "ZW": {
        coordinates: [30, -20],
        continent: "EMEA",
    },
    // Some data has EU as country
    "EU": {
        coordinates: [9.90603533916795, 49.84045019343012],
        continent: "EU",
    },
};
export type Country = keyof typeof countries;

export function countryToPoint(country: Country): [number, number] {
    // Type coercion here, so we don't have a cyclical dependency on Country type
    return countries[country]?.coordinates as [number, number]
        // Instead of undefined, return [0, 0] if we get an invalid country
        ?? [0, 0];
}

export function countryToContinent(country: Country): string {
    // Type coercion here, so we don't have a cyclical dependency on Country type
    return countries[country]?.continent as string ?? '';
}

export function countriesToContinents(cs: Country[]): string[] {
    // Type coercion here, so we don't have a cyclical dependency on Country type
    let result = new Set(cs.map(country => countries[country]?.continent as string ?? ''));
    return Array.from(result);
}

export function continentToCountryNames(continent: string): string[] {
    // Type coercion here, so we don't have a cyclical dependency on Country type
    return Object.keys(countries).reduce((result: string[], countryCode: string) => {
        const country = countries[countryCode];
        if (country.continent === continent) {
            result.push(countryCode);
        }
        return result;
    }, []) as string[] ?? null;
}
