import {observer} from "mobx-react-lite";
import {useStores} from "../../../stores";
import React, {useEffect} from "react";
import {
    Button,
    CircularProgress,
    FormControl,
    Grid,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    Typography,
} from "@mui/material";
import {TypedSearchManager} from "../../../stores/managers/TypedSearchManager";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {Clear, FilterAltOutlined, Search, UploadFile} from "@mui/icons-material";
import {ContractInsightsExpandableTable} from "../table/ContractExpandableTable";
import {CurrencyComponent} from "../../../components/currency-component/CurrencyComponent";

export const ContractInsightsComponent: React.FC = observer(() => {
    const {contractInsightsStore, p} = useStores();

    let className = 'contract-insights-component-page'
    if (p.p.disableBuFilter) {
        className = `${className} button-only`
    }

    return <Grid container className={className} alignItems="center" justifyContent="center">
        {/*Header*/}
        <Grid container justifyContent="center" style={{margin: '3em 0 3em 0'}}>
            <Grid container justifyContent="center"
                  className="key-value-container narrow-margin text-center font-bigger">
                <Grid container item xs={10} spacing={3} justifyContent="space-evenly" alignItems="center">
                    <Grid item xs={3} alignItems="center">
                        <Typography variant="caption">Active <br/> contracts</Typography>
                    </Grid>
                    <Grid item xs={3} alignItems="center">
                        <Typography variant="caption">Expired <br/> contracts</Typography>
                    </Grid>
                    <Grid item xs={3} alignItems="center">
                        <Typography variant="caption">Total transactional <br/> spend annually</Typography>
                    </Grid>
                </Grid>
                <Grid container item xs={10} spacing={3} justifyContent="space-evenly" alignItems="center">
                    <Grid item xs={3}>
                        <Typography variant="h5" className="font-weight-bold key-values">
                            134
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="h5" className="font-weight-bold key-values">
                            21
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="h5" className="font-weight-bold key-values">
                            <CurrencyComponent v={88530000} nDigits={3}/>
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>

        {/*Filter*/}
        <Grid container alignItems="center" justifyContent='space-evenly'>
            <Grid item xs={3}>
                <Typography variant="h5">
                    Contracts List
                </Typography>
                <Typography variant="caption" style={{marginTop: '0'}}>
                    1 item
                </Typography>
            </Grid>
            <Grid item xs={4}></Grid>
            <Grid item xs={5} container alignItems="center" justifyContent="flex-end" style={{marginBottom: '1em'}}>
                <Grid item xs={6}>
                    <TypedSearchComponent
                        className="contract-search-component"
                        sm={contractInsightsStore.ppvSearchManager}
                        cmpId="supplier-search"
                    />
                </Grid>
                <Grid item xs={1} style={{display: 'flex', justifyContent: 'center'}}>
                    <IconButton>
                        <FilterAltOutlined/>
                    </IconButton>
                </Grid>
                <Grid item xs={5}>
                    <Button
                        disabled={contractInsightsStore.datasetUploadIsLoading}
                        startIcon={
                            contractInsightsStore.datasetUploadIsLoading ? (
                                <CircularProgress size={20}/>
                            ) : (
                                <UploadFile/>
                            )
                        }
                        variant="outlined"
                        component="label"
                        className="upload-file-button"
                    >
                        UPLOAD CONTRACT FILE
                        <input
                            hidden
                            accept="application/pdf"
                            type="file"
                            onChange={() => contractInsightsStore.handleFileChange()}
                        />
                    </Button>
                </Grid>
            </Grid>
        </Grid>

        <Grid container justifyContent="center">
            <ContractInsightsExpandableTable/>
        </Grid>

    </Grid>
});

// TODO: We have a generic verion for this, but it's not gonna be used in the demo so it was fasted to do it this way. Change it to the generic.
const TypedSearchComponent: React.FC<{
    sm: TypedSearchManager,
    cmpId: string,
    className?: string,
    hideOptionsDropdown?: boolean
}> = observer(
    ({
         sm,
         cmpId,
         className,
         hideOptionsDropdown
     }) => {
        useEffect(() => {
            // When we initially open the page, we want to clear the search
            sm.search.clearSearch();
            sm.search.doSearch('auto-reset');
        }, [sm.search]);

        const searchTypeLabel = 'Search by';
        const hasOptions = sm.options.length > 1;

        const searchFieldLabel = `${sm.type?.placeholder || ''}`;
        const searchString = sm.search.searchString;
        return <div
            className={'typed-search-component' + (className ? ' ' + className : '') + (hasOptions ? ' has-options' : ' has-no-options')}>
            {hasOptions && !hideOptionsDropdown &&
                <FormControl style={{marginRight: '1.5em', width: '20em'}}>
                    <InputLabel id="demo-simple-select-label">{searchTypeLabel}</InputLabel>
                    <Select
                        id={cmpId + '-type'}
                        value={sm.type?.typeId}
                        label={searchTypeLabel}
                        onChange={event => sm.setType(event.target.value as string)}>
                        {sm.options.map(option =>
                            <MenuItem key={option.typeId} value={option.typeId}>{option.label}</MenuItem>
                        )}
                    </Select>
                </FormControl>
            }
            <FormControl
                className="typed-search-field"
                variant="standard"
                fullWidth={!hasOptions}>
                <InputLabel htmlFor={cmpId + '-field'}>
                    {searchFieldLabel}
                </InputLabel>
                <Input
                    id={cmpId + '-field'}
                    type="text"
                    fullWidth={true}
                    endAdornment={
                        <InputAdornment position="end">
                            {searchString &&
                                <IconButton
                                    aria-label="clear search"
                                    onClick={() => sm.search.clearSearch()}
                                >
                                    <Clear/>
                                </IconButton>
                            }
                            <IconButton
                                aria-label="search"
                                onClick={() => sm.search.doSearch('click')}
                            >
                                <Search/>
                            </IconButton>
                        </InputAdornment>
                    }
                    value={searchString}
                    onChange={e => sm.search.setSearch(e.target.value)}
                    onKeyDown={e => e.key === 'Enter' ? sm.search.doSearch('enter') : undefined}
                    aria-describedby={cmpId + '-error'}
                />
            </FormControl>
        </div>
    })