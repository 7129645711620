import React, {useEffect, useMemo} from "react";
import {observer} from "mobx-react-lite";
import {
    Button,
    ButtonGroup,
    Checkbox,
    FormControl,
    Grid,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select
} from "@mui/material";
import {useStores} from "../../../../stores";
import {SearchType, TypedSearchManager} from "../../../../stores/managers/TypedSearchManager";
import {CheckCircle, Clear, Create, FilterAlt, Search} from "@mui/icons-material";
import {
    PartReCategorizationControllerStore
} from "../../../../components/categorization/PartReCategorizationControllerStore";
import {ReviewChoice} from "../../../../services/classes/MaterializedClasses";
import {PartReCategorizationHandler} from "../../../../components/categorization/PartRecategorizationHandler";

export const ReviewFilterBar: React.FC = observer(() => {
    const {p, authStore, categorizationReviewStore} = useStores();
    const searchTypes: SearchType[] = p.searchOptions
    const reviewPageController = categorizationReviewStore.reviewPageController;
    const reviewPageFilterController = categorizationReviewStore.reviewPageController.reviewPageFilterController;
    const reviewPageSelectionController = categorizationReviewStore.reviewPageController.reviewPageSelectionController;
    const categories = categorizationReviewStore.categories;
    const viewOnly = authStore.viewOnly;

    const controller = useMemo(() => {
        if (!categories.taxonomy_categories) {
            if (categories.taxonomy_categories === null) {
                console.error('No taxonomy set...')
            }
            return undefined
        }
        return new PartReCategorizationControllerStore(categories.taxonomy_categories);
    }, [categories.taxonomy_categories])

    const groupBy = reviewPageController.singleMode ? 'parts' : 'suppliers';
    const groupByLabel = 'Group by';

    return <Grid container className="review-filter-bar" spacing={2}>
        {!viewOnly && <Grid item xs={2} alignItems={'center'}>
            <Checkbox
                className="root-selection-component"
                checked={reviewPageSelectionController.selectionState === 'some' || reviewPageSelectionController.selectionState === 'all'}
                onClick={() => reviewPageSelectionController.onClickToggleAllSelected()}
                indeterminate={reviewPageSelectionController.selectionState === 'some'}
            />
            <ButtonGroup variant="outlined" aria-label="outlined primary button group">
                <Button
                    disabled={viewOnly || !reviewPageSelectionController.isAnySelected}
                    onClick={() => reviewPageController.onClickAcceptRejectSelection(ReviewChoice.ACCEPT)}>
                    <CheckCircle/>
                </Button>
                <Button
                    disabled={viewOnly || !reviewPageSelectionController.isAnySelected}
                    onClick={event => {
                        if (!controller) {
                            console.error('No controller set');
                            return;
                        }
                        const anchor = event.currentTarget;
                        controller.setOnSelect(newCats => reviewPageController.onClickReCategorizeSelection(newCats))
                        controller.open(anchor)
                    }}>
                    <Create/>
                </Button>
                {controller && <PartReCategorizationHandler controller={controller} label="Change the category"/>}
            </ButtonGroup>
        </Grid>}

        <Grid item xs={1}>
            {!p.p.hideGroupBySupplier && <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">{groupByLabel}</InputLabel>
                <Select
                    labelId="review-filter-bar-taxonomy-dropdown-label"
                    id="review-filter-bar-taxonomy-dropdown"
                    label={groupByLabel}
                    onChange={(event) => {
                        const singleMode = event.target.value === 'parts'
                        reviewPageController.setSingleMode(singleMode)
                    }}
                    value={groupBy}
                >
                    <MenuItem value={'suppliers'}>Supplier</MenuItem>
                    <MenuItem value={'parts'}>None</MenuItem>
                </Select>
            </FormControl>}
        </Grid>

        <Grid item xs={groupBy === 'parts' ? 5 : 3}>
            {groupBy === 'parts' ?
                <TypedSearchComponent sm={reviewPageFilterController.partSearchManager} cmpId="part-search"/> :
                <TypedSearchComponent className="first" sm={reviewPageFilterController.supplierSearchManager}
                                      cmpId="supplier-search"/>
            }
        </Grid>

        <Grid item xs={3}></Grid>

        {reviewPageController.singleMode && <Grid item xs={1}>
            <Button
                endIcon={<FilterAlt/>}
                variant={reviewPageFilterController.isAdvancedFilterEnabled ? 'contained' : 'outlined'}
                onClick={() => reviewPageFilterController.setAdvancedFilterDialogOpen(true)}>
                Filter
            </Button>
        </Grid>}
    </Grid>

})


/**
 * A search component that has different search modes
 */
const TypedSearchComponent: React.FC<{
    sm: TypedSearchManager,
    cmpId: string,
    className?: string,
    hideOptionsDropdown?: boolean
}> = observer(
    ({
         sm,
         cmpId,
         className,
         hideOptionsDropdown
     }) => {
        const {categorizationReviewStore} = useStores();
        const reviewPageController = categorizationReviewStore.reviewPageController;

        useEffect(() => {
            // When we initially open the page, we want to clear the search
            sm.search.clearSearch();
            sm.search.doSearch('auto-reset');
        }, [sm.search]);

        const searchTypeLabel = 'Search by';
        const hasOptions = sm.options.length > 1;

        const searchFieldLabel = `${sm.type?.placeholder || ''}`;
        const searchString = sm.search.searchString;
        return <div
            className={'typed-search-component' + (className ? ' ' + className : '') + (hasOptions ? ' has-options' : ' has-no-options')}>
            {hasOptions && !hideOptionsDropdown &&
                <FormControl style={{marginRight: '1.5em', width: '20em'}}>
                    <InputLabel id="demo-simple-select-label">{searchTypeLabel}</InputLabel>
                    <Select
                        id={cmpId + '-type'}
                        value={sm.type?.typeId}
                        label={searchTypeLabel}
                        onChange={event => sm.setType(event.target.value as string)}>
                        {sm.options.map(option =>
                            <MenuItem key={option.typeId} value={option.typeId}>{option.label}</MenuItem>
                        )}
                    </Select>
                </FormControl>
            }
            <FormControl
                className="typed-search-field"
                variant="standard"
                fullWidth={!hasOptions}>
                <InputLabel htmlFor={cmpId + '-field'}>
                    {searchFieldLabel}
                </InputLabel>
                <Input
                    id={cmpId + '-field'}
                    type="text"
                    fullWidth={true}
                    endAdornment={
                        <InputAdornment position="end">
                            {searchString &&
                                <IconButton
                                    aria-label="clear search"
                                    onClick={() => sm.search.clearSearch()}
                                >
                                    <Clear/>
                                </IconButton>
                            }
                            <IconButton
                                aria-label="search"
                                onClick={() => sm.search.doSearch('click')}
                            >
                                <Search/>
                            </IconButton>
                        </InputAdornment>
                    }
                    value={searchString}
                    onChange={e => sm.search.setSearch(e.target.value)}
                    onKeyDown={e => e.key === 'Enter' ? sm.search.doSearch('enter') : undefined}
                    aria-describedby={cmpId + '-error'}
                />
            </FormControl>
        </div>
    })
