import {AxiosResponse} from "axios";
import {MatKpiTreeValues, PageResponse} from "./ApiTypes";
import {UNCATEGORIZED_LABEL, UNCATEGORIZED_VALUE} from "../constants";
import {
    CollapsibleIndentTreeData,
    TreeDataType
} from "../components/visualization/collapsible-indent-tree/CollapsibleIndentTree";

import {Categories, ReviewCategorizationChoice} from "./classes/AiClasses";
import {range} from "d3";
import {getSupplierFilerLevelValues, LevelSpecObject, MatSearch, MatSupplierFilter} from "./classes/MatReviewClasses";


export type L1To8 = 'l1' | 'l2' | 'l3' | 'l4' | 'l5' | 'l6' | 'l7' | 'l8';

export declare type PagePromise<X> = Promise<AxiosResponse<PageResponse<X>>>

export function kpiToReadableString(kpi: MatKpiTreeValues, currencySymbol: string): string {
    switch (kpi) {
        case 'parts':
            return 'Number of records';
        case 'spend':
            return `Spend (${currencySymbol})`;
        default:
            return kpi;
    }
}

export function kpiToDataFormat(kpi: MatKpiTreeValues): 'n' | 'currency' | '?' {
    switch (kpi) {
        case 'parts':
            return 'n';
        case 'spend':
            return `currency`;
        default:
            return '?';
    }
}

export function ParserConfigToString(p: string) {
    const anon = /anon_(.*)/.exec(p);
    if (anon) {
        return 'Anonymize ' + anon[1];
    }
    return p;
}

export function MergeConfigToString(m: string) {
    switch (m) {
        case 'concat':
            return 'Append';
        case 'single':
            return 'None';
    }
    return m;
}

// in
type ApiTreeResponseData<D> = { label: string, values: D };
export type ApiTreeResponse<D> = { children: ApiTreeResponse<D>[] } & ApiTreeResponseData<D>;
// out
export type ProcessedTree<D> = { children: ProcessedTree<D>[] } & TreeDataType<D>;

export function processTree<D>(tree: ApiTreeResponse<D>): ProcessedTree<D> {
    let id = 0;
    const p: (t: ApiTreeResponse<D>) => ProcessedTree<D> = t => {
        const _id = id++;
        return {
            id: _id,
            labelId: `${_id}`,
            label: t.label === UNCATEGORIZED_VALUE ? UNCATEGORIZED_LABEL : t.label,
            values: t.values,
            selected: false,
            childSelected: false,
            highlighted: false,
            children: t.children.map(p),
        }
    }
    return p(tree);
}

export type ReviewStatusApiDataType = {
    deleted: boolean;
    added: boolean;
    renamed: boolean;
    accepted: boolean;
    rejected: boolean;
    oldLabel: string;
    newLabel: string;
};

// in
export type ApiSuggestionTreeResponse<T> = {
    id: number;
    label: string;
    children: ApiSuggestionTreeResponse<T>[];
    values: T;
    sources: number[];
}


export type ApiUpdateSuggestionTreeResponse<T> = {
    label: string;
    children: ApiSuggestionTreeResponse<T>[];
    values: T;
}

export function getLsFromDict(p: any, taxonomy_key: string, maxLevels: number): string[] {
    let ls: string[] = []
    let l = 0
    while (l++ <= maxLevels) {
        const key = `${taxonomy_key}${l}`
        if (!(key in p)) {
            // There are no more properties
            return ls
        }
        let lValue = p[key];
        if (typeof lValue !== 'string') {
            console.warn('Cannot find taxonomy in ', p)
            lValue = String(lValue)
        }
        ls.push(lValue)
    }
    return ls
}

type HasCatsArray<Prop extends keyof any> = {
    [CatsField in Prop]: string[]
}

export function fromArrayCategories(categories: string[], taxonomySize: number): Categories {
    if (categories === undefined) {
        throw new Error('Missing categories')
    }
    const result = new Array(taxonomySize);
    for (let i = 0; i < taxonomySize; i++) {
        if (i < categories.length) {
            result[i] = categories[i] || '';
        } else {
            result[i] = '';
        }
    }
    return result;
}

/**
 * TODO: This type is very convoluted and hard to explain, can be simplified
 */
type HasCatsFields<BaseProp extends string> = {
    [CatsField in `${BaseProp}_l1`]: string | null
} & {
        [CatsField in `${BaseProp}_l${'2' | '3' | '4' | '5' | '6' | '7' | '8'}`]?: string | null
    }
export declare type ReviewCatFields = {
    [CatsField in `p_review_cat_${L1To8}`]?: string
}
export declare type StorePartReviewBySGroupsSerializer = {
    s_groups: number[]
    review_choice: number
    p_review_cat_l1?: string
    p_review_cat_l2?: string
    p_review_cat_l3?: string
    p_review_cat_l4?: string
    p_review_cat_l5?: string
    p_review_cat_l6?: string
    p_review_cat_l7?: string
    p_review_cat_l8?: string
}
export function fromFieldsCategoriesOrUncat<C extends HasCatsFields<K>, K extends string>(obj: C, base_fields: K, taxonomySize: number): Categories | false {
    const cats = fromFieldsCategories(obj, base_fields, taxonomySize);
    if (cats.every(c => !c)) return false;
    return cats;
}

export function fromFieldsCategories<C extends HasCatsFields<K>, K extends string>(obj: C, base_fields: K, taxonomySize: number): Categories {
    const result = new Array(taxonomySize);
    for (let i = 0; i < taxonomySize; i++) {
        result[i] = obj[`${base_fields}_l${i + 1}` as any] || '';
    }
    return result;
}

type HasSimpleCatsFields = {
    l1: string | null
} & {
        [CatsField in L1To8]?: string | null
    }

export function fromSimpleFieldsCategoriesOrUncat<C extends HasSimpleCatsFields>(obj: C, taxonomySize: number): Categories | false {
    const cats = fromSimpleFieldsCategories(obj, taxonomySize);
    if (cats.every(c => !c)) return false;
    return cats;
}

export function fromSimpleFieldsCategories<C extends HasSimpleCatsFields>(obj: C, taxonomySize: number): Categories {
    const result = new Array(taxonomySize);
    if (!obj) return range(taxonomySize).map(() => '');
    for (let i = 0; i < taxonomySize; i++) {
        result[i] = obj[`l${i + 1}` as any] || '';
    }
    return result;
}

export function fromObjectArrayCategories<C extends HasCatsArray<K>, K extends keyof any>(obj: C, field: K, taxonomySize: number): Categories {
    return fromArrayCategories(obj[field], taxonomySize);
}

// Default 4 level taxonomy for now
// type L4Cats = { [C in `l${'1' | '2' | '3' | '4'}`]: string }
type L8Cats = { [C in `l${'1' | '2' | '3' | '4' | '5' | '6' | '7' | '8'}`]: string }

export function catsToDict(categories: Categories, taxonomySize: number): L8Cats {
    const result = {};
    for (let i = 0; i < Math.max(categories.length, taxonomySize); i++) {
        if (i < categories.length) {
            result[`l${i + 1}`] = categories[i] || '';
        } else {
            result[`l${i + 1}`] = '';
        }
    }
    return result as any;
}

export function ciTreeToCatsDict(node: CollapsibleIndentTreeData): L8Cats & { level: number } {
    let catsDict = { level: node.depth };
    node.ancestors().forEach(n => {
        catsDict[`l${n.depth}`] = n.data.label;
    })
    return catsDict as any;
}

export function setOnObj<C extends HasCatsFields<K>, K extends string>(obj: C, base_fields: K, categories: Categories) {
    for (let i = 0; i < 8; i++) {
        if (i < categories.length) {
            obj[`${base_fields}_l${i + 1}` as any] = categories[i];
        } else {
            obj[`${base_fields}_l${i + 1}` as any] = "";
        }
    }
}

export function toCategoriesChoice(cats: Categories): ReviewCategorizationChoice {
    const result: Partial<ReviewCategorizationChoice> = {}
    if (cats.length > 4) {
        console.error('API endpoint insufficient (max size = 4): ', cats)
    }
    for (let i = 0; i < 4; i++) {
        result[`choice_l${i + 1}`] = (i < cats.length ? cats[i] : '') || ''
    }
    console.log('toCategoriesChoice', cats, result)
    return result as ReviewCategorizationChoice;
}

function isUncategorized(cats: string[] | undefined) {
    if (cats === undefined || cats === null) return true;
    return cats.every(c => c === '')
}

export function pickDefined2(catsA: string[] | undefined, catsB: string[]) {
    return isUncategorized(catsA) ? catsB : catsA;
}

export function pickDefined(...cats: Array<string[] | undefined>): string[] {
    const defined = cats.find(c => c && !isUncategorized(c))
    if (!defined) {
        console.warn('At least one must be defined', cats);
        return [];
    }
    return defined;
}

export function pickDefinedOrUncat(cats: Array<string[] | undefined>, taxonomySize: number): string[] {
    const defined = cats.find(c => c && !isUncategorized(c))
    if (defined) return defined
    return [...Array(taxonomySize)].map(() => '');
}

export function isSameCategorization(catsA: string[], catsB: string[]): boolean {
    console.assert(catsA.length === catsB.length)
    for (let i = 0; i < catsA.length; i++) {
        if (catsA[i] !== catsB[i]) {
            return false;
        }
    }
    return true;
}

export function showConfidence(score: number): string {
    switch (score) {
        case -1:
            return 'B'
        case 0:
            return '';
        default:
            return String(score);
    }
}

export function setParamOrEmpty<P>(params: P, key: string, value: string): P {
    if (value) {
        params[key] = value;
    } else {
        params[`${key}__isempty`] = 'true'
    }
    return params
}

export function setUrlParamOrEmpty(params: URLSearchParams, key: string, value: string) {
    if (value) {
        params.set(key, value)
    } else {
        params.set(`${key}__isempty`, 'true')
    }
}

export function setParamsOrEmpty<P>(base: P, valOrEmpty: { [key: string]: string }): P {
    for (const [key, value] of Object.entries(valOrEmpty)) {
        setParamOrEmpty(base, key, value);
    }
    return base;
}

export function setUrlParamsOrEmpty(base: URLSearchParams, valOrEmpty: { [key: string]: string }) {
    for (const [key, value] of Object.entries(valOrEmpty)) {
        setUrlParamOrEmpty(base, key, value);
    }
    return base;
}

export function setParamOrNull(params: URLSearchParams, key: string, value: any | null | undefined): void {
    if (value === undefined) {
        // Skip
    } else if (value === null) {
        params.set(`${key}__isnull`, 'true');
    } else {
        params.set(key, value);
    }
}

export function setUrlParamOrNull(params: URLSearchParams, key: string, value: any | null | undefined): URLSearchParams {
    if (value === undefined) {
        // Skip
    } else if (value === null) {
        params.set(`${key}__isnull`, 'true');
    } else {
        params.set(key, value);
    }
    return params
}

export function setParamsOrNull(params: URLSearchParams, valOrEmpty: { [key: string]: any | null | undefined }): void {
    for (const [key, value] of Object.entries(valOrEmpty)) {
        setParamOrNull(params, key, value);
    }
}

export function setLeveLFilterUrlParamsFromArray(params: URLSearchParams, level: number, data: string[], levels_prefix = '') {
    const levels_prefix_format = (levels_prefix ? levels_prefix + '_' : '') + 'l';
    for (let i = 0; i < level; i++) {
        const l = i + 1;
        const level_key = levels_prefix_format + l;
        const level_value = i < data.length ? data[i] : '';
        setUrlParamOrEmpty(params, level_key, level_value);
    }
    return params;
}

export function setLevelFilterParams<T extends object>(level: number, filter: LevelSpecObject, params: T): T {
    switch (level) {
        case 0:
            return params;
        case 1:
            return setParamsOrEmpty(params, {
                'l1': filter.l1
            });
        case 2:
            return setParamsOrEmpty(params, {
                'l1': filter.l1, 'l2': filter.l2,
            });
        case 3:
            return setParamsOrEmpty(params, {
                'l1': filter.l1, 'l2': filter.l2, 'l3': filter.l3,
            });
        case 4:
            return setParamsOrEmpty(params, {
                'l1': filter.l1, 'l2': filter.l2, 'l3': filter.l3, 'l4': filter.l4,
            });
        case 5:
            return setParamsOrEmpty(params, {
                'l1': filter.l1, 'l2': filter.l2, 'l3': filter.l3, 'l4': filter.l4,
                'l5': filter.l5,
            });
        case 6:
            return setParamsOrEmpty(params, {
                'l1': filter.l1, 'l2': filter.l2, 'l3': filter.l3, 'l4': filter.l4,
                'l5': filter.l5, 'l6': filter.l6,
            });
        case 7:
            return setParamsOrEmpty(params, {
                'l1': filter.l1, 'l2': filter.l2, 'l3': filter.l3, 'l4': filter.l4,
                'l5': filter.l5, 'l6': filter.l6, 'l7': filter.l7,
            });
        case 8:
            return setParamsOrEmpty(params, {
                'l1': filter.l1, 'l2': filter.l2, 'l3': filter.l3, 'l4': filter.l4,
                'l5': filter.l5, 'l6': filter.l6, 'l7': filter.l7, 'l8': filter.l8,
            });
        default:
            throw new Error(`Filter ${JSON.stringify(filter)} is not supported!`);
    }
}

export function setSearchUrlParams(params: URLSearchParams, search?: MatSearch) {
    if (!search) {
        return;
    }
    if (search.supplier) {
        params.set('search0_query', search.supplier);
        params.set('search0_fields', 's_name');
    } else {
        console.warn('Search not supported for', search);
    }
}

export function setLevelFilterUrlParams(params: URLSearchParams, f: MatSupplierFilter, levelPrefix: string = 'l') {
    const levelFilterObject = {}
    const fValues = getSupplierFilerLevelValues(f);
    for (let i = 0; i < fValues.length; i++) {
        const key = `${levelPrefix}${i + 1}`;
        levelFilterObject[key] = fValues[i];
    }
    setUrlParamsOrEmpty(params, levelFilterObject);
}

export const initialListFilters: string[][] = [
    ['page_size', '10'],
    ['page', '1'],
]