import React from "react";
import {Grid, Typography} from "@mui/material";
import {useHistory} from "react-router";
import Button from "@mui/material/Button";
import {routes} from "./routes";
import './DatasetUpdatedLandingPage.scss';

export const DatasetUpdatedLandingPage: React.FC = () => {
    const history = useHistory();
    return <div className="dataset-updated-landing-page">
        <Grid container justifyContent="center">
            <Grid item>
                <Typography variant="h3" component="h1">
                    Link expired!
                </Typography>
                <Typography variant="body2" component="h3">
                    Your data's refreshed, please go back to the home page and try again.
                </Typography>
                <Button variant="contained" color="primary" onClick={() => history.push(routes.home)}>
                    Back to home
                </Button>
            </Grid>
        </Grid>
    </div>
};
