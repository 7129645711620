import {makeAutoObservable} from "mobx";
import {SearchManager} from "./SearchManager";

export type SearchTypeId = 'supplier'
    | 'p_name'
    | 'p_description'
    | 'p_context_1'
    | 'p_context_2'
    | 'p_context_3'
    | 'p_name_description'
    | 'p_description_context_1'
    | 'p_description_context_2'
    | 'p_description_context_3'
    | 'p_name_description_context_1'
    | 'p_name_description_context_2'
    | 'p_name_description_context_3'
    | 'all_context_1'
    | 'all_context_2'
    | 'all_context_3'

export type SearchType = {
    typeId: SearchTypeId
    label: string
    placeholder?: string

    /**
     * Shows when the search option is a search in a combination of fields
     *
     * In the new design we do not consider search options that are combinations anymore
     */
    isCombination?: boolean

    /**
     * There is a single option to search all fields
     */
    isAll?: true
}

export type SearchOptionsSpecification = {
    defaultIndex: number
    options: SearchType[]
}

export class TypedSearchManager {
    type: SearchType | undefined;

    search: SearchManager

    constructor(public options: SearchType[], onSearch: (search: string, type: SearchType | undefined) => void, defaultOption = 0) {
        this.type = options[defaultOption];
        const searchTypeTag = this.type?.typeId || '?';
        this.search = new SearchManager(searchTypeTag, (searchString) => {
            onSearch(searchString, this.type);
        })
        makeAutoObservable(this)
    }

    setType(typeId: string) {
        this.type = this.options.find((t) => t.typeId === typeId)
        this.search.searchTypeTag = this.type?.typeId || '?';
    }
}