import {TransitionProps} from "@mui/material/transitions/transition";

export function getCollapseAnimationTiming(elements: undefined | any[] | null): TransitionProps['timeout'] | number | 'auto' {
    if (elements) {
        if (elements.length > 100) {
            // Performance issue
            return 0
        } else if (elements.length > 30) {
            // Does not fit on the screen anymore
            return {
                enter: 350,
                exit: 100,
            }
        }
    }
    return 'auto'
}

export function clam(value: number, min: number, max: number): number {
    if (value < min) {
        return min;
    }
    if (value > max) {
        return max;
    }
    return value;
}

export function numOrDefault<D = number>(value: string, defaultValue: D): number | D {
    if (value === '') {
        return defaultValue;
    }
    const num = Number(value);
    if (isNaN(num)) {
        return defaultValue;
    }
    return num;
}

export function axiosAllow404(status: number) {
    return (status >= 200 && status < 300) || status === 404
}
