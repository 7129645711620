import {Alert} from "@mui/material";
import React from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../../../../stores";
import moment from "moment";

type Props = {}
export const TaxonomySelectionAlert: React.FC<Props> = observer(() => {
    const {categorizationReviewStore, taxonomyManagerStore} = useStores();
    const created = categorizationReviewStore.aiJob.job?.created;
    const lastRunDate: string = created ? moment(created).format('L') : '';
    const taxonomyName: string = taxonomyManagerStore.taxonomy?.name || '';
    const severity = taxonomyName ? 'info' : 'warning';
    const style = taxonomyName ? {background: '#0262F21A', marginBottom: '1em'} : {marginBottom: '1em'};
    return <Alert severity={severity} style={style}>
        {
            taxonomyName
                ? `You are reviewing the categorization based on taxonomy ${taxonomyName}${lastRunDate ? ` from “${lastRunDate}”` : ''}`
                : `You are reviewing the categorization based on an unknown taxonomy`
        }
    </Alert>
});
