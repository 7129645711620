import {DragDropContext} from "react-beautiful-dnd";
import {v4 as uuid} from "uuid";
import {MappingMithraColumn} from "./MappingMithraColumn";
import {MappingClientColumn} from "./MappingClientColumn";
import {ClientArea, Destination, MappingTableItemType, Source} from "./MappingTableTypes";
import {useStores} from "../../../stores";
import {observer} from "mobx-react-lite";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Accordion, AccordionSummary, Grid, Typography} from '@mui/material';
import React from "react";

/*
Mapping Table
+-------------------------------------------------+
|                         |  +----------------+   |
|  +-------------------+  |  | Mithra Area    |   |
|  |  Client Area      |  |  |+--------------+|   |
|  |                   |  |  || Mithra col 1 ||   |
|  |                   |  |  || +----------+ ||   |
|  |  +--------------+ |  |  || | Item 1   | ||   |
|  |  | Client Column| |  |  || |----------| ||   |
|  |  |              | |  |  || | Item 2   | ||   |
|  |  |  +----------+| |  |  || +----------+ ||   |
|  |  |  | Item 1   || |  |  ||              ||   |
|  |  |  +----------+| |  |  |+--------------+|   |
|  |  |  | Item 2   || |  |  |                |   |
|  |  |  +----------+| |  |  |                |   |
|  |  |  | Item 3   || |  |  |                |   |
|  |  |  +----------+| |  |  |+--------------+|   |
|  |  |              | |  |  || Mithra col 2 ||   |
|  |  +--------------+ |  |  || +----------+ ||   |
|  +-------------------+  |  || | Item 3   | ||   |
|                         |  || |----------| ||   |
|                         |  || | Item 2   | ||   |
|                         |  || +----------+ ||   |
|                         |  |+--------------+|   |
|                         |  +----------------+   |
+-------------------------------------------------+
* */


/**
 * Moves element inside a list
 * @param list of items
 * @param startIndex of the item
 * @param endIndex of the item
 * @returns a new list
 */
const reorder = (list: MappingTableItemType[], startIndex: number, endIndex: number) => {
    const listCopy = Array.from(list);
    const [removed] = listCopy.splice(startIndex, 1);
    listCopy.splice(endIndex, 0, removed);
    return listCopy;
};

/**
 * Copies element from one column to another, leaving the original in place
 * @param sourceArray of the drag
 * @param destinationArray of the drag
 * @param source of the drag containing the index and the droppableId
 * @param destination of the drag containing the index and the droppableId
 * @returns a new list
 */
const copy = (sourceArray: ClientArea, destinationArray: MappingTableItemType[], source: Source, destination: Destination) => {
    const sourceClone = Array.from(sourceArray);
    const destClone = Array.from(destinationArray);
    const item: any = sourceClone[source.index];

    console.log('item');
    console.log(item);

    item.ai_result = false;
    item.user_result = true;

    destClone.splice(destination.index, 0, {...item, id: uuid()});

    return destClone;
};

/**
 * Moves element from one list to another, within the same column
 * @param sourceArray of the drag
 * @param destinationArray of the drag
 * @param source of the drag containing the index and the droppableId
 * @param destination of the drag containing the index and the droppableId
 * @returns a new list
 */
const move = (sourceArray: MappingTableItemType[], destinationArray: MappingTableItemType[], source: Source, destination: Destination) => {
    const sourceClone = Array.from(sourceArray);
    const destClone = Array.from(destinationArray);
    const [removed] = sourceClone.splice(source.index, 1);

    removed.ai_result = false;
    removed.user_result = true;

    destClone.splice(destination.index, 0, removed);

    return {sourceClone, destClone}
};

export const DatasetMapping = observer(() => {
    const {dataIngestionStore} = useStores();

    /**
     * Delete item from list, this function is passed to the child component via context
     * @param parentMithraColumn id of the parent list
     * @param itemId of the item to delete inside the parent list
     * @returns void
     */
    const deleteItemFromList = (parentMithraColumn: string, itemId: string) => {
        const newMithraArea = [...dataIngestionStore.mithraAreaState];
        const mithraColumn = dataIngestionStore.mithraAreaState.findIndex((e) => e.id === parentMithraColumn);
        const itemToDeleteIndex = dataIngestionStore.mithraAreaState[mithraColumn].items.findIndex((e) => e.id === itemId);
        newMithraArea[mithraColumn].items.splice(itemToDeleteIndex, 1);
        dataIngestionStore.mithraAreaState = newMithraArea;
    }

    /**
     * Disable droppables when the item is already in the list
     * @param source of the drag containing the index and the droppableId
     * @returns void
     */
    const disableDroppables = (source: Source) => {
        const newMithraArea = [...dataIngestionStore.mithraAreaState];
        const sourceLabelMithraCol = dataIngestionStore.mithraAreaState.find((e) => e.id === source.droppableId)?.items[source.index].name

        for (const mithraColumnIndex in newMithraArea) {
            const mithraColumn = newMithraArea[mithraColumnIndex];
            //FIXME: finding columns by labels can is wrong since labels can be the same
            //FIXME: Check if it could be more efficent with .some() or .every()

            let foundIndex = -1
            if (mithraColumn.items.length >= 1) {
                // this condition is to have just a 1to1 mapping, if the mithra column has more than 1 item, it will be disabled
                foundIndex = 0
            } else if (source.droppableId === 'CLIENTCOLUMN') {
                // source is from the client column
                foundIndex = mithraColumn.items.findIndex((e) => e.name === dataIngestionStore.clientAreaState[source.index].name && mithraColumn.id !== source.droppableId)
            } else {
                // source is from the mithra column
                foundIndex = mithraColumn.items.findIndex((e) => e.name === sourceLabelMithraCol && mithraColumn.id !== source.droppableId)
            }

            if (foundIndex !== -1) {
                const columnCopy = {...mithraColumn};
                columnCopy.disabled = true
                newMithraArea[mithraColumnIndex] = columnCopy;
            }
        }
        dataIngestionStore.mithraAreaState = newMithraArea
    }

    const onDragStart = (result) => {
        const {source} = result;
        disableDroppables(source);
    }

    const onDragEnd = (result) => {
        const {source, destination} = result;

        const copyMithraAreaState = [...dataIngestionStore.mithraAreaState];
        // Set all elements disabled to false
        for (let mithraColumnIndex in dataIngestionStore.mithraAreaState) {
            copyMithraAreaState[mithraColumnIndex].disabled = false;
        }

        // find the index of the source and destination in the copyed list
        // they will be used to find the items to with apply the results of the drag and drop in the copyed list
        const destinationCloneIndex = copyMithraAreaState.findIndex((e) => e.id === destination?.droppableId);
        const sourceCloneIndex = copyMithraAreaState.findIndex((e) => e.id === source.droppableId);

        // if the destination is null, then the item was dropped outside the droppable area
        if (!destination) {
            return;
        }

        switch (source.droppableId) {
            case destination.droppableId:
                // if the droppableId is the same, then the source and destination are the same column and the same list in mithra column,
                // so we want to reorder the items in the list
                const reorderResult = reorder(
                    copyMithraAreaState[sourceCloneIndex].items,
                    source.index,
                    destination.index
                );
                const tempReorderList = [...copyMithraAreaState];
                tempReorderList[sourceCloneIndex].items = reorderResult;
                dataIngestionStore.mithraAreaState = tempReorderList;
                break;

            case "CLIENTCOLUMN":
                // if the droppabledId is CLIENTCOLUMN, then the source is the client column
                // we want to copy the item from the client column to the mithra column while keeping the original item in the client column
                const copyResult = copy(
                    dataIngestionStore.clientAreaState,
                    copyMithraAreaState[destinationCloneIndex].items,
                    source,
                    destination
                );
                const tempCopyList = [...copyMithraAreaState];
                tempCopyList[destinationCloneIndex].items = copyResult;
                dataIngestionStore.mithraAreaState = tempCopyList;
                break;

            default:
                // if the droppableID is not 'CLIENTCOLUMN' or the same then we want to move the item from one list to another in the mithra column
                const {sourceClone, destClone} = move(
                    copyMithraAreaState[sourceCloneIndex].items,
                    copyMithraAreaState[destinationCloneIndex].items,
                    source,
                    destination
                );
                const tempMoveList = [...copyMithraAreaState];
                tempMoveList[sourceCloneIndex].items = sourceClone;
                tempMoveList[destinationCloneIndex].items = destClone;
                dataIngestionStore.mithraAreaState = tempMoveList;
                break;
        }
    }


    return (
        <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>


            {/*Title grid*/}
            <Grid container justifyContent="center" className='dataset-mapping-title-grid'>
                <Grid item xs={3} justifyContent={"flex-start"} className="client-column-title">
                    <Typography variant="h2">Dataset columns</Typography>
                </Grid>
                <Grid container
                      item xs={9} justifyContent={"flex-start"} className="mithra-column-title">
                    <Grid item xs={1}></Grid>
                    <Grid item xs={11}>
                        <Typography variant="h2">Mithra terminology</Typography>
                    </Grid>
                </Grid>
            </Grid>


            {/* Table grid */}
            <Grid container justifyContent="center">

                <Grid item xs={3}>
                    <MappingClientColumn droppableId="CLIENTCOLUMN" className="client-column"
                                         items={dataIngestionStore.clientAreaState}/>
                </Grid>

                <Grid item xs={9}
                      container className="mithra-columns-container">

                    {dataIngestionStore.mithraAreaState.map((list, index) => (
                        <React.Fragment key={index}>
                            <Grid item
                                  container direction="row" alignItems="stretch" className="row">
                                {list.is_required && list.items.length === 0 ? <Grid item xs={1}>
                                    <div className="required">*</div>
                                </Grid> : <Grid item xs={1}>
                                </Grid>}
                                <Grid item className="column" xs={4}>
                                    <MappingMithraColumn items={list.items} droppableId={list.id}
                                                         key={list.id} disabled={list.disabled}
                                                         is_required={list.is_required}/>
                                </Grid>
                                <Grid item xs={7}>
                                    <Accordion>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}
                                                          aria-controls="panel1a-content">
                                            <div>
                                                <Typography sx={{wordBreak: "break-word"}}>
                                                    {list.name}
                                                </Typography>
                                                <Typography sx={{color: 'text.secondary'}}>
                                                    {list.example}
                                                </Typography>
                                            </div>
                                        </AccordionSummary>
                                        <Typography className="typography">{list.description}</Typography>
                                    </Accordion>
                                </Grid>
                            </Grid>
                        </React.Fragment>))}
                </Grid>

            </Grid>

        </DragDropContext>
    );

})
