import React from "react";
import {SingleHeader} from "../../pages/default-menus/SingleHeader";
import {MultiHeader} from "../../pages/multi-dashboard/menu/MultiHeader";
import {MainComponentProp} from "../../components/main/MainComponent";
import {environment} from "../../env";
import {MergeXHeader} from "../../pages/multi-dashboard/merge-x-dashboard/MergeXHeader";
import {Merge2Header} from "../../pages/multi-dashboard/lg-dashboard/Merge2Header";
import {SalesDemoHeader} from "../../pages/multi-dashboard/sales-demo-dashboard/menu/SalesDemoHeader";

export const HeaderWrapper: React.FC<MainComponentProp> = ({state}) => {
    // console.debug('Re-rendering HeaderWrapper', state?.dashEnv)

    const testElement = environment.showDebugInfoInMenu
        ? <div style={{background: 'olivedrab'}}>
            <span style={{textDecoration: "line-through"}}>dashEnv:{state?.dashEnv}</span>
            &nbsp;dashEnv2:{state?.dashboardEnvironment}
            &nbsp;PACKAGE:{environment.package}.{environment.dashboardVariant}
        </div> : <></>;

    let headerElement: JSX.Element | undefined;
    if (environment.package === 'sales_demo') {
        headerElement = <SalesDemoHeader/>
    } else {
        if (environment.package === 'merge_x') {
            headerElement = <MergeXHeader/>
        }
        if (environment.package === 'merge_2') {
            headerElement = <Merge2Header/>
        } else {
            switch (state?.dashEnv) {
                case 'simple':
                    headerElement = <SingleHeader/>;
                    break;
                case 'dual':
                    headerElement = <SingleHeader/>;
                    break
                case 'multi':
                    headerElement = <MultiHeader/>;
                    break
            }
        }
    }

    return <>
        {testElement}
        {headerElement}
    </>;
}
