import {styled} from '@mui/material/styles';
import {TextField} from '@mui/material';
import React from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../stores";
import {EditorModalComponent} from "./EditorModalComponent";

const PREFIX = 'UpdateCategoryModal';

const classes = {
    typography: `${PREFIX}-typography`,
    popoverContent: `${PREFIX}-popoverContent`
};

const StyledEditorModalComponent = styled(EditorModalComponent)(({theme}) => ({
    [`& .${classes.typography}`]: {
        padding: theme.spacing(2),
    },

    // option: {
    //     fontSize: 15,
    //     '& > span': {
    //         marginRight: 10,
    //         fontSize: 18,
    //     },
    // },
    [`& .${classes.popoverContent}`]: {
        margin: 10,
        background: 'transparent',
    }
}));

export const UpdateCategoryModal: React.FC = observer(() => {
    const onSave = () => taxonomyEditorStore.saveUpdateCategory();
    const {taxonomyEditorStore} = useStores();

    return (
        <StyledEditorModalComponent
            titleId={"taxonomy-editor-modal-title"}
            className="update-model-component"
            title="Edit category"
            canSave={taxonomyEditorStore.canUpdateCategory}
            onSave={onSave}
            onClose={() => taxonomyEditorStore.setUpdateCategoryMode(false)}
        >

            <TextField
                autoFocus
                fullWidth
                style={{marginBottom: 30}}
                id="taxonomy-editor-modal-name-field"
                label="Category name"
                error={Boolean(taxonomyEditorStore.updateCategoryNameError)}
                helperText={taxonomyEditorStore.updateCategoryNameError}
                variant="outlined"
                value={taxonomyEditorStore.updateCategoryName}
                onChange={e => taxonomyEditorStore.setUpdateCategoryName(e.target.value)}
                onKeyDown={e => e.key === 'Enter' ? onSave() : undefined}
            />

            <TextField
                fullWidth
                multiline
                id="taxonomy-editor-modal-description-field"
                label="Description"
                error={Boolean(taxonomyEditorStore.updateCategoryDescriptionError)}
                helperText={taxonomyEditorStore.updateCategoryDescriptionError}
                variant="outlined"
                value={taxonomyEditorStore.updateCategoryDescription}
                onChange={e => taxonomyEditorStore.setUpdateCategoryDescription(e.target.value)}
                onKeyDown={e => e.key === 'Enter' ? onSave() : undefined}
            />
        </StyledEditorModalComponent>
    );
})
