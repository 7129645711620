import {ApiService, mithra_http} from "./Http";
import {AxiosInstance, AxiosResponse} from "axios";
import {axiosAllow404} from "../components/table/utils";
import {
    ApplyTaxonomyMappingRequestData,
    BusinessUnit,
    MatCategoryConcentrationStatistics,
    MatConcentrationStatistics,
    MaterializeRequest,
    MatPartReviewRow,
    MatReviewLevelStatisticsTreeSerializer,
    MatReviewStatisticsSerializer,
    MatSGroup,
    MatSupplierCategoryConcentrationStatistics,
    PartData,
    PpvGroup,
    PpvStatistic,
    ReviewChoice,
} from "./classes/MaterializedClasses";
import {
    ApiSuggestionTreeResponse,
    ApiUpdateSuggestionTreeResponse,
    PagePromise,
    setLevelFilterParams,
    setLevelFilterUrlParams,
    setParamsOrNull,
    setSearchUrlParams,
    StorePartReviewBySGroupsSerializer,
} from "./ApiHelpers";
import {Bag} from "./classes/Bag";
import {environment} from "../env";
import {m_taxonomy} from "./classes/TaxonomyClasses";
import {
    ApprovalPart,
    ApprovalRequest,
    ApprovalStatusCount,
    ApprovalStatusEnum,
    CategorizationApprovalDatabagInfo,
    CategorizationApprovalRequest,
    CategorizationApprovalStats,
    TaxonomyApprovalRequest,
    TaxonomyCategoryResp
} from "./classes/AiClasses";
import {
    AdvancedFilter,
    AdvancedGeoData,
    AdvancedOpportunityData,
    AdvancedSpendData,
    AdvancedSpendKPI,
    AdvancedSpendPerGroup,
    AdvancedSupplierBreakdownData,
    AdvancedTreeData,
    AggregatedStatusResults,
    AiCategorizationJobCreateSerializer,
    AiCategorizationJobSerializer,
    CategorizationVersionedStatisticsSerializer,
    DonutChartSupplierSpendData,
    GraphResponse,
    HierarchyMultiValueResponse,
    MatCommonKpiSerializer,
    MatKpiTreeData,
    MatKpiTreeValues,
    PageResponse,
    ParentSupplierSearchResponse,
    SupplierSearchResponse,
    SupplierSegmentationEnum,
    TaxonomyHealthCheckSerializer,
    TaxonomySuggestionSerializer
} from "./ApiTypes";
import {
    AbsTreeNodeFilter,
    DownloadTableRequest,
    MatPartFilter,
    MatSupplierFilter,
    MatSupplierFilter_V2,
    StorePartFeedbackManySerializer,
    StorePartFeedbackSerializer,
    StorePartReviewManySerializer,
    StoreReviewSerializer,
} from "./classes/MatReviewClasses";
import {
    MatSupplierReviewStatistics,
    ParentSupplierPostReviewStats,
    ParentSupplierPreReviewStats,
    ParentSupplierReviewRowSerializer,
    SuggestedParentSupplierSerializer,
    SupplierReviewRowSerializer,
    ViewMCSGroupRowSerializer,
    ViewMCSMemberRowSerializer,
} from "../pages/supplier-normalization/pages/SupplierNormalization.classes";
import {ParentSupplierQueryFilter} from "../stores/normalization/ParentSupplierReviewDelegate";
import {CommonSupplierQueryFilter} from "../stores/synergy/CommonSuppliersReviewDelegate";
import {CategorizationReviewSubRowRelationsData} from "../stores/ProfileStore";
import {makeObservable, observable} from "mobx";
import {taxonomy_health_check} from "./classes/TaxonomyHealthCheckClasses";
import {taxonomy_suggester_v2} from "./classes/TaxonomySuggesterClassesV2";

export type Ordering = { field: string, desc: boolean };

export default class MithraMaterializedApi extends ApiService {

    revisionHack: undefined | 'customer' | string = undefined;

    constructor(public http: AxiosInstance) {
        super(http);
        makeObservable(this, {revisionHack: observable})
    }

    static buildSGroupUrlSearchParams(filter: MatSupplierFilter): URLSearchParams {
        const params = new URLSearchParams();
        params.set('databag', String(filter.databag));
        params.set('group_by', 'supplier_id');
        setParamsOrNull(params, {'business_unit_id': filter.business_unit});
        setSearchUrlParams(params, filter.search);
        setLevelFilterUrlParams(params, filter, 'p_l');
        if (filter.approval) {
            params.set('approval', String(filter.approval));
        }
        return params
    }

    listReviewGroupBySupplier(filter: MatSupplierFilter, page: number, pageSize: number, ordering?: Ordering): PagePromise<MatSGroup> {
        const params = MithraMaterializedApi.buildSGroupUrlSearchParams(filter);
        params.set('page_size', String(pageSize));
        params.set('page', String(page));
        if (!ordering) {
            params.set('ordering', '-sum_spend')
        } else {
            console.error('Ordering not implemented yet for listReviewGroupBySupplier')
        }
        this.applyRevisionHack(params);
        return this.http.get<PageResponse<MatSGroup>>(`/m_review/mat/s_group/`, {params})
    }

    patchReviewRowBySGroup(data: StorePartReviewBySGroupsSerializer): Promise<AxiosResponse> {
        return this.http.patch(`/m_review/base/part/store_review_by_s_groups/`, data);
    }

    storeSupplierReviewByFilter(data: StoreReviewSerializer, params: URLSearchParams): Promise<AxiosResponse<unknown>> {
        this.applyRevisionHack(params);
        return this.http.patch(`/m_review/mat/s_group/store_review_by_s_group_filter/`, data, {params})
    }

    listReviewParts(
        filter: MatPartFilter, page: number, pageSize?: number,
        related_data?: CategorizationReviewSubRowRelationsData[]
    ) {
        const params = filter.urlParams ? filter.urlParams : new URLSearchParams();
        params.set('databag', String(filter.databag));
        return this._listReviewParts(params, page, pageSize, related_data);
    }

    /**
     * Get all parts below a supplier
     */
    listPartsReviewInGroup(
        mat_s_group_ids: number[],
        page: number,
        pageSize?: number,
        related_data?: CategorizationReviewSubRowRelationsData[],
        ordering?: Ordering | undefined,
    ): PagePromise<MatPartReviewRow> {
        const params = new URLSearchParams();
        params.set('s_group__in', mat_s_group_ids.join(','));
        if (ordering) {
            params.set('ordering', (ordering.desc ? '-' : '') + ordering.field);
        }
        return this._listReviewParts(params, page, pageSize, related_data);
    }

    private _listReviewParts(params: URLSearchParams, page: number, pageSize?: number, related_data?: CategorizationReviewSubRowRelationsData[]): PagePromise<MatPartReviewRow> {
        params.set('page', String(page));
        params.set('page_size', String(pageSize) || '100');

        this.applyRevisionHack(params);

        if (related_data !== undefined && related_data.length > 0) {
            let request_related_data = {};
            related_data.forEach(related_data_item => {
                request_related_data[related_data_item.relation] = related_data_item.columns.map((column) => column.db_column)
            });
            params.set('related_data', JSON.stringify(request_related_data));
            return this.http.get('/m_review/base/part/with_related_data/', {params});
        } else {
            return this.http.get(`/m_review/base/part/`, {params})
        }
    }

    getServerPing() {
        return this.http.get<boolean>('/ping/');
    }

    storePartReview(partReviewId: number, data: StoreReviewSerializer): Promise<AxiosResponse<unknown>> {
        return this.http.patch(`/m_review/base/part/${partReviewId}/store_review/`, data)
    }

    storePartReviewMany(data: StorePartReviewManySerializer): Promise<AxiosResponse<unknown>> {
        return this.http.patch(`/m_review/base/part/store_review_by_ids/`, data)
    }

    storePartReviewByFilter(data: StoreReviewSerializer, params: URLSearchParams): Promise<AxiosResponse<unknown>> {
        this.applyRevisionHack(params); // Not tested
        return this.http.patch(`/m_review/base/part/store_review_by_filter/`, data, {params})
    }

    storePartFeedback(partFeedbackId: number, data: StorePartFeedbackSerializer): Promise<AxiosResponse<unknown>> {
        return this.http.patch(`/m_review/base/part/${partFeedbackId}/store_feedback/`, data)
    }

    storePartFeedbackMany(data: StorePartFeedbackManySerializer): Promise<AxiosResponse<unknown>> {
        return this.http.patch(`/m_review/base/part/store_feedback_many/`, data)
    }

    storePartFeedbackAll(approval: number, feedback_choice: ReviewChoice): Promise<AxiosResponse<unknown>> {
        return this.http.patch(`/m_review/base/part/store_feedback_all/`, {approval, feedback_choice})
    }

    listReviewStatistics(bagId: number): Promise<AxiosResponse<MatReviewStatisticsSerializer[]>> {
        const params = new URLSearchParams();
        params.set('databag', String(bagId));

        this.applyRevisionHack(params);

        return this.http.get(`/m_review/stats/total/`, {params})
    }

    /**
     *
     * @param bagId
     * @param businessUnitId undefined: All BU, null: No BU set, number: Specific BU)
     * @param taxonomySize
     */
    listReviewLevelStatistics(bagId: number, businessUnitId: undefined | null | number, taxonomySize: number): Promise<AxiosResponse<[MatReviewLevelStatisticsTreeSerializer]>> {
        if (businessUnitId && Number.isInteger(businessUnitId) && businessUnitId < 0) {
            throw new Error(`Unexpected business unit id ${businessUnitId}`)
        }
        const params = new URLSearchParams();
        params.set('all_business_units', String(businessUnitId === undefined));
        params.set('databag', String(bagId));
        params.set('level__lte', String(taxonomySize));
        setParamsOrNull(params, {'business_unit': businessUnitId})

        this.applyRevisionHack(params);

        return this.http.get(`/m_review/stats/level/as_tree/`, {params})
    }

    getBag(id: number): Promise<AxiosResponse<Bag>> {
        return this.http.get<Bag>(`/bag/${id}/`);
    }

    getAllBags(): Promise<Bag[]> {
        return this.http.get<Bag[]>(`/bag/`).then(r => {
            let d = r.data;
            if (environment.jobOrderingNewToOld) {
                d = d.reverse()
            }
            return d;
        });
    }

    // Approval

    createTaxonomyApprovalRequest(taxonomy: number, notes: string): Promise<AxiosResponse<TaxonomyApprovalRequest>> {
        return this.http.post<TaxonomyApprovalRequest>(`/approve/taxonomy/`, {taxonomy, notes})
    }

    createCategorizationApprovalRequestForAll(notes: string, databag: number): Promise<AxiosResponse<CategorizationApprovalRequest>> {
        return this.http.post<CategorizationApprovalRequest>(`/approve/category/create_for_all/`, {
            databag,
            notes
        })
    }

    approvalStatusCount(): Promise<AxiosResponse<ApprovalStatusCount[]>> {
        return this.http.get<ApprovalStatusCount[]>('/approval/v2/group_by_status_count/')
    }

    approvalList(filters: string[][]): Promise<AxiosResponse<PageResponse<ApprovalRequest>>> {
        const params = new URLSearchParams(filters);
        return this.http.get('/approval/v2/', {params});
    }

    approvalItem(id: number): Promise<AxiosResponse<ApprovalRequest>> {
        return this.http.get('/approval/v2/' + id.toString() + '/',);
    }

    approvalCategorizationPartList(id: number, filters: string[][]): Promise<AxiosResponse<PageResponse<ApprovalPart>>> {
        const params = new URLSearchParams(filters);
        return this.http.get('/approval/v2/' + id.toString() + '/categorization/', {params});
    }

    approvalCategorizationDatabagInfo(databagId: number): Promise<AxiosResponse<CategorizationApprovalDatabagInfo>> {
        return this.http.get(`/approval/category-info/databag/${databagId}/busy_requests/`);
    }

    listApprovalRequests(): Promise<AxiosResponse<ApprovalRequest[]>> {
        return this.http.get<ApprovalRequest[]>('/approve/')
    }

    storeApprovalNotes(approvalId: number, feedback_notes: string): Promise<AxiosResponse<ApprovalRequest[]>> {
        return this.http.patch<ApprovalRequest[]>(`/approve/${approvalId}/`, {feedback_notes})
    }

    overrideApprovalStatus(approvalId: number, status: ApprovalStatusEnum) {
        return this.http.patch<ApprovalRequest[]>(`/approve/${approvalId}/override/`, {status})
    }

    deleteApproval(approvalId: number) {
        return this.http.delete(`/approve/${approvalId}/`)
    }

    getTaxonomyApprovalRequest(approvalId: number): Promise<AxiosResponse<TaxonomyApprovalRequest>> {
        return this.http.get<TaxonomyApprovalRequest>(`/approve/taxonomy/${approvalId}/`)
    }

    applyTaxonomyApproval(approvalId: number, status: ApprovalStatusEnum, feedback_notes: string): Promise<AxiosResponse<TaxonomyApprovalRequest>> {
        return this.http.post<TaxonomyApprovalRequest>(`/approve/taxonomy/${approvalId}/apply/`, {
            status,
            feedback_notes
        })
    }

    getCategorizationApprovalRequest(approvalId: number): Promise<AxiosResponse<CategorizationApprovalRequest>> {
        return this.http.get<CategorizationApprovalRequest>(`/approve/category/${approvalId}/`)
    }

    getCategorizationApprovalStatsRequest(approvalId: number): Promise<AxiosResponse<CategorizationApprovalStats>> {
        return this.http.get(`/approve/category/${approvalId}/stats/`)
    }

    applyCategorizationApproval(approvalId: number, status: ApprovalStatusEnum): Promise<AxiosResponse<never>> {
        // Do we want to add feedback_notes here as well?
        return this.http.post(`/approve/category/${approvalId}/apply/`, {status})
    }

    // Taxonomy

    getTaxonomyCategories(taxonomyId: number): Promise<AxiosResponse<TaxonomyCategoryResp>> {
        // Extracted directly from the dataset
        return this.http.get(`/taxonomy/${taxonomyId}/category_list/`)
    }

    listMTaxonomyForBag(bagId: number): Promise<AxiosResponse<m_taxonomy.SimpleSerializer[]>> {
        return this.http.get(`/taxonomy/`, {
            params: {
                databag: bagId,
                hidden: false,
            }
        })
    }

    getMTaxonomy(taxonomyId: number): Promise<AxiosResponse<m_taxonomy.FullSerializer>> {
        return this.http.get(`/taxonomy/${taxonomyId}/`)
    }

    listMTaxonomyHistory(taxonomyId: number): Promise<AxiosResponse<m_taxonomy.SimpleTaxonomyOperationSerializer[]>> {
        return this.http.get(`/taxonomy/${taxonomyId}/history/`)
    }

    gotoMTaxonomyHistory(taxonomyId: number, d: m_taxonomy.GotoHistorySerializer): Promise<AxiosResponse<m_taxonomy.FullSerializer>> {
        return this.http.patch(`/taxonomy/${taxonomyId}/goto_history/`, d)
    }

    createMTaxonomyState(taxonomyId: number, d: m_taxonomy.CreateTaxonomyOperationSerializer): Promise<AxiosResponse<m_taxonomy.FullSerializer>> {
        // pre: operation_number == taxonomy.operation_number + 1
        // Note: This method is allowed to overwrite history
        return this.http.post(`/taxonomy/${taxonomyId}/store_state/`, d)
    }

    // KPI

    getCommonKpi(bagId: number): Promise<AxiosResponse<MatCommonKpiSerializer>> {
        return this.http.get(`/bag_kpi/${bagId}/common_kpi/`);
    }

    getTaxonomyKpiFullDepth(databag: number): Promise<AxiosResponse<HierarchyMultiValueResponse<MatKpiTreeData>>> {
        return this.http.get(`/m_kpi/tree/`, {params: {databag}});
    }

    getTaxonomyKpi(databag: number, taxonomySize: number): Promise<AxiosResponse<HierarchyMultiValueResponse<MatKpiTreeData>>> {
        return this.http.get(`/m_kpi/tree/`, {params: {databag, level__lte: taxonomySize}});
    }

    /**
     * Returns the sankey graph for a single value (spend/parts/etc)
     */
    getTaxonomyGraphKpi(databag: number, value: MatKpiTreeValues): Promise<AxiosResponse<GraphResponse>> {
        // Possibly add level__lte to limit tree size
        return this.http.get(`/m_kpi/tree/as_graph/`, {params: {databag, value}});
    }

    // KOI's

    listSupplierSegmentationResult(bagId: number): Promise<AxiosResponse<Array<{
        // PageResponse<{}>
        id: number
        s_id: string
        p_spend: number
        cum_p_spend_perc: number
        cum_s_n_parts_perc: number
        s_n_parts: number
        s_n_cats: number
        s_seg: SupplierSegmentationEnum
        databag: number
    }>>> {
        return this.http.get(`/m_koi/ss/`, {params: {databag: bagId, no_page: 1}}).then(r => {
            r.data.forEach(r => r.s_seg = SupplierSegmentationEnum[r.s_seg])
            return r;
        })
    }

    listSpendConcentration(bagId: number): Promise<MatConcentrationStatistics | null> {
        return this.http.get<MatConcentrationStatistics[]>(`/m_koi/sc/`, {params: {databag: bagId, no_page: 1}})
            .then(r => {
                r.data.forEach(r => {
                    r.total_spend = Number(r.total_spend)
                    r.top_n_spend = Number(r.top_n_spend)
                })
                return r.data[0] || null;
            })
    }

    listSpendConcentrationCategoryL1(bagId: number): Promise<AxiosResponse<MatCategoryConcentrationStatistics[]>> {
        return this.http.get<MatCategoryConcentrationStatistics[]>(`/m_koi/sc/category/`, {
            params: {
                databag: bagId,
                no_page: 1,
                level: 1,
            }
        }).then(r => {
            r.data.forEach(r => {
                r.total_spend = Number(r.total_spend)
                r.top_n_spend = Number(r.top_n_spend)
            })
            return r;
        })
    }

    listSpendConcentrationCategory(bagId: number, level: number): Promise<AxiosResponse<MatCategoryConcentrationStatistics[]>> {
        return this.http.get<MatCategoryConcentrationStatistics[]>(`/m_koi/sc/category/`, {
            params: {
                databag: bagId,
                no_page: 1,
                level__lte: level,
            }
        }).then(r => {
            r.data.forEach(r => {
                r.total_spend = Number(r.total_spend)
                r.top_n_spend = Number(r.top_n_spend)
            })
            return r;
        })
    }

    listSpendConcentrationSupplierL1(bagId: number): Promise<AxiosResponse<MatSupplierCategoryConcentrationStatistics[]>> {
        // Note: For the first iteration this view is not paginated
        return this.http.get<MatSupplierCategoryConcentrationStatistics[]>(`/m_koi/sc/supplier/`, {
            params: {
                databag: bagId,
                no_page: 1,
                level: 1,
                // level__lte: 1,
            }
        }).then(r => {
            r.data.forEach(r => {
                r.s_c_spend = Number(r.s_c_spend)
                r.s_total_spend = Number(r.s_total_spend)
            })
            return r;
        })
    }

    listPaginatedSpendConcentrationSupplier(filter: MatSupplierFilter_V2, page: number, page_size: number, ordering?: Ordering): PagePromise<MatSupplierCategoryConcentrationStatistics> {
        let params = {
            databag: filter.databag,
            level: filter.filterLevel,
            page_size,
            page,
        };
        if (ordering) {
            // BE: By default it's on the spend given the filter, other options are: s_total_spend or s_total_parts
            params['ordering'] = (ordering.desc ? '-' : '') + ordering.field;
        }
        if (filter.search) {
            if (filter.search.supplier) {
                params['search'] = filter.search.supplier;
            } else {
                console.warn('Search not supported for', filter.search);
            }
        }
        let fixLevels = filter.fixLevels === undefined ? filter.filterLevel : filter.fixLevels;
        params = setLevelFilterParams(fixLevels, filter, params);
        // console.log('SUPPLIER_RET:listSpendConcentrationSupplier supplierRequestFilter.params=', params);
        return this.http.get<PageResponse<MatSupplierCategoryConcentrationStatistics>>(`/m_koi/sc/supplier/`, {params}).then(r => {
            r.data.results.forEach(r => {
                r.s_c_spend = Number(r.s_c_spend)
                r.s_total_spend = Number(r.s_total_spend)
            })
            return r;
        })
    }

    listSpendConcentrationSupplier(filter: MatSupplierFilter_V2, ordering?: Ordering): Promise<AxiosResponse<MatSupplierCategoryConcentrationStatistics[]>> {
        let params = {
            databag: filter.databag,
            level: filter.filterLevel,
            no_page: 1,
        };
        if (ordering) {
            // BE: By default it's on the spend given the filter, other options are: s_total_spend or s_total_parts
            params['ordering'] = (ordering.desc ? '-' : '') + ordering.field;
        }
        if (filter.search) {
            if (filter.search.supplier) {
                params['search'] = filter.search.supplier;
            } else {
                console.warn('Search not supported for', filter.search);
            }
        }
        let fixLevels = filter.fixLevels === undefined ? filter.filterLevel : filter.fixLevels;
        params = setLevelFilterParams(fixLevels, filter, params);
        // console.log('SUPPLIER_RET:listSpendConcentrationSupplier supplierRequestFilter.params=', params);
        return this.http.get<MatSupplierCategoryConcentrationStatistics[]>(`/m_koi/sc/supplier/`, {params}).then(r => {
            r.data.forEach(r => {
                r.s_c_spend = Number(r.s_c_spend)
                r.s_total_spend = Number(r.s_total_spend)
            })
            return r;
        })
    }

    listPartData(bagId: number, supplierId: number, page: number, page_size: number): PagePromise<PartData> {
        return this.http.get<PageResponse<PartData>>(`/data/part/`, {
            params: {
                databag_id: bagId, // This is not referenced in backend as it's the source data
                supplier: supplierId,
                page,
                page_size,
            }
        })
            .then(r => {
                r.data.results.forEach(r => {
                    r.p_spend = Number(r.p_spend)
                })
                return r;
            })
    }

    listBusinessUnitData(bagId: number, ordering?: Ordering): Promise<AxiosResponse<BusinessUnit[]>> {
        let params = {
            databag_id: bagId,
            no_page: true,
        };
        if (ordering) params['ordering'] = (ordering.desc ? '-' : '') + ordering.field;
        return this.http.get(`/data/business_unit/`, {params})
    }

    listMatTaxonomy(src_taxonomy: number): Promise<AxiosResponse<m_taxonomy.MaterializedTaxonomy[]>> {
        if (environment.isDemo) {
            if (src_taxonomy === 400) return mithra_http.mockApi.get('sales_demo/suggestor/src_taxonomy_a.json')
            else return mithra_http.mockApi.get('sales_demo/suggestor/src_taxonomy_b.json')
        }
        const params = {src_taxonomy, hidden: false};
        return this.http.get<m_taxonomy.MaterializedTaxonomy[]>(`/m_taxonomy/list_full/`, {params})
    }

    listMatTaxonomyMapping(src_mat_taxonomy: number, dst_mat_taxonomy: number): Promise<AxiosResponse<m_taxonomy.MaterializedCategoryMap[]>> {
        if (environment.isDemo) return mithra_http.mockApi.get('/sales_demo/suggestor/category_map.json');
        return this.http.get<m_taxonomy.MaterializedCategoryMap[]>(`/m_taxonomy/category_map/`, {
            params: {src_mat_taxonomy, dst_mat_taxonomy}
        })
    }

    createMatTaxonomyMapping(create: m_taxonomy.CreateMaterializedCategoryMap): Promise<AxiosResponse<m_taxonomy.MaterializedCategoryMap[]>> {
        return this.http.post(`/m_taxonomy/category_map/`, create);
    }

    createMatTaxonomyMapping2(create: m_taxonomy.CreateMaterializedCategoryMap2): Promise<AxiosResponse<m_taxonomy.MaterializedCategoryMap[]>> {
        return this.http.post(`/m_taxonomy/category_map/create_raw/`, create);
    }

    deleteMatTaxonomyMapping(id: number): Promise<AxiosResponse<void>> {
        return this.http.delete(`/m_taxonomy/category_map/${id}/`);
    }

    downloadTaxonomyExcel(taxonomyId: number, filename: string) {
        return this.http.post(`/taxonomy/${taxonomyId}/download_as_excel/`, {filename}, {responseType: 'blob'})
    }

    applyTaxonomyMappingAi(data: ApplyTaxonomyMappingRequestData): Promise<AxiosResponse<{ dst_databag: number }>> {
        return this.http.post(`/m_taxonomy/category_map/apply/apply_taxonomy_mapping_ai/`, data)
    }

    materializeReview(data: MaterializeRequest): Promise<AxiosResponse> {
        return this.http.post(`/materialize_manager/create_review_materialization/`, data)
    }

    debugDeleteAiClassification(databag: number): Promise<AxiosResponse> {
        return this.http.post(`/m_taxonomy/category_map/apply/delete_ai_result/`, {databag})
    }

    debugDeleteReviewMaterialization(databag: number): Promise<AxiosResponse> {
        return this.http.post(`/materialize_manager/delete_review_materialization/`, {databag})
    }

    downloadReviewExcel(databag: number, filename: string): Promise<AxiosResponse> {
        return this.http.post(`/m_review/export/download_excel/`, {databag, filename}, {responseType: 'blob'})
    }

    listPpvStatistics(filter: AbsTreeNodeFilter, ordering?: Ordering): Promise<AxiosResponse<PpvStatistic[]>> {
        let params = {
            databag: filter.databag,
            level: filter.type === 'children' ? filter.level + 1 : filter.level,
            no_page: true,
        };
        params = setLevelFilterParams(filter.level, filter, params);
        if (ordering) params['ordering'] = (ordering.desc ? '-' : '') + ordering.field;
        return this.http.get<PpvStatistic[]>(`/m_koi/ppv_statistics/`, {params})
    }

    listPpvGroups(page: number, filter: AbsTreeNodeFilter, ordering?: Ordering): Promise<AxiosResponse<PageResponse<PpvGroup>>> {
        let params = {
            databag: filter.databag,
            level: filter.type === 'children' ? filter.level + 1 : filter.level,
            page,
        };
        params = setLevelFilterParams(filter.level, filter, params);
        if (ordering) params['ordering'] = (ordering.desc ? '-' : '') + ordering.field;
        return this.http.get<PageResponse<PpvGroup>>(`/m_koi/ppv/`, {params})
    }

    downloadPpvTable(data: DownloadTableRequest) {
        return this.http.post(`/m_koi/ppv_download/`, data, {responseType: 'blob'})
    }

    listParentSupplierSuggestions(databag_id: number, search: string): Promise<AxiosResponse<SuggestedParentSupplierSerializer[]>> {
        // Or non paged?: no_page: true
        return this.http.get(`/m_sp_review/suggested_parent_suppliers/`, {
            params: {
                // page,
                no_page: true,
                databag_id,
                search,
            }
        })
    }

    listParentSupplierReviewRows(databag_id: number, page: number, filter: ParentSupplierQueryFilter): Promise<AxiosResponse<PageResponse<ParentSupplierReviewRowSerializer>>> {
        const params = {
            page,
            page_size: 20,
            databag_id,
            search: filter.parent_search || '',
        };
        if (!filter.showStandaloneSuppliers) params['sp_n_suppliers__gt'] = 1;
        return this.http.get(`/m_sp_review/parent_rows/`, {params})
    }

    listSupplierReviewRows(databag_id: number, rowIds: number[]): Promise<AxiosResponse<SupplierReviewRowSerializer[]>> {
        return this.http.get(`/m_sp_review/supplier_rows/`, {
            params: {
                no_page: true,
                databag_id,
                parent_supplier_review_row__in: rowIds.join(','),
            }
        })
    }

    listCommonSupplierGroups(synergy_id: number, page: number, filter: CommonSupplierQueryFilter): Promise<AxiosResponse<PageResponse<ViewMCSGroupRowSerializer>>> {
        const params = {
            page,
            page_size: 20,
            synergy_id,
            search: filter.group_search || '',
        };
        if (!filter.showStandaloneSuppliers) {
            params['cs_n_members__gt'] = 1;
            // ORIGINAL ISSUE: CAT-893: Used just in LG (Liberty Global) since the definition of common suppliers was insufficient
            // For common suppliers, the standalone button is not shown currently (if we add it cs_mixed_sources may cause problems)
            params['cs_mixed_sources'] = true;
        }
        // We need to ignore the default sorting by mixed sources in SHV to show all the big suppliers on the first page
        params['ordering'] = '-cs_total_spend';

        return this.http.get(`/synergy/mat/common_supplier_groups/`, {params})
    }

    listCommonSupplierMembers(synergy_id: number, rowIds: number[]): Promise<AxiosResponse<ViewMCSMemberRowSerializer[]>> {
        return this.http.get(`/synergy/mat/common_supplier_member/`, {
            params: {
                no_page: true,
                synergy_id,
                group_row__in: rowIds.join(','),
            }
        })
    }

    storeSupplierReviewUpdate(
        supplierRowId: number,
        parent_supplier: number | undefined,
        sp_id: number | undefined,
        sp_name: string,
    ): Promise<AxiosResponse<unknown>> {
        const data: any = {sp_name}
        if (sp_id) data['sp_id'] = sp_id
        if (parent_supplier) data['parent_supplier'] = parent_supplier
        return this.http.patch(`/m_sp_review/supplier_rows/${supplierRowId}/store_review/`, data)
    }

    /**
     * @deprecated In favour of getMatSupplierReviewStatistics
     */
    getParentSupplierReviewPreStats(databag: number) {
        return this.http.get<ParentSupplierPreReviewStats>(`/m_sp_review/statistics/${databag}/pre_stats/`)
    }

    /**
     * @deprecated In favour of updateMatSupplierReviewStatistics
     */
    getParentSupplierReviewPostStats(databag: number) {
        return this.http.get<ParentSupplierPostReviewStats>(`/m_sp_review/statistics/${databag}/post_stats/`)
    }

    getMatSupplierReviewStatistics(databag_id: number) {
        return this.http.get<MatSupplierReviewStatistics>(`/m_sp_review/statistics/${databag_id}/`)
    }

    updateMatSupplierReviewStatistics(databag_id: number) {
        return this.http.patch<MatSupplierReviewStatistics>(`/m_sp_review/statistics/${databag_id}/`)
    }

    getTaxonomySuggestionsV2(taxonomy_suggestion_v2: number): Promise<TaxonomySuggestionSerializer<ApiSuggestionTreeResponse<taxonomy_suggester_v2.APIValues>>> {
        // TODO: [CAT-1346] For demo purposes we use the dpw_demo data
        console.log("taxonomy suggestion avaiable!")
        const baseURL = window.location.origin;
        return this.http.get('taxonomy-suggestion/taxonomy_suggestion.json', {baseURL}).then((r) => {
            return r.data;
        });

        // return this.http.get(`/taxonomy_suggestion/${taxonomy_suggestion_v2}/`).then((r) => {
        //     return r.data;
        // });
    }

    getTaxonomySuggestions(taxonomy_suggestion: number): Promise<TaxonomySuggestionSerializer<ApiSuggestionTreeResponse<taxonomy_health_check.APIValues>>> {
        // TODO: [CAT-1346] For demo purposes we use the dpw_demo data
        // const baseURL = window.location.origin;
        // return this.http.get('dpw_demo/suggestor_example.json', {baseURL}).then((r) => {
        //     return r.data;
        // });

        return this.http.get(`/taxonomy_suggestion/${taxonomy_suggestion}/`).then((r) => {
            return r.data;
        });
    }

    getTaxonomyHealthChecks(taxonomy: number): Promise<TaxonomyHealthCheckSerializer<ApiSuggestionTreeResponse<m_taxonomy.Data>>[]> {
        if (environment.isDemo) {
            // TODO: Verify that this data works with the new THC components
            return mithra_http.mockApi.get('sales_demo/suggestor/health_check.json')
        }
        return this.http.get(`/taxonomy_health_checks/?taxonomy_id=${taxonomy}`).then((r) => {
            return r.data;
        });
    }

    putTaxonomyHealthCheck(taxonomyHealthCheckId: number, data: taxonomy_health_check.HealthCheckApiResponse): Promise<AxiosResponse<TaxonomyHealthCheckSerializer<ApiSuggestionTreeResponse<m_taxonomy.Data>>>> {
        return this.http.put(`/taxonomy_health_checks/${taxonomyHealthCheckId}/`, data);
    }

    applyTaxonomyHealthChecks(taxonomyHealthCheckId: number): Promise<AxiosResponse<void>> {
        return this.http.post(`/taxonomy_health_checks/${taxonomyHealthCheckId}/apply/`);
    }

    createNewTaxonomyHealthCheck(taxonomy: number): Promise<AxiosResponse<void>> {
        return this.http.post(`/taxonomy_health_checks/`, {taxonomy});
    }

    putTaxonomySuggestions(taxonomy_suggestion: number, data: ApiUpdateSuggestionTreeResponse<m_taxonomy.Data>): Promise<AxiosResponse<ApiSuggestionTreeResponse<m_taxonomy.Data>>> {

        const suggestion_state = {
            'suggestion_state': data
        }

        return this.http.put(`/taxonomy_suggestion/${taxonomy_suggestion}/`, suggestion_state);
    }

    saveTaxonomySuggestions(taxonomy_suggestion: number): Promise<AxiosResponse<ApiSuggestionTreeResponse<m_taxonomy.Data>>> {
        return this.http.post(`taxonomy_suggestion/${taxonomy_suggestion}/apply_suggestion/`, {});
    }

    updateTaxonomySuggestionValues(taxonomy_suggestion_id: number): Promise<any> {
        return this.http.post(`/taxonomy_suggestion/${taxonomy_suggestion_id}/update_values/`);
    }

    createAiJob(data: AiCategorizationJobCreateSerializer): Promise<AxiosResponse<{ id: number }>> {
        return this.http.post('/ai_io/', data)
    }

    restartAiJob(aiJobId: number) {
        return this.http.patch(`/ai_io/${aiJobId}/restart/`)
    }

    getVertexAiJobStatus(aiJobId: number): Promise<AxiosResponse<{ vertex_ai_status: string }>> {
        return this.http.get(`/ai_io/${aiJobId}/vertex_ai_status/`)
    }

    getAiLatestJob(databag_id: number): Promise<AxiosResponse<AiCategorizationJobSerializer | null>> {
        return this.http.get(`/ai_io/latest_job/?databag=${databag_id}`, {validateStatus: axiosAllow404})
    }

    approveReview(aiJobId: number) {
        return this.http.post(`/ai_io/${aiJobId}/accept_review/`)
    }

    rejectReview(aiJobId: number) {
        return this.http.post(`/ai_io/${aiJobId}/reject_review/`)
    }

    getCategorizationVersionedStatistics(databag: number): Promise<AxiosResponse<CategorizationVersionedStatisticsSerializer>> {
        const params = new URLSearchParams()
        this.applyRevisionHack(params);

        if (environment.isDemo) {
            return this.http.get(`/m_review/stats/versioned/${databag}/cached/`, {params})
        }

        return this.http.get(`/m_review/stats/versioned/${databag}/`, {params})
    }

    getAdvancedSpendTree(databag_id: number, filters: AdvancedFilter = {}): Promise<AxiosResponse<AdvancedTreeData[]>> {
        return this.http.get<AdvancedTreeData[]>(`/insights/spend_data/${databag_id}/tree_data/`, {params: filters});
    }

    getAdvancedSpendGeo(databag_id: number, filters: AdvancedFilter = {}): Promise<AxiosResponse<AdvancedGeoData[]>> {
        return this.http.get<AdvancedGeoData[]>(`/insights/spend_data/${databag_id}/country_data/`, {params: filters});
    }

    getAdvancedSpendTimePerSupplier(databag_id: number, filters: AdvancedFilter = {}): Promise<AxiosResponse<AdvancedSpendPerGroup[]>> {
        return this.http.get<AdvancedSpendPerGroup[]>(`/insights/spend_data/${databag_id}/line_data_per_supplier/`, {params: filters});
    }

    getAdvancedSpendTimePerCategory(databag_id: number, filters: AdvancedFilter = {}, category: string): Promise<AxiosResponse<AdvancedSpendPerGroup[]>> {
        return this.http.get<AdvancedSpendPerGroup[]>(`/insights/spend_data/${databag_id}/line_data_per_category/?active=${category}`, {params: filters});
    }

    getAdvancedSpendKPI(databag_id: number, filters: AdvancedFilter = {}): Promise<AxiosResponse<AdvancedSpendKPI>> {
        return this.http.get<AdvancedSpendKPI>(`/insights/spend_data/${databag_id}/kpi/`, {params: filters});
    }

    getAdvancedDonutData(databag_id: number, filters: AdvancedFilter = {}): Promise<AxiosResponse<DonutChartSupplierSpendData[]>> {
        return this.http.get<AdvancedSpendData[]>(`/insights/spend_data/${databag_id}/donut_data/`, {params: filters});
    }

    getAdvancedSpendConcentrationData(databag_id: number, filters: AdvancedFilter = {}): Promise<AxiosResponse<AdvancedSpendData[]>> {
        return this.http.get<AdvancedSpendData[]>(`/insights/spend_data/${databag_id}/spend_concentration_data/`, {params: filters});
    }

    getAdvancedOpportunityData(databag_id: number, filters: AdvancedFilter = {}, ordering: string): Promise<AxiosResponse<AdvancedOpportunityData[]>> {
        let params = {...filters}
        params['ordering'] = ordering;
        return this.http.get<AdvancedOpportunityData[]>(`/insights/spend_data/${databag_id}/opportunity_data/`, {params: params});
    }

    getAdvancedSupplierBreakdownData(databag_id: number, filters: AdvancedFilter = {}, ordering: string): Promise<AdvancedSupplierBreakdownData[]> {
        let params = {...filters}
        params['ordering'] = ordering;
        return this.http.get<AdvancedSupplierBreakdownData[]>(`/insights/spend_data/${databag_id}/supplier_breakdown_data/`, {params: params})
            .then(resp => {
                // See if we should be converted or not
                let d = resp.data as any;
                if (Array.isArray(d))
                    return d as AdvancedSupplierBreakdownData[];
                if (d.data === undefined || d.columns === undefined) {
                    throw new Error("Invalid response from server");
                }
                const columns = d.columns as string[];
                d = d.data.map((row: any) =>
                    columns.reduce((obj: any, key: string, index: number) => {
                        obj[key] = row[index];
                        return obj;
                    }, {}));
                return d as AdvancedSupplierBreakdownData[];
            });
    }

    searchSupplier(databag_id: number, search: string): Promise<AxiosResponse<SupplierSearchResponse>> {
        return this.http.get<SupplierSearchResponse>(`/data/supplier/`, {params: {search, databag_id}});
    }

    searchParentSupplier(databag_id: number, search: string): Promise<AxiosResponse<ParentSupplierSearchResponse>> {
        return this.http.get<ParentSupplierSearchResponse>(`/data/parent_supplier/`, {params: {search, databag_id}});
    }

    private applyRevisionHack(params: URLSearchParams) {
        if (this.revisionHack !== undefined) {
            if (this.revisionHack === 'customer') {
                params.set('revision__isnull', 'true');
            } else {
                params.set('revision', String(this.revisionHack));
            }
        }
    }

    getAggregatedStatus(databagId: number, pageSize?: number, page?: number, search0_fields?: string, search0_query?: string): Promise<AxiosResponse<AggregatedStatusResults>> {
        //eg: ?search0_query=kpmg&search0_fields=s_name&databag=1450&page=1&page_size=100
        const params = new URLSearchParams();
        params.set('databag', String(databagId));
        params.set('page_size', String(pageSize));
        params.set('page', String(page));
        if (search0_fields && search0_query) {
            params.set('search0_fields', search0_fields);
            params.set('search0_query', search0_query);
        }
        return this.http.get<AggregatedStatusResults>(`/m_review/base/part/aggregated_status/`, {params});
    }
}
