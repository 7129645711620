import './TaxonomyMapperReview.scss';
import React from "react";
import {observer} from "mobx-react-lite";
import {useRouteMatch} from "react-router-dom";
import {JobRouteMatch, routes} from "../../routing/routes";
import {useStores} from "../../stores";
import {Button, Grid} from "@mui/material";
import {KoiFacade} from "../../components/koi-facade/KoiFacade";
import {generatePath, useHistory} from "react-router";
import Typography from "@mui/material/Typography";
import {TaxonomyMapperReviewTable} from "./table/TaxonomyMapperReviewTable";
import {ChevronRight} from "@mui/icons-material";
import {TaxonomyMapperUnmappedReviewTable} from "./table/TaxonomyMapperUnmappedReviewTable";
import {environment} from "../../env";
import {generateGotoDashboardPath, generateHomePath} from "../../routing/routing.utils";
import {C} from "../../configurations/active-configuration";
import {hackGetTaxonomyMapperStore} from "../../stores/hack";

export const TaxonomyMapperReviewPage: React.FC = observer(() => {
    let {p, rootAppStore, taxonomyMapperStore} = useStores();
    if (C.package === 'merge_x') {
        taxonomyMapperStore = hackGetTaxonomyMapperStore(rootAppStore.app);
    }
    const routeMatch = useRouteMatch<JobRouteMatch>();
    const history = useHistory();
    // const theme = useTheme();
    // const mediaTablet = useMediaQuery(theme.breakpoints.down('md'));

    let backUrl;
    if (environment.package === 'merge_1') {
        // In the merge_1 package there are no id's in the url
        backUrl = generatePath(routes.merge_1_common_categories, routeMatch.params);
    } else if (environment.package === 'sales_demo') {
        backUrl = generatePath(routes.demo_merge_taxonomy_mapper, routeMatch.params);
    } else {
        backUrl = generatePath(routes.taxonomy_mapper, routeMatch.params);
    }
    // TODO: CAT-712
    // rootAppStore.app.dashEnv === 'simple'
    //     ? generatePath(routes.pic_common_categories, routeMatch.params)
    //     : rootAppStore.app.dashEnv === 'nestle'
    //         ? generatePath(routes.nestle_common_categories, routeMatch.params)
    //         : generatePath(routes.synergy_v2_taxonomy_mapper, routeMatch.params)

    const toDashboard = p.p.skipAiTaxonomyMapperModel
        || environment.package === 'merge_1' || environment.package === 'merge_x';
    if (!toDashboard) console.warn('Submiting the data is not supported yet...');

    return <KoiFacade
        title="Taxonomy Mapper Review"
        // helpContent={<PurchasePriceVarianceHelp/>}
        intro={<></>}
        back={backUrl}
        next={{
            type: 'component',
            node: <Button
                color="primary"
                variant="outlined"
                endIcon={<ChevronRight/>}
                onClick={() => {
                    if (environment.package === 'sales_demo') {
                        history.push(generateHomePath(routeMatch, undefined, rootAppStore.app))
                        return
                    }
                    // SHV
                    if (toDashboard) {
                        // In the merge_1 package there are no id's in the url

                        // Go back to the dashboard
                        history.push(generateGotoDashboardPath(routeMatch, routeMatch.params))
                        return
                    }

                    if (environment.package === 'merge_1') {
                        // In the merge_1 package there are no id's in the url

                        // Go back to the dashboard
                        history.push(generateGotoDashboardPath(routeMatch, routeMatch.params))
                        return
                    }

                    history.push(generatePath(routes.job_dashboard, routeMatch.params))
                    // TODO: CAT-712
                    // if (rootAppStore.package.dashEnv === 'nestle') {
                    //     // Dashboard
                    //     history.push(generatePath(routes.nestle_dashboard))
                    // } else if (rootAppStore.package.dashEnv === 'multi') {
                    //     // AiCategorizationPage
                    //     history.push(generatePath(routes.synergy_merge_review, routeMatch.params))
                    // } else {
                    //     // AiCategorizationPage
                    //     history.push(generatePath(routes.synergy_v2_review, routeMatch.params))
                    // }
                }}>
                {/*{rootAppStore.app.dashEnv === 'nestle' ? 'Dashboard' : 'Submit'}*/}
                {toDashboard ? 'Dashboard' : 'Submit'} {/*SHV*/}

            </Button>
        }}
        className="taxonomy-mapper-review-page">

        <section className="unmatched">
            <header>
                <Typography variant="h2" className="title">
                    Not matched categories
                </Typography>
                <Typography variant="body1" className="explanation">
                    The categories below are not matched to an existing category.
                    You can still continue with the review process.
                    However, the entries associated with these categories will either be assigned as Uncategorized
                    or receive an Ai suggested category.
                </Typography>
            </header>

            <TaxonomyMapperUnmappedReviewTable/>

        </section>

        <section className="matched">
            <header>
                <Typography variant="h2" className="title">
                    Matched categories
                </Typography>
                <Grid container justifyContent="end" className="taxonomy-titles">
                    <Grid item sm={6}>
                        <Typography variant="h3" className="taxonomy-title base-title">
                            {taxonomyMapperStore.baseTaxonomyName}
                        </Typography>
                    </Grid>
                    <Grid item sm={6}>
                        <Typography variant="h3" className="taxonomy-title target-title">
                            {taxonomyMapperStore.targetTaxonomyName}
                        </Typography>
                    </Grid>
                </Grid>
            </header>

            <TaxonomyMapperReviewTable/>
        </section>
    </KoiFacade>
});
