import {
    CurrencyAbbreviation,
    findAbbreviationOfGroup,
    NO_ABBREVIATION
} from "../../../components/currency-component/CurrencyClasses";
import {ParentSupplier, ParentSupplierUserData} from "../../../stores/normalization/ParentSupplierDelegate";

export type SuggestedParentSupplierSerializer = {
    id: number | null
    sp_id: string
    sp_name: string
}
export type ParentSupplierReviewRowSerializer = {
    id: number
    sp_id: string
    sp_name: string
    sp_city: string
    sp_country: string
    sp_region: string
    sp_context_1: string
    sp_context_2: string
    sp_context_3: string
    sp_total_spend: number
    sp_total_parts: number
    sp_total_l1s: string[]
    sp_total_bus: string[]
    sp_mixed_supplier_sources: boolean
    sp_n_suppliers: number
}
export type SupplierReviewRowSerializer = {
    id: number
    s_id: string
    s_name: string
    s_city: string
    s_country: string
    s_context_3: 'base' | 'master' | ''
    s_region: string
    s_total_spend: number
    s_total_parts: number
    s_total_l1s: string[]
    s_total_bus: string[]
    parent_supplier_review_row: number // Just the ID
    parent_supplier_id: number | null
    sp_id: string
    sp_name: string
    ai_suggested: boolean
    ai_score: number
    review_user_id: number | null
    review_notes: string
}
export type ParentSupplierReviewRowState = ParentSupplierReviewRowSerializer & {
    type: 'parent_supplier'
    subRows: SupplierReviewRowState[] | null
}
export type SupplierReviewRowState = SupplierReviewRowSerializer & {
    type: 'supplier'
    parent_row: ParentSupplierReviewRowSerializer
    parent_match: ParentSupplierMatch | null
}
/**
 * @deprecated TODO: Split this up
 */
type AnyRow =
    SupplierReviewRowState
    | ParentSupplierReviewRowState
    | CommonSupplierReviewGroupRowState
    | CommonSupplierReviewMemberRowState;

export type ViewMCSGroupRowSerializer = {
    id: number
    cs_id: string
    cs_name: string
    cs_city: string
    cs_country: string
    cs_region: string
    cs_context_1: string
    cs_context_2: string
    cs_context_3: string
    cs_total_spend: string
    cs_total_parts: number
    cs_total_l1s: string[]
    cs_total_bus: string[]
    cs_mixed_sources: boolean
    cs_n_members: number
}
export type ViewMCSMemberRowSerializer = {
    id: number
    s_id: string
    s_name: string
    s_city: string
    s_country: string
    s_context_3: string | '' | 'Mixed'
    s_region: string

    total_spend: string
    total_parts: number
    total_l1s: string[]
    total_bus: string[]

    group_row_id: number // Just the ID

    s_match_id: number | null
    // sp_id: string
    // sp_name: string
    ai_result: boolean
    ai_score: number
    review_user_id: number | null
}
export type CommonSupplierReviewGroupRowState = ViewMCSGroupRowSerializer & {
    type: 'group'
    subRows: CommonSupplierReviewMemberRowState[] | null
}
export type CommonSupplierReviewMemberRowState = ViewMCSMemberRowSerializer & {
    type: 'member'
    group_row: ViewMCSGroupRowSerializer
    // match?: ... | null
}

export type ApiParentSupplierMatch = { type: 'parent_supplier', data: ParentSupplier };
/**
 * Some kind of supplier parent match from the user or the backend
 */
export type ParentSupplierMatch =
    ApiParentSupplierMatch
    | { type: 'new_parent_supplier', data: ParentSupplierUserData }


export type ParentSupplierPreReviewStats = {
    total_reviewed_spend: number
    total_suppliers_count: number
    total_parent_suppliers_count: number
    parent_suppliers_count: number
    total_standalone_suppliers_count: number
}
export type ParentSupplierPostReviewStats = ParentSupplierPreReviewStats & {
    total_standalone_suppliers_spend: number
}

export type MatSupplierReviewStatistics = {
    databag: number,
    total_suppliers: number,
    total_spend: number,
    total_parts: number,
    parent_suppliers: number,
    parent_spend: number,
    parent_parts: number,
    standalone_suppliers: number,
    standalone_spend: number,
    standalone_parts: number
}

export function getSpend(row: AnyRow): number {
    switch (row.type) {
        case 'parent_supplier':
            return row.sp_total_spend;
        case 'supplier':
            return row.s_total_spend;
        case 'group':
            return Number(row.cs_total_spend);
        case 'member':
            return Number(row.total_spend);
    }
    return 0;
}

export function getAbbreviation(data: AnyRow[] | undefined | null): CurrencyAbbreviation {
    if (data) {
        const spends = data.map(d => getSpend(d))
        if (spends.length > 0)
            return findAbbreviationOfGroup(spends)
    }
    return NO_ABBREVIATION;
}
