import {Collapse, IconButton, Table, TableBody, TableCell, TableContainer, TableRow, Typography} from "@mui/material";
import {MithraTableCell} from "../../../components/table/MithraTableCell";
import {Edit, ExpandMore, InsertChart, KeyboardArrowRight, Visibility} from "@mui/icons-material";
import {getCollapseAnimationTiming} from "../../../components/table/utils";
import {getContractInsightsColumns, getContractInsightsSubColumns} from "./ContractInsightsColumns";
import {useStores} from "../../../stores";
import {observer} from "mobx-react-lite";
import {ContractRowState} from "../classes";
import {MithraTableHeadColumns} from "../../../components/table/MithraTableHeadColumns";
import React from "react";
import {routes} from "../../../routing/routes";
import {generatePath, useHistory} from "react-router";


type Props = {
    contractRow: ContractRowState,
};

export const ContractMainRow: React.FC<Props> = observer(({contractRow}) => {
    const {p, bagStore, contractInsightsStore} = useStores();
    const history = useHistory();
    const COLUMNS = getContractInsightsColumns(p);
    const SUBCOLUMNS = getContractInsightsSubColumns(p);
    const hasPartData = false;

    const [isOpen, setIsOpen] = React.useState(false);

    const className = `contract-row`;
    let i = 0;
    return <>
        <TableRow className={className}>
            <MithraTableCell c={COLUMNS[i++]}
                             onClick={() => setIsOpen(!isOpen)}>
                <IconButton aria-label="expand row" size="small">
                    {isOpen ? <ExpandMore/> : <KeyboardArrowRight/>}
                </IconButton>
            </MithraTableCell>
            <MithraTableCell c={COLUMNS[i++]}>
                {contractRow.data.contract_title || 'contract_title'}
            </MithraTableCell>
            <MithraTableCell c={COLUMNS[i++]}>
                {contractRow.data.contract_type || 'contract_type'}
            </MithraTableCell>
            <MithraTableCell c={COLUMNS[i++]}>
                {contractRow.data.parent_supplier || 'parent_supplier'}
            </MithraTableCell>
            <MithraTableCell c={COLUMNS[i++]}>
                {contractRow.data.involved_supplier.join(', ') || 'involved_supplier'}
            </MithraTableCell>
            <MithraTableCell c={COLUMNS[i++]}>
                {contractRow.data.effective_date || 'effective_date'}
            </MithraTableCell>
            <MithraTableCell c={COLUMNS[i++]}>
                {contractRow.data.expiration_date || 'expiration_date'}
            </MithraTableCell>

            <MithraTableCell c={COLUMNS[i++]}>
                <IconButton className={'button'}>
                    <Visibility onClick={() => {
                        contractInsightsStore.setDetailModalData(contractRow.data)
                        contractInsightsStore.toggleDetailModal()
                    }}/>
                </IconButton>
                <IconButton className={'button'}>
                    <Edit onClick={() => {
                        contractInsightsStore.setEditModalData(contractRow.data)
                        contractInsightsStore.toggleEditModal()
                    }}/>
                </IconButton>
                <IconButton className={'button'}>
                    <InsertChart onClick={() => {
                        history.push(generatePath(routes.demo_cleanup_analytics_contract_analytics, {
                            id: bagStore.bagId,
                            contract: contractRow.data.contract_id ? contractRow.data.contract_id : -1
                        }));
                    }}/>
                </IconButton>
            </MithraTableCell>
        </TableRow>

        <TableRow>
            <TableCell
                className={"cell-sub-table" + (hasPartData ? ' has-data' : '')}
                colSpan={COLUMNS.length}
                style={{paddingLeft: COLUMNS[0].width}}>
                {<Collapse in={isOpen} timeout={getCollapseAnimationTiming(contractRow.itemStates)} unmountOnExit>

                    <TableContainer className={'tableClass'}>
                        <Table className="sub-table" size="small">
                            <MithraTableHeadColumns columns={SUBCOLUMNS}
                                                    enableLoading
                                                    isLoading={false}
                            />
                            <TableBody>
                                <TableRow>
                                    <MithraTableCell c={SUBCOLUMNS[0]} style={{backgroundColor: '#F6F6F6'}}>
                                        <Typography variant='body1'
                                                    style={{
                                                        color: '#707070',
                                                        margin: '5px 0 0 15px'
                                                    }}>Scope</Typography>
                                        <Typography
                                            variant='subtitle2'
                                            style={{margin: '0 0 8px 15px'}}>{contractRow.data.scope ? contractRow.data.scope : 'N/A'}</Typography>
                                    </MithraTableCell>
                                </TableRow>
                                <TableRow>
                                    <MithraTableCell c={SUBCOLUMNS[0]} style={{backgroundColor: '#F6F6F6'}}>
                                        <Typography variant='body1' style={{
                                            color: '#707070',
                                            margin: '5px 0 0 15px'
                                        }}>Pricing</Typography>
                                        <Typography
                                            variant='subtitle2'
                                            style={{margin: '0 0 8px 15px'}}>{contractRow.data.price_adjustment ? contractRow.data.price_adjustment : 'N/A'}</Typography>
                                    </MithraTableCell>
                                </TableRow>
                                <TableRow>
                                    <MithraTableCell c={SUBCOLUMNS[0]} style={{backgroundColor: '#F6F6F6'}}>
                                        <Typography variant='body1' style={{color: '#707070', margin: '5px 0 0 15px'}}>Payment
                                            Terms</Typography>
                                        <Typography
                                            variant='subtitle2'
                                            style={{margin: '0 0 8px 15px'}}>{contractRow.data.payment_terms ? contractRow.data.payment_terms : 'N/A'}</Typography>
                                    </MithraTableCell>
                                </TableRow>
                                <TableRow>
                                    <MithraTableCell c={SUBCOLUMNS[0]} style={{backgroundColor: '#F6F6F6'}}>
                                        <Typography variant='body1' style={{color: '#707070', margin: '5px 0 0 15px'}}>Renewal
                                            Terms</Typography>
                                        <Typography
                                            variant='subtitle2'
                                            style={{margin: '0 0 8px 15px'}}>{contractRow.data.renewal_condition ? contractRow.data.renewal_condition : 'N/A'}</Typography>
                                    </MithraTableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>


                </Collapse>
                }
            </TableCell>
        </TableRow>
    </>
})
