import '../SalesDemoDashboard.scss';
import '../../../cleanup-dashboard/CleanupDashBoard.scss';
import React from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../../stores";
import {Grid} from "@mui/material";
import {DASHBOARD_CONSTANTS, drawOpportunityValue} from "../../../../components/dashboard/Dashboard.utils";
import {generatePath} from "react-router";
import {routes} from "../../../../routing/routes";
import {ClassificationHierarchyIcon} from "../../../../components/icons/ClassificationHierarchyIcon";
import {CurrencyComponent} from "../../../../components/currency-component/CurrencyComponent";
import {DashboardRow} from "../../../../components/dashboard/DashboardRow";
import {ArrowCollapsedIcon} from "../../../../components/icons/ArrowCollapsedIcon";
import {environment} from "../../../../env";
import {QueryStats} from "@mui/icons-material";
import {DashboardHeader} from "../../../dashboard/DashboardHeader";
import {DashBoardCard} from "../../../dashboard/DashBoardCard";
import {SupplierSegmentationIcon} from "../../../../components/icons/SupplierSegmentationIcon";
import {MoveIcon} from "../../../../components/icons/MoveIcon";
import {ContractIcon} from "../../../../components/icons/ContractIcon";

/**
 */
export const SalesDemoDashboardAnalytics: React.FC = observer(() => {
    const {
        bagStore,
        ppvControllerStore,
        spendConcentrationStore
    } = useStores();
    // TODO: CAT-1140: Make a proper design for all the dashboard cards
    const bagId = bagStore.bagId;
    const scTop = spendConcentrationStore.topValueP;
    const ppvTotal = ppvControllerStore.spendTotalPPV;
    return <>
        {environment.isTest && <h1>SalesDemoDashboardAnalytics</h1>}
        <DashboardHeader/>
        <Grid container
              className="dashboard sales-demo-dashboard-wrapper cleanup-dashboard-wrapper"
              justifyContent="space-evenly"
              spacing={0}>
            <DashboardRow
                title="Suppliers"
                className="suppliers-row"
                subtitle={bagStore.kpi.common
                    ? String(bagStore.kpi.common.n_suppliers)
                    : DASHBOARD_CONSTANTS.SUBTITLE_LOADING}>
                <DashBoardCard
                    cardKey="koi-spend-segmentation"
                    avatar={<SupplierSegmentationIcon/>}
                    title="Supplier Segmentation"
                    link={generatePath(routes.demo_cleanup_analytics_koi_ss, {id: bagId})}
                />
                <DashBoardCard
                    cardKey="contract-insights"
                    avatar={<ContractIcon/>}
                    title="Contract Insights"
                    link={generatePath(routes.demo_cleanup_analytics_contract_insights, {id: bagId})}
                />
            </DashboardRow>
            <DashboardRow
                title="Categories"
                className="categories-row"
                subtitle={bagStore.kpi.common
                    ? String(bagStore.kpi.common.n_categories)
                    : DASHBOARD_CONSTANTS.SUBTITLE_LOADING}>
                <DashBoardCard
                    cardKey="explore-cats"
                    avatar={<ClassificationHierarchyIcon/>}
                    title="Explore Categories"
                    link={generatePath(routes.demo_cleanup_analytics_koi_kdf, {id: bagId})}
                />
                <DashBoardCard
                    cardKey="adv-looker-dashboard"
                    avatar={<QueryStats fontSize="large"/>}
                    title="Opportunity Dashboard"
                    // subtitle=""
                    link={generatePath(routes.demo_cleanup_analytics_adv_dashboard, {id: bagId})}
                    // badge="preview"
                />
            </DashboardRow>
            <DashboardRow
                title="Spend"
                className="spend-row"
                subtitle={bagStore.kpi.common
                    ? <CurrencyComponent v={bagStore.kpi.common.total_spend} nDigits={3}/>
                    : DASHBOARD_CONSTANTS.SUBTITLE_LOADING}>
                <DashBoardCard
                    cardKey="koi-ppv"
                    avatar={<MoveIcon/>}
                    title="Analyze Price Variance"
                    value={drawOpportunityValue(ppvTotal)}
                    valueColor={ppvTotal ? (ppvTotal >= DASHBOARD_CONSTANTS.OPPORTUNITY_THRESHOLD) ? 'red' : 'green' : 'gray'}
                    subtitle="total price variance"
                    link={generatePath(routes.demo_cleanup_analytics_koi_ppv, {id: bagId})}
                />
                <DashBoardCard
                    cardKey="koi-spend-concentration"
                    avatar={<ArrowCollapsedIcon/>}
                    title="Spend Concentration"
                    value={scTop ? `${Math.round(scTop)}%` : DASHBOARD_CONSTANTS.VALUE_LOADING}
                    valueColor={scTop ? (scTop < DASHBOARD_CONSTANTS.OPPORTUNITY_THRESHOLD_PARETO) ? 'red' : 'green' : 'gray'}
                    subtitle="of total spend with top 20% suppliers"
                    link={generatePath(routes.demo_cleanup_analytics_koi_sc, {id: bagId})}
                />
            </DashboardRow>
            {/*<DashboardRow*/}
            {/*    title="Suppliers"*/}
            {/*    className="suppliers-row"*/}
            {/*    subtitle={bagStore.kpi.common*/}
            {/*        ? String(bagStore.kpi.common.n_suppliers)*/}
            {/*        : DASHBOARD_CONSTANTS.SUBTITLE_LOADING}>*/}
            {/*    <DashBoardCard*/}
            {/*        cardKey="supplier-normalization"*/}
            {/*        avatar={<SupplierNormalizationV2Icon/>}*/}
            {/*        title="Supplier Normalization"*/}
            {/*        value={drawOpportunityValue(pendingSupplierNormalization)}*/}
            {/*        subtitle="suppliers not reviewed yet"*/}
            {/*        link={generatePath(routes.supplier_normalization, {id: bagId})}*/}
            {/*        badge="Ai"*/}
            {/*    />*/}
            {/*    {!p.p.hideOpportunityDashboardCard &&*/}
            {/*        <DashBoardCard*/}
            {/*            cardKey="adv-looker-dashboard"*/}
            {/*            avatar={<QueryStats fontSize="large"/>}*/}
            {/*            title="Opportunity Dashboard"*/}
            {/*            // subtitle=""*/}
            {/*            link={generatePath(routes.job_advanced_dashboard, {id: bagId})}*/}
            {/*            // badge="preview"*/}
            {/*        />*/}
            {/*    }*/}
            {/*</DashboardRow>*/}
            {/*<DashboardRow*/}
            {/*    title="Categories"*/}
            {/*    className="categories-row"*/}
            {/*    subtitle={bagStore.kpi.common*/}
            {/*        ? String(bagStore.kpi.common.n_categories)*/}
            {/*        : DASHBOARD_CONSTANTS.SUBTITLE_LOADING}>*/}
            {/*    <DashBoardCard*/}
            {/*        cardKey="taxonomy-builder"*/}
            {/*        avatar={<ClassificationHierarchyIcon/>}*/}
            {/*        title="Taxonomy Builder"*/}
            {/*        subtitle={taxonomyManagerStore.taxonomy?.name}*/}
            {/*        link={generatePath(routes.taxonomy_builder, {id: bagId})}*/}
            {/*    />*/}
            {/*    <DashBoardCard*/}
            {/*        cardKey="explore-cats"*/}
            {/*        avatar={<ClassificationHierarchyIcon/>}*/}
            {/*        title="Explore Categories"*/}
            {/*        // value={nCategories}*/}
            {/*        // subtitle="categories"*/}
            {/*        link={generatePath(routes.job_koi_kdf, {id: bagId})}*/}
            {/*    />*/}
            {/*</DashboardRow>*/}
            {/*<DashboardRow*/}
            {/*    title="Spend"*/}
            {/*    className="spend-row"*/}
            {/*    subtitle={bagStore.kpi.common*/}
            {/*        ? <CurrencyComponent v={bagStore.kpi.common.total_spend} nDigits={3}/>*/}
            {/*        : DASHBOARD_CONSTANTS.SUBTITLE_LOADING}>*/}
            {/*    <DashBoardCard*/}
            {/*        cardKey="improve-cats"*/}
            {/*        avatar={<ClassificationIcon/>}*/}
            {/*        title="Improve Categorization"*/}
            {/*        value={drawOpportunityValue(unclassifiedEgoSpend)}*/}
            {/*        valueColor={unclassifiedEgoSpend*/}
            {/*            ? (unclassifiedEgoSpend >= DASHBOARD_CONSTANTS.OPPORTUNITY_THRESHOLD) ? 'red' : 'green' : 'gray'}*/}
            {/*        subtitle="uncategorized"*/}
            {/*        link={generatePath(routes.categorization_review, {id: bagId})}*/}
            {/*        badge="Ai"*/}
            {/*    />*/}
            {/*    <DashBoardCard*/}
            {/*        cardKey="koi-spend-concentration"*/}
            {/*        avatar={<ArrowCollapsedIcon/>}*/}
            {/*        title="Spend Concentration"*/}
            {/*        value={scTop ? `${Math.round(scTop)}%` : DASHBOARD_CONSTANTS.VALUE_LOADING}*/}
            {/*        valueColor={scTop ? (scTop < DASHBOARD_CONSTANTS.OPPORTUNITY_THRESHOLD_PARETO) ? 'red' : 'green' : 'gray'}*/}
            {/*        subtitle="of total spend with top 20% suppliers"*/}
            {/*        link={generatePath(routes.koi_spend_concentration, {id: bagId})}*/}
            {/*    />*/}
            {/*</DashboardRow>*/}
        </Grid>
    </>
});
