import {makeAutoObservable, runInAction} from "mobx";
import {CategorizationReviewStore} from "./CategorizationReviewStore";
import MithraMaterializedApi from "../../services/MithraMaterializedApi";
import {AiCategorizationJobSerializer, AiCategorizationJobStatusEnum} from "../../services/ApiTypes";
import AuthStore from "../AuthStore";

/**
 * This class is responsible for the communication with the backend regarding the AI jobs
 */
export class AiJobDelegate {

    job: AiCategorizationJobSerializer | undefined

    isRunAiModalOpen = false
    isRunAiRequestPending = false
    hasRunAiRequestError: true | undefined = undefined

    constructor(
        private matApi: MithraMaterializedApi,
        private categorizationReviewStore: CategorizationReviewStore,
        private auth: AuthStore,
    ) {
        makeAutoObservable(this)
    }

    get bagId() {
        return this.categorizationReviewStore.bagId
    }

    get aiStatus(): AiCategorizationJobStatusEnum | undefined {
        return this.job?.current_status.status
    }

    initializeAiJobDelegate() {
        this.refreshAiJobStatus()
    }

    refreshAiJobStatus() {
        if (!this.bagId || this.bagId === -1) {
            this.job = undefined
            return
        }
        this.matApi.getAiLatestJob(this.bagId)
            .then(r => runInAction(() => {
                this.job = r.data || undefined
            }))
    }

    openRunAiModal() {
        this.resetIsRunAiRequestError()
        this.isRunAiModalOpen = true
    }

    closeRunAiModal() {
        this.resetIsRunAiRequestError()
        this.isRunAiModalOpen = false
    }

    setIsRunAiRequestPending(isPending: boolean) {
        this.isRunAiRequestPending = isPending
    }

    setIsRunAiRequestError() {
        this.hasRunAiRequestError = true
    }

    resetIsRunAiRequestError() {
        this.hasRunAiRequestError = undefined
    }

    /**
     * Shows the end user if they still have to wait for the AI or not
     */
    get isAiRunning(): boolean {
        switch (this.aiStatus) {
            case undefined:
            case AiCategorizationJobStatusEnum.LIVE:
            case AiCategorizationJobStatusEnum.ERROR:
                return false;
            default:
                return true;
        }
    }

    /**
     * Shows the end user if they can modify and the review screen w.r.t. the AiJob status
     */
    get aiAllowsEdit(): boolean {
        switch (this.aiStatus) {
            case undefined: // There is no job yet
            case AiCategorizationJobStatusEnum.LIVE: // The previous job is finished
                return true
            case AiCategorizationJobStatusEnum.REVIEW:
                // noinspection RedundantIfStatementJS
                if (this.auth.isMithraStaff) {
                    // When the user is staff it's also allowed to edit the reviewed state
                    return true;
                }
                return false;
            default: // All intermediate states
                return false;
        }
    }

    startRunAiJob() {
        if (this.isRunAiRequestPending) return;
        const dataBag = this.bagId;

        this.setIsRunAiRequestPending(true);
        this.matApi.createAiJob({src_data_bag: dataBag, requested_task: 'categorize_all'})
            .then(async createResp => {
                console.log('startRunAiJob: Created a new ai job', createResp.data.id);
                const getResponse = await this.matApi.getAiLatestJob(dataBag);
                runInAction(() => {
                    console.log('startRunAiJob: getAiLatestJob', getResponse.data?.id)
                    this.job = getResponse.data || undefined
                    this.closeRunAiModal()
                });
            })
            .catch(err => {
                console.error(err)
                this.setIsRunAiRequestError()
            })
            .finally(() => {
                this.setIsRunAiRequestPending(false);
            })
    }
}