import './GoldenRecordPage.scss';
import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../stores";
import {generateHomePath} from "../../routing/routing.utils";
import {useRouteMatch} from "react-router-dom";
import {JobRouteMatch} from "../../routing/routes";
import {KoiFacade} from "../../components/koi-facade/KoiFacade";
import {GoldenRecordBarsComponent} from "./components/GoldenRecordBarsComponent";
import {GoldenRecordFilterModal} from "./components/modals/GoldenRecordFilterModal";
import {GoldenRecordFilterComponent} from "./components/GoldenRecordFilterComponent";
import {GoldenRecordTableComponent} from "./components/GoldenRecordTableComponent";
import {GoldenRecordEditModal} from "./components/modals/GoldenRecordEditModal";
import {GoldenRecordRemoveModal} from "./components/modals/GoldenRecordRemoveModal";

export const GoldenRecordPage: React.FC = observer(() => {
    const {rootAppStore, goldenRecordStore} = useStores();
    const routeMatch = useRouteMatch<JobRouteMatch>();

    useEffect(() => {
        // TODO: Maybe only refresh once
        goldenRecordStore.refreshData()
    }, [goldenRecordStore]);

    return <KoiFacade
        title="Golden Records"
        // helpContent={<PurchasePriceVarianceHelp/>}
        intro={<></>}
        back={generateHomePath(routeMatch, routeMatch.params, rootAppStore.app)}
        className="golden-record-page"
    >
        <GoldenRecordFilterModal/>
        <GoldenRecordEditModal/>
        <GoldenRecordRemoveModal/>

        <GoldenRecordBarsComponent/>
        <GoldenRecordFilterComponent/>
        <GoldenRecordTableComponent/>
    </KoiFacade>
})