import React, {useState} from "react";
import {initializeApp} from "firebase/app";
import {FirebaseApp} from "@firebase/app";
import {environment} from "../../env";
import ReactGA from "react-ga4";

export type FirebaseContextType = {
    app: FirebaseApp;
}

export const FirebaseContext = React.createContext<FirebaseContextType | undefined>(undefined);

export const FirebaseProvider: React.FC = ({children}) => {
    const [firebase] = useState<FirebaseContextType | undefined>(
        () => {
            if (!environment.firebaseConfig.apiKey) {
                console.log('No firebase setup; not initializing app')
                return undefined;
            }
            // Initialize Firebase
            const app = initializeApp(environment.firebaseConfig);
            const firebaseAppName = app.name;
            if (environment.isGA) {
                ReactGA.initialize(environment.firebaseConfig.measurementId);
                console.log(`Firebase analytics initialized with app name: ${firebaseAppName}`)
            } else {
                console.log(`Firebase initialized with app name: ${firebaseAppName}`)
            }
            return {app};
        }
    );
    return <FirebaseContext.Provider value={firebase}>{children}</FirebaseContext.Provider>;
}