import {observer} from "mobx-react-lite";
import {
    Autocomplete,
    Box,
    Button,
    createFilterOptions,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    TextField
} from "@mui/material";
import React, {useEffect} from "react";
import {useStores} from "../../../../stores";
import {ParentSupplierMatch} from "../SupplierNormalization.classes";
import {Delete, Refresh, Undo} from "@mui/icons-material";

type Option = ParentSupplierMatch | { type: 'new_option', inputValue: string, label: string };

const filter = createFilterOptions<Option>({
    limit: 20,
});

/**
 * As the data is not parsed correctly
 * TODO: We are not able to handle case and accent sensitivity in the BE, we can stop changing the string to lowercase
 * @param label
 */
function showLabel(label: string) {
    return label.toLowerCase();
}

type Props = {
    value: ParentSupplierMatch | null,
    submitNewValue: (value: ParentSupplierMatch | null) => void,
    refresh?: () => void,
    label: string
}
export const ParentSupplierChooser: React.FC<Props> = observer(({value, submitNewValue, refresh, label}) => {
    const {authStore, supplierNormalizationStore} = useStores();
    const ps = supplierNormalizationStore.ps;

    const [autocompleteValue, setAutocompleteValue] = React.useState<ParentSupplierMatch | null>(value);
    useEffect(() => {
        setAutocompleteValue(value);
    }, [value]);

    const [lastValue, setLastValue] = React.useState<ParentSupplierMatch | null>(value);
    useEffect(() => {
        if (value) {
            setLastValue(value);
        }
    }, [value]);

    const [open, setOpen] = React.useState(false);
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const newMatch = ps.createNewParentSupplier();
        submitNewValue(newMatch);
        handleClose();
    };

    const handleClose = () => {
        setOpen(false);
    };
    return <Grid container alignItems="center">
        <Grid item flexGrow="1">
            <Autocomplete
                value={autocompleteValue}
                onBlur={() => {
                    if (autocompleteValue === null) {
                        setAutocompleteValue(value);
                    }
                }}
                onChange={(_, option) => {
                    if (option === null) {
                        // When pressing the 'clear'-button do not automatically submit the value
                        setAutocompleteValue(option);
                    } else if (typeof option === 'string') {
                        // timeout to avoid instant validation of the dialog's form.
                        setTimeout(() => {
                            setOpen(true);
                            ps.initNewSupplier(showLabel(option));
                        });
                    } else if (option && option.type === 'new_option') {
                        setOpen(true);
                        ps.initNewSupplier(showLabel(option.inputValue));
                    } else {
                        submitNewValue(option);
                    }
                }}
                filterOptions={(options, params) => {
                    if (!options) return [];
                    const filtered = filter(options, params);

                    if (params.inputValue !== '') {
                        filtered.push({
                            type: 'new_option',
                            inputValue: showLabel(params.inputValue),
                            label: `Add "${showLabel(params.inputValue)}"`,
                        });
                    }

                    return filtered;
                }}
                options={supplierNormalizationStore.ps.allSuggestedParentsSupplier as Option[]}
                getOptionLabel={(option) => {
                    // e.g. value selected with enter, right from the input
                    if (typeof option === 'string') {
                        return showLabel(option);
                    }
                    if (option.type === 'new_option') {
                        return showLabel(option.inputValue);
                    }
                    return showLabel(option.data.sp_name);
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                renderOption={(props, option) =>
                    <Box {...props} component="li">
                        {option.type === 'new_option'
                            ? showLabel(option.label)
                            : showLabel(option.data.sp_name)}
                    </Box>
                }
                size="small"
                disableClearable={true as any} // Disable the type change of the Autocomplete component
                freeSolo
                renderInput={(params) =>
                    <TextField {...params} label={label + (authStore.viewOnly ? ' (view only)' : '')}/>
                }
                disabled={authStore.viewOnly}
            />
            <Dialog open={open} onClose={handleClose}>
                <form onSubmit={handleSubmit}>
                    <DialogTitle>Add a new</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            value={showLabel(ps.newParentSupplier.sp_name)}
                            onChange={(event) => ps.setNewParentSupplierName(showLabel(event.target.value))}
                            label="name"
                            type="text"
                            variant="standard"
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button type="submit">Add</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </Grid>
        <Grid item>
            {value !== null
                ? <IconButton aria-label="Remove" title="Remove" onClick={() => submitNewValue(null)}>
                    <Delete/>
                </IconButton>
                : lastValue !== null
                    ? <IconButton aria-label="Restore" title="Restore" onClick={() => submitNewValue(lastValue)}>
                        <Undo/>
                    </IconButton>
                    : refresh
                        ? <IconButton aria-label="Refresh" title="Refresh" onClick={() => refresh()}>
                            <Refresh/>
                        </IconButton>
                        : null
            }
        </Grid>
    </Grid>
})
