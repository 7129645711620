import "./ContractInsightsPage.scss";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../stores";
import {useRouteMatch} from "react-router-dom";
import {JobRouteMatch} from "../../../routing/routes";
import {generateHomePath} from "../../../routing/routing.utils";
import React from "react";
import {KoiFacade} from "../../../components/koi-facade/KoiFacade";
import {ContractInsightsComponent} from "../components/ContractInsightsComponent";
import {ContractUploadComponent} from "../components/ContractUploadComponent";
import {ModalContractDetails} from "../modals/ModalContractDetails";
import {ModalContractEdit} from "../modals/ModalContractEdit";

type Props = {}
export const ContractInsightsPage: React.FC<Props> = observer(() => {
    const {rootAppStore, contractInsightsStore} = useStores();

    const routeMatch = useRouteMatch<JobRouteMatch>();

    return <KoiFacade
        title={'Contract Insights'}
        intro={<></>}
        back={generateHomePath(routeMatch, routeMatch.params, rootAppStore.app)}
        className="contract-insights-page">

        <ModalContractDetails isOpen={contractInsightsStore.isModalContractsDetailsOpen}/>
        <ModalContractEdit isOpen={contractInsightsStore.isModalEditContractsOpen}/>

        {!(contractInsightsStore.contracts && contractInsightsStore.contracts?.length > 0) &&
            <ContractUploadComponent/>}

        {(contractInsightsStore.contracts && contractInsightsStore.contracts?.length > 0) &&
            <ContractInsightsComponent/>}
    </KoiFacade>
});
