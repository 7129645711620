import {makeAutoObservable, reaction, runInAction} from "mobx";
import {m_taxonomy} from "../services/classes/TaxonomyClasses";
import {AxiosResponse} from "axios";
import {catchError, EMPTY, forkJoin, from, mergeMap, Observable, of, Subscription, tap, throwError} from "rxjs";
import {BagStore} from "./BagStore";
import {ApprovalStore} from "./ApprovalStore";
import AuthStore from "./AuthStore";
import ProfileStore from "./ProfileStore";
import MithraMaterializedApi from "../services/MithraMaterializedApi";
import {ApprovalStatusEnum} from "../services/classes/AiClasses";

type HistoryState = 'updating_history'
    | 'applying_action'
    | 'ready'

const NOTIFICATION_WAITING_FOR_APPROVAL = 'Waiting for the approval of this taxonomy'

/**
 * For controlling the taxonomy
 */
export default class TaxonomyManagerStore {
    private getSubscription?: Subscription;

    desiredTaxonomyId?: number
    taxonomy: m_taxonomy.FullSerializer | undefined
    error: string = '';

    historyState: HistoryState = 'ready';
    history: m_taxonomy.SimpleTaxonomyOperationSerializer[] | undefined;

    isSendForApprovalDialog = false;
    isBusySendForApproval = false;

    notification: string = '';
    notification_type: 'info' | 'success' = 'info';

    constructor(
        private api: MithraMaterializedApi,
        private bagStore: BagStore,
        private approvalStore: ApprovalStore,
        private authStore: AuthStore,
        private profileStore: ProfileStore,
    ) {
        if (this.profileStore.p.hardcodeTaxonomyId) {
            this.desiredTaxonomyId = this.profileStore.p.hardcodeTaxonomyId;
        }
        makeAutoObservable(this)
        reaction(() => [bagStore.bagId, this.desiredTaxonomyId], ([bagId, taxId]) => {
            console.debug(`TaxonomyManagerStore: Bag or taxonomy changed`, {bag: bagId, taxonomy: taxId})
            if (bagId !== undefined)
                this._requestTaxonomy(bagId, taxId)
        })
    }

    setDesiredTaxonomyId(taxonomyId?: number) {
        this.desiredTaxonomyId = taxonomyId;
    }

    _requestTaxonomy(bagId: number, taxonomyId?: number) {
        if (this.getSubscription) this.getSubscription.unsubscribe()
        // console.log(`TaxonomyManagerStore: Reloading taxonomy`, {bag: bagId, taxonomy: taxonomyId})
        this.taxonomy = undefined

        let getTaxonomyId: Observable<number>
        if (taxonomyId === undefined) {
            getTaxonomyId = from(this.api.listMTaxonomyForBag(bagId)).pipe(mergeMap(r => {
                const taxonomies = r.data
                if (taxonomies.length === 0) {
                    // If no taxonomy is found for this bag, throw an error
                    return throwError(() => new Error('No taxonomies found'));
                }
                if (taxonomies.length > 1) {
                    console.warn('More then 1 taxonomy found for this bag, actually found:', taxonomies.length)
                }
                return of(taxonomies[taxonomies.length - 1].id)
            }))
        } else {
            getTaxonomyId = of(taxonomyId)
        }
        this.getSubscription = getTaxonomyId
            .pipe(
                mergeMap(taxonomyId => forkJoin([
                    from(this.api.getMTaxonomy(taxonomyId)).pipe(tap(
                        r => this._setTaxonomy(r.data)
                    )),
                    from(this.api.listMTaxonomyHistory(taxonomyId)).pipe(tap(
                        r => runInAction(() => this.history = r.data)
                    )),
                ])),
                catchError(err => {
                    console.error(err, {bag: bagId, taxonomy: taxonomyId})
                    runInAction(() => {
                        this.taxonomy = undefined
                        this.history = undefined
                        this.error = String(err)
                        this.notification = '';
                    })
                    return EMPTY
                }),
            )
            .subscribe({
                next: () => runInAction(() => this.error = ''),
            })
    }

    _setTaxonomy(taxonomy: m_taxonomy.FullSerializer) {
        this.taxonomy = taxonomy;
        this.notification = taxonomy.is_submitted_for_approval ? NOTIFICATION_WAITING_FOR_APPROVAL : '';
    }

    get minHistoryNumber() {
        if (!this.history) return -1
        return Math.min(...this.history.map(h => h.operation_number))
    }

    get maxHistoryNumber() {
        if (!this.history) return -1
        return Math.max(...this.history.map(h => h.operation_number))
    }

    get stateInSync(): boolean {
        return this.historyState === 'ready';
    }

    get incompleteCategories(): string[][] {
        if (!this.profileStore.p.allowIncompleteTaxonomy && this.taxonomy) {
            return TaxonomyManagerStore.getIncompleteCategories([this.taxonomy.tree], this.taxonomy.size)
                .map(path => path.slice(1).map(t => t.label))
                .sort()
        }
        return [];
    }

    private static getIncompleteCategories(path: m_taxonomy.Tree[], taxonomySize: number): m_taxonomy.Tree[][] {
        let incompleteNodes: m_taxonomy.Tree[][] = []
        const level = path.length - 1;
        if (level <= taxonomySize - 1) {
            const tree = path[path.length - 1];
            if (tree.children.length === 0) {
                return [path];
            }
            tree.children.forEach(c => {
                incompleteNodes.push(...TaxonomyManagerStore.getIncompleteCategories([...path, c], taxonomySize))
            })
        }
        return incompleteNodes;
    }

    undo() {
        if (!this.taxonomy) return
        this.gotoHistoryState(this.taxonomy.current_operation_number - 1)
    }

    /**
     * Shows if the user can undo
     * Also it shows if there exists a change to actually undo
     */
    get undoAllowed() {
        if (this.historyState !== 'ready') return false
        if (this.canEditTaxonomy && this.taxonomy && this.history !== undefined) {
            return this.taxonomy.current_operation_number > this.minHistoryNumber
        }
        return false
    }

    redo() {
        if (!this.taxonomy) return
        this.gotoHistoryState(this.taxonomy.current_operation_number + 1)
    }

    get redoAllowed() {
        if (this.historyState !== 'ready') return false
        if (this.canEditTaxonomy && this.taxonomy && this.history !== undefined) {
            return this.taxonomy.current_operation_number < this.maxHistoryNumber
        }
        return false
    }

    get isLatest() {
        if (this.taxonomy && this.history !== undefined) {
            return this.taxonomy.current_operation_number === this.maxHistoryNumber
        }
        return true
    }

    /**
     * Get a list of operations that have happened before, including the state before the operation (to revert it)
     */
    get undoHistory(): { operation: m_taxonomy.SimpleTaxonomyOperationSerializer, undoNumber: number }[] {
        if (!this.history || !this.taxonomy) return []
        const currentOperationNumber = this.taxonomy.current_operation_number;
        const firstAction = this.history[0]
        const undoHistory = this.history
            .filter((operation, index) =>
                // The first operation can never be undone
                // All operations should have happened before, so including the current operation_number
                index >= 1 && operation.operation_number <= currentOperationNumber
            )
            .map((operation, index, array) => {
                // If we want to undo an action, we should goto the state before that actions
                const before = index === 0 ? firstAction : array[index - 1]
                return {
                    operation,
                    undoNumber: before.operation_number,
                }
            })
        // Show the most recent operation as the first
        undoHistory.reverse()
        return undoHistory
    }

    get redoHistory() {
        if (!this.history || !this.taxonomy) return []
        const currentOperationNumber = this.taxonomy?.current_operation_number || 1;
        return this.history.filter(h => h.operation_number > currentOperationNumber)
    }

    gotoHistoryState(number: number) {
        if (this.historyState !== 'ready') return;
        if (!this.taxonomy) return
        const taxonomyId = this.taxonomy.id;
        this.historyState = 'updating_history'
        this.api.gotoMTaxonomyHistory(taxonomyId, {goto_history_number: number})
            .then(r => runInAction(() => {
                this._setTaxonomy(r.data)

                // Retrieve the next history elements
                return this.api.listMTaxonomyHistory(taxonomyId)
                    .then(r => runInAction(() => this.history = r.data))
            }))
            .finally(() => runInAction(() => {
                this.historyState = 'ready'
            }))
    }

    onChangeTaxonomy(operation: m_taxonomy.Operation, newState: m_taxonomy.Tree) {
        if (!this.taxonomy) return
        console.log('Updating taxonomy to API', operation)
        const taxonomyId = this.taxonomy.id;

        let promise: Promise<AxiosResponse<m_taxonomy.FullSerializer>>;
        const nextOperation: m_taxonomy.CreateTaxonomyOperationSerializer = {
            next_node_id: this.taxonomy.next_node_id + 1,
            operation_number: this.taxonomy.current_operation_number + 1,
            operation_name: operation.valueOf(),
            state: newState,
        };
        promise = this.api.createMTaxonomyState(taxonomyId, nextOperation)

        this.historyState = 'applying_action'

        promise
            .then(r => {
                const taxonomy = r.data;
                runInAction(() => {
                    this._setTaxonomy(taxonomy)
                    this.history = this.history?.filter(h => h.operation_number < taxonomy.current_operation_number)
                })
                return this.api.listMTaxonomyHistory(taxonomyId)
                    .then(r => runInAction(() => this.history = r.data))
            })
            .finally(() => runInAction(() => {
                this.historyState = 'ready'
            }))
    }

    get canEditTaxonomy() {
        if (!this.taxonomy) return false;
        if (this.authStore.isMithraStaff) return true;
        if (this.profileStore.p.taxonomyBuilderViewOnly) return false;
        return !this.taxonomy.is_submitted_for_approval
            && TaxonomyManagerStore.approvalReadyForRevision(this.taxonomy.result_of_approval?.current_status.status)
    }

    approvalNotes = ''

    setApprovalNotes(s: string) {
        this.approvalNotes = s;
    }

    sendForApproval() {
        if (!this.taxonomy) return;
        if (this.getSubscription) this.getSubscription.unsubscribe();

        const taxonomyId = this.taxonomy.id;
        this.isBusySendForApproval = true;

        this.getSubscription = from(this.api.createTaxonomyApprovalRequest(this.taxonomy.id, this.approvalNotes)).pipe(mergeMap(() =>
            forkJoin([
                from(this.api.getMTaxonomy(taxonomyId)).pipe(
                    tap(r => runInAction(() => {
                        this.notification_type = 'success';
                        this._setTaxonomy(r.data);
                        this.isSendForApprovalDialog = false;
                    }))
                ),
                from(this.approvalStore.fetchAll())
            ])
        )).subscribe({
            complete: () => runInAction(() => this.isBusySendForApproval = false)
        })
    }

    forceSetStatus(status: ApprovalStatusEnum) {
        const approvalId = this.taxonomy?.result_of_approval?.id;
        const taxonomyId = this.taxonomy?.id;
        const bagId = this.bagStore.bagId;
        if (approvalId && taxonomyId) {
            this.api.overrideApprovalStatus(approvalId, status).then(() =>
                this._requestTaxonomy(bagId, taxonomyId)
            );
        }
    }

    forceDeleteTaxonomyApprovalRequest() {
        const approvalId = this.taxonomy?.result_of_approval?.id;
        const taxonomyId = this.taxonomy?.id;
        const bagId = this.bagStore.bagId;
        if (approvalId && taxonomyId) {
            this.api.deleteApproval(approvalId).then(() =>
                this._requestTaxonomy(bagId, taxonomyId)
            );
        }
    }

    setSendForApprovalDialog(open: boolean) {
        this.isSendForApprovalDialog = open;
    }

    private static approvalReadyForRevision(srcApprovalStatus?: ApprovalStatusEnum) {
        if (!srcApprovalStatus) {
            // There was no source, so it's safe to edit this taxonomy
            return true;
        }
        switch (srcApprovalStatus) {
            case ApprovalStatusEnum.APPROVED:
            case ApprovalStatusEnum.REJECTED:
                // Continue editing
                return true;
            default:
                // Wait for the system or Mithra to complete
                return false;
        }
    }

    overwriteTaxonomyHealthCheckResult(has_taxonomy_health_check: boolean) {
        if (this.taxonomy) {
            // It must become true the next time we query the backend
            this.taxonomy.has_taxonomy_health_check = has_taxonomy_health_check;
        }
    }
}
