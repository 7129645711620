import {ColSpec} from "../../../../components/table/MithraTableHeadColumns";
import ProfileStore from "../../../../stores/ProfileStore";
import {environment} from "../../../../env";

export const PARENT_SUPPLIER_COLUMNS: Array<ColSpec> = [
    // {txt: 'p_id', label: 'p_id', minWidth: 170},
    {cls: 'col-dropdown', txt: '', align: 'center', width: 50},
    {cls: 'col-sp_n_suppliers xs-padding', txt: '', align: 'center'},
    {cls: 'col-sp_name', txt: 'Parent supplier', width: 320},
    {cls: 'col-sp_spend', txt: 'Total spend', align: 'right', width: 100},
    {cls: 'col-l1s', txt: 'L1(s) of parent'},
    // {cls: 'col-bus', txt: 'Entity'},
    // {cls: 'col-s_cats', txt: ''},
    // {cls: 'col-s_YN', txt: 'Approve', align: 'right' as const},
]


export function getSupplierNormalizationSupplierColumns(p: ProfileStore) {
    const arr: (ColSpec | undefined)[] = [
        {cls: 'col-choose-parent', txt: '', width: 350},
        p.p.showSupplierIdInSupplierNormalization ? {cls: 'col-s_id', txt: 'Supplier ID', width: 100} : undefined,
        {cls: 'col-s_name', txt: 'Child supplier', width: 300},
        {cls: 'col-s_spend', txt: 'Spend', align: 'right', width: 105},
        {cls: 'col-s_city', txt: 'Location', width: 100},
        {cls: 'col-s_l1s', txt: 'L1(s) of child', width: 400},
        environment.isTest ? {cls: 'col-review_id', txt: 'Reviewer ID', width: 100} : undefined,
        // {cls: 'col-s_suggestion_score', txt: 'Suggestion score'},
    ]
    return arr.filter(c => c) as ColSpec[];
}

export function getSupplierCommonSupplierColumns(p: ProfileStore) {
    const arr: (ColSpec | undefined)[] = [
        // {cls: 'col-choose-parent', txt: '', width: 350},
        p.p.showSupplierIdInSupplierNormalization ? {cls: 'col-s_id', txt: 'Supplier ID', width: 100} : undefined,
        {cls: 'col-s_name', txt: 'Child supplier', width: 300},
        {cls: 'col-s_spend', txt: 'Spend', align: 'right', width: 105},
        {cls: 'col-s_city', txt: 'Location', width: 100},
        {cls: 'col-s_l1s', txt: 'L1(s) of child', width: 400},
        environment.isTest ? {cls: 'col-review_id', txt: 'Reviewer ID', width: 100} : undefined,
        // {cls: 'col-s_suggestion_score', txt: 'Suggestion score'},
        {cls: 'col-dataset', txt: 'Dataset', width: 300},
    ]
    return arr.filter(c => c) as ColSpec[];
}

