import React from "react";
import {generatePath} from "react-router";
import {useRouteMatch} from "react-router-dom";
import {CmpDataBagRouteMatch, routes, TaxonomyRouteMatch} from "../../../../routing/routes";
import {lookupHomePath} from "../../../../routing/routing.utils";
import {MenuItem, MenuItems} from "../../../../components/main/MenuItems";

export const SalesDemoSynergyMainMenu: React.FC = () => {
    const m = useRouteMatch<CmpDataBagRouteMatch | TaxonomyRouteMatch>();
    const hasId1 = m.params.id !== undefined;
    const hasId2 = m.params['id2'] !== undefined;
    const homeLink = lookupHomePath(m.path);

    // const existsHome = homeLink !== null;
    const isHome = m.path === homeLink;

    const menu: MenuItem[] = [
        {
            label: 'Data',
            link: hasId1 ? generatePath(routes.job_manage, m.params) : routes.home,
            active: m.path === routes.home || m.path === routes.job_manage,
            className: '',
        },
        {
            label: 'Synergy Analytics',
            link: hasId1 ? generatePath(routes.demo_synergy_analytics_dashboard, m.params) : routes.home,
            active: (
                m.path === routes.demo_synergy_analytics_dashboard
                || m.path === routes.demo_synergy_suppliers
                || m.path === routes.demo_synergy_categories
                || m.path === routes.demo_synergy_spend
            ),
            className: '',
        },
        {
            label: 'Merge',
            link: hasId1 ? generatePath(routes.demo_merge_dashboard, m.params) : routes.synergy_v3,
            active: (
                m.path === routes.demo_merge_dashboard
                || m.path === routes.demo_merge_taxonomy_viewer
                || m.path === routes.demo_merge_taxonomy_viewer_id
                || m.path === routes.demo_merge_taxonomy_builder
                || m.path === routes.demo_merge_taxonomy_builder_id
                || m.path === routes.demo_merge_review
                || m.path === routes.demo_merge_review_result
                || m.path === routes.demo_merge_taxonomy_mapper
                || m.path === routes.demo_merge_taxonomy_mapper_review
                || m.path === routes.demo_merge_cat_review
            ),
            // className: '',
        },
        {
            label: 'Opportunities',
            link: hasId1 ? generatePath(routes.demo_synergy_opportunities_id, m.params) : routes.home,
            active: !isHome && m.path === routes.demo_synergy_opportunities_id,
            isTrial: false,
            className: 'menu-item-2nd-last',
        },
        // {
        //     label: 'Report',
        //     link: hasId1 ? generatePath(routes.job_report, m.params) : routes.home,
        //     active: !isHome && m.path === routes.job_report,
        //     isTrial: true,
        //     className: 'menu-item-last',
        // },
    ]
    return <MenuItems className="full-main-menu" items={menu}/>
}
