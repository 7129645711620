import ProfileStore from "../../../../stores/ProfileStore";
import MithraMaterializedApi from "../../../../services/MithraMaterializedApi";
import {makeAutoObservable} from "mobx";
import {Decimal} from "../../../../services/classes/MaterializedClasses";
import {BarDataPoint} from "../../../../components/visualization/BarChart";
import {toCurrency} from "../../../../components/currency-component/CurrencyClasses";

/**
 * Accessible via: `categorizationReviewStore.statisticsDashboardController`
 */
export class CategorizationStatisticsDashboardController {
    constructor(
        private profile: ProfileStore,
        private api: MithraMaterializedApi,
    ) {
        makeAutoObservable(this)
    }

    // Body of versioned statistics
    id: number | undefined
    version: number | undefined
    actor: number | undefined
    created: string | undefined
    average_confidence_score: number | undefined
    total_parts: number | undefined
    very_low_confidence_parts: number | undefined
    low_confidence_parts: number | undefined
    medium_confidence_parts: number | undefined
    high_confidence_parts: number | undefined
    very_high_confidence_parts: number | undefined
    reviewed_parts: number | undefined
    reviewed_spend: Decimal | undefined
    unreviewed_parts: number | undefined
    unreviewed_spend: Decimal | undefined
    categorized_reviewed_spend: Decimal | undefined
    categorized_unreviewed_spend: Decimal | undefined
    ai_categorization_job: number | undefined
    total_transactions: number | undefined

    reviewed_transactions: number | undefined
    unreviewed_transactions: number | undefined
    covered_by_ai_spend: number | undefined
    covered_by_ai_total_spend: number | undefined
    covered_by_ai_reviewed_spend: number | undefined
    covered_by_ai_reviewed_total_spend: number | undefined
    covered_by_ai_unreviewed_total_spend: number | undefined
    reviewed_total_pend: number | undefined
    covered_by_ai_unreviewed_spend: number | undefined
    unreviewed_total_spend: number | undefined
    covered_reviewed_suppliers: number | undefined
    covered_unreviewed_suppliers: number | undefined
    covered_suppliers: number | undefined
    total_suppliers: number | undefined
    total_categories: number | undefined
    covered_categories: number | undefined
    uncovered_categories: number | undefined

    recategorized_spend: number | undefined
    recategorized_parts: number | undefined
    recategorized_total_spend: number | undefined
    recategorized_total_transactions: number | undefined
    recategorized_reviewed_spend: Decimal | undefined
    recategorized_unreviewed_spend: Decimal | undefined
    recategorized_reviewed_parts: number | undefined
    recategorized_unreviewed_parts: number | undefined

    categorized_spend: number | undefined
    categorized_parts: number | undefined
    categorized_total_spend: number | undefined
    categorized_total_transactions: number | undefined
    // categorized_reviewed_spend: number | undefined
    // categorized_unreviewed_spend: number | undefined
    categorized_reviewed_parts: number | undefined
    categorized_unreviewed_parts: number | undefined

    uncategorized_spend: number | undefined
    uncategorized_parts: number | undefined
    uncategorized_total_spend: number | undefined
    uncategorized_total_transactions: number | undefined
    uncategorized_reviewed_spend: Decimal | undefined
    uncategorized_unreviewed_spend: Decimal | undefined
    uncategorized_reviewed_parts: number | undefined
    uncategorized_unreviewed_parts: number | undefined

    getCategorizationVersionedStatistics(databag: number) {
        this.api.getCategorizationVersionedStatistics(databag)
            .then(r => {
                this.id = r.data.id;
                this.version = r.data.version;
                this.actor = r.data.actor;
                this.created = r.data.created;
                this.total_parts = r.data.total_parts;
                this.average_confidence_score = r.data.average_confidence_score;
                this.very_low_confidence_parts = r.data.very_low_confidence_parts;
                this.low_confidence_parts = r.data.low_confidence_parts;
                this.medium_confidence_parts = r.data.medium_confidence_parts;
                this.high_confidence_parts = r.data.high_confidence_parts;
                this.very_high_confidence_parts = r.data.very_high_confidence_parts;
                this.reviewed_parts = r.data.reviewed_parts;
                this.reviewed_spend = Number(r.data.reviewed_spend);
                this.unreviewed_parts = r.data.unreviewed_parts;
                this.unreviewed_spend = Number(r.data.unreviewed_spend);
                this.ai_categorization_job = r.data.ai_categorization_job;

                this.reviewed_total_pend = r.data.reviewed_total_pend; //TODO: ASK MOJ TO FIX TYPO
                this.unreviewed_total_spend = r.data.unreviewed_total_spend;
                this.reviewed_transactions = r.data.reviewed_transactions;
                this.unreviewed_transactions = r.data.unreviewed_transactions;
                this.total_transactions = r.data.total_transactions;

                this.covered_by_ai_spend = Number(r.data.covered_by_ai_spend)
                this.covered_by_ai_total_spend = Number(r.data.covered_by_ai_total_spend)
                this.covered_by_ai_reviewed_spend = Number(r.data.covered_by_ai_reviewed_spend)
                this.covered_by_ai_reviewed_total_spend = Number(r.data.covered_by_ai_reviewed_total_spend)
                this.covered_by_ai_unreviewed_spend = Number(r.data.covered_by_ai_unreviewed_spend)
                this.covered_by_ai_unreviewed_total_spend = Number(r.data.covered_by_ai_unreviewed_total_spend)
                this.covered_reviewed_suppliers = Number(r.data.covered_reviewed_suppliers)
                this.covered_unreviewed_suppliers = Number(r.data.covered_unreviewed_suppliers)
                this.covered_suppliers = Number(r.data.covered_suppliers)
                this.total_suppliers = Number(r.data.total_suppliers)

                this.recategorized_spend = r.data.recategorized_spend
                this.recategorized_parts = r.data.recategorized_parts
                this.recategorized_total_spend = r.data.recategorized_total_spend
                this.recategorized_total_transactions = r.data.recategorized_total_transactions
                this.recategorized_reviewed_spend = r.data.recategorized_reviewed_spend
                this.recategorized_unreviewed_spend = r.data.recategorized_unreviewed_spend
                this.recategorized_reviewed_parts = r.data.recategorized_reviewed_parts
                this.recategorized_unreviewed_parts = r.data.recategorized_unreviewed_parts

                this.categorized_spend = r.data.categorized_spend
                this.categorized_parts = r.data.categorized_parts
                this.categorized_spend = Number(r.data.categorized_spend); //TODO: ASK MOJ TO ADD THIS
                this.categorized_total_spend = r.data.categorized_total_spend
                this.categorized_total_transactions = r.data.categorized_total_transactions
                this.categorized_reviewed_spend = Number(r.data.categorized_reviewed_spend);
                this.categorized_unreviewed_spend = Number(r.data.categorized_unreviewed_spend);
                this.categorized_reviewed_parts = r.data.categorized_reviewed_parts
                this.categorized_unreviewed_parts = r.data.categorized_unreviewed_parts

                this.uncategorized_spend = r.data.uncategorized_spend
                this.uncategorized_parts = r.data.uncategorized_parts
                this.uncategorized_spend = Number(r.data.uncategorized_spend); // TODO: ASK MOJ TO ADD THIS
                this.uncategorized_total_spend = r.data.uncategorized_total_spend
                this.uncategorized_total_transactions = r.data.uncategorized_total_transactions
                this.uncategorized_reviewed_spend = r.data.uncategorized_reviewed_spend
                this.uncategorized_unreviewed_spend = r.data.uncategorized_unreviewed_spend
                this.uncategorized_reviewed_parts = r.data.uncategorized_reviewed_parts
                this.uncategorized_unreviewed_parts = r.data.uncategorized_unreviewed_parts


                this.total_categories = r.data.total_categories;
                this.covered_categories = r.data.covered_categories;
                this.uncovered_categories = r.data.uncovered_categories;

            })
    }

    getDistributionBarDataPoint(): BarDataPoint[] {
        let veryLowConfidencePercentage: number = 0;
        let lowConfidencePercentage: number = 0
        let mediumConfidencePercentage: number = 0
        let highConfidencePercentage: number = 0
        let veryHighConfidencePercentage: number = 0

        if (this.total_parts !== 0 && this.total_parts !== undefined) {
            veryLowConfidencePercentage = (this.very_low_confidence_parts === 0 || this.very_low_confidence_parts === undefined) ? 0 : Math.round(this.very_low_confidence_parts / this.total_parts * 100);
            lowConfidencePercentage = (this.low_confidence_parts === 0 || this.low_confidence_parts === undefined) ? 0 : Math.round(this.low_confidence_parts / this.total_parts * 100);
            mediumConfidencePercentage = (this.medium_confidence_parts === 0 || this.medium_confidence_parts === undefined) ? 0 : Math.round(this.medium_confidence_parts / this.total_parts * 100);
            highConfidencePercentage = (this.high_confidence_parts === 0 || this.high_confidence_parts === undefined) ? 0 : Math.round(this.high_confidence_parts / this.total_parts * 100);
            veryHighConfidencePercentage = (this.very_high_confidence_parts === 0 || this.very_high_confidence_parts === undefined) ? 0 : Math.round(this.very_high_confidence_parts / this.total_parts * 100);
        }

        const veryLowConfidence = {
            value: veryLowConfidencePercentage / 60,
            valueLabel: `${this.very_low_confidence_parts || 0} | ${veryLowConfidencePercentage}%`,
            category: "0_20",
            categoryLabel: "Very low confidence (0-20)",
        };

        const lowConfidence = {
            value: lowConfidencePercentage / 60,
            valueLabel: `${this.low_confidence_parts || 0} | ${lowConfidencePercentage}%`,
            category: "20_40",
            categoryLabel: "Low confidence (20-40)",
        };

        const mediumConfidence = {
            value: mediumConfidencePercentage / 60,
            valueLabel: `${this.medium_confidence_parts || 0} | ${mediumConfidencePercentage}%`,
            category: "40_60",
            categoryLabel: "Medium confidence (40-60)",
        };

        const highConfidence = {
            value: highConfidencePercentage / 60,
            valueLabel: `${this.high_confidence_parts || 0} | ${highConfidencePercentage}%`,
            category: "60_80",
            categoryLabel: "High confidence (60-80)",
        };

        const veryHighConfidence = {
            value: veryHighConfidencePercentage / 60,
            valueLabel: `${this.very_high_confidence_parts || 0} | ${veryHighConfidencePercentage}%`,
            category: "80_100",
            categoryLabel: "Very high confidence (80-100)",
        };

        return [veryLowConfidence, lowConfidence, mediumConfidence, highConfidence, veryHighConfidence];
    }

    getTotalReviewedAiResultBarDataPoint(): BarDataPoint[] {
        const currencySymbol = this.profile.currencySymbol;
        let reviewedPercentage: number = 0;
        let unreviewedPercentage: number = 0

        if (this.total_transactions !== 0 && this.total_transactions !== undefined) {
            reviewedPercentage = (this.reviewed_transactions === 0 || this.reviewed_transactions === undefined) ? 0 : Math.round(this.reviewed_transactions / this.total_transactions * 100);
            unreviewedPercentage = (this.unreviewed_transactions === 0 || this.unreviewed_transactions === undefined) ? 0 : Math.round(this.unreviewed_transactions / this.total_transactions * 100);
        }

        const reviewedData = {
            value: reviewedPercentage / 200,
            valueLabel: `${this.reviewed_transactions || 0} | ${toCurrency(currencySymbol, (this.reviewed_spend || 0))}  | ${reviewedPercentage}%`,
            category: "reviewed",
            categoryLabel: "Reviewed",
        };
        const unreviewedData = {
            value: unreviewedPercentage / 200,
            valueLabel: `${this.unreviewed_transactions || 0} | ${toCurrency(currencySymbol, (this.unreviewed_spend || 0))}  | ${unreviewedPercentage}%`,
            category: "",
            categoryLabel: "Unreviewed",
        };
        console.log([reviewedData, unreviewedData])
        return [reviewedData, unreviewedData];
    };

    getCoveredByAiSpendPercentage(): number {
        if (this.covered_by_ai_total_spend === 0 || this.covered_by_ai_total_spend === undefined || this.covered_by_ai_spend === undefined || this.covered_by_ai_spend === 0) {
            return 0;
        } else {
            return Math.round(this.covered_by_ai_spend / this.covered_by_ai_total_spend * 100);
        }
    }

    getSpendCoveredByAiBarDataPoint(): BarDataPoint[] {
        const currencySymbol = this.profile.currencySymbol;
        let reviewedPercentage: number = 0;
        let unreviewedPercentage: number = 0

        if (this.covered_by_ai_spend !== 0 && this.covered_by_ai_spend !== undefined) {
            reviewedPercentage = (this.covered_by_ai_reviewed_spend === 0 || this.covered_by_ai_reviewed_spend === undefined) ? 0 : Math.round(this.covered_by_ai_reviewed_spend / this.covered_by_ai_spend * 100);
            unreviewedPercentage = (this.covered_by_ai_unreviewed_spend === 0 || this.covered_by_ai_unreviewed_spend === undefined) ? 0 : Math.round(this.covered_by_ai_unreviewed_spend / this.covered_by_ai_spend * 100);
        }

        const reviewedData = {
            value: reviewedPercentage / 100,
            valueLabel: `${toCurrency(currencySymbol, (this.covered_by_ai_reviewed_spend || 0))}  | ${reviewedPercentage}%`,
            category: "reviewed",
            categoryLabel: "Reviewed",
        };
        const unreviewedData = {
            value: unreviewedPercentage / 100,
            valueLabel: `${toCurrency(currencySymbol, (this.covered_by_ai_total_spend || 0))}  | ${unreviewedPercentage}%`,
            category: "",
            categoryLabel: "Unreviewed",
        };
        return [reviewedData, unreviewedData];
    }

    getCoveredByAiSuppliersPercentage(): number {
        if (this.covered_suppliers === undefined || this.covered_suppliers === 0 || this.total_suppliers === undefined || this.total_suppliers === 0) {
            return 0;
        } else {
            return Math.round(this.covered_suppliers / this.total_suppliers * 100);
        }
    }

    getSuppliersCoveredByAiBarDataPoint(): BarDataPoint[] {
        let reviewedPercentage: number = 0;
        let unreviewedPercentage: number = 0

        if (this.covered_suppliers !== 0 && this.covered_suppliers !== undefined) {
            reviewedPercentage = (this.covered_reviewed_suppliers === 0 || this.covered_reviewed_suppliers === undefined) ? 0 :
                Math.round(this.covered_reviewed_suppliers / this.covered_suppliers * 100);
            unreviewedPercentage = (this.covered_unreviewed_suppliers === 0 || this.covered_unreviewed_suppliers === undefined) ? 0 :
                Math.round(this.covered_unreviewed_suppliers / this.covered_suppliers * 100);
        }

        const reviewedData = {
            value: reviewedPercentage / 100,
            valueLabel: `${String(this.covered_reviewed_suppliers || 0)}  | ${reviewedPercentage}%`,
            category: "reviewed",
            categoryLabel: "Reviewed",
        };
        const unreviewedData = {
            value: unreviewedPercentage / 100,
            valueLabel: `${String(this.covered_unreviewed_suppliers || 0)}  | ${unreviewedPercentage}%`,
            category: "",
            categoryLabel: "Unreviewed",
        };
        return [reviewedData, unreviewedData];
    }

    getCategoriesCoveredByAiBarDataPoint(): BarDataPoint[] {
        let coveredPercentage: number = 0;

        if (this.total_categories !== 0 && this.total_categories !== undefined) {
            coveredPercentage = (this.covered_categories === 0 || this.covered_categories === undefined) ? 0 : Math.round(this.covered_categories / this.total_categories * 100);
        }

        const covered = {
            value: coveredPercentage / 100,
            valueLabel: `${String(this.covered_categories)} | ${coveredPercentage}%`,
            category: "covered",
            categoryLabel: "Covered",
        };

        return [covered]
    }

    getCategoriesUncoveredByAiBarDataPoint(): BarDataPoint[] {
        let uncoveredPercentage: number = 0

        if (this.total_categories !== 0 && this.total_categories !== undefined) {
            uncoveredPercentage = (this.uncovered_categories === 0 || this.uncovered_categories === undefined) ? 0 : Math.round(this.uncovered_categories / this.total_categories * 100);
        }

        const uncovered = {
            value: uncoveredPercentage / 100,
            valueLabel: `${String(this.uncovered_categories)} | ${uncoveredPercentage}%`,
            category: "",
            categoryLabel: "Not covered",
        };
        return [uncovered];
    }

    getCategorizationRecategorized(): BarDataPoint[] {
        const currencySymbol = this.profile.currencySymbol;
        let recategorizedPercentage: number = 0
        let reviewedPercentage: number = 0;
        let unreviewedPercentage: number = 0

        let recategorized_parts = (this.recategorized_reviewed_parts || 0) + (this.recategorized_unreviewed_parts || 0)


        if (this.recategorized_spend !== 0 && this.recategorized_spend !== undefined && this.recategorized_total_spend !== 0 && this.recategorized_total_spend !== undefined) {
            recategorizedPercentage = Math.round(this.recategorized_spend / this.recategorized_total_spend * 100);
            console.log(recategorizedPercentage);
        }
        reviewedPercentage = (this.recategorized_reviewed_parts === 0 || this.recategorized_reviewed_parts === undefined) ? 0 : Math.round(this.recategorized_reviewed_parts / recategorized_parts * 100);
        unreviewedPercentage = (this.recategorized_unreviewed_parts === 0 || this.recategorized_unreviewed_parts === undefined) ? 0 : Math.round(this.recategorized_unreviewed_parts / recategorized_parts * 100);


        const recategorized = {
            value: recategorizedPercentage / 100,
            valueLabel: `${toCurrency(currencySymbol, (this.recategorized_spend || 0))}  | ${recategorizedPercentage}%`,
            category: " ",
            categoryLabel: " ",
        };

        const recategorizedReviewed = {
            value: reviewedPercentage / 100,
            valueLabel: `${toCurrency(currencySymbol, (this.recategorized_reviewed_spend || 0))} | ${this.recategorized_reviewed_parts || 0} | ${reviewedPercentage}%`,
            category: "reviewed",
            categoryLabel: "Reviewed",
        };

        const recategorizedUnreviewed = {
            value: unreviewedPercentage / 100,
            valueLabel: `${toCurrency(currencySymbol, (this.recategorized_unreviewed_spend || 0))} | ${this.recategorized_unreviewed_parts || 0} | ${unreviewedPercentage}%`,
            category: "",
            categoryLabel: "Unreviewed",
        };

        return [recategorized, recategorizedReviewed, recategorizedUnreviewed];
    }

    getCategorizationCategorized(): BarDataPoint[] {
        const currencySymbol = this.profile.currencySymbol;
        let categorizedPercentage: number = 0
        let reviewedPercentage: number = 0;
        let unreviewedPercentage: number = 0

        let categorized_parts = (this.categorized_reviewed_parts || 0) + (this.categorized_unreviewed_parts || 0)


        if (this.categorized_spend !== 0 && this.categorized_spend !== undefined && this.categorized_total_spend !== 0 && this.categorized_total_spend !== undefined) {
            categorizedPercentage = Math.round(this.categorized_spend / this.categorized_total_spend * 100);
        }
        reviewedPercentage = (this.categorized_reviewed_parts === 0 || this.categorized_reviewed_parts === undefined) ? 0 : Math.round(this.categorized_reviewed_parts / categorized_parts * 100);
        unreviewedPercentage = (this.categorized_unreviewed_parts === 0 || this.categorized_unreviewed_parts === undefined) ? 0 : Math.round(this.categorized_unreviewed_parts / categorized_parts * 100);


        const categorized = {
            value: categorizedPercentage / 100,
            valueLabel: `${toCurrency(currencySymbol, (this.categorized_spend || 0))}  | ${categorizedPercentage}%`,
            category: " ",
            categoryLabel: " ",
        };

        const categorizedReviewed = {
            value: reviewedPercentage / 100,
            valueLabel: `${toCurrency(currencySymbol, (this.categorized_reviewed_spend || 0))} | ${this.categorized_reviewed_parts || 0} | ${reviewedPercentage}%`,
            category: "reviewed",
            categoryLabel: "Reviewed",
        };

        const categorizedUnreviewed = {
            value: unreviewedPercentage / 100,
            valueLabel: `${toCurrency(currencySymbol, (this.categorized_unreviewed_spend || 0))} | ${this.categorized_unreviewed_parts || 0} | ${unreviewedPercentage}%`,
            category: "",
            categoryLabel: "Unreviewed",
        };

        return [categorized, categorizedReviewed, categorizedUnreviewed];
    }

    getCategorizationUncategorized(): BarDataPoint[] {
        const currencySymbol = this.profile.currencySymbol;
        let uncategorizedPercentage: number = 0
        let reviewedPercentage: number = 0;
        let unreviewedPercentage: number = 0

        let uncategorized_parts = (this.uncategorized_reviewed_parts || 0) + (this.uncategorized_unreviewed_parts || 0)


        if (this.uncategorized_spend !== 0 && this.uncategorized_spend !== undefined && this.uncategorized_total_spend !== 0 && this.uncategorized_total_spend !== undefined) {
            uncategorizedPercentage = Math.round(this.uncategorized_spend / this.uncategorized_total_spend * 100);
        }
        reviewedPercentage = (this.uncategorized_reviewed_parts === 0 || this.uncategorized_reviewed_parts === undefined) ? 0 : Math.round(this.uncategorized_reviewed_parts / uncategorized_parts * 100);
        unreviewedPercentage = (this.uncategorized_unreviewed_parts === 0 || this.uncategorized_unreviewed_parts === undefined) ? 0 : Math.round(this.uncategorized_unreviewed_parts / uncategorized_parts * 100);


        const uncategorized = {
            value: uncategorizedPercentage / 100,
            valueLabel: `${toCurrency(currencySymbol, (this.uncategorized_spend || 0))}  | ${uncategorizedPercentage}%`,
            category: " ",
            categoryLabel: " ",
        };

        const uncategorizedReviewed = {
            value: reviewedPercentage / 100,
            valueLabel: `${toCurrency(currencySymbol, (this.uncategorized_reviewed_spend || 0))} | ${this.uncategorized_reviewed_parts || 0} | ${reviewedPercentage}%`,
            category: "reviewed",
            categoryLabel: "Reviewed",
        };

        const uncategorizedUnreviewed = {
            value: unreviewedPercentage / 100,
            valueLabel: `${toCurrency(currencySymbol, (this.uncategorized_unreviewed_spend || 0))} | ${this.uncategorized_unreviewed_parts || 0} | ${unreviewedPercentage}%`,
            category: "",
            categoryLabel: "Unreviewed",
        };

        return [uncategorized, uncategorizedReviewed, uncategorizedUnreviewed];
    }
}