import './CategorizationPage.scss'
import React, {useEffect, useState} from "react";
import {CategorizationStepper} from "./components/stepper/CategorizationStepper";
import {CategorizationWelcome} from "./components/welcome/CategorizationWelcome";
import {Alert, Button, CircularProgress, Dialog, Grid} from "@mui/material";
import {ChevronLeft, ChevronRight, CloudDownload, Done, Refresh} from "@mui/icons-material";
import {useHistory} from "react-router";
import {useRouteMatch} from "react-router-dom";
import {JobRouteMatch} from '../../routing/routes';
import {generateHomePath} from "../../routing/routing.utils";
import {useStores} from "../../stores";
import {observer} from "mobx-react-lite";
import {CategorizationReviewPage} from "./CategorizationReviewPage";
import {ConfirmSendForApproval} from "./components/modals/ConfirmSendForApproval";
import {CAT_FILTER_MODAL_TITLE_ID, CategorizationFilterModal} from "./components/modals/CategorizationFilterModal";
import {CategorizationStatisticsDashboard} from "./components/statistics/CategorizationStatisticsDashboard";
import {environment} from "../../env";
import {AI_MODEL_FAKER, DemoAiBusy} from "./components/demo-ai-busy/DemoAiBusy";

type Props = {}
export const CategorizationPage: React.FC<Props> = observer(() => {
    const {rootAppStore, authStore, bagStore, categorizationReviewStore, api, p, approvalStore} = useStores();
    const approvalController = categorizationReviewStore.reviewPageController.reviewPageApprovalController;
    const pageStateController = categorizationReviewStore.reviewPageController.reviewPageStateController;
    const reviewDataController = categorizationReviewStore.reviewPageController.reviewPageDataController;
    const reviewFilterController = categorizationReviewStore.reviewPageController.reviewPageFilterController;
    const statisticsDashboardController = categorizationReviewStore.statisticsDashboardController;

    const bagId = bagStore.bagId;
    const routeMatch = useRouteMatch<JobRouteMatch>();

    const history = useHistory();
    const back = generateHomePath(routeMatch, {id: String(bagId)}, rootAppStore.app)

    const [isDownloadingExcel, setIsDownloadingExcel] = useState(false)
    const [hasDownloadedExcel, setHasDownloadedExcel] = useState(false)

    // useEffect(() => {
    //     let intervalId;
    //     reviewPageController.getAiStatus(); // Disabled for development
    //     reviewPageController.getCategorizationVersionedStatistics(); // Disabled for development
    //     if (reviewPageController.aiStatus === 'busy') {
    //         reviewPageController.getAiStatus();
    //         intervalId = setInterval(() => {
    //             reviewPageController.getAiStatus();
    //         }, 10000);
    //     }
    //     return () => {
    //         clearInterval(intervalId);
    //     };
    // }, [reviewPageController.page]);

    const [isFakedStartingAiRun, setIsFakedStartingAiRun] = useState(false);
    useEffect(() => {
        let intervalId;
        if (isFakedStartingAiRun) {
            intervalId = setInterval(() => {
                setIsFakedStartingAiRun(false);
                categorizationReviewStore.reviewPageController.reviewPageStateController.setPage("demo-ai-run")
                AI_MODEL_FAKER.start()
                // history.push(generateHomePath(routeMatch, routeMatch.params, rootAppStore.app));
            }, 800);
        }
        return () => {
            clearInterval(intervalId);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFakedStartingAiRun]);

    useEffect(() => {
        // Request the categorizationApprovalInfo every 30 seconds while in the categorization review page
        const databagId = categorizationReviewStore.bagId
        if (databagId > 0) {
            approvalStore.categorizationApprovalInfo.request({databagId})
            const t = setInterval(() => {
                approvalStore.categorizationApprovalInfo.request({databagId})
            }, 1000 * 30);
            return () => {
                approvalStore.categorizationApprovalInfo.cleanup()
                clearInterval(t)
            };
        } else {
            approvalStore.categorizationApprovalInfo.cleanup()
        }
    }, [categorizationReviewStore.bagId, approvalStore.categorizationApprovalInfo]);

    return <div>
        <Dialog
            open={approvalController.isSendForApprovalDialogOpen}
            onClose={() => approvalController.setSendForApprovalDialog(false)}
            aria-labelledby={"review-modal-title"}
            maxWidth="lg"
        >
            {approvalController.isSendForApprovalDialogOpen && <ConfirmSendForApproval/>}
        </Dialog>
        <Dialog
            open={reviewFilterController.isAdvFilterDialogOpen}
            onClose={() => reviewFilterController.setAdvancedFilterDialogOpen(false)}
            aria-labelledby={CAT_FILTER_MODAL_TITLE_ID}
            maxWidth="lg"
        >
            {reviewFilterController.isAdvFilterDialogOpen && <CategorizationFilterModal/>}
        </Dialog>

        {/*A header with back button*/}
        <div className="categorization-page">

            {approvalController.isSendForApprovalWaiting &&
                <Grid container justifyContent="center">
                    <Grid item>
                        <Alert severity="info">
                            <span className="ml-2 mr-1">
                                <CircularProgress size={12}/>
                            </span>
                            Approval is being send in the background ...
                        </Alert>
                    </Grid>
                </Grid>
            }

            {pageStateController.page === 'review-dashboard' && categorizationReviewStore.reviewPageController.reviewPageStatisticsController._reviewLevelStatistics.error &&
                <Grid container justifyContent="center">
                    <Grid item>
                        <Alert severity="warning">
                            The categorization review is not available
                            ({categorizationReviewStore.reviewPageController.reviewPageStatisticsController._reviewLevelStatistics.error})
                        </Alert>
                    </Grid>
                </Grid>
            }

            <Grid container alignItems="center" className="header">
                <Grid item xs={2} className="text-center align-with-stepper">
                    {pageStateController.previousLabel &&
                        <Button
                            color="primary"
                            variant="outlined"
                            startIcon={<ChevronLeft/>}
                            onClick={() => pageStateController.onClickPrevious(history, back)}>
                            {pageStateController.previousLabel}
                        </Button>
                    }
                </Grid>

                <Grid item xs={8}>
                    <CategorizationStepper/>
                </Grid>

                <Grid item xs={2} className="align-with-stepper">
                    {(pageStateController.page === 'review') && <>
                        {!p.p.hideApprovalButtons && <Button
                            color="primary"
                            variant="outlined"
                            disabled={reviewDataController.anyStoreRequestBusy || authStore.viewOnly}
                            style={{marginTop: '2em', minWidth: '14em'}}
                            // endIcon={<ChevronRight/>}
                            onClick={() => approvalController.setSendForApprovalDialog(true)}>
                            Send for approval
                            {authStore.viewOnly && <><br/>(Not allowed)</>}
                        </Button>}
                        {p.p.allowReviewDownloadExcel &&
                            <Button
                                color="primary"
                                variant="outlined"
                                className="btn-with-circular-progress"
                                startIcon={isDownloadingExcel ?
                                    <CircularProgress size={14}/> : (hasDownloadedExcel ?
                                        <Done/> :
                                        <CloudDownload/>)}
                                endIcon={isDownloadingExcel ? undefined : (hasDownloadedExcel ? undefined : undefined)}
                                disabled={isDownloadingExcel || true}
                                style={{marginTop: '2em', minWidth: '14em'}}
                                onClick={() => {
                                    setIsDownloadingExcel(true)
                                    api.downloadCategorizationResultExcel(bagId)
                                        .then(resp => {
                                            let filename = 'Result.xlsx';
                                            const contentHeader: string = resp.headers['content-disposition'] || '';
                                            if (contentHeader) {
                                                let split = contentHeader.split('filename=');
                                                if (split.length > 1) {
                                                    filename = split[1];
                                                }
                                            }
                                            const url = URL.createObjectURL(new Blob([resp.data]));
                                            const link = document.createElement('a');
                                            link.href = url;
                                            link.setAttribute('download', filename);
                                            document.body.appendChild(link);
                                            link.click();
                                            link.parentNode?.removeChild(link);
                                            setHasDownloadedExcel(true)
                                        })
                                        .finally(() => {
                                            setIsDownloadingExcel(false)
                                        })
                                }}>
                                {isDownloadingExcel ? 'Preparing data' : 'Download Excel file (BROKEN)'}
                            </Button>
                        }
                        {p.p.demoFakeReinforcedCategorization &&
                            <Button
                                color="primary"
                                variant="outlined"
                                className="btn-with-circular-progress"
                                startIcon={isFakedStartingAiRun ?
                                    <CircularProgress size={14}/> : (isFakedStartingAiRun ?
                                        <Done/> :
                                        <CloudDownload/>)}
                                style={{marginTop: '2em', minWidth: '14em'}}
                                disabled={isFakedStartingAiRun}
                                onClick={() => {
                                    setIsFakedStartingAiRun(true)
                                }}>
                                {/*{isDownloadingExcel ? 'Preparing data' : 'Download Excel file (BROKEN)'}*/}
                                Re-Run AI
                            </Button>
                        }
                    </>}
                    {pageStateController.nextLabel && <Button
                        color="primary"
                        variant="outlined"
                        disabled={reviewDataController.anyStoreRequestBusy}
                        style={{marginTop: '2em'}}
                        endIcon={<ChevronRight/>}
                        onClick={() => pageStateController.onClickNext()}>
                        {pageStateController.nextLabel}
                    </Button>}
                    {(pageStateController.page === 'review-dashboard') && <>
                        <Button
                            color="primary"
                            variant="outlined"
                            style={{marginTop: '2em', marginLeft: '1em'}}
                            startIcon={<Refresh/>}
                            onClick={() => statisticsDashboardController.getCategorizationVersionedStatistics(categorizationReviewStore.bagId)}>
                            Update statistics
                        </Button>
                    </>}

                </Grid>
            </Grid>

            {environment.isTestReviewPage && <>
                <h1>DEBUG CategoziationV2:</h1>
                <CategorizationReviewPage/>
                <h1>END DEBUG</h1>
            </>}

            {pageStateController.page === 'ai-run' &&
                <CategorizationWelcome/>
            }
            {pageStateController.page === 'demo-ai-run' &&
                <DemoAiBusy/>
            }
            {pageStateController.page === 'review-dashboard' &&
                <CategorizationStatisticsDashboard/>
            }
            {(pageStateController.page === 'review') &&
                <CategorizationReviewPage/>
            }
        </div>

    </div>
});
