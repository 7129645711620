import '../SalesDemoDashboard.scss';
import '../../../cleanup-dashboard/CleanupDashBoard.scss';
import React from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../../stores";
import {Grid} from "@mui/material";
import {DASHBOARD_CONSTANTS, drawOpportunityValue} from "../../../../components/dashboard/Dashboard.utils";
import {generatePath} from "react-router";
import {routes} from "../../../../routing/routes";
import {ClassificationHierarchyIcon} from "../../../../components/icons/ClassificationHierarchyIcon";
import {CurrencyComponent} from "../../../../components/currency-component/CurrencyComponent";
import {DashboardRow} from "../../../../components/dashboard/DashboardRow";
import {SupplierNormalizationV2Icon} from "../../../../components/icons/SupplierNormalizationV2Icon";
import {ClassificationIcon} from "../../../../components/icons/ClassificationIcon";
import {environment} from "../../../../env";
import {DashboardHeader} from "../../../dashboard/DashboardHeader";
import {DashBoardCard} from "../../../dashboard/DashBoardCard";
import {VerifiedOutlined} from "@mui/icons-material";
import {GOLDEN_RECORD_DUMMY_DATA_STATS} from "../../../golden-record/classes/GoldenRecordDummyData";
import {TaxonomySuggestionV2Icon} from "../../../../components/icons/TaxonomySuggestionV2Icon";

/**
 */
export const SalesDemoDashboardCleanup: React.FC = observer(() => {
    const {
        p,
        bagStore,
        categorizationReviewStore,
        supplierNormalizationStore,
        taxonomyManagerStore,
        goldenRecordStore,
    } = useStores();
    // TODO: CAT-1140: Make a proper design for all the dashboard cards
    const bagId = bagStore.bagId;
    const unclassifiedEgoSpend = categorizationReviewStore.summaryResultKeyValues?.after.unclassified_spend;
    const pendingSupplierNormalization = supplierNormalizationStore.postKeyValues?.total_standalone_suppliers_spend;
    return <>
        {environment.isTest && <h1>SalesDemoDashboardCleanup</h1>}
        <DashboardHeader/>
        <Grid container
              className="dashboard sales-demo-dashboard-wrapper cleanup-dashboard-wrapper"
              justifyContent="space-evenly"
              spacing={0}>
            <DashboardRow
                title="Suppliers"
                className="suppliers-row"
                subtitle={bagStore.kpi.common
                    ? String(bagStore.kpi.common.n_suppliers)
                    : DASHBOARD_CONSTANTS.SUBTITLE_LOADING}>
                <DashBoardCard
                    cardKey="supplier-normalization"
                    avatar={<SupplierNormalizationV2Icon/>}
                    title="Supplier Normalization"
                    value={drawOpportunityValue(pendingSupplierNormalization)}
                    subtitle="suppliers not reviewed yet"
                    link={generatePath(routes.demo_cleanup_supplier_normalization, {id: bagId})}
                    badge="Ai"
                />
            </DashboardRow>
            <DashboardRow
                title="Categories"
                className="categories-row"
                subtitle={bagStore.kpi.common
                    ? String(bagStore.kpi.common.n_categories)
                    : DASHBOARD_CONSTANTS.SUBTITLE_LOADING}>
                <DashBoardCard
                    cardKey="improve-cats"
                    avatar={<ClassificationIcon/>}
                    title="Improve Categorization"
                    value={drawOpportunityValue(unclassifiedEgoSpend)}
                    valueColor={unclassifiedEgoSpend
                        ? (unclassifiedEgoSpend >= DASHBOARD_CONSTANTS.OPPORTUNITY_THRESHOLD) ? 'red' : 'green' : 'gray'}
                    subtitle="uncategorized"
                    link={generatePath(routes.demo_cleanup_categorization, {id: bagId})}
                    badge="Ai"
                />
                {p.p.demoFakeGoldenRecords && <DashBoardCard
                    cardKey="golden-record"
                    avatar={<VerifiedOutlined fontSize="large"/>}
                    title="Golden Records"
                    value={drawOpportunityValue(GOLDEN_RECORD_DUMMY_DATA_STATS.total_golden_records_spend)}
                    subtitle="total value"
                    link={generatePath(routes.demo_cleanup_golden_record, {id: bagId})}
                    badge="Ai"
                />}
            </DashboardRow>
            <DashboardRow
                title="Spend"
                className="spend-row"
                subtitle={bagStore.kpi.common
                    ? <CurrencyComponent v={bagStore.kpi.common.total_spend} nDigits={3}/>
                    : DASHBOARD_CONSTANTS.SUBTITLE_LOADING}>
                <DashBoardCard
                    cardKey="taxonomy-builder"
                    avatar={<ClassificationHierarchyIcon/>}
                    title="Taxonomy Builder"
                    subtitle={taxonomyManagerStore.taxonomy?.name}
                    link={generatePath(routes.demo_cleanup_taxonomy_builder, {id: bagId})}
                />

                {/* [CAT-1572] Does this really need to be here? */}
                <DashBoardCard
                    cardKey="taxonomy-builder"
                    avatar={<TaxonomySuggestionV2Icon/>}
                    title="Taxonomy Suggestion"
                    link={generatePath(routes.demo_cleanup_taxonomy_suggestion_v2, {id: bagId})}
                />
            </DashboardRow>
        </Grid>
    </>
});
