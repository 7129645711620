import {HeaderWrapper} from "../HeaderWrapper";
import {LoadingBarComponent} from "../../../components/loading-bar/LoadingBarComponent";
import {NotFound} from "../../../routing/NotFound";
import {LoadingSpinnerPie} from "../../../components/loading-pie/LoadingSpinnerPie";
import {MainComponent} from "../../../components/main/MainComponent";
import React from "react";
import {useStores} from "../../../stores";
import {RouteWrapperProps} from "../RouteWrapperProps";
import {DatasetUpdatedLandingPage} from "../../../routing/DatasetUpdatedLandingPage";
import {observer} from "mobx-react-lite";

export const SingleDatabagWrapped: React.FC<RouteWrapperProps> = observer(({children, state}) => {
    const {authStore, bagStore, bagLoadingStore} = useStores();
    const isNoData = state.dataType === 'no_data';
    return <MainComponent state={state} header={<HeaderWrapper state={state}/>}>
        {/*bagStore.bag={'' + Boolean(bagStore.bag)}<br/>*/}
        {/*authStore.isMithraStaff={'' + Boolean(authStore.isMithraStaff)}<br/>*/}
        <LoadingBarComponent/>
        {
            isNoData
                ? children
                : (
                    bagLoadingStore.bagNotFound
                        ? <NotFound msg={"Could not find data on the server anymore"}/>
                        : !bagStore.bag
                            ? <div className="loading-spinner">
                                <LoadingSpinnerPie/>
                            </div>
                            : authStore.isMithraStaff || !bagStore.bag.hidden
                                ? children
                                : <DatasetUpdatedLandingPage/>
                )
        }
    </MainComponent>
})
