import React from 'react';
import {Chip, Collapse, IconButton, Table, TableBody, TableCell, TableContainer, TableRow} from "@mui/material";
import {getCollapseAnimationTiming} from "../../../components/table/utils";
import {Cancel, CheckCircle, ExpandMore, KeyboardArrowRight, Warning} from "@mui/icons-material";
import {useStores} from "../../../stores";
import {MithraTableHeadColumns} from "../../../components/table/MithraTableHeadColumns";
import {MithraTableCell} from "../../../components/table/MithraTableCell";
import {CurrencyComponent} from "../../../components/currency-component/CurrencyComponent";
import {taxonomy_suggestion_demo} from "../Classes";

type Node = taxonomy_suggestion_demo.ExtendedD3HierarchyNode;

type Props = {
    data?: Node
    changed: number // Hack for triggering re-render by mobx for demo purpose
}
export const TaxonomySuggestionDemoTable: React.FC<Props> = ({data}) => {
    const {taxonomySuggestionDemoStore} = useStores();
    const columns = taxonomySuggestionDemoStore.COLUMNS;
    if (!data) return <></>
    return <TableContainer className="taxonomy-suggestion-demo-table">

        {/*<p>leafs={data.leaves().length}</p>*/}
        {/*<p>filtered=*/}
        {/*    {*/}
        {/*        data.descendants()*/}
        {/*            .reduce((acc, d) => acc + (d.data.viz.filtered ? 1 : 0), 0)*/}
        {/*    }*/}
        {/*</p>*/}
        {/*<p>*/}
        {/*    root = {'' + data.data.viz.label}*/}
        {/*</p>*/}
        {/*<p>*/}
        {/*    changed = {'' + changed}*/}
        {/*</p>*/}

        <Table stickyHeader>
            <MithraTableHeadColumns columns={columns}/>
            <TableBody>
                {data &&
                    <TaxonomyTableElement row={data}/>
                }
            </TableBody>
        </Table>
    </TableContainer>
};

type TaxonomyTableElementProps = {
    row: Node
};
const TaxonomyTableElement: React.FC<TaxonomyTableElementProps> = ({row}) => {
    const {taxonomySuggestionDemoStore} = useStores();
    const columns = taxonomySuggestionDemoStore.COLUMNS;
    const {children} = row;
    const isRootNode = row.depth === 0;
    const hidden = row.data.viz.filtered;

    // const label = row.data.viz.label;
    // const review_status = row.data.apiValues.review_status;
    // const values = row.data.apiValues.values;

    const [open, setOpen] = React.useState(true);


    return <>
        {/*<TableRow>*/}
        {/*    <TableCell>*/}
        {/*        <p>TaxonomyTableElement label="{'' + row.data.viz.label}"</p>*/}
        {/*        <p>hidden={'' + hidden} isRootNode={'' + isRootNode} childen={'' + children?.length} </p>*/}
        {/*    </TableCell>*/}
        {/*</TableRow>*/}
        {!hidden && <>
            {!isRootNode &&
                <TaxonomyTableRow row={row} open={open} setOpen={setOpen}/>
            }
            <TableRow className={open ? 'is-open' : ''}>
                <TableCell colSpan={columns.length} style={{padding: '0'}}>
                    {children && children.length > 0 && children.length &&
                        <Collapse in={open} timeout={getCollapseAnimationTiming(children)} unmountOnExit>
                            <Table>
                                <TableBody>
                                    {children.map(c =>
                                        <TaxonomyTableElement key={c.data.id} row={c}/>
                                    )}
                                </TableBody>
                            </Table>
                        </Collapse>
                    }
                </TableCell>
            </TableRow>
        </>}
    </>
}


type TaxonomyTableRowProps = {
    row: Node
    open: boolean
    setOpen: (open: boolean) => void
};
const TaxonomyTableRow: React.FC<TaxonomyTableRowProps> = ({row, open, setOpen}) => {
    const {taxonomySuggestionDemoStore} = useStores();
    const columns = taxonomySuggestionDemoStore.COLUMNS;
    const {children} = row;

    const indentation = `${row.depth - 1}rem`;
    const getSuggestionType = (review_status: taxonomy_suggestion_demo.ReviewStatusType) => {
        if (review_status.added) {
            return 'Added';
        } else if (review_status.deleted) {
            return 'Deleted';
        } else if (review_status.renamed) {
            return 'Renamed';
        }
    }
    let i = 0;
    const reviewStatus = row.data.apiValues.review_status;
    return <TableRow>
        <MithraTableCell c={columns[i++]} onClick={() => setOpen(!open)}>
            <div style={{marginLeft: indentation}}>

                {/*<p>f={taxonomySuggestionDemoStore.taxonomyTableController.filter}</p>*/}
                {/*<p>filtered={'' + row.data.viz.filtered}</p>*/}

                {(children ? children.length > 0 : false) ?
                    <IconButton aria-label="expand row" size="small">
                        {open ? <ExpandMore/> : <KeyboardArrowRight/>}
                    </IconButton> :
                    <IconButton aria-label="expand row" size="small" style={{visibility: 'hidden'}}>
                        {open ? <ExpandMore/> : <KeyboardArrowRight/>}
                    </IconButton>}

                {row.data.viz.hasChangesInChildren ?
                    <IconButton> <Warning style={{color: '#FAA915'}}/> </IconButton> : null}

                {reviewStatus?.renamed
                    ? (
                        reviewStatus?.accepted === false && reviewStatus?.rejected === false
                            ? <span>{`${reviewStatus.oldLabel} → ${reviewStatus.newLabel}`}</span>
                            : reviewStatus?.accepted
                                ? <span>{`${reviewStatus.newLabel} (${reviewStatus.oldLabel})`}</span>
                                : reviewStatus?.rejected
                                    ? <span>{`${reviewStatus.oldLabel} (${reviewStatus.newLabel})`}</span>
                                    : null
                    )
                    : row.data.viz.label
                }
            </div>
        </MithraTableCell>
        <MithraTableCell c={columns[i++]}>
            <CurrencyComponent v={row.data.apiValues.p__spend__sum || 0} nDigits={3}/>
        </MithraTableCell>
        <MithraTableCell c={columns[i++]}>
            {row.data.apiValues.s__id__nunique}
        </MithraTableCell>
        <MithraTableCell c={columns[i++]}>
            {row.data.apiValues.p__id__count}
        </MithraTableCell>
        <MithraTableCell c={columns[i++]}>
            {reviewStatus && (reviewStatus.added || reviewStatus.deleted || reviewStatus.renamed) &&
                <Chip label={getSuggestionType(reviewStatus)}
                      className={'chip ' + getSuggestionType(reviewStatus)}
                      style={{width: '100%'}}/>}
        </MithraTableCell>
        <MithraTableCell c={columns[i++]}>
            {reviewStatus && (reviewStatus.added || reviewStatus.deleted || reviewStatus.renamed) &&
                <>
                    <IconButton
                        className={reviewStatus.accepted ? 'accept-reject-button accepted' : 'accept-reject-button'}
                        // onClick={() => handleAccept(row)}
                    >
                        <CheckCircle/>
                    </IconButton>

                    <IconButton
                        className={reviewStatus.rejected ? 'accept-reject-button rejected' : 'accept-reject-button'}
                        // onClick={() => handleReject(row)}
                    >
                        <Cancel/>
                    </IconButton>
                </>}
        </MithraTableCell>
    </TableRow>
}
