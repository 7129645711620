import {TextField, Typography} from '@mui/material';
import React from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../stores";
import {EditorModalComponent} from "./../EditorModalComponent";
import {TAXONOMY_DEMO_DATA} from "./TaxonomyDemoData";
import MUIRichTextEditor from "mui-rte";

export const DemoUpdateCategoryModal: React.FC = observer(() => {
    const onSave = () => taxonomyEditorStore.saveUpdateCategory();
    const {taxonomyEditorStore} = useStores();

    const categoryName = taxonomyEditorStore.updateCategoryName
    const INIT_DEMO_DATA = TAXONOMY_DEMO_DATA[String(categoryName).toLowerCase()]

    const [field1, setField1] = React.useState<string>(INIT_DEMO_DATA?.at(0) || '');
    const [field2, setField2] = React.useState<string>(INIT_DEMO_DATA ? (typeof INIT_DEMO_DATA[1] === 'object' ? JSON.stringify(INIT_DEMO_DATA[1]) : INIT_DEMO_DATA[1]) : '');
    const [field3, setField3] = React.useState<string>(INIT_DEMO_DATA?.at(2) || '');

    return (
        <EditorModalComponent
            titleId={"demo-taxonomy-editor-modal-title"}
            className="update-model-component demo-update-model-component"
            title="Edit category"
            canSave={taxonomyEditorStore.canUpdateCategory}
            onSave={onSave}
            onClose={() => taxonomyEditorStore.setUpdateCategoryMode(false)}
        >
            <TextField
                autoFocus
                fullWidth
                id="demo-taxonomy-editor-modal-name-field"
                label="Name"
                variant="outlined"
                value={taxonomyEditorStore.updateCategoryName}
                onChange={e => taxonomyEditorStore.setUpdateCategoryName(e.target.value)}
                onKeyDown={e => e.key === 'Enter' ? onSave() : undefined}
            />

            <TextField
                fullWidth
                multiline
                id="demo-taxonomy-editor-modal-description-field"
                label="Definition"
                variant="outlined"
                defaultValue={field1}
                // value={taxonomyEditorStore.updateCategoryDescription}
                // onChange={e => taxonomyEditorStore.setUpdateCategoryDescription(e.target.value)}
                // onKeyDown={e => e.key === 'Enter' ? onSave() : undefined}
            />

            <Typography variant="h6" className="form-title">Key requirements</Typography>
            <MUIRichTextEditor
                id="demo-taxonomy-editor-modal-field3"
                label=""
                controls={['title', 'bold', 'italic', 'underline', 'strikethrough', 'link', 'undo', 'redo', 'save']}
                defaultValue={field2}
                onSave={data => {
                    // Copy the saved dict to the TaxonomyDemoData.ts file
                    console.log(data)
                }}
            />

            <TextField
                fullWidth
                multiline
                id="demo-taxonomy-editor-modal-field2"
                label="Regulatory compliance"
                variant="outlined"
                defaultValue={field3}
            />
        </EditorModalComponent>
    );
})
