import "./ContractAnalyticsPage.scss";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../stores";
import {routes} from "../../../routing/routes";
import {KoiFacade} from "../../../components/koi-facade/KoiFacade";
import {Button, Divider, Grid, Typography} from "@mui/material";
import {AutoFixHigh} from "@mui/icons-material";
import React from "react";
import {ContractHeader} from "../components/ContractHeader";
import {ContractHeaderFiltersBar} from "../components/ContractHeaderFiltersBar";
import {ContractAnalyticsTable} from "../table/ContractAnalyticsTable";
import {generatePath, useHistory} from "react-router";
import {CurrencyComponent} from "../../../components/currency-component/CurrencyComponent";
import {ModalMoveTransaction} from "../modals/ModalMoveTransaction";
import {ContractScopeTable} from "../table/ContractScopeTable";


interface SubTableProps {
    h: { key: string; label: string; type: string }[];
    className?: string;
    data?: any[];
    isLoading?: boolean;
    pageSize?: number;
    pageSizeOptions?: number[];
}

type Props = {}
export const ContractAnalyticsPage: React.FC<Props> = observer(() => {
    const {bagStore, contractAnalyticsStore} = useStores();
    const history = useHistory();
    const supplierHierarchyTableCols: { key: string; label: string; type: string }[] = [
        {key: 'parent_supplier', label: 'Parent supplier', type: 'string'},
        {key: 'suppliers_involved', label: '', type: 'string'},
    ]
    const supplierHierarchyTableData = [
        {
            parent_supplier: 'BM Holding',
            suppliers_involved: '2 child suppliers involved',
        }
    ]
    const supplierHierarchySubTableCols: { key: string; label: string; type: string }[] = [
        {key: 'supplier', label: 'Supplier', type: 'string'},
        {key: 'total_spend', label: 'Total spend', type: 'currency'},
        {key: 'contract_spend', label: 'Contract spend', type: 'currency'},
        {key: 'out_contract_spend', label: 'Out contract spend', type: 'currency'},
    ]
    const supplierHierarchySubTableData = [
        {
            supplier: 'Bright motion Ams BV',
            total_spend: 28617000,
            contract_spend: 23400000,
            out_contract_spend: 5200000
        },
        {
            supplier: 'Bright motion Eind BV',
            total_spend: 3800000,
            contract_spend: 0,
            out_contract_spend: 3800000
        },
    ]

    const contractScopeTableCols: { key: string; label: string; type: string }[] = [
        {key: 'parent_supplier', label: 'Parent supplier', type: 'string'},
        {key: 'supplier', label: 'Supplier', type: 'string'},
        {key: 'business_unit', label: 'Business Unit', type: 'string'},
        {key: 'p_count', label: 'Part Number', type: 'string'},
        {key: 'p_description', label: 'Description', type: 'string'},
        {key: 'tot_quantity', label: 'Total Quantity', type: 'string'},
        {key: 'tot_spend', label: 'Total Spend', type: 'currency'},
        {key: 'scope', label: 'Scope', type: 'chip'},
    ]
    const contractScopeTableData = [
        {
            parent_supplier: 'BM Holding',
            supplier: 'Bright motion Amst BV',
            business_unit: 'Rotterdam NL',
            p_count: '2',
            p_description: 'LD4261ST',
            tot_quantity: '683',
            tot_spend: 23000000,
            scope: 'In contract',
        },
        {
            parent_supplier: 'BM Holding',
            supplier: 'Bright motion Amst BV',
            business_unit: 'Maastricht NL',
            p_count: '2',
            p_description: 'JP9621',
            tot_quantity: '151',
            tot_spend: 5200000,
            scope: 'Out of contract',
        },
        {
            parent_supplier: 'BM Holding',
            supplier: 'Bright motion Eind BV',
            business_unit: 'Haarlem NL',
            p_count: '2',
            p_description: 'MK1970',
            tot_quantity: '91',
            tot_spend: 3800000,
            scope: 'Out of contract',
        },
    ]
    const contractScopeSubTableCols: { key: string; label: string; type: string }[] = [
        {key: 'p_date', label: 'Date', type: 'string'},
        {key: 'p_quantity', label: 'Quantity', type: 'number'},
        {key: 'p_unit_of_measure', label: 'Unit of measure', type: 'string'},
        {key: 'p_spend', label: 'Invoice spend', type: 'currency'},
        {key: 'move', label: '', type: 'move'},
    ]
    const contractScopeSubTableData = [
        {
            p_date: '4/1/2023',
            p_quantity: 501,
            p_unit_of_measure: 'Kg',
            p_spend: 16000000,
        },
        {
            p_date: '9/1/2023',
            p_quantity: 181,
            p_unit_of_measure: 'Kg',
            p_spend: 7400000,
        },
    ]
    const contractScopeSubTableData2 = [
        {
            p_date: '4/1/2023',
            p_quantity: 140,
            p_unit_of_measure: 'Kg',
            p_spend: 5200000 * (140 / (140 + 41)),
        },
        {
            p_date: '9/1/2023',
            p_quantity: 41,
            p_unit_of_measure: 'Kg',
            p_spend: 5200000 * (41 / (140 + 41)),
        },
    ];
    const contractScopeSubTableData3 = [
        {
            p_date: '4/1/2023',
            p_quantity: 45,
            p_unit_of_measure: 'Kg',
            p_spend: 3800000 * (45 / (45 + 46)),
        },
        {
            p_date: '9/1/2023',
            p_quantity: 46,
            p_unit_of_measure: 'Kg',
            p_spend: 3800000 * (46 / (45 + 46)),
        },
    ];


    return <KoiFacade
        title={'Contract Analytics'}
        intro={<></>}
        back={
            generatePath(routes.demo_cleanup_analytics_contract_insights, {
                id: bagStore.bagId,
                contract: -1
            })
        }
        next={{
            type: 'component',
            node: <Button
                color="primary"
                variant="outlined"
                startIcon={<AutoFixHigh/>}
                onClick={() => {
                    history.push(generatePath(routes.demo_cleanup_analytics_contract_opportunities, {
                        id: bagStore.bagId,
                        contract: -1
                    }));
                }}>
                GENERATE OPPORTUNITIES
            </Button>
        }}
        className="contract-analytics-page">

        <ModalMoveTransaction isOpen={contractAnalyticsStore.isMoveTransactionsModalOpen}/>
        <ContractHeader/>

        <ContractHeaderFiltersBar/>

        <Typography variant={'h5'} style={{marginBottom: '1em'}} className={'advanced-dashboard-title'}>
            Supplier Hierarchy Analytics
        </Typography>
        <ContractAnalyticsTable className={'supplier-hierarchy-table'} h={supplierHierarchyTableCols}
                                data={supplierHierarchyTableData}
                                subTable={(props: SubTableProps) => (
                                    <ContractAnalyticsTable
                                        className={'supplier-hierarchy-subtable'}
                                        h={supplierHierarchySubTableCols}
                                        data={supplierHierarchySubTableData}
                                        compactNumbers={true}
                                    />
                                )}
        />

        <Typography variant={'h5'} style={{marginTop: '4em', marginBottom: '2em'}}
                    className={'advanced-dashboard-title'}>Contract Scope Analytics</Typography>
        <Grid className="table-header-kpis" container alignItems="center" justifyContent="center">
            <Grid item xs={5}>
                <RowGrid title="Total spend (In contract)" number="23400000"/>
            </Grid>
            <Divider orientation="vertical" flexItem/>
            <Grid item xs={5}>
                <RowGrid title="Total spend (Out contract)" number="9000000"/>
            </Grid>
        </Grid>
        {/*<ContractAnalyticsTable className={'contract-scope-table'} h={contractScopeTableCols}*/}
        {/*                        data={contractScopeTableData}*/}
        {/*                        compactNumbers={true}*/}
        {/*                        subTable={(props: SubTableProps) => (*/}
        {/*                            <ContractAnalyticsTable*/}
        {/*                                className={'contract-scope-subtable'}*/}
        {/*                                h={contractScopeSubTableCols}*/}
        {/*                                data={contractScopeSubTableData}*/}
        {/*                                compactNumbers={true}*/}
        {/*                            />*/}
        {/*                        )}*/}
        {/*/>*/}


        <ContractScopeTable className={'contract-scope-table'} h={contractScopeTableCols}
                            data={contractScopeTableData}
                            compactNumbers={true}
                            subTable={(props: SubTableProps) => (
                                <ContractScopeTable
                                    className={'contract-scope-subtable'}
                                    h={contractScopeSubTableCols}
                                    data={contractScopeSubTableData}
                                    compactNumbers={true}
                                />
                            )}
        />

    </KoiFacade>
});


const RowGrid = ({title, number}) =>
    <Grid item className={`dashboard-row`}>
        <Grid container direction="column" alignItems="center">
            <Grid item className="row-title">
                <Typography variant="h6" component="h6">
                    {title}
                </Typography>
            </Grid>
            <Grid item className="row-subtitle">
                <Typography variant="body1" component="p">
                    <CurrencyComponent v={number} nDigits={3}/>
                </Typography>
            </Grid>
        </Grid>
    </Grid>
