import {action, makeObservable, observable} from "mobx";
import objectHash from "object-hash";
import {finalize, forkJoin, map, Observable, Subject, switchMap} from "rxjs";
import {range as d3Range} from 'd3-array';

const hashChunkSize = 10_000;

export class LoadingDataWrapper<D> {
    public loading = false;

    public keys: string[] = [] // TODO: figure out why this is used

    constructor(
        public elements: D[] = []
    ) {
        makeObservable(this, {
            elements: observable.shallow,
            keys: observable.shallow,
            loading: observable,
            updateData: action,
            setLoading: action,
        })
    }

    updateData(elements: D[]) {
        this.elements = elements;
    }

    setLoading(loading: boolean) {
        this.loading = loading;
    }
}

export class HashedLoadingDataWrapper<D> {
    public hash = ''
    readonly d: LoadingDataWrapper<D>;
    readonly hashingPipe = new Subject<D[]>();

    constructor(elements: D[] = []) {
        this.d = new LoadingDataWrapper<D>(elements);
        makeObservable(this, {
            hash: observable,
        })
        this.hashingPipe
            .pipe(switchMap(elements => {
                this.setLoading(true);
                // Split async work into chunks and execute them in separate tasks
                const chunks = Math.ceil(elements.length / hashChunkSize);

                // console.time('HashedLoadingDataWrapper.hashingPipe');
                // console.log('HashedLoadingDataWrapper.hashingPipe', elements.length, chunks);

                return forkJoin(d3Range(chunks).map((_, i) => {
                    const dataChunk = elements.slice(i * hashChunkSize, (i + 1) * hashChunkSize);
                    return new Observable(o => {
                        const t = setTimeout(() => {
                            const stringObjects = dataChunk.map(
                                d => Object.entries(d as any).reduce((keyVal, acc) => acc + String(keyVal[1]), '')
                            );
                            const singleString = stringObjects.reduce((a, b) => a + b, '');
                            const newHash = objectHash(singleString);
                            o.next(newHash);
                            o.complete();
                        }, 0)
                        return () => clearTimeout(t);
                    })
                })).pipe(
                    map(hashes => objectHash(hashes)),
                    finalize(() => {
                        // console.timeEnd('HashedLoadingDataWrapper.hashingPipe');
                        // this.setLoading(false);
                    }),
                );
            }))
            .subscribe(hash => this.updateHash(hash))
    }

    get elements() {
        return this.d.elements;
    }

    updateData(elements: D[]) {
        this.d.setLoading(true);
        this.d.updateData(elements);
        this.hashingPipe.next(elements);
    }

    updateHash(hash: string) {
        this.hash = hash;
    }

    setLoading(loading: boolean) {
        this.d.setLoading(loading);
    }

}