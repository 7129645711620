import './CategorizationReviewPage.scss';
import React from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../stores";
import Typography from "@mui/material/Typography";
import {Alert, Button, CircularProgress, Grid} from "@mui/material";
import {CurrencyComponent} from "../../components/currency-component/CurrencyComponent";
import {SingleBarChart} from "../../components/visualization/single-barchart/SingleBarChart";
import {NO_MARGIN} from "../../utils/margin";
import {UNCATEGORIZED_VALUE} from "../../constants";
import {CurrencyAbbreviationContext} from '../../components/currency-component/CurrencyAbbreviationContext';
import {ReviewBreadcrumbs} from "./components/review-breadcrumbs/ReviewBreadcrumbs";
import {
    SingularCategorizationReviewTable
} from "./components/review-table/singular-review-table/SingularCategorizationReviewTable";
import {
    GroupedCategorizationReviewMainTable
} from "./components/review-table/grouped-review-table/GroupedCategorizationReviewMainTable";
import {ReviewFilterBar} from "./components/review-filter/ReviewFilterBar";
import {environment} from "../../env";
import {ChevronRight} from "@mui/icons-material";
import {generatePath, useHistory} from "react-router";
import {CmpDataBagRouteMatch, routes} from "../../routing/routes";
import {useRouteMatch} from "react-router-dom";
import {CategorizationDetailModal} from "./CategorizationDetailModal";
import {
    AllSingularSelectionAlert
} from "./components/review-table/grouped-review-table/alerts/AllSingularSelectionAlert";
import {AllGroupedSelectionAlert} from "./components/review-table/grouped-review-table/alerts/AllGroupedSelectionAlert";
import {TaxonomySelectionAlert} from "./components/review-table/grouped-review-table/alerts/TaxonomySelectionAlert";
import {ApprovalProcessingAlert} from "./components/review-table/grouped-review-table/alerts/ApprovalProcessingAlert";

export const CategorizationReviewPage: React.FC = observer(() => {
    const {p, categorizationReviewStore, approvalStore} = useStores();
    const history = useHistory();
    const routeMatch = useRouteMatch<CmpDataBagRouteMatch>();
    const reviewPageController = categorizationReviewStore.reviewPageController;
    const reviewDataController = categorizationReviewStore.reviewPageController.reviewPageDataController;
    const reviewPageFilterController = categorizationReviewStore.reviewPageController.reviewPageFilterController;
    const reviewPageSelectionController = categorizationReviewStore.reviewPageController.reviewPageSelectionController;

    const parentCharts = categorizationReviewStore.reviewPageController.parentCharts;
    const selectionCharts = categorizationReviewStore.reviewPageController.selectionCharts;
    const keyValues = categorizationReviewStore.reviewPageController.reviewPageStatisticsController.summaryResultKeyValues;

    const showTable = Boolean(categorizationReviewStore.reviewPageController.reviewPageDataController.supplierPages); //FIXME: that this needs updating to scale to group by similar parts
    const [isExtended, setIsExtended] = React.useState<boolean>(false);

    const dataMax = Math.max(selectionCharts?.max || 1, parentCharts?.max || 1);

    const reviewPageIsLoading: boolean = categorizationReviewStore.reviewPageController.reviewPageIsLoading;
    if (reviewPageIsLoading) {
        return <Grid container justifyContent="center" style={{marginTop: '7em'}}>
            <Grid item>
                <CircularProgress size={24}/>
            </Grid>
        </Grid>
    }

    return <div className="categorization-review-page">

        {environment.isTestReviewPage && <pre>
            selectedFilter={JSON.stringify(categorizationReviewStore.reviewPageController.reviewPageFilterController.selectedFilter)}<br/>
            isReviewLevelStatisticsEmpty={'' + categorizationReviewStore.reviewPageController.reviewPageStatisticsController.isReviewLevelStatisticsEmpty}<br/>
            reviewStatistics={JSON.stringify(categorizationReviewStore.reviewPageController.reviewPageStatisticsController.reviewStatistics)}<br/>
            reviewLevelStatistics={JSON.stringify(categorizationReviewStore.reviewPageController.reviewPageStatisticsController.reviewLevelStatistics)}<br/>
            selectedLevel={JSON.stringify(categorizationReviewStore.reviewPageController.reviewPageFilterController.selectedLevel)}<br/>
            selectedCategory={JSON.stringify(categorizationReviewStore.reviewPageController.reviewPageFilterController.selectedCategory)}<br/>
            keyValues={JSON.stringify(keyValues)}<br/>
        </pre>}

        <CategorizationDetailModal isOpen={false
        } setOpen={() => {
        }}></CategorizationDetailModal>
        <Grid container justifyContent="center">
            <Grid className=""
                  item xs={6}
                  container>
                <ReviewBreadcrumbs/>
            </Grid>
        </Grid>


        <Grid container justifyContent="center">
            {/* 3-6-3 */}
            <Grid className="key-value-container narrow-margin text-center font-bigger"
                  item xs={6}
                  container justifyContent="space-between">
                <Grid item>
                    <Typography variant="caption">
                        Categorized
                    </Typography>
                    <Typography variant="h5" className="font-weight-bold key-value">
                        {keyValues
                            ? <CurrencyComponent
                                v={keyValues.after.classified_spend}
                                nDigits={3}
                                abbreviation={keyValues.abbreviation}
                                hideAlmostZero={true}
                            />
                            : <>&nbsp;</>}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="caption">
                        Re-categorized
                    </Typography>
                    <Typography variant="h5" className="font-weight-bold key-value">
                        {keyValues
                            ? <CurrencyComponent
                                v={keyValues.after.reclassified_spend}
                                nDigits={3}
                                abbreviation={keyValues.abbreviation}
                                hideAlmostZero={true}
                            />
                            : <>&nbsp;</>}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="caption">
                        Uncategorized
                    </Typography>
                    <Typography variant="h5" className="font-weight-bold key-value value-warning">
                        {keyValues
                            ? <CurrencyComponent
                                v={keyValues.after.unclassified_spend}
                                nDigits={3}
                                abbreviation={keyValues.abbreviation}
                                hideAlmostZero={true}
                            />
                            : <>&nbsp;</>}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>

        {/*{categorizationReviewStore.data.parentCharts &&*/}
        {/*    <Grid container>*/}
        {/*        <Grid item xs={3}>*/}
        {/*            <Button*/}
        {/*                className="nav-all-cats-btn"*/}
        {/*                color="primary"*/}
        {/*                variant="text"*/}
        {/*                startIcon={<ChevronLeft/>}*/}
        {/*                onClick={() => categorizationReviewStore.data.unsetSelectedCat()}>*/}
        {/*                All categories*/}
        {/*            </Button>*/}
        {/*        </Grid>*/}
        {/*        <Grid item xs={9} className="detail-bar-chart">*/}
        {/*            {categorizationReviewStore.data.parentCharts.charts.map(({category, data}, index) =>*/}
        {/*                <div key={data.mainLabel + index}*/}
        {/*                     className={*/}
        {/*                         `bar-chart-container l${index + 1}` +*/}
        {/*                         (categorizationReviewStore.data.canClickParentChart(index) ? ' clickable' : '')*/}
        {/*                     }*/}
        {/*                     onClick={() => categorizationReviewStore.data.clickParentChart(index)}>*/}
        {/*                    <SingleBarChart*/}
        {/*                        data={data}*/}
        {/*                        className={category === UNCATEGORIZED_VALUE ? 'uncat' : ''}*/}
        {/*                        options={{width: 1300, height: 45, margin: NO_MARGIN, dataMax, labelHover: true}}*/}
        {/*                    />*/}
        {/*                </div>*/}
        {/*            )}*/}
        {/*        </Grid>*/}
        {/*    </Grid>*/}
        {/*}*/}

        {selectionCharts &&
            <Grid container justifyContent="center">
                <Grid item xs={3}/>
                <Grid item xs={9} className={'overview-bar-charts'}>
                    {/* The bars of the bar chart */}
                    {selectionCharts.data.slice(0, isExtended ? undefined : 3).map(changeChartData => (
                        <div key={changeChartData.category}
                             className={`clickable bar-chart-container l${reviewPageFilterController.selectedLevel + 1}`}
                             onClick={() => reviewPageFilterController.selectNextCategoryDown(changeChartData.category)}>
                            <SingleBarChart
                                key={changeChartData.category}
                                data={{
                                    mainLabel: changeChartData.label,
                                    values: changeChartData.values,
                                }}
                                className={changeChartData.category === UNCATEGORIZED_VALUE ? 'uncat' : ''}
                                options={{
                                    width: 1300,
                                    height: 45,
                                    margin: NO_MARGIN,
                                    dataMax,
                                    labelHover: true
                                }}
                            />
                        </div>
                    ))}
                </Grid>


                <Grid xs={12} item className={'text-center'} style={{margin: '1em', textDecoration: 'underline'}}
                      alignItems={'center'}>
                    <Typography variant="h6" className={'clickable'} onClick={() => setIsExtended(!isExtended)}>
                        {isExtended ? 'Show less' : 'Show more'}
                    </Typography>

                    {p.p.disableReviewStatisticsPage && <Button
                        color="primary"
                        variant="outlined"
                        className="toolbar-btn large-icon-btn"
                        style={{margin: '1em'}}
                        startIcon={<ChevronRight/>}
                        onClick={() => {
                            if (environment.package === 'merge_x') {
                                history.push(generatePath(routes.merge_x_cleanup_categorization_statistics, routeMatch.params));
                            } else if (environment.package === 'cleanup') {
                                history.push(generatePath(routes.categorization_statistics, routeMatch.params));
                            } else {
                                console.error('Missing categorization_statistics route for package', environment.package)
                            }
                        }}>
                        See statistics
                    </Button>}
                </Grid>
            </Grid>
        }
        {showTable &&
            <>
                <ReviewFilterBar/>

                {reviewDataController.error &&
                    <Grid container className="review-table-head-alerts-container" justifyContent="center" spacing={3}>
                        <Grid item>
                            <Alert severity="error">
                                {reviewDataController.error}
                            </Alert>
                        </Grid>
                    </Grid>
                }

                <Grid container className="review-table-head-alerts-container" justifyContent="center" spacing={3}>
                    <Grid item>
                        {reviewPageSelectionController.isAnySelected
                            ? (
                                reviewPageController.singleMode ? <AllSingularSelectionAlert/> :
                                    <AllGroupedSelectionAlert/>
                            )
                            : approvalStore.isCategorizationApprovalBusy
                                ? <ApprovalProcessingAlert/>
                                : <TaxonomySelectionAlert/>
                        }
                    </Grid>
                </Grid>
                <Grid container justifyContent="center">
                    <Grid item xs={12}>
                        {/*<Typography variant="h5">Not changed</Typography>*/}
                        {keyValues?.abbreviation
                            && <CurrencyAbbreviationContext.Provider value={keyValues.abbreviation}>
                                {/*
                                * The width of the table is set to the width of the window minus the padding (24px each side)
                                * which is applied at main-component-container, but since the page contains a scrollbar, 
                                * we can't totally rely on 100vw but using window.innerWidth has its own weaknesses.
                                * 99vw here is an estimation!
                                */}
                                <div style={{'width': 'calc(99vw - 48px)'}}>
                                    {reviewPageController.singleMode
                                        ? <SingularCategorizationReviewTable/>
                                        : <GroupedCategorizationReviewMainTable/>}
                                </div>
                            </CurrencyAbbreviationContext.Provider>
                        }
                    </Grid>
                </Grid>
            </>
        }
    </div>
});
