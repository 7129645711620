import {Button, Dialog, Grid, IconButton, PaperProps, Typography} from "@mui/material";
import {Close} from "@mui/icons-material";

export const GenericModalVertical: React.FC<{
    className?: string
    isOpen: boolean,
    paperProps?: Partial<PaperProps>,
    onSave: () => void
    saveDisabled?: boolean
    saveTitle?: string,
    onCancel: () => void
    cancelDisabled?: boolean
    cancelTitle?: string,
    title: string
    titleId?: string
    isLoading?: boolean
}> = ({
          className,
          isOpen,
          onSave,
          saveDisabled,
          saveTitle,
          onCancel,
          cancelDisabled,
          cancelTitle,
          title,
          titleId,
          isLoading,
          paperProps,
          children,
      }) => {
    className = 'edit-model-component ' + (className ? ' ' + className : ''); //TODO: edit-model-component is needed to center it but needs to be made more generic, find the css to do as such
    titleId = titleId || 'generic-modal-vertical';


    return (
        // <Dialog
        //     open={isOpen}
        //     onClose={onCancel}
        //     aria-labelledby={titleId}
        //     maxWidth="sm"
        //     PaperProps={paperProps as any}
        // >
        //     <Grid container className={className}  justifyContent="flex-start" alignItems="stretch">
        //         <Grid item xs={10} container direction="column" justifyContent="flex-start" alignItems="stretch"
        //               spacing={3}>
        //             <Grid container item>
        //                 <Grid item xs={11}>
        //                     <Typography id={titleId || ''} variant="h5">
        //                         {title}
        //                     </Typography>
        //                 </Grid>
        //                 <Grid item xs={1}>
        //                     <IconButton onClick={onCancel}>
        //                         <Close/>
        //                     </IconButton>
        //                 </Grid>
        //             </Grid>
        //         </Grid>
        //
        //         {children}
        //
        //         <Grid container item xs={10} spacing={2} justifyContent="flex-end" style={{marginTop: '2em'}}>
        //             <Grid item xs={2}>
        //                 <Button
        //                     fullWidth
        //                     disabled={saveDisabled || isLoading}
        //                     onClick={onSave}
        //                     color="primary"
        //                     variant="contained">
        //                     Done
        //                 </Button>
        //             </Grid>
        //         </Grid>
        //     </Grid>
        // </Dialog>

        <Dialog
            open={isOpen}
            onClose={onCancel}
            aria-labelledby={titleId}
            maxWidth="sm"
            PaperProps={paperProps as any}
        >
            <Grid container className={className}>
                <Grid item xs={10} container direction="column" justifyContent="flex-start" alignItems="stretch"
                      spacing={3}>
                    <Grid container item>
                        <Grid item xs={11}>
                            <Typography id={titleId || ''} variant="h5">
                                {title}
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <IconButton onClick={onCancel}>
                                <Close/>
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>

                {children}

                <Grid container item xs={10} spacing={2} justifyContent="flex-end" style={{marginTop: '2em'}}>
                    <Grid item xs={2}>
                        <Button
                            fullWidth
                            disabled={saveDisabled || isLoading}
                            onClick={onSave}
                            color="primary"
                            variant="contained"
                        >
                            Done
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Dialog>
    );
}