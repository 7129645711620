import './PartReCategorizationComponent.scss';
import React from "react";
import {ExpandMore} from "@mui/icons-material";
import {ButtonBase, Chip} from "@mui/material";
import {UNCATEGORIZED_LABEL, UNCATEGORIZED_VALUE} from "../../constants";

type Props = {
    cats: string[] | false
    handleOpenSearch: (event: React.MouseEvent<HTMLButtonElement>) => void
    selectedL1Category: string | undefined
}
export const PartReCategorizationComponent: React.FC<Props> = ({cats, handleOpenSearch, selectedL1Category}) =>
    <div className="part-recat-component">
        {(cats !== false && cats.length === 0)
            ? <ButtonBase onClick={handleOpenSearch}>
                <Chip
                    variant="outlined"
                    color="primary"
                    size="small"
                    deleteIcon={<ExpandMore/>}
                    onDelete={handleOpenSearch}
                    label="Change all categories for supplier"/>
            </ButtonBase>
            : <ButtonBase onClick={handleOpenSearch}>
                {cats === false
                    ? <>{UNCATEGORIZED_LABEL}</>
                    : cats.map((c, i) => {
                        let categoryLabel = c === UNCATEGORIZED_VALUE ? UNCATEGORIZED_LABEL : c;
                        return <React.Fragment key={i}>
                            <Chip
                                variant="outlined"
                                color={selectedL1Category === c ? "primary" : "default"}
                                size="small"
                                deleteIcon={i === cats.length - 1 ? <ExpandMore/> : undefined}
                                onDelete={i === cats.length - 1 ? handleOpenSearch : undefined}
                                label={categoryLabel}/>
                            {(i < cats.length - 1) ? ' > ' : ''}
                        </React.Fragment>
                    })
                }
            </ButtonBase>
        }
    </div>
