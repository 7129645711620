/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {useHistory, useLocation} from "react-router";
import {JobRouteMatch, MithraHistoryState} from "../../routing/routes";
import {useRouteMatch} from "react-router-dom";
import {useStores} from "../../stores";
import {RouteWrapperProps} from "./RouteWrapperProps";
import {reaction} from "mobx";
import {SingleDatabagWrapped} from "./base/SingleDatabagWrapped";

/**
 * Wrapper for the merge2 product offering
 */
export const Merge2EnvWrapper: React.FC<RouteWrapperProps> = observer(({children, state}) => {
    const {
        rootAppStore,
        bagStore,
        bagLoadingStore,
        supplierNormalizationStore,
        masterSupplierNormalizationStore,
        taxonomyManagerStore,
        taxonomySuggestorStore,
        p
    } = useStores();
    const location = useLocation<MithraHistoryState>();
    const match = useRouteMatch<JobRouteMatch>();
    const history = useHistory();
    const isNoData = state.dataType === 'no_data';

    // TODO: See if this can be optimized, probably by the usage of react Context
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // Beware: This can cause the page to be rendered with the previous state, we have to be resilient against this
    useEffect(() => rootAppStore.setApp(state), [])

    // The magic that loads the data
    useEffect(() => {
        taxonomySuggestorStore.setSelectedNodeLabel(undefined)
        taxonomySuggestorStore.setSelectedNodeValues(undefined)
        if (!p.p.hardcodeMasterBagId) throw new Error('No hardcoded hardcodeMasterBagId provided');
        if (!p.p.hardcodedCombinedBagId) throw new Error('No hardcodedCombinedBagId provided');

        masterSupplierNormalizationStore.init(p.p.hardcodedCombinedBagId, 4) // Only used for the card titles

        // noinspection JSUnusedLocalSymbols
        const disposer = reaction(
            () => [bagStore.bagId, rootAppStore.app.dashboardEnvironment, bagStore.bag?.taxonomy_size] as const,
            ([bagId, dashboardEnvironment, taxonomySize]) => {
                /**
                 * @deprecated TODO: architecture for pre-loading of data is required, this is too hacky
                 */
                if (!bagId || !p.p.hardcodedCombinedBagId) return;

                if (p.p.hackNSuppliersFromCommonSuppliers && bagId && taxonomySize)
                    supplierNormalizationStore.init(bagId, taxonomySize)

                if (p.p.hardcodeMasterBagId && bagId && bagId === p.p.hardcodeMasterBagId) supplierNormalizationStore.isMaster = true;
            })

        if (!isNoData) {
            bagLoadingStore.initBag(location, history, match)

            // Overwrite the taxonomy for the categorization store
            // Each Taxonomy Editor uses loads in its own taxonomy
            taxonomyManagerStore.setDesiredTaxonomyId(p.p.masterTaxonomyId);

            // bagLoadingStore.loadBagWithoutLoadingStores(p.p.hardcodedSubsidiaryBagId).then(bag => {
            //     console.log(`Wrapper: Bag ${p.p.hardcodedBagId} loaded`);
            //     cmpStore.setCompareBag(bag);
            // });
        }
        return () => disposer();
    }, [])

    return <SingleDatabagWrapped state={state}>{children}</SingleDatabagWrapped>;
});
