import React from "react";
import {BarDataPoint} from "../../../../components/visualization/BarChart";
import {BarChartV2, Options,} from "../../../../components/visualization/bar-chart/BarChart2";

const defaultOptions: Partial<Options> = {
    vertical: false,
    width: 900,
    height: 'auto',
    redLine: 0.8,
    labelMargin: 300, // Of the total width
    valueLabelPxH: 26,
    valueAxisPercentage: true,
    hideAxis: true,
    padding: 'auto',
    barPadding: 0,
    redLineText1: '80%',
    redLineText2: '(of total spend with top 20% suppliers)',
};

type Props = {
    data?: BarDataPoint[];
    setSelected?: (d: BarDataPoint) => void;
    options?: Partial<Options>;
};

// TODO: Rename this one
export const ParetoOverviewVisualization: React.FC<Props> = ({
                                                                 data,
                                                                 setSelected,
                                                                 options,
                                                             }) => {
    const mergedOptions: Partial<Options> = options ?? defaultOptions;
    if (setSelected) {
        mergedOptions.onClicked = setSelected;
    }

    return (
        <section className="pareto-overview-visualization">
            {data && <BarChartV2 data={data} options={mergedOptions}/>}
        </section>
    );
};
