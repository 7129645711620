export const TAXONOMY_DEMO_DATA: { [key: string]: [string, any, string] } = {
    'pouches': [
        'Flexible packaging typically made of plastic films, foils, or a combination thereof, designed to hold sterile medical devices',
        // @formatter:off
        {"blocks":[{"key":"a8hmo","text":"Material: High-density polyethylene (HDPE), Tyvek®, multi-layer laminates.","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":10,"style":"BOLD"}],"entityRanges":[],"data":{}},{"key":"pa28","text":"Thickness: Typically 50-150 microns, depending on the application.","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":10,"style":"BOLD"}],"entityRanges":[],"data":{}},{"key":"9qvgj","text":"Seal Strength: Minimum seal strength of 1.5 N/15mm for peelable seals.","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":14,"style":"BOLD"}],"entityRanges":[],"data":{}},{"key":"49ttp","text":"Sterilization Compatibility: Suitable for ethylene oxide (EtO), gamma radiation, and autoclave sterilization.","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":28,"style":"BOLD"}],"entityRanges":[],"data":{}},{"key":"34aqr","text":"Barrier Properties: Water vapor transmission rate (WVTR) < 0.1 g/m²/day, oxygen  transmission rate (OTR) < 0.5 cc/m²/day.","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":19,"style":"BOLD"}],"entityRanges":[],"data":{}},{"key":"1gos","text":"Printing: Up to 6-color printing for branding and instructions.","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":9,"style":"BOLD"}],"entityRanges":[],"data":{}}],"entityMap":{}},
        // @formatter:on
        'ISO 11607, FDA, and EU MDR compliance',
    ],
    'blisters': [
        'Rigid or semi-rigid packaging typically made of plastics or a combination of plastic and foil, forming cavities to protect individual medical devices',
        // @formatter:off
        {"blocks":[{"key":"a8hmo","text":"Material: Polyvinyl chloride (PVC), Polyethylene terephthalate (PET), or aluminum foil.","type":"unordered-list-item","depth":0,"inlineStyleRanges":[{"offset":0,"length":9,"style":"BOLD"}],"entityRanges":[],"data":{}},{"key":"samv","text":"Thickness: 200-500 microns.","type":"unordered-list-item","depth":1,"inlineStyleRanges":[{"offset":0,"length":10,"style":"BOLD"}],"entityRanges":[],"data":{}},{"key":"cvbfn","text":"Cavity Design: Customizable cavity dimensions to fit specific devices.","type":"unordered-list-item","depth":1,"inlineStyleRanges":[{"offset":0,"length":14,"style":"BOLD"}],"entityRanges":[],"data":{}},{"key":"akkaa","text":"Sealant Layer: Adhesive or heat-seal coating compatible with lidding material.","type":"unordered-list-item","depth":1,"inlineStyleRanges":[{"offset":0,"length":14,"style":"BOLD"}],"entityRanges":[],"data":{}},{"key":"9o7th","text":"Sterilization Compatibility: Must withstand EtO, gamma radiation, and autoclave sterilization.","type":"unordered-list-item","depth":1,"inlineStyleRanges":[{"offset":0,"length":28,"style":"BOLD"}],"entityRanges":[],"data":{}},{"key":"b1773","text":"Barrier Properties: WVTR < 0.1 g/m²/day, OTR < 0.5 cc/m²/day.","type":"unordered-list-item","depth":1,"inlineStyleRanges":[{"offset":0,"length":19,"style":"BOLD"}],"entityRanges":[],"data":{}}],"entityMap":{}},
        // @formatter:on
        'ISO 11607, FDA, and EU MDR compliance',
    ],
    'corrugate': [
        'Corrugated fiberboard used for creating shipping and storage boxes, providing structural strength and cushioning',
        // @formatter:off
        {"blocks":[{"key":"a8hmo","text":"Material: Single wall (E, B, or C flute) or double wall (BC or EB flute) corrugated cardboard.","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":10,"style":"BOLD"}],"entityRanges":[],"data":{}},{"key":"4mnrv","text":"Burst Strength: Minimum 200 psi.","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":15,"style":"BOLD"}],"entityRanges":[],"data":{}},{"key":"1pg5h","text":"Edge Crush Test (ECT): Minimum 32 ECT for single wall, 48 ECT for double wall.","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":22,"style":"BOLD"}],"entityRanges":[],"data":{}},{"key":"89gnp","text":"Dimensions: Customizable to fit specific packaging needs.","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":11,"style":"BOLD"}],"entityRanges":[],"data":{}},{"key":"em570","text":"Printability: High-quality flexographic or digital printing.","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":13,"style":"BOLD"}],"entityRanges":[],"data":{}},{"key":"4675e","text":"Recyclability: Made from 100% recyclable materials.","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":14,"style":"BOLD"}],"entityRanges":[],"data":{}}],"entityMap":{}},
        // @formatter:on
        'Compliance with ISTA standards for shipping',
    ],
    'carton': [
        'Folding cartons made of paperboard, used for secondary packaging of medical devices',
        // @formatter:off
        {"blocks":[{"key":"a8hmo","text":"Material: Solid Bleached Sulfate (SBS) paperboard, 16-24 pt thickness.","type":"unordered-list-item","depth":0,"inlineStyleRanges":[{"offset":0,"length":9,"style":"BOLD"}],"entityRanges":[],"data":{}},{"key":"fk5h4","text":"Coating: UV or aqueous coating for durability.","type":"unordered-list-item","depth":1,"inlineStyleRanges":[{"offset":0,"length":8,"style":"BOLD"}],"entityRanges":[],"data":{}},{"key":"6u4i9","text":"Printing: High-resolution offset printing, up to 6 colors.","type":"unordered-list-item","depth":1,"inlineStyleRanges":[{"offset":0,"length":9,"style":"BOLD"}],"entityRanges":[],"data":{}},{"key":"2m431","text":"Structural Integrity: Must pass drop and compression tests.","type":"unordered-list-item","depth":1,"inlineStyleRanges":[{"offset":0,"length":21,"style":"BOLD"}],"entityRanges":[],"data":{}},{"key":"b58rf","text":"Sustainability: FSC-certified or made from recycled content.","type":"unordered-list-item","depth":1,"inlineStyleRanges":[{"offset":0,"length":15,"style":"BOLD"}],"entityRanges":[],"data":{}}],"entityMap":{}},
        // @formatter:on
        'ISO 11607, FDA, and EU MDR compliance',
    ],
    'labels': [
        'Adhesive labels used on packaging to provide essential information about the medical device',
        // @formatter:off
        {"blocks":[{"key":"a8hmo","text":"Material: White BOPP (biaxially-oriented polypropylene) or polyester film.","type":"unordered-list-item","depth":0,"inlineStyleRanges":[{"offset":0,"length":9,"style":"BOLD"}],"entityRanges":[],"data":{}},{"key":"7dmls","text":"Adhesive: Permanent medical-grade adhesive, suitable for various surfaces.","type":"unordered-list-item","depth":1,"inlineStyleRanges":[{"offset":0,"length":9,"style":"BOLD"}],"entityRanges":[],"data":{}},{"key":"6thkg","text":"Dimensions: Customizable size based on packaging needs.","type":"unordered-list-item","depth":1,"inlineStyleRanges":[{"offset":0,"length":11,"style":"BOLD"}],"entityRanges":[],"data":{}},{"key":"flldh","text":"Print Quality: Minimum 600 dpi resolution, thermal transfer or laser printing.","type":"unordered-list-item","depth":1,"inlineStyleRanges":[{"offset":0,"length":14,"style":"BOLD"}],"entityRanges":[],"data":{}},{"key":"3ser5","text":"Durability: Resistant to moisture, chemicals, and sterilization processes.","type":"unordered-list-item","depth":1,"inlineStyleRanges":[{"offset":0,"length":11,"style":"BOLD"}],"entityRanges":[],"data":{}}],"entityMap":{}},
        // @formatter:on
        'ISO 15223-1, FDA, and EU MDR compliance',
    ],
    'instruction for use paper': [
        'Printed documents included in packaging to provide detailed instructions on the use of the medical device',
        // @formatter:off
        {"blocks":[{"key":"a8hmo","text":"Material: 60-80 gsm medical-grade paper.","type":"unordered-list-item","depth":0,"inlineStyleRanges":[{"offset":0,"length":9,"style":"BOLD"}],"entityRanges":[],"data":{}},{"key":"58gsk","text":"Print Quality: High-resolution printing with smudge-resistant ink.","type":"unordered-list-item","depth":1,"inlineStyleRanges":[{"offset":0,"length":14,"style":"BOLD"}],"entityRanges":[],"data":{}},{"key":"3j846","text":"Content: Clear, concise, and comprehensive instructions with diagrams.","type":"unordered-list-item","depth":1,"inlineStyleRanges":[{"offset":0,"length":8,"style":"BOLD"}],"entityRanges":[],"data":{}},{"key":"9rs5j","text":"Fold Type: Z-fold, tri-fold, or booklet format.","type":"unordered-list-item","depth":1,"inlineStyleRanges":[{"offset":0,"length":10,"style":"BOLD"}],"entityRanges":[],"data":{}},{"key":"cf7os","text":"Sterilization Compatibility: Must withstand EtO and gamma radiation without ink bleed or paper degradation.","type":"unordered-list-item","depth":1,"inlineStyleRanges":[{"offset":0,"length":28,"style":"BOLD"}],"entityRanges":[],"data":{}}],"entityMap":{}},
        // @formatter:on
        'ISO 15223-1, FDA, and EU MDR compliance',
    ],
    'materials': [
        'Raw materials used in the manufacturing of packaging components for medical devices',
        // @formatter:off
        {"blocks":[{"key":"a8hmo","text":"Material Types: Medical-grade plastics (HDPE, PET, PP), metals, paperboard.","type":"unordered-list-item","depth":0,"inlineStyleRanges":[{"offset":0,"length":15,"style":"BOLD"}],"entityRanges":[],"data":{}},{"key":"1u3mi","text":"Purity: Free from contaminants, certified by material safety data sheets (MSDS).","type":"unordered-list-item","depth":1,"inlineStyleRanges":[{"offset":0,"length":7,"style":"BOLD"}],"entityRanges":[],"data":{}},{"key":"7pq9u","text":"Certifications: RoHS, REACH, and USP Class VI compliance.","type":"unordered-list-item","depth":1,"inlineStyleRanges":[{"offset":0,"length":15,"style":"BOLD"}],"entityRanges":[],"data":{}},{"key":"1rt2q","text":"Traceability: Batch-level traceability with full documentation.","type":"unordered-list-item","depth":1,"inlineStyleRanges":[{"offset":0,"length":13,"style":"BOLD"}],"entityRanges":[],"data":{}}],"entityMap":{}},
        // @formatter:on
        'ISO 11607, FDA, and EU MDR compliance',
    ],
    'foam': [
        'Foam materials used for cushioning and protecting medical devices within packaging',
        // @formatter:off
        {"blocks":[{"key":"a8hmo","text":"Equipment Types: Packaging machinery (sealers, printers, thermoformers), cleanroom       facilities.","type":"unordered-list-item","depth":0,"inlineStyleRanges":[{"offset":0,"length":16,"style":"BOLD"}],"entityRanges":[],"data":{}},{"key":"aqadi","text":"Compliance: Must comply with ISO 14644 cleanroom standards.","type":"unordered-list-item","depth":1,"inlineStyleRanges":[{"offset":0,"length":11,"style":"BOLD"}],"entityRanges":[],"data":{}},{"key":"a8h5f","text":"Efficiency: High throughput, automated or semi-automated systems.","type":"unordered-list-item","depth":1,"inlineStyleRanges":[{"offset":0,"length":11,"style":"BOLD"}],"entityRanges":[],"data":{}},{"key":"1ec08","text":"Scalability: Modular systems that can be expanded as needed.","type":"unordered-list-item","depth":1,"inlineStyleRanges":[{"offset":0,"length":12,"style":"BOLD"}],"entityRanges":[],"data":{}}],"entityMap":{}},
        // @formatter:on
        'ISO 11607, FDA, and EU MDR compliance',
    ],
    'capital expenses': [
        'Major expenditures on equipment and facilities for packaging operations',
        // @formatter:off
        {"blocks":[{"key":"d80u9","text":"Equipment Types: Packaging machinery (sealers, printers, thermoformers), cleanroom facilities.","type":"unordered-list-item","depth":0,"inlineStyleRanges":[{"offset":0,"length":16,"style":"BOLD"}],"entityRanges":[],"data":{}},{"key":"7oevk","text":"Compliance: Must comply with ISO 14644 cleanroom standards.","type":"unordered-list-item","depth":1,"inlineStyleRanges":[{"offset":0,"length":11,"style":"BOLD"}],"entityRanges":[],"data":{}},{"key":"d63gs","text":"Efficiency: High throughput, automated or semi-automated systems.","type":"unordered-list-item","depth":1,"inlineStyleRanges":[{"offset":0,"length":11,"style":"BOLD"}],"entityRanges":[],"data":{}},{"key":"cmpgm","text":"Scalability: Modular systems that can be expanded as needed.","type":"unordered-list-item","depth":1,"inlineStyleRanges":[{"offset":0,"length":12,"style":"BOLD"}],"entityRanges":[],"data":{}},{"key":"261ge","text":"Maintenance: Service contracts and support availability.","type":"unordered-list-item","depth":1,"inlineStyleRanges":[{"offset":0,"length":12,"style":"BOLD"}],"entityRanges":[],"data":{}}],"entityMap":{}},
        // @formatter:on
        '',
    ],

}