import {observer} from "mobx-react-lite";
import {useStores} from "../../../stores";
import {Autocomplete, Button, Checkbox, Grid, TextField} from "@mui/material";
import {ShadcnDateRangePicker} from "../../../shadcn/components/ui/ShadcnDateRangePicker";
import {CheckBox, CheckBoxOutlineBlank, CloseOutlined} from "@mui/icons-material";
import {CurrencyComponent} from "../../../components/currency-component/CurrencyComponent";
import {BagAdvancedInsightsManager} from "../../../stores/managers/BagAdvancedInsightsManager";
import {
    countriesToContinents,
    Country,
    countryToContinent
} from "../../../components/visualization/bubble-map/countries";
import moment from "moment";

export const ContractHeaderFiltersBar = observer(() => {
    const {bagStore} = useStores();
    return <Grid container className="filter-container header-filter-container">
        <Grid container item xs={11}>
            <Grid item xs={3}>
                <Autocomplete
                    className="adv-dashboard-filter-continent"
                    multiple
                    limitTags={1}
                    value={countriesToContinents(bagStore.advanced.filters.country as Country[] || [])}
                    options={bagStore.advanced.continentFilterData}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option ? option : '-'}
                    renderOption={(props, option) => {
                        const s = bagStore.advanced.filters.country && bagStore.advanced.filters.country.some((selectedCountry) => {
                            const continent = countryToContinent(selectedCountry as Country);
                            return continent === option;
                        });
                        return (
                            <li {...props}>
                                <Checkbox
                                    icon={<CheckBoxOutlineBlank fontSize="small"/>}
                                    checkedIcon={<CheckBox fontSize="small"/>}
                                    style={{marginRight: 8}}
                                    checked={s}
                                />
                                <Grid container>
                                    <Grid item xs={6}>
                                        {option ? option : '-'}
                                    </Grid>
                                    <Grid item xs={6}>
                                        {/*TODO: No calculation in render thread*/}
                                        <CurrencyComponent
                                            v={BagAdvancedInsightsManager.calculateContinentSpend(bagStore.advanced.geoData, option)}
                                            nDigits={3}/>
                                    </Grid>
                                </Grid>
                            </li>
                        );
                    }}
                    onChange={(_, i) => {
                        const newCountries = i.flatMap((continent) => {
                            return bagStore.advanced.geoData.filter((country) => {
                                return countryToContinent(country.country) === continent;
                            }).map((country) => country.country);
                        });
                        bagStore.advanced.countryFilterChanged(newCountries);
                    }}
                    renderInput={(params) => (
                        <TextField {...params} label="Child suppliers"/>
                    )}
                />
            </Grid>

            <Grid item xs={3}>
                <Autocomplete
                    className="adv-dashboard-filter-continent"
                    multiple
                    limitTags={1}
                    value={countriesToContinents(bagStore.advanced.filters.country as Country[] || [])}
                    options={bagStore.advanced.continentFilterData}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option ? option : '-'}
                    renderOption={(props, option) => {
                        const s = bagStore.advanced.filters.country && bagStore.advanced.filters.country.some((selectedCountry) => {
                            const continent = countryToContinent(selectedCountry as Country);
                            return continent === option;
                        });
                        return (
                            <li {...props}>
                                <Checkbox
                                    icon={<CheckBoxOutlineBlank fontSize="small"/>}
                                    checkedIcon={<CheckBox fontSize="small"/>}
                                    style={{marginRight: 8}}
                                    checked={s}
                                />
                                <Grid container>
                                    <Grid item xs={6}>
                                        {option ? option : '-'}
                                    </Grid>
                                    <Grid item xs={6}>
                                        {/*TODO: No calculation in render thread*/}
                                        <CurrencyComponent
                                            v={BagAdvancedInsightsManager.calculateContinentSpend(bagStore.advanced.geoData, option)}
                                            nDigits={3}/>
                                    </Grid>
                                </Grid>
                            </li>
                        );
                    }}
                    onChange={(_, i) => {
                        const newCountries = i.flatMap((continent) => {
                            return bagStore.advanced.geoData.filter((country) => {
                                return countryToContinent(country.country) === continent;
                            }).map((country) => country.country);
                        });
                        bagStore.advanced.countryFilterChanged(newCountries);
                    }}
                    renderInput={(params) => (
                        <TextField {...params} label="Business unit"/>
                    )}
                />
            </Grid>

            <Grid item xs={3}>
                <ShadcnDateRangePicker
                    onUpdate={(values) => {
                        const startDate = moment(values.range.from).format('YYYY-MM-DD'); // Convert from Date to formatted string
                        const endDate = moment(values.range.to).format('YYYY-MM-DD'); // Convert to Date to formatted string, if available
                        console.log('startDate', startDate, values.range.from)
                        console.log('endDate', endDate, values.range.to)
                    }}
                    dateFrom={'2022-01-01'}
                    dateTo={'2025-12-31'}
                    align="start"
                    width={400}
                />
            </Grid>

            <Grid item xs={3}>
                <Autocomplete
                    className="adv-dashboard-country-filter"
                    multiple
                    limitTags={1}
                    value={bagStore.advanced.filters.country || []}
                    options={bagStore.advanced.geoDataCountryStringArr}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option ? option : '-'}
                    renderOption={(props, option, {selected}) =>
                        <li {...props}>
                            <Checkbox
                                icon={<CheckBoxOutlineBlank fontSize="small"/>}
                                checkedIcon={<CheckBox fontSize="small"/>}
                                style={{marginRight: 8}}
                                checked={selected}
                            />

                            <Grid container>
                                <Grid item xs={2}>
                                    <img
                                        loading="lazy"
                                        width="20"
                                        srcSet={`https://flagcdn.com/w40/${option.toLowerCase()}.png 2x`}
                                        src={`https://flagcdn.com/w20/${option.toLowerCase()}.png`}
                                        alt=""
                                    />
                                </Grid>
                                <Grid item xs={5} style={{display: 'flex', alignItems: 'center'}}>
                                    {option ? option : '-'}
                                </Grid>
                                <Grid item xs={1}></Grid>
                                <Grid item xs={4} style={{display: 'flex', alignItems: 'center'}}>
                                    <CurrencyComponent
                                        v={BagAdvancedInsightsManager.getCountrySpend(bagStore.advanced.geoData, option)}
                                        nDigits={3}/>
                                </Grid>
                            </Grid>
                        </li>
                    }
                    onChange={(_, value) => {
                        bagStore.advanced.countryFilterChangedNew(value);
                    }}
                    renderInput={(params) => (
                        <TextField {...params} label="Scope of contract"/>
                    )}
                />
            </Grid>
        </Grid>

        <Grid item xs={1} style={{display: 'flex', justifyContent: 'flex-end'}}>
            <Button
                variant="outlined"
                style={{
                    width: '100%',
                    height: '4em',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
                onClick={() => {
                    bagStore.advanced.reset()
                }}>
                <CloseOutlined/>
            </Button>
        </Grid>
    </Grid>
})
