import {Divider, Grid, Typography} from "@mui/material";
import {observer} from "mobx-react-lite";
import {GenericModalVertical} from "../../../components/modal/GenericModalVertical";
import React from "react";
import {useStores} from "../../../stores";
import {Analytics, Dashboard, Gavel, Info, Inventory, LocalShipping, RequestPage} from "@mui/icons-material";
import {ModalCardBlockType, ModalCardProps} from "../classes";

type Props = {
    isOpen: boolean
}

export const ModalContractDetails: React.FC<Props> = observer(({isOpen}) => {
    const {contractInsightsStore} = useStores();

    const modalContractsDetailsData = contractInsightsStore.modalContractsDetailsData;
    const contractInfoCard: ModalCardBlockType[] = [
        {
            blockTitle: 'Contract ID',
            bulletPoints: modalContractsDetailsData && modalContractsDetailsData.contract_id ? [modalContractsDetailsData.contract_id] : []
        },
        {
            blockTitle: 'Contract Title',
            bulletPoints: modalContractsDetailsData && modalContractsDetailsData.contract_title ? [modalContractsDetailsData.contract_title] : []
        },
        {
            blockTitle: 'Type',
            bulletPoints: modalContractsDetailsData && modalContractsDetailsData.type ? [modalContractsDetailsData.type] : []
        },
        {
            blockTitle: 'Parent Supplier',
            bulletPoints: modalContractsDetailsData && modalContractsDetailsData.parent_supplier ? [modalContractsDetailsData.parent_supplier] : []
        },
        {
            blockTitle: 'Sister companies',
            bulletPoints: modalContractsDetailsData && modalContractsDetailsData.sister_companies ? [modalContractsDetailsData.sister_companies] : ['Bright motion Amst BV', 'Bright motion Eind BV']
        },
        {
            blockTitle: 'Contract Duration',
            bulletPoints: modalContractsDetailsData && modalContractsDetailsData.contract_duration ? [modalContractsDetailsData.contract_duration] : ['Effective date 01/01/2022', 'Expiration date 31/12/2025']
        },
        {
            blockTitle: 'Scope',
            bulletPoints: modalContractsDetailsData && modalContractsDetailsData.scope ? [modalContractsDetailsData.scope] : []
        }
    ];
    const contractFinancialTermsCard: ModalCardBlockType[] = [
        {
            blockTitle: 'Pricing',
            bulletPoints: modalContractsDetailsData && modalContractsDetailsData.pricing ? [modalContractsDetailsData.pricing] : ["Product pricing consists of 'Added Value' and 'HDPE' components."]
        },
        {
            blockTitle: 'Payment Terms',
            bulletPoints: modalContractsDetailsData && modalContractsDetailsData.payment_terms ? [modalContractsDetailsData.payment_terms] : []
        }
    ];
    const contractProductDetailsCard: ModalCardBlockType[] = [
        {
            blockTitle: 'Price Table',
            bulletPoints: modalContractsDetailsData && modalContractsDetailsData.price_table ? [modalContractsDetailsData.price_table] : [
                "Product thickness and width deviations allowed within\n" +
                "certain limits.\n" +
                "Over or under delivery acceptable within specified percentages based on net weight."]
        },
        {
            blockTitle: 'Quantity Threshold',
            bulletPoints: modalContractsDetailsData && modalContractsDetailsData.quantity_threshold ? [modalContractsDetailsData.quantity_threshold] : ['Bright Motion Ams commits to purchasing an indicative volume annually, with potential increase during the contract period.']
        }
    ];
    const contractDeliveryNStockCard: ModalCardBlockType[] = [
        {
            blockTitle: 'Delivery Conditions',
            bulletPoints: modalContractsDetailsData && modalContractsDetailsData.delivery_conditions ? [modalContractsDetailsData.delivery_conditions] : ['DDP (Incoterms 2020) to LWM\'s locations in the\n' +
            'Netherlands.']
        },
        {
            blockTitle: 'Quantity Thresholds',
            bulletPoints: modalContractsDetailsData && modalContractsDetailsData.quantity_thresholds ? ['BM Holding maintains a minimum stock level with maximum stock in the supply chain not exceeding 13 weeks.'] : []
        }
    ];
    const contractTerminationNLegaCard: ModalCardBlockType[] = [
        {
            blockTitle: 'Renewal Conditions',
            bulletPoints: modalContractsDetailsData && modalContractsDetailsData.renewal_conditions ? [modalContractsDetailsData.renewal_conditions] : ['Not specified']
        },
        {
            blockTitle: 'Termination Provisions',
            bulletPoints: modalContractsDetailsData && modalContractsDetailsData.termination_conditions ? [modalContractsDetailsData.termination_conditions] : ['Parties may agree to terminate before expiration.\n' +
            'Extraordinary termination provisions specified.']
        },
        {
            blockTitle: 'Liability & Legal',
            bulletPoints: modalContractsDetailsData && modalContractsDetailsData.liability ? [modalContractsDetailsData.liability] : ['Various provisions regarding liability, confidentiality, disputes resolution, and governing law.']
        }
    ];
    const contractPerformanceNComplianceCard: ModalCardBlockType[] = [
        {
            blockTitle: 'Performance Obligations',
            bulletPoints: modalContractsDetailsData && modalContractsDetailsData.performance_obligations ? [modalContractsDetailsData.performance_obligations] : ['BM Holding must ensure skilled, dependable staff and comply with safety instructions.']
        }
    ];
    const contractGroupOfProductsCard: ModalCardBlockType[] = [
        {
            blockTitle: 'Products / Services',
            bulletPoints: modalContractsDetailsData && modalContractsDetailsData.products_services ? [modalContractsDetailsData.products_services] : ['(Un-) Printed Packaging Foils']
        },
        {
            blockTitle: 'Annual Cost & Volume',
            bulletPoints: modalContractsDetailsData && modalContractsDetailsData.annual_cost_volume ? [modalContractsDetailsData.annual_cost_volume] : ['Not specified']
        }
    ];

    if (!modalContractsDetailsData) {
        return null;
    }


    return (
        <GenericModalVertical
            titleId={""}
            className="contract-details-modal"
            isOpen={isOpen}
            onSave={() => {
                contractInsightsStore.toggleDetailModal()
            }}
            saveTitle={"Done"}
            onCancel={() => {
                contractInsightsStore.toggleDetailModal()
            }}
            title={"Contract details"}
        >
            <Grid container>
                <Grid container item xs={10} spacing={2} justifyContent="flex-end" alignItems="flex-end">
                    <ModalCard title="Contract Info" icon={<Info/>} blocks={contractInfoCard}/>
                    <ModalCard title="Contract Financial Terms" icon={<RequestPage/>}
                               blocks={contractFinancialTermsCard}/>
                    <ModalCard title="Contract Product Details" icon={<Inventory/>}
                               blocks={contractProductDetailsCard}/>
                    <ModalCard title="Contract Delivery & Stock" icon={<LocalShipping/>}
                               blocks={contractDeliveryNStockCard}/>
                    <ModalCard title="Contract Termination & Legal" icon={<Gavel/>}
                               blocks={contractTerminationNLegaCard}/>
                    <ModalCard title="Contract Performance & Compliance" icon={<Analytics/>}
                               blocks={contractPerformanceNComplianceCard}/>
                    <ModalCard title="Contract Group of Products" icon={<Dashboard/>}
                               blocks={contractGroupOfProductsCard}/>
                </Grid>
            </Grid>
        </GenericModalVertical>
    );
})

const ModalCard: React.FC<ModalCardProps> = ({title, icon, blocks}) => (
    <Grid container className={`modal-row`}
          style={{
              margin: '1em 0',
              padding: '1em',
              background: '#F6F6F6',
              borderRadius: '5px',
              border: '1px groove #D1D6DD'
          }}>
        <Grid item xs={1} className="row-icon">
            {icon}
        </Grid>
        <Grid item xs={11} className="row-title">
            <Typography variant="h5" component="h5">
                {title}
            </Typography>
        </Grid>
        <Grid item xs={12} className="row-content">
            {blocks.map(block => (
                block.bulletPoints.length > 0 && (
                    <>
                        <Grid item className="block" style={{marginTop: '1.5em', marginBottom: '1em'}}>
                            <Typography variant="h6" component="h6">
                                {block.blockTitle}
                            </Typography>
                            <ul>
                                {block.bulletPoints.map(bulletPoint => (
                                    <li>{bulletPoint}</li>
                                ))}
                            </ul>
                        </Grid>
                        <Divider/>
                    </>
                )
            ))}
        </Grid>
    </Grid>
);
