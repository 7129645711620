import React from "react";
import './SynergySuppliersPage.scss';
import {Grid, useMediaQuery, useTheme} from "@mui/material";
import Typography from "@mui/material/Typography";
import {KoiFacade} from "../../../components/koi-facade/KoiFacade";
import {generatePath} from "react-router";
import {CmpDataBagRouteMatch, routes} from "../../../routing/routes";
import {useRouteMatch} from "react-router-dom";
import {AnnotatedValueComponent} from "../../../components/annotated-value-component/AnnotatedValueComponent";
import {CurrencyComponent} from "../../../components/currency-component/CurrencyComponent";
import {useStores} from "../../../stores";
import {hardcoded_dpw} from "../hardcoded/hardcoded_dpw";
import {
    CmpBreakdownSingleVisualization
} from "../../../components/visualization/cmp-breakdown-single/CmpBreakdownSingleVisualization";
import {SynergySupplierTable} from "./SynergySupplierTable";
import {
    cmpVizOptionsLeft,
    cmpVizOptionsRight,
    cmpVizTabletOptionsLeft,
    cmpVizTabletOptionsRight
} from "../synergyTypes";
import {SynergySuppliersDetail} from "./SynergySuppliersDetail";
import {generateHomePath, getAppEnvironment} from "../../../routing/routing.utils";
import {environment} from "../../../env";

export const SynergySuppliersPage: React.FC = () => {
    const routeMatch = useRouteMatch<CmpDataBagRouteMatch>();
    const {rootAppStore, cmpStore} = useStores();
    const theme = useTheme();
    const mediaTablet = useMediaQuery(theme.breakpoints.down('md'));

    const {masterData, cmpData} = cmpStore.supplierCompareBreakdown;

    const optionsLeft = mediaTablet ? cmpVizTabletOptionsLeft : cmpVizOptionsLeft;
    const optionsRight = mediaTablet ? cmpVizTabletOptionsRight : cmpVizOptionsRight;

    const appEnv = getAppEnvironment(routeMatch.path);
    const backLink = appEnv === 'synergy_v3' || appEnv === 'synergy_merge' || environment.package === 'sales_demo'
        ? generateHomePath(routeMatch, routeMatch.params, rootAppStore.app)
        : generatePath(routes.job_synergy_v1_dashboard, routeMatch.params);

    return <KoiFacade
        title="Compare Suppliers"
        // helpContent={<PurchasePriceVarianceHelp/>}
        intro={<></>}
        back={backLink}
        className="synergy-suppliers-page">
        <section className="page-item">
            <Grid container spacing={0} justifyContent="space-evenly" alignItems="center" className="page-bag-titles">
                <Grid item lg={4} md={5} sm={6}>
                    <Typography variant="h3" className="bag-title">{cmpStore.getMasterBagName()}</Typography>
                </Grid>
                <Grid item lg={3} md={2} sm="auto">
                </Grid>
                <Grid item lg={4} md={5} sm={6}>
                    <Typography variant="h3" className="bag-title">{cmpStore.getCmpBagName()}</Typography>
                </Grid>
            </Grid>
            <Grid container spacing={0} justifyContent="space-evenly" alignItems="stretch"
                  className="page-breakdown-viz">
                <Grid item lg={4} md={4}>
                    <CmpBreakdownSingleVisualization
                        data={masterData}
                        options={optionsLeft}
                    />
                </Grid>

                <Grid item lg={3} md={3}
                      container direction="column" justifyContent="space-between" className="breakdown-values">
                    <Grid item className="top-breakdown">
                        <Typography variant="h4" className="title">
                            Common suppliers
                        </Typography>
                        <Typography variant="h4" className="value">
                            <AnnotatedValueComponent v={hardcoded_dpw.OVERLAP.overlap_n_suppliers}/>
                        </Typography>
                    </Grid>
                    <Grid item className="bottom-breakdown">
                        <Typography variant="h4" className="title">
                            Suppliers spend
                        </Typography>
                        <Typography variant="h4" className="value">
                            <CurrencyComponent
                                v={hardcoded_dpw.OVERLAP.overlap_total_combined_spend}
                                nDigits={3}
                            />
                        </Typography>
                    </Grid>
                </Grid>

                <Grid item lg={4} md={4}>
                    <CmpBreakdownSingleVisualization
                        data={cmpData}
                        options={optionsRight}
                    />
                </Grid>
            </Grid>

            <Grid container justifyContent="center">
                <Grid item lg={8} md={12}>
                    <SynergySupplierTable/>
                </Grid>
            </Grid>

            <Grid container justifyContent="center">
                <Grid item xs={12}>
                    <SynergySuppliersDetail/>
                </Grid>
            </Grid>
        </section>
    </KoiFacade>
};
