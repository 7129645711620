import {Alert, AlertTitle} from "@mui/material";
import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../../../../stores";
import moment from "moment";
import {noop} from "rxjs";

type Props = {}
export const ApprovalProcessingAlert: React.FC<Props> = observer(() => {
    const {approvalStore} = useStores();
    const info = approvalStore.categorizationApprovalInfo.result;

    // Triggers re-initialize
    const [received, setReceived] = React.useState<Date | undefined>(undefined);
    useEffect(() => {
        if(info?.remaining_seconds) {
            setReceived(new Date());
        } else {
            setReceived(undefined);
        }
    }, [info?.remaining_seconds]);

    // Triggers re-render
    const [tick, setTick] = React.useState<number>(0);
    useEffect(() => {
        if(!received) return noop;
        const t = setInterval(() => setTick(tick + 1), 1000);
        return () => {
            setTick(0)
            clearInterval(t)
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [received]);

    if(!info || !received) return <></>;

    const estCompletionTime = moment(received).to(moment(received).add(info.remaining_seconds, 'seconds'));
    return <Alert severity="warning">
        <AlertTitle>
            {info.n_busy_requests > 1
                ? `${info.n_busy_requests} approval requests are still being processed`
                : `An approval requests is still being processed`
            }
        </AlertTitle>
        This page might not be up to date.
        Estimated completion time: {estCompletionTime}
    </Alert>
});
