import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useStores} from "../../stores";
import {
    Autocomplete,
    autocompleteClasses,
    Checkbox,
    CircularProgress,
    Grid,
    Popper,
    TextField,
    Typography
} from "@mui/material";
import {CheckBox, CheckBoxOutlineBlank} from "@mui/icons-material";
import {styled} from "@mui/material/styles";
import {observer} from "mobx-react-lite";
import {runInAction} from "mobx";
import {Option} from "../../stores/managers/BagAdvancedInsightsManager";

const WIDE_SEARCH_BAR_CUTOFF = 25;
const EXTRA_WIDE_SEARCH_BAR_CUTOFF = 50;

const StyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.listbox}`]: {
        boxSizing: 'border-box',
        '& ul': {
            padding: 0,
            margin: 0,
        },
        'li > div': {
            marginLeft: 8,
        }
    },
});

interface Props {
    wrapperClassName?: string,
    limitTags?: number,
}

export const SearchParentSupplierAutocomplete: React.FC<Props> = observer(({
                                                                               wrapperClassName,
                                                                               limitTags
                                                                           }) => {
    const {bagStore} = useStores();
    const inputRef = useRef<HTMLInputElement>();

    const [isOpen, setIsOpen] = useState(false);

    /**
     * Limited list of 100 search results
     */
    const [isLoadingSearch, setIsLoadingSearch] = useState(false);
    /**
     * For triggering a selection of the search results that arrive
     */
    const [selectNextSearchTerm, setSelectNextSearchTerm] = useState(false);

    /**
     * This is needed to be memoized or stored in a state
     * https://github.com/mui/material-ui/issues/32425
     */
    const selectedSuppliersHash: string = bagStore.advanced.filters.sp_ids ? JSON.stringify(bagStore.advanced.filters.sp_ids) : '';
    const values: Option[] = useMemo(() => {
        if (!bagStore.advanced.filters.sp_ids) return [];
        return bagStore.advanced.filters.sp_ids.map(v =>
                bagStore.advanced.searchParentSupplierResult?.find(s => s.value === v) || bagStore.advanced.searchParentSupplierCachedOptions.find(s => s.value === v) || ({
                    value: v,
                    label: v,
                    subLabel: '',
                })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSuppliersHash])

    const disableFilter = () => {
        bagStore.advanced.setSearchParentSupplierCachedOptions([])
        bagStore.advanced.parentSupplierFilterChanged([])
        setIsOpen(false)
        bagStore.advanced.setSearchParentSupplierSearchTerm('')
        runInAction(() => {
            bagStore.advanced.searchParentSupplierResult = [] //not sure if needed
        })
        setIsLoadingSearch(false)
        setSelectNextSearchTerm(false)
        inputRef.current?.blur();
    }

    const pickAllResultOptions = () => {
        // Select all from searchResults
        const searchResultsOptionValues = bagStore.advanced.searchParentSupplierResult?.map(({value}) => value);
        if (!bagStore.advanced.searchParentSupplierResult) {
            return
        }
        bagStore.advanced.setSearchParentSupplierCachedOptions([...bagStore.advanced.searchParentSupplierResult]);
        // Propagate the change
        bagStore.advanced.parentSupplierFilterChanged(searchResultsOptionValues);

        // Reset focus
        setIsOpen(false);
        bagStore.advanced.setSearchParentSupplierSearchTerm('')
        inputRef.current?.blur();
    }

    useEffect(() => {
        const subscription = bagStore.advanced.searchParentSupplierPipe.subscribe();
        return () => {
            subscription.unsubscribe();
        };
    }, []);

    const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.code === 'Enter') {
            if (bagStore.advanced.searchParentSupplierSearchTerm === '') {
                // We will select all supplier, so we simply disable the filter
                disableFilter()
            } else {
                if (!isLoadingSearch) {
                    // Enter is pressed, just pick everything that is now in the view
                    setSelectNextSearchTerm(false);
                    pickAllResultOptions();
                } else {
                    // Pick everything as soon as the loading is done
                    setSelectNextSearchTerm(true);
                }
            }
            e.preventDefault();
        }
    }

    useEffect(() => {
        if (selectNextSearchTerm && !isLoadingSearch) {
            setSelectNextSearchTerm(false);
            pickAllResultOptions();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bagStore.advanced.searchParentSupplierPipe, isLoadingSearch, selectNextSearchTerm]);

    const isWide = bagStore.advanced.filters.sp_ids && bagStore.advanced.filters.sp_ids?.length >= WIDE_SEARCH_BAR_CUTOFF;
    const isExtraWide = bagStore.advanced.filters.sp_ids && bagStore.advanced.filters.sp_ids?.length >= EXTRA_WIDE_SEARCH_BAR_CUTOFF;
    const className = (wrapperClassName ? `${wrapperClassName}` : '')
        + (isWide ? ' wide' : '')
        + (isExtraWide ? ' extra-wide' : '')
    return <div className={className}>
        <Autocomplete
            multiple
            limitTags={limitTags ?? 3}
            freeSolo
            value={values}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            options={bagStore.advanced.searchParentSupplierResult || []}
            filterOptions={x => x}
            noOptionsText={'No suppliers found'}
            disableCloseOnSelect
            open={isOpen}
            onOpen={() => setIsOpen(true)}
            onClose={() => setIsOpen(false)}
            renderOption={(props, option, {selected}) => {
                props['key'] = option.value;
                return <li {...props}>
                    <Checkbox
                        icon={<CheckBoxOutlineBlank fontSize="small"/>}
                        checkedIcon={<CheckBox fontSize="small"/>}
                        checked={selected}
                    />

                    <Grid container>
                        <Grid item xs={12}>
                            <Typography>
                                {option.label}
                                {option.subLabel && <>
                                    <br/>
                                    <small>{option.subLabel}</small>
                                </>}
                            </Typography>
                        </Grid>
                    </Grid>
                </li>;
            }}
            onChange={(event, options, reason) => {
                if (event.type === 'keydown' && (event as any as KeyboardEvent).code === 'Enter') return;
                const optionValues = options.map(o => typeof o === 'string' ? o : o.value)
                switch (reason) {
                    case 'clear':
                        // setCachedOptions([])
                        bagStore.advanced.parentSupplierFilterChanged([])
                        break
                    case "removeOption":
                        bagStore.advanced.parentSupplierFilterChanged(optionValues)
                        break
                    case 'selectOption':
                        // TODO: is this needed?
                        // setCachedOptions([optionValues?])
                        bagStore.advanced.setSearchParentSupplierSearchTerm('')
                        bagStore.advanced.parentSupplierFilterChanged(optionValues)
                        break
                    case 'createOption':
                        break
                }
            }}
            inputValue={bagStore.advanced.searchParentSupplierSearchTerm}
            onInputChange={(_, value, reason) => {
                switch (reason) {
                    case "input":
                        bagStore.advanced.setSearchParentSupplierSearchTerm(value)
                        break
                    case 'clear':
                        bagStore.advanced.setSearchParentSupplierSearchTerm('')
                        break;
                }
            }}
            renderInput={(params) => {
                return (
                    <TextField
                        {...params}
                        label="Parent suppliers"
                        onKeyDown={e => onKeyDown(e as any)}
                        InputProps={{
                            ...params.InputProps,
                            inputRef: inputRef,
                            endAdornment: (
                                <>
                                    {isLoadingSearch && <CircularProgress color="inherit" size={20}/>}
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                        }}
                    />
                );
            }}
            PopperComponent={StyledPopper}
        />
    </div>
})
