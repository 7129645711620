import React from "react";
import {Alert, AlertProps, AlertTitle, Button, Grid, Typography} from "@mui/material";
import {ApprovalStore} from "../../../stores/ApprovalStore";
import {ApprovalStatusEnum} from "../../../services/classes/AiClasses";
import {useStores} from "../../../stores";
import {observer} from "mobx-react-lite";
import {environment} from "../../../env";

type Props = {
    gridItem?: true
}
export const TaxonomyApprovalAlert: React.FC<Props> = observer(({gridItem}) => {
    const {taxonomyManagerStore, authStore} = useStores();
    const approval = taxonomyManagerStore.taxonomy?.result_of_approval;
    if (!approval) return <></>;

    const approvalStatus = approval.current_status.status;
    const severity: AlertProps['severity'] = approvalStatus === ApprovalStatusEnum.REJECTED ? 'error' : 'info';
    const alert = <Alert
        severity={severity}
        action={
            authStore.isMithraStaff ?
                <div>
                    <Button color="primary"
                            onClick={() => taxonomyManagerStore.forceSetStatus(ApprovalStatusEnum.APPROVED)}>
                        SET approved
                    </Button>
                    <br/>
                    <Button color="warning"
                            onClick={() => taxonomyManagerStore.forceSetStatus(ApprovalStatusEnum.HALTED)}>
                        set HALTED
                    </Button>
                    {environment.isDemo && <>
                        <br/>
                        <Button color="error" onClick={() =>
                            // eslint-disable-next-line no-restricted-globals
                            confirm('Are you sure you want to delete this approval?')
                            && taxonomyManagerStore.forceDeleteTaxonomyApprovalRequest()
                        }>
                            Destroy
                        </Button>
                    </>}
                </div>
                : undefined
        }>
        <Typography>
            <AlertTitle>Approval: {ApprovalStore.getApprovalStatusName(approvalStatus)}</AlertTitle>
            {approval.feedback_notes || 'No notes provided'}
        </Typography>
    </Alert>
    return gridItem ? <Grid item>{alert}</Grid> : alert;
})