import {observer} from "mobx-react-lite";
import {useStores} from "../../../stores";
import {MithraTableHeadColumns} from "../../../components/table/MithraTableHeadColumns";
import {Table, TableBody, TableCell, TableContainer, TableRow} from "@mui/material";
import React from "react";
import {getContractInsightsColumns} from "./ContractInsightsColumns";
import {ContractInsightsData} from "../../../stores/contract/ContractInsightsStore";
import {ContractMainRow} from "./ContractMainRow";
import {ContractRowState} from "../classes";

export const ContractInsightsExpandableTable: React.FC = observer(() => {
    const {p, contractInsightsStore} = useStores()
    const COLUMNS = getContractInsightsColumns(p);

    const isLoading = false;
    const data: ContractInsightsData[] | undefined = (contractInsightsStore.contracts)


    const tableClass = 'mithra-table x-design contract-insights-table x-design-narrow' + (isLoading ? ' loading' : '');
    return <TableContainer className={tableClass}>
        <Table cellPadding="0">
            <MithraTableHeadColumns columns={COLUMNS} enableLoading isLoading={isLoading}/>
            <TableBody className="contract-inishgts-table-body">
                {data ? <>
                        {data.map(row => <React.Fragment key={row.id}>
                            <ContractMainRow contractRow={ContractRowState.build(row, 1)}/>
                        </React.Fragment>)}
                        {data.length === 0 &&
                            <TableRow className="no-data">
                                <TableCell colSpan={COLUMNS.length}>
                                    No data
                                </TableCell>
                            </TableRow>
                        }
                    </>
                    : <TableRow className="no-data-yet"/>
                }
            </TableBody>
        </Table>
    </TableContainer>
})
