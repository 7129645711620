import './BusinessCaseReportPage.scss'
import './BusinessCase.scss'
import {observer} from "mobx-react-lite";
import {
    Alert,
    Button,
    Card,
    CardContent,
    Dialog,
    Divider,
    Grid,
    IconButton,
    TextField,
    Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useStores} from "../../stores";
import {generatePath, useHistory} from "react-router";
import {ClassificationHierarchyIcon} from "../../components/icons/ClassificationHierarchyIcon";
import {BcrTable} from "./components/BcrTable";
import {RiskAssessmentTable} from "./components/RiskAssessmentTable";
import {DonutChart} from "../../components/visualization/donut-chart/DonutChart";
import {AdvancedBarChart} from "../../components/visualization/bar-chart/AdvancedBarChart";
import {routes} from "../../routing/routes";
import {BcrHeader} from "./components/BcrHeader";
import {ChevronLeft, Download, Help, Share} from "@mui/icons-material";
import {ShareModal} from "./components/modals/ShareModal";
import {toCurrency} from "../../components/currency-component/CurrencyClasses";
import {expectedSavingsHelperText} from "./components/ReviewOpportunitiesTableSection";
import {SkeletonLoader} from "./components/FakeLoader";
import {runInAction} from "mobx";
import {ModalMasterAgreementKPI} from "./components/modals/ModalMasterAgreementKPI";


type Props = {}
export const BusinessCaseReportPage: React.FC<Props> = observer(() => {
    const {p, businessCaseGeneratorStore, bagStore} = useStores();
    const history = useHistory();
    const [isShareOpen, setIsShareOpen] = React.useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        runInAction(() => {
            businessCaseGeneratorStore.hasLoaded = false;
            businessCaseGeneratorStore.risksHaveLoaded = false;
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const fakeLoaderTimeout = setTimeout(() => {
            setIsLoading(false);
        }, Math.floor(Math.random() * (5000 - 3000 + 1)) + 3000);

        return () => clearTimeout(fakeLoaderTimeout);
    }, []);

    if (isLoading) {
        return <SkeletonLoader/>;
    }

    return <section className="business-case-report-page">
        <ShareModal isOpen={isShareOpen} onClose={() => setIsShareOpen(false)}/>
        <Dialog
            open={businessCaseGeneratorStore.isKPIsModalOpen}
            onClose={() => runInAction(() => {
                businessCaseGeneratorStore.isKPIsModalOpen = false
            })}
            aria-labelledby={"kpi-modal-title"}
            maxWidth="md"
        >
            {businessCaseGeneratorStore.isKPIsModalOpen &&
                <ModalMasterAgreementKPI isOpen={businessCaseGeneratorStore.isKPIsModalOpen}
                                         onClose={() => runInAction(() => {
                                             businessCaseGeneratorStore.isKPIsModalOpen = false
                                         })}/>
            }
        </Dialog>

        <Grid container alignItems="center" className="header">
            <Grid item xs={2} className="">
                <Button
                    variant="outlined"
                    startIcon={<ChevronLeft/>}
                    onClick={() => {
                        history.push(generatePath(routes.job_advanced_dashboard, {
                            id: bagStore.bagId,
                        }))
                    }}>
                    Back to Dashboard
                </Button>
            </Grid>
            <Grid item xs={6}>
                <Typography variant="h1" className="title">
                    Business Case Report
                </Typography>
            </Grid>
            <Grid item xs={4} className="text-right button-toolbar-right">
                <IconButton onClick={() => setIsShareOpen(true)}>
                    <Share/>
                </IconButton>
                <Button
                    variant="outlined"
                    startIcon={<Download/>}
                    onClick={downloadFile}>
                    Download
                </Button>

            </Grid>
        </Grid>


        <BcrHeader/>

        <div className="general-infos">
            <Alert severity="info">
                Please review detected key opportunities for the requested business case report.
            </Alert>
        </div>

        {/******************************************************************************************************
         ** Master supplier agreements
         ******************************************************************************************************/}
        {!businessCaseGeneratorStore.isTable1Deleted && <section className="table-section report-section">
            <Grid container className="table-section-header" alignItems="center">
                <Grid item className="table-section-header-icon">
                    <ClassificationHierarchyIcon/>
                </Grid>
                <Grid item className="table-section-header-title">
                    <Typography variant="h6" component="h6">Master supplier agreements
                        <IconButton onClick={() => runInAction(() => {
                            businessCaseGeneratorStore.isKPIsModalOpen = true
                        })}>
                            <Help/>
                        </IconButton>
                    </Typography>
                </Grid>
            </Grid>

            <Grid className="table-header-kpis"
                  container>
                <Grid xs={4}>
                    <RowGrid title="Identified parent suppliers" number="4"/>
                </Grid>
                <Grid xs={4}>
                    <RowGrid title="Number of child suppliers" number="12"/>
                </Grid>
                <Grid xs={4}>
                    <RowGrid title="Aggregated value of spend" number={toCurrency(p.currencySymbol, 18500000, 3)}/>
                </Grid>
            </Grid>

            <Grid className="ai-opportunity-values-wrapper"
                  container>
                <Grid item xs={2}>
                    <div className="opp-savings-field-wrapper">
                        <TextField
                            id="opp-table1-year1"
                            className="opp-savings-field"
                            label="Savings in year 1"
                            variant="standard"
                            fullWidth
                            defaultValue={toCurrency('', businessCaseGeneratorStore.oppTable1Year1ExpectedSavings || 0, 3)}
                            helperText={expectedSavingsHelperText(
                                businessCaseGeneratorStore.oppTable1Year1ExpectedSavings,
                                businessCaseGeneratorStore.oppTable1YearTotalValue,
                            )}
                            InputProps={{readOnly: true}}
                        />
                    </div>
                </Grid>
                <Grid item xs={2}>
                    <div className="opp-savings-field-wrapper">
                        <TextField
                            id="opp-table1-year2"
                            className="opp-savings-field"
                            label="Savings in year 2"
                            variant="standard"
                            fullWidth
                            defaultValue={toCurrency('', businessCaseGeneratorStore.oppTable1Year2ExpectedSavings || 0, 3)}
                            helperText={expectedSavingsHelperText(
                                businessCaseGeneratorStore.oppTable1Year2ExpectedSavings,
                                businessCaseGeneratorStore.oppTable1YearTotalValue,
                            )}
                            InputProps={{readOnly: true}}
                        />
                    </div>
                </Grid>
                <Grid item xs={2}>
                    <div className="opp-savings-field-wrapper">
                        <TextField
                            id="opp-table1-year3"
                            className="opp-savings-field"
                            label="Savings in year 3"
                            variant="standard"
                            fullWidth
                            defaultValue={toCurrency('', businessCaseGeneratorStore.oppTable1Year3ExpectedSavings || 0, 3)}
                            helperText={expectedSavingsHelperText(
                                businessCaseGeneratorStore.oppTable1Year3ExpectedSavings,
                                businessCaseGeneratorStore.oppTable1YearTotalValue,
                            )}
                            InputProps={{readOnly: true}}
                        />
                    </div>
                </Grid>

                <Grid xs={4}>
                </Grid>

                <Grid xs={2}>
                    <TextField id="standard-basic" label="Search supplier" variant="standard"/>
                </Grid>
            </Grid>

            <BcrTable
                className="advanced-opportunity-table"
                headers={businessCaseGeneratorStore.REPORT_TABLE_HEADERS}
                data={businessCaseGeneratorStore.masterSupplierAgreementData}
                isLoading={businessCaseGeneratorStore.masterSupplierAgreementLoading}
                notEditable={true}
            />
        </section>}

        {/******************************************************************************************************
         ** One time tail spend
         ******************************************************************************************************/}
        {!businessCaseGeneratorStore.isTable2Deleted && <section className="table-section report-section">
            <Grid container className="table-section-header" alignItems="center">
                <Grid item className="table-section-header-icon">
                    <ClassificationHierarchyIcon/>
                </Grid>
                <Grid item className="table-section-header-title">
                    <Typography variant="h6" component="h6">One time tail spend</Typography>
                </Grid>
            </Grid>

            <Grid className="table-header-kpis"
                  container>
                <Grid xs={4}>
                    <RowGrid title="Identified parent suppliers" number="0"/>
                </Grid>
                <Grid xs={4}>
                    <RowGrid title="Number of child suppliers" number="10"/>
                </Grid>
                <Grid xs={4}>
                    <RowGrid title="Aggregated value of spend" number={toCurrency(p.currencySymbol, 300000, 3)}/>
                </Grid>
            </Grid>

            <Grid className="ai-opportunity-values-wrapper"
                  container>
                <Grid item xs={2}>
                    <div className="opp-savings-field-wrapper">
                        <TextField
                            id="opp-table2-year1"
                            className="opp-savings-field"
                            label="Savings in year 1"
                            variant="standard"
                            fullWidth
                            defaultValue={toCurrency('', businessCaseGeneratorStore.oppTable2Year1ExpectedSavings || 0, 3)}
                            helperText={expectedSavingsHelperText(
                                businessCaseGeneratorStore.oppTable2Year1ExpectedSavings,
                                businessCaseGeneratorStore.oppTable2YearTotalValue,
                            )}
                            InputProps={{readOnly: true}}
                        />
                    </div>
                </Grid>
                <Grid item xs={2}>
                    <div className="opp-savings-field-wrapper">
                        <TextField
                            id="opp-table2-year2"
                            className="opp-savings-field"
                            label="Savings in year 2"
                            variant="standard"
                            fullWidth
                            defaultValue={toCurrency('', businessCaseGeneratorStore.oppTable2Year2ExpectedSavings || 0, 3)}
                            helperText={expectedSavingsHelperText(
                                businessCaseGeneratorStore.oppTable2Year2ExpectedSavings,
                                businessCaseGeneratorStore.oppTable2YearTotalValue,
                            )}
                            InputProps={{readOnly: true}}
                        />
                    </div>
                </Grid>
                <Grid item xs={2}>
                    <div className="opp-savings-field-wrapper">
                        <TextField
                            id="opp-table2-year3"
                            className="opp-savings-field"
                            label="Savings in year 3"
                            variant="standard"
                            fullWidth
                            defaultValue={toCurrency('', businessCaseGeneratorStore.oppTable2Year3ExpectedSavings || 0, 3)}
                            helperText={expectedSavingsHelperText(
                                businessCaseGeneratorStore.oppTable2Year3ExpectedSavings,
                                businessCaseGeneratorStore.oppTable2YearTotalValue,
                            )}
                            InputProps={{readOnly: true}}
                        />
                    </div>
                </Grid>

                <Grid xs={4}>
                </Grid>

                <Grid xs={2}>
                    <TextField id="standard-basic" label="Search supplier" variant="standard"/>
                </Grid>
            </Grid>

            <BcrTable
                className="advanced-opportunity-table"
                headers={businessCaseGeneratorStore.REPORT_TABLE_HEADERS}
                data={businessCaseGeneratorStore.oneTimeTailSpendData}
                isLoading={businessCaseGeneratorStore.oneTimeTailSpendLoading}
                notEditable={true}
            />
        </section>}

        {/******************************************************************************************************
         ** Frequent tail spend
         ******************************************************************************************************/}
        {!businessCaseGeneratorStore.isTable3Deleted && <section className="table-section report-section">
            <Grid container className="table-section-header" alignItems="center">
                <Grid item className="table-section-header-icon">
                    <ClassificationHierarchyIcon/>
                </Grid>
                <Grid item className="table-section-header-title">
                    <Typography variant="h6" component="h6">Frequent tail spend</Typography>
                </Grid>
            </Grid>

            <Grid className="table-header-kpis"
                  container>
                <Grid xs={4}>
                    <RowGrid title="Identified parent suppliers" number="0"/>
                </Grid>
                <Grid xs={4}>
                    <RowGrid title="Number of child suppliers" number="17"/>
                </Grid>
                <Grid xs={4}>
                    <RowGrid title="Aggregated value of spend" number={toCurrency(p.currencySymbol, 500000, 3)}/>
                </Grid>
            </Grid>

            <Grid className="ai-opportunity-values-wrapper"
                  container>
                <Grid item xs={2}>
                    <div className="opp-savings-field-wrapper">
                        <TextField
                            id="opp-table3-year1"
                            className="opp-savings-field"
                            label="Savings in year 1"
                            variant="standard"
                            fullWidth
                            defaultValue={toCurrency('', businessCaseGeneratorStore.oppTable3Year1ExpectedSavings || 0, 3)}
                            helperText={expectedSavingsHelperText(
                                businessCaseGeneratorStore.oppTable3Year1ExpectedSavings,
                                businessCaseGeneratorStore.oppTable3YearTotalValue,
                            )}
                            InputProps={{readOnly: true}}
                        />
                    </div>
                </Grid>
                <Grid item xs={2}>
                    <div className="opp-savings-field-wrapper">
                        <TextField
                            id="opp-table3-year2"
                            className="opp-savings-field"
                            label="Savings in year 2"
                            variant="standard"
                            fullWidth
                            defaultValue={toCurrency('', businessCaseGeneratorStore.oppTable3Year2ExpectedSavings || 0, 3)}
                            helperText={expectedSavingsHelperText(
                                businessCaseGeneratorStore.oppTable3Year2ExpectedSavings,
                                businessCaseGeneratorStore.oppTable3YearTotalValue,
                            )}
                            InputProps={{readOnly: true}}
                        />
                    </div>
                </Grid>
                <Grid item xs={2}>
                    <div className="opp-savings-field-wrapper">
                        <TextField
                            id="opp-table3-year3"
                            className="opp-savings-field"
                            label="Savings in year 3"
                            variant="standard"
                            fullWidth
                            defaultValue={toCurrency('', businessCaseGeneratorStore.oppTable3Year3ExpectedSavings || 0, 3)}
                            helperText={expectedSavingsHelperText(
                                businessCaseGeneratorStore.oppTable3Year3ExpectedSavings,
                                businessCaseGeneratorStore.oppTable3YearTotalValue,
                            )}
                            InputProps={{readOnly: true}}
                        />
                    </div>
                </Grid>

                <Grid xs={4}>
                </Grid>

                <Grid xs={2}>
                    <TextField id="standard-basic" label="Search supplier" variant="standard"/>
                </Grid>
            </Grid>

            <BcrTable
                className="advanced-opportunity-table"
                headers={businessCaseGeneratorStore.REPORT_TABLE_HEADERS}
                data={businessCaseGeneratorStore.freqTailSpendData}
                isLoading={businessCaseGeneratorStore.freqTailSpendLoading}
                notEditable={true}
            />
        </section>}

        <Grid container className="graphs report-section">
            <Grid item xs={6} style={{padding: '0.5em'}}>
                <Card style={{height: '100%'}}>
                    <CardContent>
                        <Typography variant={'h6'} className={'advanced-dashboard-title'}>
                            Supplier spend
                        </Typography>
                        <Typography sx={{fontSize: 14}} color="text secondary" gutterBottom
                                    className={'advanced-dashboard-subtitle'}>
                            Total spend distribution per supplier
                        </Typography>
                        <Divider style={{marginTop: '0.5em', marginBottom: '0.5em'}}/>
                        <AdvancedBarChart
                            dataWrapper={bagStore.advanced.spendConcentrationData}
                            selectedSuppliers={bagStore.advanced.filters.s_ids}
                            supplierFilterChanged={bagStore.advanced.supplierFilterChanged}
                        />
                    </CardContent>
                </Card>
            </Grid>


            <Grid item xs={6} style={{padding: '0.5em'}}>
                <Card style={{height: '100%'}}>
                    <CardContent>
                        <Typography variant={'h6'} className={'advanced-dashboard-title'}>
                            Spend breakdown
                        </Typography>
                        <Typography sx={{fontSize: 14}} color="text secondary" gutterBottom
                                    className={'advanced-dashboard-subtitle'}>
                            Spend distribution by top suppliers
                        </Typography>
                        <Divider style={{marginTop: '0.5em', marginBottom: '0.5em'}}/>
                        <DonutChart
                            data={bagStore.advanced.spendDonutData}
                            loading={bagStore.advanced.spendDonutDataLoading}
                            onClickedSupplier={bagStore.advanced.supplierFilterChanged}
                        />
                    </CardContent>
                </Card>
            </Grid>
        </Grid>

        <section className="risk-assesment-section report-section">
            <RiskAssessmentTable isEditable={false} data={businessCaseGeneratorStore.riskFactorData}/>
        </section>

        <section className="report-section">
            <Card className="success-factors-card success" elevation={3}>
                <TextField
                    fullWidth
                    label="Success factors"
                    multiline
                    rows={5}
                    defaultValue={businessCaseGeneratorStore.successFactors || ''}
                    variant="outlined"
                    InputProps={{
                        readOnly: true,
                    }}
                />
                <TextField
                    fullWidth
                    label="ESG Opportunities (Optional)"
                    multiline
                    rows={5}
                    defaultValue={businessCaseGeneratorStore.esgOpportunities || ''}
                    variant="outlined"
                    InputProps={{
                        readOnly: true,
                    }}
                />
            </Card>
        </section>

    </section>

});


const RowGrid = ({title, number}) =>
    <Grid item className={`dashboard-row`}>
        <Grid container direction="column" alignItems="center">
            <Grid item className="row-title">
                <Typography variant="h6" component="h6">
                    {title}
                </Typography>
            </Grid>
            <Grid item className="row-subtitle">
                <Typography variant="body1" component="p">
                    {number}
                </Typography>
            </Grid>
        </Grid>
    </Grid>


function downloadFile() {
    const fileUrl = process.env.PUBLIC_URL + '/sales_demo/business_case_generator/BusinessCaseReport.pptx';
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = 'Business Case Report.pptx';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}
