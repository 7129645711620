import './DashBoard.scss'
import React from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../stores";
import {Grid} from "@mui/material";
import {DashBoardCard} from "./DashBoardCard";
import {generatePath} from "react-router";
import {routes} from "../../routing/routes";
import {CurrencyComponent} from "../../components/currency-component/CurrencyComponent";
import {ClassificationHierarchyIcon} from "../../components/icons/ClassificationHierarchyIcon";
import {ClassificationIcon} from "../../components/icons/ClassificationIcon";
import {DashboardRow} from "../../components/dashboard/DashboardRow";
import {DASHBOARD_CONSTANTS, drawOpportunityValue} from "../../components/dashboard/Dashboard.utils";
import {SupplierNormalizationV2Icon} from "../../components/icons/SupplierNormalizationV2Icon";

export const MinimalDashboard: React.FC = observer(() => {
    const {
        p,
        bagStore,
        categorizationReviewStore,
        // Temporary fix for CAT-824
        // TODO: CAT-888
        supplierNormalizationStore
    } = useStores();
    const bagId = bagStore.bagId;
    const unclassifiedSpend = categorizationReviewStore.summaryResultKeyValues?.after.unclassified_spend;
    const nCategories = bagStore.kpi.common ? String(bagStore.kpi.common.n_categories) : DASHBOARD_CONSTANTS.SUBTITLE_LOADING;
    const pendingSupplierNormalization = supplierNormalizationStore.postKeyValues?.total_standalone_suppliers_spend;

    /**
     * TODO: It's not possible yet to show the uncategorized values when the hack is enabled
     */
    const showCategorizationKoi = !p.p.hackHideUncategorizedInReview;

    return <>
        <Grid container
              className="dashboard minimal dashboard-v2-wrapper"
              justifyContent="space-evenly"
              spacing={0}>
            <DashboardRow
                title="Categories"
                className="categories-row"
                subtitle={nCategories}>
                {/*<DashBoardCard*/}
                {/*    cardKey="explore-cats"*/}
                {/*    avatar={<ClassificationHierarchyIcon/>}*/}
                {/*    title="Explore Categories"*/}
                {/*    // value={nCategories}*/}
                {/*    // subtitle="categories"*/}
                {/*    link={generatePath(routes.job_koi_kdf, {id: bagId})}*/}
                {/*/>*/}
                <DashBoardCard
                    cardKey="taxonomy-builder"
                    avatar={<ClassificationHierarchyIcon/>}
                    title="Taxonomy Builder"
                    link={generatePath(routes.job_taxonomy_builder, {id: bagId})}
                />
            </DashboardRow>
            <DashboardRow
                title="Spend"
                className="spend-row"
                subtitle={bagStore.kpi.common ?
                    <CurrencyComponent v={bagStore.kpi.common.total_spend}
                                       nDigits={3}/> : DASHBOARD_CONSTANTS.SUBTITLE_LOADING}>
                {/*<DashBoardCard*/}
                {/*    cardKey="koi-ppv"*/}
                {/*    avatar={<MoveIcon/>}*/}
                {/*    title="Analyze Price Variance"*/}
                {/*    value={drawOpportunityValue(ppvTotal)}*/}
                {/*    valueColor={ppvTotal ? (ppvTotal >= DASHBOARD_CONSTANTS.OPPORTUNITY_THRESHOLD) ? 'red' : 'green' : 'gray'}*/}
                {/*    subtitle="total price variance"*/}
                {/*    link={generatePath(routes.job_koi_ppv, {id: bagId})}*/}
                {/*/>*/}
                <DashBoardCard
                    cardKey="improve-cats"
                    avatar={<ClassificationIcon/>}
                    title="Improve Categorization"
                    value={showCategorizationKoi ? drawOpportunityValue(unclassifiedSpend) : undefined}
                    valueColor={unclassifiedSpend
                        ? (unclassifiedSpend >= DASHBOARD_CONSTANTS.OPPORTUNITY_THRESHOLD) ? 'red' : 'green' : 'gray'}
                    subtitle={showCategorizationKoi ? "uncategorized" : undefined}
                    link={generatePath(routes.job_ai_cat_welcome, {id: bagId})}
                    badge="Ai"
                />
            </DashboardRow>
            <DashboardRow
                title="Suppliers"
                className="suppliers-row"
                subtitle={bagStore.kpi.common ? String(bagStore.kpi.common.n_suppliers) : DASHBOARD_CONSTANTS.SUBTITLE_LOADING}>
                {/*<DashBoardCard*/}
                {/*    cardKey="koi-spend-concentration"*/}
                {/*    avatar={<ArrowCollapsedIcon/>}*/}
                {/*    title="Spend Concentration"*/}
                {/*    // Temporary fix for CAT-824*/}
                {/*    // TODO: CAT-888*/}
                {/*    // value={scTop ? `${Math.round(scTop)}%` : DASHBOARD_CONSTANTS.VALUE_LOADING}*/}
                {/*    value={numberOfSuppliers ? `${Math.round(numberOfSuppliers)}%` : DASHBOARD_CONSTANTS.VALUE_LOADING}*/}
                {/*    valueColor={scTop ? (scTop < DASHBOARD_CONSTANTS.OPPORTUNITY_THRESHOLD_PARETO) ? 'red' : 'green' : 'gray'}*/}
                {/*    subtitle="of total spend with top 20% suppliers"*/}
                {/*    link={generatePath(routes.job_koi_sc, {id: bagId})}*/}
                {/*/>*/}
                {
                    p.p.useSupplierNormalizationCard && <DashBoardCard
                        cardKey="supplier-normalization"
                        avatar={<SupplierNormalizationV2Icon/>}
                        title="Supplier Normalization"

                        // TODO: Looks weird for SIG.. Maybe we do want this for other customers?
                        // value={drawOpportunityValue(pendingSupplierNormalization)}
                        // subtitle="suppliers not reviewed yet"

                        link={generatePath(routes.job_supplier_normalization, {id: bagId})}
                        badge="Ai"
                    />
                }
            </DashboardRow>
        </Grid>
    </>
});
