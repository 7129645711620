import React from "react";
import {useStores} from "../../stores";
import {_toCurrencyWithP, CurrencyAbbreviation} from "./CurrencyClasses";

type Props = {
    v: number
    vP?: number
    nDigits?: number
    abbreviation?: CurrencyAbbreviation
    hideAlmostZero?: boolean
};
export const CurrencyComponent: React.FC<Props> = ({v, vP, nDigits, abbreviation, hideAlmostZero}) => {
    const {p} = useStores();
    const [str, vanillaStr] = _toCurrencyWithP(v, p.currencyFormat, p.currencySymbol, vP, nDigits, abbreviation, hideAlmostZero);
    return <span title={vanillaStr}>{str}</span>
};
