import React from "react";
import {observer} from "mobx-react-lite";
import {TableRow} from "@mui/material";
import {CurrencyComponent} from "../../../../components/currency-component/CurrencyComponent";
import {CurrencyAbbreviationContext} from "../../../../components/currency-component/CurrencyAbbreviationContext";
import {getSpend, ParentSupplierMatch, SupplierReviewRowState} from "../SupplierNormalization.classes";
import {getSupplierIdHack} from "../../../../stores/hacks/display";
import {getSupplierNormalizationSupplierColumns} from "./columns";
import {UNCATEGORIZED_LABEL, UNCATEGORIZED_VALUE} from "../../../../constants";
import {ParentSupplierChooser} from "../components/ParentSupplierChooser";
import {MithraTableCell} from "../../../../components/table/MithraTableCell";
import {useStores} from "../../../../stores";
import {environment} from "../../../../env";
import {runInAction} from "mobx";

type Props = {
    subRow: SupplierReviewRowState
};
// FIXME: [CAT-1458] Refactor to also update number of supplier in prent row
// FIXME: [CAT-1458] Think of a better way to transition saved children to another row
export const ParentSupplierReviewSubRow: React.FC<Props> = observer(({subRow}) => {
    const {p, supplierNormalizationStore} = useStores()

    const [value, setValue] = React.useState<ParentSupplierMatch | null>(subRow.parent_match);
    const SUPPLIER_COLUMNS = getSupplierNormalizationSupplierColumns(p);
    const submitParentSupplier = (newValue: ParentSupplierMatch | null) => {
        supplierNormalizationStore.review.sendSubRowUpdate(subRow, newValue);
        setValue(newValue);
        runInAction(() => {
            subRow.parent_match = newValue;
        })
    }
    let i = 0;
    return <TableRow className={"sub-row" + (value === null ? ' removed' : '')}>
        <MithraTableCell c={SUPPLIER_COLUMNS[i++]}>
            <ParentSupplierChooser
                value={value}
                submitNewValue={submitParentSupplier}
                refresh={() => supplierNormalizationStore.refreshReview()}
                label="Parent Supplier"
            />
        </MithraTableCell>
        {p.p.showSupplierIdInSupplierNormalization &&
            <MithraTableCell c={SUPPLIER_COLUMNS[i++]}>
                {getSupplierIdHack(subRow)}
            </MithraTableCell>
        }
        <MithraTableCell c={SUPPLIER_COLUMNS[i++]}>
            {subRow.s_name}
        </MithraTableCell>
        <MithraTableCell c={SUPPLIER_COLUMNS[i++]}>
            {/*{environment.isTest && <>p_id={part.p_id}<br/></>}*/}
            <CurrencyAbbreviationContext.Consumer>{abbreviation =>
                <CurrencyComponent v={getSpend(subRow)} abbreviation={abbreviation}/>
            }</CurrencyAbbreviationContext.Consumer>
        </MithraTableCell>
        <MithraTableCell c={SUPPLIER_COLUMNS[i++]}>
            {subRow.s_city || subRow.s_country}
        </MithraTableCell>
        <MithraTableCell c={SUPPLIER_COLUMNS[i++]}>
            {subRow.s_total_l1s.map(l => l === UNCATEGORIZED_VALUE ? UNCATEGORIZED_LABEL : l).join(', ')}
        </MithraTableCell>
        {environment.isTest &&
            <MithraTableCell c={SUPPLIER_COLUMNS[i++]}>
                {'' + subRow.review_user_id}
            </MithraTableCell>
        }
        {/*<MithraTableCell c={SUPPLIER_COLUMNS[i++]}>*/}
        {/*    {Math.round(subRow.s_suggestion_score)}*/}
        {/*</MithraTableCell>*/}
    </TableRow>
})
