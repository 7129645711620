import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {useHistory, useLocation} from "react-router";
import {JobRouteMatch, MithraHistoryState} from "../../routing/routes";
import {useRouteMatch} from "react-router-dom";
import {useStores} from "../../stores";
import {RouteWrapperProps} from "./RouteWrapperProps";
import {SingleDatabagWrapped} from "./base/SingleDatabagWrapped";

export const DoubleEnvWrapper: React.FC<RouteWrapperProps> = observer(({children, state}) => {
    const {rootAppStore, bagLoadingStore} = useStores();
    const location = useLocation<MithraHistoryState>();
    const match = useRouteMatch<JobRouteMatch>();
    const history = useHistory();

    // TODO: See if this can be optimized, probably by the usage of react Context
    // Beware: This can cause the page to be rendered with the previous state, we have to be resilient against this
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => rootAppStore.setApp(state), [])

    // The magic that loads the job
    useEffect(() => {
        bagLoadingStore.initBag(location, history, match)
        // cmpStore.initBags(location, history, match as any)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <SingleDatabagWrapped state={state}>{children}</SingleDatabagWrapped>;
});
