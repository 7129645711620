import React, {useEffect, useRef} from "react";
import * as d3 from "d3";
import {PieArcDatum} from "d3";
import './DonutChart.scss';
import {DonutChartSupplierSpendData} from "../../../services/ApiTypes";
import objectHash from "object-hash";
import {CircularProgress, Grid} from "@mui/material";
import {observer} from "mobx-react-lite";
import {toJS} from "mobx";


const sortData = (a, b) => {
    if (a.group === "Other") {
        return 1;
    }
    if (b.group === "Other") {
        return -1;
    }
    return b.spend - a.spend;
}

export const DonutChart: React.FC<{
    data: DonutChartSupplierSpendData[],
    highlight?: {
        matches: string[],
        color: string,
    }
    legend?: { label: string, color: string }[],
    expand?: boolean,
    loading?: boolean,
    onClickedSupplier: (supplier?: string[]) => void,
}> = observer(({data, highlight, legend, expand, loading, onClickedSupplier}) => {
    const width = 750;
    const height = 409;
    const radius = Math.min(width, height) / 2 - 10;
    const legendPosition = d3.arc().innerRadius(radius / 1.75).outerRadius(radius);
    // const colors = ['#8ce8ad', '#57e188', '#34c768', '#2db757', '#27acaa', '#42c9c2', '#60e6e1', '#93f0e6', '#87d3f2', '#4ebeeb', '#35a4e8', '#188ce5', '#542ea5', '#724bc3', '#9c82d4', '#c981b2', '#b14891', '#ff6d00', '#ff810a', '#ff9831', '#ffb46a', '#ff9a91', '#ff736a', '#f95d54', '#ff4136', '#c4c4cd'];
    let colors;
    const OTHER_COLOR = '#cfd8e6';
    const START_COLOR = '#193150';
    const END_COLOR = '#5579a6';
    const OTHER_COLOR_CONTRAST = '#222';
    const MAIN_COLOR_CONTRAST = '#dedede';
    if (data.length === 2) {
        colors = [START_COLOR, END_COLOR]
    } else if (data.length < 2) {
        colors = [START_COLOR]
    } else {
        const colorScale = d3.scaleLinear([0, 1], [START_COLOR, END_COLOR]);
        colors = d3.quantize(colorScale, data.length - 1)
        colors.push(OTHER_COLOR)
    }

    const totalSpend = data.reduce((partialSum, a) => partialSum + a.spend, 0);

    const svgRef = useRef<SVGSVGElement>(null);
    const tooltipRef = useRef<HTMLDivElement>(null);

    const currency = d3.formatLocale({
        "decimal": ".",
        "thousands": ",",
        "grouping": [3],
        "currency": ["€", ""],
    })
    const format = currency.format("$,d");

    useEffect(() => {
        if (!svgRef.current) {
            return;
        }
        const datahash = objectHash(data);
        if (svgRef.current.dataset['datahash'] === datahash) {
            // Don't re-render if the input data is already rendered
            return;
        }
        svgRef.current.dataset['datahash'] = datahash;

        // Ensure we do not modify the state directly
        const sData = Array.from(toJS(data))
            // Do not show other if there is no other spend
            .filter(d => !(d.s_id === null && (d.spend === null || d.spend === 0)))

        sData.sort((a, b) => sortData(a, b))

        const svg = d3.select(svgRef.current);
        const tooltip = d3.select(tooltipRef.current);

        svg.html("");
        svg
            .attr('width', width)
            .attr('height', height);

        if (sData.length === 0) {
            // TODO: Fix adding text below
            svg.append('g')
                .attr("transform", `translate(${width / 2}, ${height / 2})`)
                .enter()
                .append("text")
                .text('No corresponding data.').style("text-anchor", "middle")
                .style("font-weight", 700)
                .style("fill", '#222')
                .style("font-size", 14);
            return;
        }

        const pie = d3.pie<DonutChartSupplierSpendData>()
            .value(d => d.spend)
            .sort((a, b) => sortData(a, b));
        const data_ready = pie(sData)

        tooltip
            .style("opacity", 0);

        function mouseover(this: any) {
            tooltip.style("opacity", 1);
            d3.select(this).attr('fill-opacity', 0.8);
        }

        function mousemove(this: any, event: MouseEvent, d: PieArcDatum<DonutChartSupplierSpendData>) {
            tooltip.html(`${d.data.group}: ${format(d.data.spend)} (${((d.data.spend / totalSpend) * 100).toFixed(2)}%)`)
                .style("left", event.pageX + "px")
                .style("top", (event.pageY + 20) + "px");
        }

        function mouseleave(this: any) {
            tooltip.style("opacity", 0);
            d3.select(this).attr('fill-opacity', 1);
        }

        function click(this: any) {
            const d = d3.select(this).datum() as any;
            if (!d || !d.data) return;
            const data = d.data as DonutChartSupplierSpendData;
            if (data.s_id === null) {
                // Other was clicked, do nothing
            } else {
                onClickedSupplier?.([data.s_id]);
            }
        }

        svg
            .selectAll('.donut')
            .data(data_ready)
            .enter()
            .append('path')
            .attr('fill', (d) => colors[d.index])
            .attr("stroke", "#fff")
            .attr("transform", `translate(${width / 3}, ${height / 2})`)
            .style("stroke-width", "2")
            .style("opacity", "0.8")
            .attr("d", (d) => {
                return d3.arc<PieArcDatum<DonutChartSupplierSpendData>>()
                    .innerRadius(radius / 1.75)
                    .outerRadius(radius)
                    (d)
            })
            .attr("style", 'cursor: pointer;')
            .on("mouseover", mouseover)
            .on("mousemove", mousemove)
            .on("mouseleave", mouseleave)
            .on("click", click)

        // Legend group and legend name
        svg
            .selectAll('slices')
            .data(data_ready)
            .enter()
            .append('g')
            .attr("transform", d => {
                const centroid = legendPosition.centroid(d as any)
                const position = [centroid[0] + width / 3, centroid[1] + height / 2]
                return `translate(${position})`
            })
            .attr("class", 'legend-g')
            .style("user-select", "none")
            .append('text')
            .text((d: any) => (d.endAngle - d.startAngle) > 0.5 ? ((d.data.spend / totalSpend) * 100).toFixed(1) + '%' : '')
            .style("text-anchor", "middle")
            .style("font-weight", 700)
            .style("fill", (_, index, arr) =>
                arr.length === 1
                    ? MAIN_COLOR_CONTRAST
                    : (index === arr.length - 1 ? OTHER_COLOR_CONTRAST : MAIN_COLOR_CONTRAST))
            .style("font-size", 14);

        // Add one dot in the legend for each name.
        svg.selectAll(".dots")
            .data(data_ready)
            .enter()
            .append("circle")
            .attr("cx", (3 * width / 5) + 40)
            .attr("cy", (d, i) => height / 4 + i * 25)
            .attr("r", 7)
            .style("fill", (d) => colors[d.index])

        // Add one dot in the legend for each name.
        svg.selectAll(".labels")
            .data(data_ready)
            .enter()
            .append("text")
            .attr("x", (3 * width / 5) + 60)
            .attr("y", (d, i) => height / 4 + i * 25)
            .style("fill", "black")
            .text((d) => d.data.group)
            .attr("text-anchor", "left")
            .style("alignment-baseline", "middle")

    }, [colors, data, format, legendPosition, radius, totalSpend]);

    return <div className="donut-chart-container" style={{height: height + 'px'}}>
        {loading ? (
            <Grid container justifyContent={'center'} alignItems={'center'}
                  style={{width: width + 'px', height: height + 'px'}}>
                <CircularProgress color="inherit"/>
            </Grid>
        ) : (
            <>
                <svg
                    className="donut-chart"
                    ref={svgRef}
                    viewBox={`0 0 ${width} ${height}`}
                />
                <div className='tooltip' ref={tooltipRef}/>
            </>
        )}
    </div>;
});
