import {makeAutoObservable} from "mobx";
import {environment} from "../../../env";
import {CategorizationReviewPageController} from "./CategorizationReviewPageController";
import {History} from 'history'
import ProfileStore from "../../../stores/ProfileStore";
import AuthStore from "../../../stores/AuthStore";

export type Page = 'ai-run' // or 'welcome'
    | 'demo-ai-run'
    | 'review-dashboard'
    | 'review'

/**
 Accessible by: `categorizationReviewStore.reviewPageController.reviewPageStateController`
 */
export class CategorizationReviewPageStateController {

    step = 0;
    page: Page = environment.isTestReviewPage ? 'review' : 'ai-run';

    constructor(
        private categorizationReviewPageController: CategorizationReviewPageController,
        private profileStore: ProfileStore,
        private auth: AuthStore,
    ) {
        makeAutoObservable(this)
    }

    reset() {
        this.step = 0;
        this.page = 'ai-run';
    }

    setPage(page: Page) {
        switch (page) {
            case "demo-ai-run":
            case "ai-run":
                this.step = 0;
                break;
            case "review-dashboard":
                this.step = 1;
                break;
            case "review":
                this.step = 2;
                break;
        }
        this.page = page;
    }

    get previousLabel(): string | null {
        switch (this.page) {
            case "ai-run":
            case "demo-ai-run":
            case "review-dashboard":
            case "review":
                return 'Back'
            default:
                return null
        }
    }

    onClickPrevious(history: History, backUrl: string) {
        switch (this.page) {
            case "ai-run":
            case "demo-ai-run":
                history.push('' + backUrl);
                return
            case "review-dashboard":
                this.categorizationReviewPageController.navigateToPage('ai-run')
                return;
            case "review":
                if (this.profileStore.p.disableReviewStatisticsPage) {
                    this.categorizationReviewPageController.navigateToPage('ai-run')
                } else {
                    this.categorizationReviewPageController.navigateToPage('review-dashboard')
                }
                return;

        }
    }

    get nextLabel(): string | null {
        switch (this.page) {
            case "review-dashboard":
                return 'Next'
            default:
                return null;
        }
    }

    onClickNext() {
        switch (this.page) {
            case "ai-run":
            case "demo-ai-run":
                if (this.profileStore.p.disableReviewStatisticsPage) {
                    this.categorizationReviewPageController.navigateToPage('review')
                } else {
                    this.categorizationReviewPageController.navigateToPage('review-dashboard')
                }
                return
            case "review-dashboard":
                this.categorizationReviewPageController.navigateToPage('review')
                return
        }
    }

    get steps(): string[] | undefined {
        if (this.profileStore.p.disableReviewStatisticsPage) {
            // return [
            //     'AI',
            //     `Review${this.auth.viewOnly ? '' : ' (view only)'}`
            // ]
            return undefined;
        } else {
            return [
                'AI',
                'Overview',
                'Review'
            ]
        }
    }
}