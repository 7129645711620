import React from "react";
import {observer} from "mobx-react-lite";
import {Table, TableBody} from "@mui/material";
import {SynergyCommonSupplierReviewSubRow} from "./SynergyCommonSupplierReviewSubRow";
import {CurrencyAbbreviationContext} from "../../../components/currency-component/CurrencyAbbreviationContext";
import {
    CommonSupplierReviewGroupRowState,
    getAbbreviation
} from "../../supplier-normalization/pages/SupplierNormalization.classes";
import {getSupplierCommonSupplierColumns} from "../../supplier-normalization/pages/parent-suppliers/columns";
import {MithraTableHeadColumns} from "../../../components/table/MithraTableHeadColumns";
import {useStores} from "../../../stores";

type Props = {
    data: CommonSupplierReviewGroupRowState
};
export const SynergyCommonSupplierReviewSubTable: React.FC<Props> = observer(({data}) => {
    const {p} = useStores();
    const SUPPLIER_COLUMNS = getSupplierCommonSupplierColumns(p);
    return <Table
        className="sub-table"
        size="small">
        <MithraTableHeadColumns columns={SUPPLIER_COLUMNS}/>
        <TableBody>
            {data.subRows?.map(subRow =>
                <React.Fragment key={subRow.id}>
                    <CurrencyAbbreviationContext.Provider value={getAbbreviation(data.subRows)}>
                        <SynergyCommonSupplierReviewSubRow subRow={subRow}/>
                    </CurrencyAbbreviationContext.Provider>
                </React.Fragment>
            )}
        </TableBody>
    </Table>
})
