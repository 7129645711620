import './TaxonomySuggestor.scss';
import React from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../stores";
import {Button, Grid, Tab, Tabs} from "@mui/material";
import {SaveAsOutlined} from "@mui/icons-material";
import {generatePath, useHistory} from "react-router";
import {JobRouteMatch, routes} from "../../routing/routes";
import {TaxonomySuggestionSelection} from "./TaxonomySuggestionSelection";
import {TaxonomySuggestionTableDepr1} from "./TaxonomySuggestionTableDepr1";
import {TaxonomySuggestionSearchComponent} from "./TaxonomySuggestionSearchComponent";
import TaxonomySuggestionTableV2 from "./TaxonomySuggestionTableDepr2";
import {generateGotoDashboardPath, generateGotoTaxonomyBuilderPath} from "../../routing/routing.utils";
import {KoiFacade} from "../../components/koi-facade/KoiFacade";
import {environment} from "../../env";
import {useRouteMatch} from "react-router-dom";
import {TaxSuggesterExtendedD3HierarchyNode} from "../../services/classes/TaxonomySuggestorClasses";


export const TaxonomySuggestorPage: React.FC = observer(() => {
    const {rootAppStore, p, taxonomySuggestorStore, bagStore, taxonomyManagerStore} = useStores();

    const bagId = bagStore.bagId;
    const history = useHistory();
    const routeMatch = useRouteMatch<JobRouteMatch>();

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        taxonomySuggestorStore.taxonomyTableController.setFilter(newValue);
    };

    return <KoiFacade
        title={'Taxonomy Suggester'}
        subTitle={taxonomyManagerStore.taxonomy?.name}
        onBack={() => {
            if (environment.package === 'sales_demo') {
                history.push(generateGotoDashboardPath(routeMatch, routeMatch.params, rootAppStore.app))
                return
            }
            history.push(generateGotoTaxonomyBuilderPath(bagId))
        }}
        className="taxonomy-editor-page taxonomy-suggestor-page"
    >
        <div className='taxonomy-suggestor-page'>
            <div className='taxonomy-suggestor-toolbar'>

                {environment.package === 'sales_demo'
                    ? <Grid className='taxonomy-suggestor-filters'></Grid> // Disabled for the sales_demo as it's not working
                    : <Grid className='taxonomy-suggestor-filters'>
                        <Tabs value={taxonomySuggestorStore.taxonomyTableController.filter} onChange={handleChange}
                              aria-label="Filter tabs">
                            <Tab label="No filter" value={0}/>
                            <Tab label={`TO ADD (${taxonomySuggestorStore.suggestionKpis.numberOfAdditions})`}
                                 value={1}/>
                            <Tab label={`TO DELETE (${taxonomySuggestorStore.suggestionKpis.numberOfDeletions})`}
                                 value={2}/>
                            <Tab label={`TO RENAME (${taxonomySuggestorStore.suggestionKpis.numberOfRenames})`}
                                 value={3}/>
                        </Tabs>
                    </Grid>
                }


                {/*DEBUG ONLY*/}
                {/*<ButtonGroup>*/}
                {/*    <Button*/}
                {/*        color="error"*/}
                {/*        variant="outlined"*/}
                {/*        onClick={() => {*/}
                {/*            console.log('Updating values...')*/}
                {/*            taxonomySuggestorStore.updateTaxonomySuggestionValues()*/}
                {/*        }}*/}
                {/*    >*/}
                {/*        Update values*/}
                {/*    </Button>*/}
                {/*    <Button*/}
                {/*        color="primary"*/}
                {/*        variant="outlined"*/}
                {/*        startIcon={<SaveAsOutlined/>}*/}
                {/*        onClick={() => {*/}
                {/*            taxonomySuggestorStore.saveTaxonomySuggestion()*/}
                {/*            history.push(generatePath(routes.job_taxonomy_builder, {id: bagId, taxonomyId: 1}))*/}
                {/*        }}>*/}
                {/*        Save*/}
                {/*    </Button>*/}
                {/*</ButtonGroup>*/}

                {!p.p.readOnlySuggestion
                    && !p.p.taxonomyBuilderViewOnly // Probably we also want to disable this, but this is not delivered to customer yet
                    && <Grid style={{marginTop: '2em', marginRight: '1em'}}>
                        <Button
                            color="primary"
                            variant="outlined"
                            startIcon={<SaveAsOutlined/>}
                            onClick={() => {
                                if (environment.package === 'sales_demo') {
                                    history.push(generatePath(routes.demo_merge_taxonomy_builder, {id: bagId,}))
                                    return
                                }
                                taxonomySuggestorStore.saveTaxonomySuggestion()
                                history.push(generatePath(routes.merge_x_taxonomy_builder, {id: bagId,}))
                            }}>
                            Save
                        </Button>
                    </Grid>}
            </div>

            <Grid container className='taxonomy-suggestor-main-container'>
                <Grid item
                      xs={(taxonomySuggestorStore.selectedNodeLabel && taxonomySuggestorStore.selectedNodeData) ? 5 : 12}>
                    <section className='taxonomy-container'>
                        {taxonomySuggestorStore.taxonomyTableController.data && (
                            <div>
                                {taxonomySuggestorStore.taxonomyTableController.filter === 0 && (
                                    <TaxonomySuggestionTableV2 columns={taxonomySuggestorStore.SUGGESTOR_COLUMNS}/>
                                )}
                                {taxonomySuggestorStore.taxonomyTableController.filter === 1 && (
                                    <TaxonomySuggestionTableV2 columns={taxonomySuggestorStore.SUGGESTOR_COLUMNS}/>
                                )}
                                {taxonomySuggestorStore.taxonomyTableController.filter === 2 && (
                                    <TaxonomySuggestionTableV2 columns={taxonomySuggestorStore.SUGGESTOR_COLUMNS}/>
                                )}
                                {taxonomySuggestorStore.taxonomyTableController.filter === 3 && (
                                    <TaxonomySuggestionTableV2 columns={taxonomySuggestorStore.SUGGESTOR_COLUMNS}/>
                                )}
                            </div>
                        )}
                    </section>
                </Grid>
                {taxonomySuggestorStore.selectedNodeLabel && taxonomySuggestorStore.selectedNodeData && (
                    <Grid item
                          xs={(taxonomySuggestorStore.selectedNodeLabel && taxonomySuggestorStore.selectedNodeData) ? 7 : 12}>
                        <section className='taxonomy-suggestion-selection-container'>
                            <div className='selection-overview'>
                                <TaxonomySuggestionSelection
                                    selectedNodeLabel={taxonomySuggestorStore.selectedNodeLabel}
                                    selectedNodeData={taxonomySuggestorStore.selectedNodeData}
                                />
                            </div>
                            <div className='selection-data-section'>
                                <Grid container className="selection-data-toolbar">
                                    <Grid item><TaxonomySuggestionSearchComponent/></Grid>
                                </Grid>
                                {taxonomySuggestorStore.isCategorySelected && <TaxonomySuggestionTableDepr1/>}
                            </div>
                        </section>
                    </Grid>
                )}
            </Grid>
        </div>
    </KoiFacade>
});

type Filter = undefined | ((d: TaxSuggesterExtendedD3HierarchyNode) => boolean);
export const TSTB_FILTERS: Filter[] = [
    // undefined,
    d => !d?.data.apiValues.review_status || d.data.apiValues.review_status.added,
    d => !d?.data.apiValues.review_status || d.data.apiValues.review_status.deleted,
    d => !d?.data.apiValues.review_status || d.data.apiValues.review_status.renamed,
]
