import './DataIngestion.scss'
import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../stores";
import {Alert, Button, Grid, IconButton, Typography} from "@mui/material";
import {useRouteMatch} from "react-router-dom";
import {JobRouteMatch, routes} from '../../routing/routes';
import {generatePath, useHistory} from "react-router";
import {generateHomePath} from "../../routing/routing.utils";
import {KoiFacade} from "../../components/koi-facade/KoiFacade";
import {ChevronRight, Refresh} from '@mui/icons-material';
import {LoadingSpinnerPie} from "../../components/loading-pie/LoadingSpinnerPie";


type Props = {}
export const DatasetOverviewPage: React.FC<Props> = observer(() => {
    const {bagStore, dataIngestionStore, p} = useStores();
    const routeMatch = useRouteMatch<JobRouteMatch>();
    const bagId = bagStore.bagId;
    const loading = dataIngestionStore.datasetList === undefined;
    const noData = dataIngestionStore.datasetList?.length === 0;
    const manyData = dataIngestionStore.datasetList && dataIngestionStore.datasetList.length > 9;
    const error = dataIngestionStore.datasetListManager.error;

    const history = useHistory();
    useEffect(() => {
        dataIngestionStore.datasetListManager.request()
    }, [dataIngestionStore, p])

    const uploadButton = <Button
        onClick={() => history.push(generatePath(routes.data_upload))}
        color="primary"
        variant="outlined"
        endIcon={<ChevronRight/>}
    >
        Upload new dataset
    </Button>;

    const actions = <Grid container className="dataset-overview-actions" justifyContent="center">
        <Grid item>
            {uploadButton}
        </Grid>
    </Grid>

    return (
        <KoiFacade
            title='Data Ingestion'
            back={generateHomePath(routeMatch, {id: String(bagId)})}
            className={`data-ingestion data-overview-page${noData ? ' no-data' : ''}`}
            next={manyData
                ? {type: 'component', node: uploadButton}
                : undefined
            }>
            {error
                ? <Grid container justifyContent="center" className="error-alert-container">
                    <Grid item>
                        <Alert
                            severity="error"
                            action={<IconButton
                                size="small"
                                onClick={() => dataIngestionStore.datasetListManager.request()}
                                disabled={dataIngestionStore.datasetListManager.busy}>
                                <Refresh/>
                            </IconButton>
                            }>
                            {dataIngestionStore.datasetListManager.error}
                        </Alert>
                    </Grid>
                </Grid>
                : loading
                    ? <LoadingSpinnerPie/>
                    : noData
                        ? actions
                        : <>

                            <Grid container justifyContent="center" className="dataset-overview-table-titles">
                                <Grid item xs={2}>
                                    <Typography variant="h6" sx={{mb: 2}}>Dataset name</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography variant="h6" sx={{mb: 2}}>Dataset status</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography variant="h6" sx={{mb: 2}}>Upload date</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                </Grid>
                            </Grid>

                            <Grid container justifyContent="center" className="dataset-overview-table-content">
                                {dataIngestionStore.datasetList?.map((dataset) => (
                                    <Grid container justifyContent="center" key={'dataset' + dataset.id}>
                                        <Grid item xs={2}>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography variant="body1" sx={{mb: 2}}>{dataset.name}</Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography variant="body1"
                                                        sx={{mb: 2}}>{dataset.current_status}</Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography variant="body1" sx={{mb: 2}}>{dataset.date}</Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Button
                                                color="primary"
                                                variant="outlined"
                                                startIcon={<ChevronRight/>}
                                                sx={{mb: 2}}
                                                onClick={() => history.push(generatePath(routes.data_upload_mapping, {dataFileId: String(dataset.id)}))}
                                            >
                                            </Button>
                                        </Grid>
                                        <Grid item xs={2}>
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>

                            {actions}

                        </>
            }
        </KoiFacade>
    )
});
