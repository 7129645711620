import {makeAutoObservable} from "mobx";
import MithraMaterializedApi from "../../services/MithraMaterializedApi";
import {ContractAnalyticsStore} from "./ContractAnalyticsStore";
import {ContractInsightsKpiSerializer} from "../../services/classes/ContractClasses";
import ProfileStore from "../ProfileStore";
import {TypedSearchManager} from "../managers/TypedSearchManager";
import {contractInsightsData} from "../../pages/contract-insights/data/ContractInsightsData";

export type ContractInsightsData = any & {
    contract_id: string
    contract_title: string
}

export type ContractOverviewFilter = {
    search: string
}

export class ContractInsightsStore {
    // Contract upload
    datasetUploadIsLoading: boolean = false;
    errorsToShow: string[] = [];
    dataFileId: number | undefined = undefined;
    datasetName: string | undefined = undefined;

    //Overview
    contractsCount: number = 0;
    contracts: ContractInsightsData[] | undefined = undefined;
    readonly overviewFilter: ContractOverviewFilter = {search: ''};
    kpi: ContractInsightsKpiSerializer | undefined = undefined;

    //Modals
    isModalContractsDetailsOpen: boolean = false;
    modalContractsDetailsData: ContractInsightsData | undefined = undefined;
    isModalEditContractsOpen: boolean = false;
    modalContractsEditData: ContractInsightsData | undefined = undefined;
    isModalFilterContractsOpen: boolean = false;

    selectedContractDetails: ContractInsightsData = {};

    ppvSearchManager = new TypedSearchManager(
        this.profile.supplierSearchSpecification.options,
        () => this.onSearch(),
        this.profile.supplierSearchSpecification.defaultIndex
    );

    //Contract analytics
    readonly contractAnalytics: ContractAnalyticsStore = new ContractAnalyticsStore(this, this.api);


    constructor(
        private api: MithraMaterializedApi,
        private profile: ProfileStore,
    ) {
        makeAutoObservable(this);
    }

    async handleFileChange() {
        console.log('doing stuff 0')
        this.setDatasetUploadLoading(true);


        // Fake it for now
        setTimeout(() => {
            this.setDatasetUploadLoading(false);
            this.setDataFileId(10)
            this.resetErrorToShow()
            this.setContracts(contractInsightsData)
            console.log("Fake API call completed.");
        }, 4000);


        // if (e.target.files) {
        //     this.setDatasetUploadLoading(true);
        //
        //     const file = e.target.files[0];
        //
        //     if (this.datasetName == null || this.datasetName.trim() === '') {
        //         this.setDatasetName(file.name);
        //     }
        //     console.log('doing stuff 1')
        //
        //
        //     // Fake it for now
        //     setTimeout(() => {
        //         this.setDatasetUploadLoading(false);
        //         this.setDataFileId(-1)
        //         this.resetErrorToShow()
        //         this.setContracts(contractInsightsData)
        //         // this.navigateToPage('data_mapping')
        //         console.log('doing stuff 2')
        //     }, 800);
        // }
    };

    // async uploadDatafile(file: File) {
    //     const data = await this.dataIngestionApi.uploadDatafile(file, this.datasetName).then((data) => {
    //         console.log('File uploaded! ' + data.id);
    //         this.setDataFileId(data.id)
    //         this.resetErrorToShow();
    //         return data
    //     }).catch((error) => {
    //         console.error('Error uploading file!!!');
    //         console.error(error);
    //         this.resetErrorToShow();
    //         this.pushErrorToShow("Error uploading file")
    //         throw error;
    //     })
    //     return data.id
    // }

    resetErrorToShow() {
        this.errorsToShow = [];
    }

    pushErrorToShow(error: string) {
        this.errorsToShow.push(error);
    }

    setDataFileId(dataFileId: number) {
        this.dataFileId = dataFileId;
    }

    setDatasetName(datasetName: string) {
        this.datasetName = datasetName;
    }

    setDatasetUploadLoading(isLoading: boolean) {
        this.datasetUploadIsLoading = isLoading;
    }

    editContractOnSave() {
        //Call Api to save
        console.log('editContractOnSave');
    }

    onSearch() {
        console.log('onSearch');
    }

    setContracts(contracts: ContractInsightsData[]) {
        this.contracts = contracts;
    }

    toggleDetailModal() {
        this.isModalContractsDetailsOpen = !this.isModalContractsDetailsOpen;
    }

    setDetailModalData(part: ContractInsightsData) {
        this.modalContractsDetailsData = part
    }

    toggleEditModal() {
        this.isModalEditContractsOpen = !this.isModalEditContractsOpen;
    }

    setEditModalData(part: ContractInsightsData) {
        this.modalContractsEditData = part
    }

}
