import React from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../../../stores";
import {Checkbox, Chip, TableRow} from "@mui/material";
import {CurrencyComponent} from "../../../../../components/currency-component/CurrencyComponent";
import {PartCategorizationComponent} from "../../../../../components/categorization/PartCategorizationComponent";
import {PartReCategorizationComponent} from "../../../../../components/categorization/PartReCategorizationComponent";
import {
    PartReCategorizationControllerStore
} from "../../../../../components/categorization/PartReCategorizationControllerStore";
import {environment} from "../../../../../env";
import {ReviewChoice} from "../../../../../services/classes/MaterializedClasses";
import {getSpecificCategorization} from "../../../../../services/classes/MatReviewHelpers";
import {getCatReviewSubColumns} from "../columns";
import {MithraTableCell} from "../../../../../components/table/MithraTableCell";
import {SingleReviewButtons} from "../../approval-acc-rej/SingleReviewButtons";
import {GoogleLink} from "../../../../../components/_tiny/GoogleLink";
import {AiScore} from "../../../../../components/_tiny/AiScore";
import moment from "moment";
import {PartRowState} from "../../../classes/PartRowState";
import {getCategorizationStateClassName} from "../../../classes/CategorizationUtils";


type Props = {
    part: PartRowState
    controller?: PartReCategorizationControllerStore // TODO: Move to context
};
export const SingularCategorizationReviewRow: React.FC<Props> = observer(({part, controller}) => {
    const {p, authStore, bagStore, categorizationReviewStore} = useStores();
    const reviewPageController = categorizationReviewStore.reviewPageController;
    const reviewPageSelectionController = categorizationReviewStore.reviewPageController.reviewPageSelectionController;
    const filterController = categorizationReviewStore.reviewPageController.reviewPageFilterController;
    const viewOnly = authStore.viewOnly;
    const COLUMNS = getCatReviewSubColumns(p, categorizationReviewStore.bagId, true, viewOnly);

    const combinedStateVisual = part.combinedStateVisual;
    const editAllowed = !authStore.viewOnly && combinedStateVisual.editAllowed;

    // FIXME: taxonomySize is coming from the bagStore, not sure what is the intended behavior
    const taxonomySize = bagStore.taxonomy_size;
    const leftCats = getSpecificCategorization(part.data, taxonomySize, combinedStateVisual.leftCat);
    const rightCats = getSpecificCategorization(part.data, taxonomySize, combinedStateVisual.rightCat);

    let categoryComponent;
    let onAccept: undefined | (() => void) = undefined;
    if (!editAllowed) {
        categoryComponent = <PartCategorizationComponent cats={rightCats} isEditAllowed={editAllowed}/>
    } else {
        onAccept = () => reviewPageController.onClickAcceptRejectPart(part, ReviewChoice.ACCEPT);
        if (categorizationReviewStore.categories.taxonomy_categories) {
            categoryComponent = <PartReCategorizationComponent
                cats={rightCats}
                handleOpenSearch={event => {
                    if (!controller) {
                        console.error('No controller set');
                        return;
                    }
                    // When the category component is opened, reset the selection to avoid confusion
                    reviewPageSelectionController.onDeselectAll();

                    const anchor = event.currentTarget;
                    controller.setOnSelect(newCats => reviewPageController.onClickReCategorizePart(part, newCats))
                    controller.open(anchor)
                }}
                selectedL1Category={filterController.selectedL1Category}
            />
        } else {
            categoryComponent = <PartCategorizationComponent cats={rightCats} isEditAllowed={editAllowed}/>
        }
    }
    let i = 0;
    return <TableRow className={`${part.selected ? 'is-selected' : ''}`}>
        {!viewOnly && <MithraTableCell c={COLUMNS[i++]} component="th" scope="row">
            <Checkbox
                checked={part.selected}
                onClick={event => reviewPageSelectionController.onClickToggleSelectionSubRow(part, event.shiftKey)}
            />
        </MithraTableCell>}
        <MithraTableCell c={COLUMNS[i++]}>
            {environment.isTestReviewPage && <>
                p_id={part.data.p_id}<br/>
                {'u=' + part.data.review_user_id} ({'me=' + authStore.userId})<br/>
                review_choice={'' + part.data.review_choice}<br/>
                p_review_cat_l1={'' + part.data.p_review_cat_l1}<br/>
                isOpenToOnlyAccept={'' + part.isOpenToOnlyAccept()}<br/>
                isOpenToRecat={'' + part.isOpenToRecat()}<br/>
                {/*{JSON.stringify(partState)}<br/>*/}
            </>}
            <GoogleLink type="part_with_supplier" objs={[part.data]}>
                {part.data.s_name}
            </GoogleLink>
        </MithraTableCell>
        <MithraTableCell c={COLUMNS[i++]}>
            {environment.isTestReviewPage && <>p_id={part.data.p_id}<br/></>}
            {part.data.p_name}
        </MithraTableCell>
        <MithraTableCell c={COLUMNS[i++]}>
            <CurrencyComponent v={part.data.p_spend} nDigits={3}/>
        </MithraTableCell>
        <MithraTableCell c={COLUMNS[i++]}>
            {moment(part.data.p_ddate_1).format('L')}
        </MithraTableCell>
        {p.p.p_description_col_name !== false &&
            <MithraTableCell c={COLUMNS[i++]}>
                <GoogleLink type="part" objs={[part.data]}>
                    {part.data.p_description}
                </GoogleLink>
            </MithraTableCell>
        }

        {p.partContextColNames.map(({dataField, colType}) =>
            <MithraTableCell key={dataField} c={COLUMNS[i++]}>
                {colType === 'date'
                    ? moment(part.data[dataField]).format('L')
                    : String(part.data[dataField])}
            </MithraTableCell>
        )}

        {p.p.categorizationReviewSubRowRelationData?.flatMap(related_data_item =>
            related_data_item.columns.map(column =>
                <MithraTableCell c={COLUMNS[i++]} key={related_data_item.relation + column.db_column}>
                    {part.data[related_data_item.relation]
                        ? String(part.data[related_data_item.relation][column.db_column])
                        : '-'
                    }
                </MithraTableCell>
            )
        )}

        {p.p.show_bu_name_in_review_pages && <MithraTableCell c={COLUMNS[i++]}>
            {part.data.bu_name}
        </MithraTableCell>}
        <MithraTableCell c={COLUMNS[i++]}>
            <PartCategorizationComponent cats={leftCats} isEditAllowed={editAllowed}/>
        </MithraTableCell>
        {p.showCategorizationScoreForBag(categorizationReviewStore.bagId) &&
            <MithraTableCell c={COLUMNS[i++]}>
                <AiScore score={part.data.p_suggestion_score}/>
            </MithraTableCell>
        }
        <MithraTableCell c={COLUMNS[i++]}>
            {categoryComponent}
        </MithraTableCell>
        <MithraTableCell c={COLUMNS[i++]}>
            <Chip label={combinedStateVisual.approvalState.replace('_', ' ')}
                  className={'chip ' + getCategorizationStateClassName(combinedStateVisual.approvalState)}/>
        </MithraTableCell>
        <MithraTableCell c={COLUMNS[i++]}>
            {rightCats !== false && rightCats.every(cat => cat === '') ? null : (
                // part.combinedStateLoading ?
                //     <CircularProgress size={20}/>
                //     : <SingleReviewButtons onAccept={onAccept} state={combinedStateVisual}/>
                <SingleReviewButtons onAccept={onAccept} state={combinedStateVisual} editAllowed={editAllowed}
                                     part={part}/>
            )}
        </MithraTableCell>
    </TableRow>
})
