import React, {useEffect} from "react";
import {useLocation} from "react-router";
import {environment} from "../../env";
import ReactGA from "react-ga4";
import {useRouteMatch} from "react-router-dom";

export const AnalyticsCollector: React.FC = ({children}) => {
    const location = useLocation();
    const routeMatch = useRouteMatch();
    useEffect(() => {
        if (location) {
            const page = location.pathname + location.search;
            const title = routeMatch.url;
            console.log('AnalyticsCollector', page, title);
            if (environment.isGA) ReactGA.send({hitType: 'pageview', page, title});
        }
    }, [location]);
    return <>{children}</>;
}