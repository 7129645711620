import {MatPartReviewRow} from "./MaterializedClasses";
import {PickAndOptional, Range} from "../../utils/ts-utils";
import {range} from "d3";

type LS = 'l1' | 'l2' | 'l3' | 'l4' | 'l5' | 'l6' | 'l7' | 'l8';

export type MatSearch = {
    supplier?: string
}

type Ls = `l${Range<1, 9>}` // Excludes end range
export type LevelSpecObject = Record<Ls, string>

/**
 Filter that retrieves a single node

 Can directly be used as a filter to the BE
 */
export type TreeNodeFilter = {
    type: 'node',
    databag: number
    level: number,
    l1: string, l2: string, l3: string, l4: string,
    l5: string, l6: string, l7: string, l8: string,
}
/**
 * Filter that retrieves all the children under a specific node
 *
 * Will send level + 1 as filter to the BE
 */
export type TreeChildrenFilter = {
    type: 'children',
    databag: number
    level: number,
    l1: string, l2: string, l3: string, l4: string,
    l5: string, l6: string, l7: string, l8: string,
}
export type AbsTreeNodeFilter = TreeNodeFilter | TreeChildrenFilter

export function filterOnRoot(databag: number) {
    return {type: 'node', level: 0, databag, l1: ''} as TreeNodeFilter
}

export type MatSupplierFilter = {
    databag: number
    business_unit: null | number | undefined
    search?: MatSearch
    level: number,
    l1: string, l2: string, l3: string, l4: string,
    l5: string, l6: string, l7: string, l8: string,
    /**
     * Only show suppliers that have been send for approval
     */
    approval?: number
}

export function getSupplierFilerLevelValues(f: MatSupplierFilter): string[] {
    return range(f.level).map(l => f[`l${l + 1}`])
}
export type MatPartFilter = {
    databag: number
    urlParams?: URLSearchParams
}
export type MatSupplierFilter_V2 = {
    databag: number
    business_unit: null | number | undefined
    search?: MatSearch
    /**
     * TODO: Doc required
     */
    filterLevel: number,

    /**
     * TODO: Doc required
     * Move elements below to a single class or array...
     */
    fixLevels?: number | undefined,
    l1: string, l2: string, l3: string, l4: string,
    l5: string, l6: string, l7: string, l8: string,
}

export type StoreReviewSerializer = PickAndOptional<MatPartReviewRow, 'review_choice', `p_review_cat_${LS}`>
export type StorePartFeedbackSerializer = Pick<MatPartReviewRow, 'feedback_choice'>

export type StorePartReviewManySerializer = {
    parts: number[]
} & StoreReviewSerializer
export type StorePartFeedbackManySerializer = {
    objects: number[]
} & StorePartFeedbackSerializer

export type DownloadTableRequest = {
    databag: number
    filename: string
    fields: string[]
    headers: string[]
    currency_symbol: string
    currency_fields: string[]
}
