import './Merge2DashBoard.scss';
import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../stores";
import {DashboardHeader} from "../../dashboard/DashboardHeader";
import {environment} from "../../../env";
import {AppState} from "../../../stores/RootAppStore";
import {Merge2CleanupDashboard} from "./Merge2CleanupDashboard";
import {Merge2AnalyticsDashboard} from "./Merge2AnalyticsDashboard";
import {Merge2MasterDashboard} from "./Merge2MasterDashboard";
import {Merge2MergeCombinedDashboard} from "./Merge2MergeCombinedDashboard";
import {Merge2MergeDashboard} from "./Merge2MergeDashboard";


export const LANDING_ENVIRONMENT = 'cleanup' as const;

function getDashboardComponent(app: AppState): JSX.Element {
    if (app.dataType === 'master') {
        return <Merge2MasterDashboard/>
    }
    const env = app.dashboardEnvironment || LANDING_ENVIRONMENT;
    switch (env) {
        case 'cleanup':
            return <Merge2CleanupDashboard/>;
        case 'analytics':
            return <Merge2AnalyticsDashboard/>;
        case 'merge':
            if (environment.dashboardVariant === 'simple')
                return <Merge2MergeCombinedDashboard/>;
            return <Merge2MergeDashboard/>;
    }
    console.error('Cannot determine dashboard environment from environment', env);
    return <></>
}

/**
 * Dashboard that uses only merge-1 links, without any databags in the url
 */
export const Merge2Dashboard: React.FC = observer(() => {
    const {
        rootAppStore,
        bagStore,
        cmpStore,
        baseSupplierNormalizationStore,
        masterSupplierNormalizationStore,
    } = useStores();
    const baseBagId = bagStore.bag?.id;
    const masterBagId = cmpStore.masterBag?.id;
    useEffect(() => {
        if (baseBagId && masterBagId) {
            masterSupplierNormalizationStore._postStatsRequestManager.request({databag: masterBagId})
            baseSupplierNormalizationStore._postStatsRequestManager.request({databag: baseBagId})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [baseBagId, masterBagId])
    return <>
        <DashboardHeader/>
        {getDashboardComponent(rootAppStore.app)}
    </>
});
