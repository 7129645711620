import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../../stores";
import {Grid} from "@mui/material";
import {DashboardRow} from "../../../../components/dashboard/DashboardRow";
import {DASHBOARD_CONSTANTS, drawOpportunityValue} from "../../../../components/dashboard/Dashboard.utils";
import {DashBoardCard} from "../../../dashboard/DashBoardCard";
import {SupplierNormalizationV2Icon} from "../../../../components/icons/SupplierNormalizationV2Icon";
import {generatePath} from "react-router";
import {routes} from "../../../../routing/routes";
import {CurrencyComponent} from "../../../../components/currency-component/CurrencyComponent";
import {ClassificationHierarchyIcon} from "../../../../components/icons/ClassificationHierarchyIcon";
import {ClassificationIcon} from "../../../../components/icons/ClassificationIcon";
import {environment} from "../../../../env";
import {Bag} from "../../../../services/classes/Bag";

/**
 * Dashboard that uses only merge-1 links, without any databags in the url
 */
export const MergeXCleanupDashboard: React.FC = observer(() => {
    const {
        p,
        bagStore,
        cmpStore,
        categorizationReviewStore,
        baseSupplierNormalizationStore,
        masterSupplierNormalizationStore,
        supplierNormalizationStore,
        taxonomyManagerStore,
    } = useStores();
    const bagId = bagStore.bagId;
    const bag = bagStore.bag as Bag;
    const pendingSupplierNormalizationMaster = masterSupplierNormalizationStore.postKeyValues?.total_standalone_suppliers_spend;
    const pendingSupplierNormalizationBase = baseSupplierNormalizationStore.postKeyValues?.total_standalone_suppliers_spend;
    const masterCommonKpi = cmpStore.masterKpi;
    const baseCommonKpi = bagStore.kpi.common;
    const unclassifiedEgoSpend = categorizationReviewStore.summaryResultKeyValues?.after.unclassified_spend;
    const pendingSupplierNormalization = supplierNormalizationStore.postKeyValues?.total_standalone_suppliers_spend;

    // Temporary fix for CAT-824
    // TODO: CAT-888
    let isLoadingHack824 = false;
    let numberOfSuppliers = bagStore.kpi.common?.n_suppliers;
    if (p.p.hackNSuppliersFromCommonSuppliers) {
        isLoadingHack824 = supplierNormalizationStore._postStatsRequestManager.busy;
        numberOfSuppliers = supplierNormalizationStore.postKeyValues?.total_suppliers_count;
    }

    const baseBagId = bagStore.bag?.id;
    const masterBagId = cmpStore.masterBag?.id;
    useEffect(() => {
        if (baseBagId && masterBagId) {
            masterSupplierNormalizationStore._postStatsRequestManager.request({databag: masterBagId})
            baseSupplierNormalizationStore._postStatsRequestManager.request({databag: baseBagId})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [baseBagId, masterBagId])

    return <>
        {environment.isTest && <h1>MergeXCleanupDashboard</h1>}
        <Grid container
              className="dashboard merge-x-dashboard cleanup-dashboard"
              justifyContent="space-evenly"
              spacing={0}>
            <DashboardRow
                title="Suppliers"
                className="suppliers-row"
                // subtitle={bagStore.kpi.common
                //     ? String(bagStore.kpi.common.n_suppliers)
                //     : DASHBOARD_CONSTANTS.SUBTITLE_LOADING}
                // Temporary fix for CAT-824 (from SHV)
                // TODO: CAT-888
                subtitle={numberOfSuppliers && !isLoadingHack824
                    ? String(numberOfSuppliers)
                    : DASHBOARD_CONSTANTS.SUBTITLE_LOADING}
            >
                <DashBoardCard
                    cardKey="supplier-normalization"
                    avatar={<SupplierNormalizationV2Icon/>}
                    title="Supplier Normalization"
                    value={supplierNormalizationStore.supplierNormalizationKpi !== undefined ? supplierNormalizationStore.supplierNormalizationKpi : DASHBOARD_CONSTANTS.VALUE_LOADING}
                    subtitle="suppliers"
                    link={generatePath(routes.merge_x_cleanup_supplier_normalization, {id: bagId})}
                    badge="Ai"
                    // isTrial={true}
                />
            </DashboardRow>
            <DashboardRow
                title="Categories"
                className="categories-row"
                // subtitle={bagStore.kpi.common
                //     ? String(bagStore.kpi.common.n_categories)
                //     : DASHBOARD_CONSTANTS.SUBTITLE_LOADING}

                //From SHV
                subtitle={bagStore.kpi.common && !isLoadingHack824
                    ? String(bagStore.kpi.common.n_categories)
                    : DASHBOARD_CONSTANTS.SUBTITLE_LOADING}>
                <DashBoardCard
                    cardKey="improve-cats"
                    avatar={<ClassificationIcon/>}
                    title="Improve Categorization"
                    value={drawOpportunityValue(unclassifiedEgoSpend)}
                    valueColor={unclassifiedEgoSpend
                        ? (unclassifiedEgoSpend >= DASHBOARD_CONSTANTS.OPPORTUNITY_THRESHOLD) ? 'red' : 'green' : 'gray'}
                    subtitle="uncategorized"
                    link={generatePath(routes.merge_x_cleanup_categorization, {id: bagId})}
                    badge="Ai"
                />
            </DashboardRow>
            <DashboardRow
                title="Spend"
                className="spend-row"
                // subtitle={bagStore.kpi.common
                //     ? <CurrencyComponent v={bagStore.kpi.common.total_spend} nDigits={3}/>
                //     : DASHBOARD_CONSTANTS.SUBTITLE_LOADING}

                // From SHV
                subtitle={bagStore.kpi.common && !isLoadingHack824
                    ? <CurrencyComponent v={bagStore.kpi.common.total_spend} nDigits={3}/>
                    : DASHBOARD_CONSTANTS.SUBTITLE_LOADING}>
                <DashBoardCard
                    cardKey="taxonomy-builder"
                    avatar={<ClassificationHierarchyIcon/>}
                    title="Taxonomy Viewer"
                    subtitle={taxonomyManagerStore.taxonomy?.name}
                    link={generatePath(routes.merge_x_cleanup_taxonomy_viewer, {id: bagId})}
                />
            </DashboardRow>
        </Grid>
    </>
});
