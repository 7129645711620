import {makeAutoObservable} from "mobx";
import {ContractInsightsData} from "../../stores/contract/ContractInsightsStore";
import React from "react";

export class ContractRowState {
    open = false;
    isLoadingRows = false;
    page = 1
    pageSize = 15;

    get id(): number {
        return this.data.id;
    }

    constructor(
        public loading: boolean,
        public currentPageNumber: number,
        public itemStates: any[] | undefined,
        public data: ContractInsightsData,
    ) {
        makeAutoObservable(this)
    }

    public static build(data: ContractInsightsData, pageNumber: number): ContractRowState {
        return new ContractRowState(false, pageNumber, undefined, data);
    }

    public setOpen(open: boolean) {
        this.open = open;
    }

    public setIsLoading(isLoading: boolean) {
        this.isLoadingRows = isLoading;
    }
}

export type ModalCardBlockType = { blockTitle: string, bulletPoints: string[] }
export type ModalCardProps = {
    title: string;
    icon: React.ReactNode;
    blocks: ModalCardBlockType[];
}

export type ModalEditCardProps = {
    title: string,
    contractTitle: string,
    icon: React.ReactNode,
    parentSupplier: string,
    sisterCompanies: string[],
    startDate: string,
    endDate: string,
    scope: string,
}