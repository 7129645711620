import {makeAutoObservable, reaction, toJS} from "mobx";
import ProfileStore from "../ProfileStore";
import MithraMaterializedApi from "../../services/MithraMaterializedApi";
import AuthStore from "../AuthStore";
import {BagStore} from "../BagStore";
import {
    CategorizationReviewPageController
} from "../../pages/categorization/controllers/CategorizationReviewPageController";
import {AiJobDelegate} from "./AiJobDelegate";
import {TaxonomyCategoryProvider} from "../TaxonomyCategoryProvider";
import {ApprovalStore} from "../ApprovalStore";
import {
    CategorizationStatisticsDashboardController
} from "../../pages/categorization/components/statistics/CategorizationStatisticsDashboardController";
import {PartRowState} from "../../pages/categorization/classes/PartRowState";

/**
 * CategorizationReviewStore:
 * - Starting point for initialization and loading
 * - Exposes simple KPI's that can be used throughout the application
 */
export class CategorizationReviewStore {
    readonly aiJob = new AiJobDelegate(this.api, this, this.auth)
    readonly categories = new TaxonomyCategoryProvider(this.profile, this.api);
    readonly reviewPageController = new CategorizationReviewPageController(this, this.approvalStore, this.api, this.auth, this.profile)
    readonly statisticsDashboardController = new CategorizationStatisticsDashboardController(this.profile, this.api);
    isDetailModalOpen: boolean = false;
    detailModalData: PartRowState | undefined = undefined;

    _desiredBagId: number | null = null
    _desiredTaxonomySize: number | null = null

    constructor(
        private approvalStore: ApprovalStore,
        private auth: AuthStore,
        private bagStore: BagStore,
        private api: MithraMaterializedApi,
        private profile: ProfileStore,
    ) {
        makeAutoObservable(this)

        // Setup links that have dependencies in both directions
        this.approvalStore.categorizationApproval.categorizationStore = this;

        // TODO: Consider moving to reviewPageController?
        reaction(() => [
            this.bagId,
            this.reviewPageController.reviewPageFilterController.filteredBusinessUnitId,
            this.reviewPageController.singleMode,
        ] as const, ([bagId, filteredBusinessUnitId, singleMode]) => {
            console.debug('CategorizationReviewStore: change', {bagId, filteredBusinessUnitId, singleMode});
            this.reviewPageController.loadData(false)
        })

        reaction(() => [this.bagId, this.bagStore.bu.selectedBusinessUnitId] as const, ([, businessUnitId]) => {
            this.reviewPageController.reviewPageFilterController.setFilteredBusinessUnitId(businessUnitId)
        })
        // // TODO: Consider moving to reviewPageController?
        // reaction(() => [this.bagId, this.taxonomySize] as const, ([bagId, taxonomySize]) => {
        //     console.log('CategorizationStore Triggering change of bagId', bagId);
        //     if (bagId && taxonomySize) {
        //         this.reviewPageController.setRequestBagId(bagId)
        //         this.reInitialize(bagId, taxonomySize)
        //     }
        // });
    }

    get bagId(): number {
        return this._desiredBagId || -1;
    }

    /**
     * This denotes how deep we can navigate in the taxonomy and how deep we load in the statistics
     *
     * Initially it must be set, so we pick 3, but later this must will updated when the bag is known
     * TODO[integration]: Move max level to profile store or get it from the bag
     */
    get taxonomySize(): number {
        return this._desiredTaxonomySize || -1;
    }

    reInitializeWithTaxonomy(bagId: number, taxonomyId: number, taxonomySize: number) {
        console.log('CategorizationStore.CategorizationReviewPageController.reInitialize', toJS(({bagId, taxonomySize})));

        this.categories.fillCategoriesFromTaxonomy(taxonomyId)

        this.aiJob.initializeAiJobDelegate();
        this.reviewPageController.reviewPageFilterController.resetFilter(undefined);
        this._desiredBagId = bagId || null;
        this._desiredTaxonomySize = taxonomySize;
        this.reviewPageController.reviewPageStatisticsController.reset()
        this.reviewPageController.reviewPageSelectionController.lastSelected = undefined;

        const businessUnitId = this.reviewPageController.reviewPageFilterController.filteredBusinessUnitId;
        this.reviewPageController.reviewPageStatisticsController.request(this.bagId, businessUnitId, taxonomySize)
    }

    reInitializeForApprovalV3(approval: number, bagId: number, taxonomySize: number) {
        console.log('reInitializeForApprovalV3', {approval, bagId, taxonomySize});
        console.log('CategorizationStore reInitializeForApprovalV3', {approval, bagId, taxonomySize});

        this._desiredBagId = bagId;
        this._desiredTaxonomySize = taxonomySize;
        this.reviewPageController.reviewPageStatisticsController.reset()
        this.reviewPageController.reviewPageSelectionController.lastSelected = undefined;
        this.reviewPageController.reviewPageFilterController.resetFilter(undefined);

        this.reviewPageController.setSingleMode(false);
        this.reviewPageController.resetAndRequestSupplierPage();
    }

    reloadView(initialLoad = false) {
        this.reviewPageController.loadData(initialLoad)
    }

    get summaryResultKeyValues() {
        // Helper function to expose the same key results everywhere
        return this.reviewPageController.reviewPageStatisticsController.summaryResultKeyValues;
    }

    /**
     * Is loading the parts table (or grouped parts)
     */
    get isLoading(): boolean {
        // Helper function to expose the same key results everywhere
        return this.reviewPageController.reviewPageDataController.isLoadingParts
    }

    toggleDetailModal() {
        this.isDetailModalOpen = !this.isDetailModalOpen;
    }

    setDetailModalPart(part: PartRowState) {
        this.detailModalData = part
    }
}
