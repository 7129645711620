import './CommonSuppliers.scss';
import React from "react";
import {observer} from "mobx-react-lite";
import {useRouteMatch} from "react-router-dom";
import {useStores} from "../../stores";
import {KoiFacade} from "../../components/koi-facade/KoiFacade";
import {environment} from "../../env";
import {generateHomePath} from "../../routing/routing.utils";
import {JobRouteMatch} from '../../routing/routes';
import {SynergyCommonSupplierCompare} from "../synergy_v1/common-suppliers/SynergyCommonSupplierStep";
import {useHistory} from "react-router";
import {
    CmpBreakdownSingleVisualization,
    Data
} from "../../components/visualization/cmp-breakdown-single/CmpBreakdownSingleVisualization";
import {toCurrency} from "../../components/currency-component/CurrencyClasses";
import {Grid, Typography} from "@mui/material";

export const CommonSuppliersPage: React.FC = observer(() => {
    const {p, rootAppStore, bagStore, synergyStore} = useStores();
    const bagId = bagStore.bagId;
    const history = useHistory();
    const routeMatch = useRouteMatch<JobRouteMatch>();
    const areValidCmpBreakdownData = synergyStore.commonSuppliersDelegate.numberTotalSuppliersRight !== -1 &&
        synergyStore.commonSuppliersDelegate.numberCommonSuppliersRight !== -1 &&
        synergyStore.commonSuppliersDelegate.totalSuppliersSpendRight !== -1 &&
        synergyStore.commonSuppliersDelegate.commonSuppliersSpendRight !== -1 &&
        synergyStore.commonSuppliersDelegate.numberTotalSuppliersLeft !== -1 &&
        synergyStore.commonSuppliersDelegate.numberCommonSuppliersLeft !== -1 &&
        synergyStore.commonSuppliersDelegate.totalSuppliersSpendLeft !== -1 &&
        synergyStore.commonSuppliersDelegate.commonSuppliersSpendLeft !== -1;


    const dataSuppliersRight: Data = {
        top: {
            id: "1",
            total: synergyStore.commonSuppliersDelegate.numberTotalSuppliersRight,
            value: synergyStore.commonSuppliersDelegate.numberCommonSuppliersRight,
            valueTitle: synergyStore.commonSuppliersDelegate.numberCommonSuppliersRight.toString(),
            valueTitleHover: synergyStore.commonSuppliersDelegate.numberCommonSuppliersRight.toString(),
        },
        bottom: {
            id: "2",
            total: synergyStore.commonSuppliersDelegate.totalSuppliersSpendRight,
            value: synergyStore.commonSuppliersDelegate.commonSuppliersSpendRight,
            valueTitle: toCurrency(p.currencySymbol, synergyStore.commonSuppliersDelegate.commonSuppliersSpendRight, 3),
            valueTitleHover: toCurrency(p.currencySymbol, synergyStore.commonSuppliersDelegate.commonSuppliersSpendRight, 3,),
        },
    };


    const dataSuppliersLeft: Data = {
        top: {
            id: "1",
            total: synergyStore.commonSuppliersDelegate.numberTotalSuppliersLeft,
            value: synergyStore.commonSuppliersDelegate.numberCommonSuppliersLeft,
            valueTitle: synergyStore.commonSuppliersDelegate.numberCommonSuppliersLeft.toString(),
            valueTitleHover: synergyStore.commonSuppliersDelegate.numberCommonSuppliersLeft.toString(),
        },
        bottom: {
            id: "2",
            total: synergyStore.commonSuppliersDelegate.totalSuppliersSpendLeft,
            value: synergyStore.commonSuppliersDelegate.commonSuppliersSpendLeft,
            valueTitle: toCurrency(p.currencySymbol, synergyStore.commonSuppliersDelegate.commonSuppliersSpendLeft, 3),
            valueTitleHover: toCurrency(p.currencySymbol, synergyStore.commonSuppliersDelegate.commonSuppliersSpendLeft, 3,),
        },
    };

    const optionsLeft = {
        width: 1000,
        height: 500,
        margin: {top: 50, left: 0, bottom: 50, right: 0},
        alignRight: true,
        fixedWidth: false,
        barPortion: 0.5,
        flowStrokeWidth: 0,
        hoverVisible: false,
        defaultWideMargin: true,
    }

    const optionsRight = {
        width: 1000,
        height: 500,
        margin: {top: 50, left: 0, bottom: 50, right: 0},
        alignRight: false,
        fixedWidth: false,
        barPortion: 0.5,
        flowStrokeWidth: 0,
        hoverVisible: false,
        defaultWideMargin: true,
    }

    return <KoiFacade
        title='Common Suppliers'
        intro={<></>}
        onBack={() => history.push(generateHomePath(routeMatch, {id: String(bagId)}, rootAppStore.app))}
        backLabel='Home'
        className="sup-norm-page">

        {<Grid container justifyContent="space-evenly" className="supplier-normalization-stepper">

            {areValidCmpBreakdownData && (environment.package === 'merge_2' || environment.package === 'merge_x') &&
                <Grid container justifyContent="center" style={{marginTop: '2em', marginBottom: '2em'}}>
                    <Grid item md={2}></Grid>
                    <Grid item md={3}>
                        <Typography variant="h5">{synergyStore.datasetNameLeft}</Typography>
                    </Grid>
                    <Grid item md={2}></Grid>
                    <Grid item md={3}>
                        <Typography variant="h5">{synergyStore.datasetNameRight}</Typography>
                    </Grid>
                    <Grid item md={2}></Grid>

                    <Grid item md={2}></Grid>
                    <Grid item md={3}>
                        <CmpBreakdownSingleVisualization
                            data={dataSuppliersLeft}
                            options={optionsLeft}
                        />
                    </Grid>
                    <Grid item md={2}></Grid>
                    <Grid item md={3}>
                        <CmpBreakdownSingleVisualization
                            data={dataSuppliersRight}
                            options={optionsRight}
                        />
                    </Grid>
                    <Grid item md={2}></Grid>
                </Grid>}
        </Grid>}

        <SynergyCommonSupplierCompare/>

    </KoiFacade>
});
