/* eslint-disable @typescript-eslint/no-unused-vars */
import {AppConfiguration} from "./app-configuration-types";
export const C: AppConfiguration = {
    package: 'sales_demo',
    dashboardVariant: '?',
    menu: '?',
    profile: {
        p_name_col_name: 'Name',
        p_description_col_name: 'Description',
        sankeyMinHeight: 250,
        sankeyMaxHeight: 500, // What fits on screen
        locale: 'en-us',
        currency: 'EUR',
        taxonomy_builder_only_n_cats: false,
        allowIncompleteTaxonomy: true,
        enableOpportunityTracker: true,

        hardcodedTaxonomySuggestionId: 1,
        hardcodeMasterBagId: 99110,
        hardcodedCombinedBagId: 99110,

        useAiTaxonomyHealthCheck: true,

        taxonomyBuilderHeight: 1500,

        showSupplierIdInSupplierNormalization: true,
        demoFakeDataIngestion: true,
        demoFakeGoldenRecords: true,
        demoFakeDataIngestionTarget: {
            databagId: 99101,
        },
        demoFakeReinforcedCategorization: true,
        demoFakeReinforcedCategorizationTarget: {
            databagId: 99110,
            taxonomyId: 1,
            taxonomySize: 3,
        },
        demoFakeSendTaxonomyForApprovalTarget: {
            databagId: 99110,
            taxonomyId: 1,
            taxonomySize: 3,
        },
        masterTaxonomyId: 400,
        hardcodedBaseTaxonomyId: 1,
        demoFakeBusinessCaseGenerator: true,

        demoAltTaxonomyFields: true,
    },
}
